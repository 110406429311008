/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';

// kendo react modules ==========================
import '@progress/kendo-theme-material/dist/all.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { orderBy } from '@progress/kendo-data-query';

// import demodata =============================
import reservation from 'data/SelectEducatorData.json';

import { Link } from 'react-router-dom';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

import * as url from 'urlhelpers/UrlHelper';
import { getData, putData } from 'utils/Gateway';

//import context
import { GlobalProvider } from 'context/GlobalContext';
import { assetImages } from 'constants';

const EducatorSelectionPopup = () => {
  const {
    setReloadReservationList,
    changeReserveEducatorData,
    setChangeReserveEducatorData,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const token = localStorage.getItem('token');
  const [userList, setUserList] = useState([]); //educator option
  const [responseReceived, setResponseReceived] = useState(false);

  const [showWarning, setShowWarning] = useState(false);
  const [prevEducatorId, setPrevEducatorId] = useState(null);

  const { t } = useTranslation(); //for translation
  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(userList, initialDataState));

  const onDataStateChange = event => {
    console.log('event -->', event);
    setDataState(event.dataState);
    // Apply sorting
    const sortedData = orderBy(userList, event.dataState.sort);
    setResult(process(sortedData, event.dataState));
  };

  //function for get all user list
  const getALlEducatorList = async () => {
    try {
      setShowWarning(false);
      if (changeReserveEducatorData != null) {
        let requestUrl =
          url.API_BaseUrl +
          url.GET_ALL_EDUCATOR +
          `?token=${token}&date=${changeReserveEducatorData.startdate}&starttime=${changeReserveEducatorData.starttime}&endtime=${changeReserveEducatorData.endtime}&activitycode=${changeReserveEducatorData.activitycode}`;

        const response = await getData(requestUrl);

        console.log('list of educatros -->', response);

        if (response.status) {
          setUserList(response.data);
          setResponseReceived(true);
          setResult(process(response.data, initialDataState));
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // Educator 1 Block
  const educatorListblockHTML = props => {
    return (
      <td className="educator">
        {/* ----- educator name section start ------ */}
        <p className="d-flex gap-2 align-items-center text-nowrap">
          <span className="d-block icon-user">
            <img
              src={
                props.dataItem.photoimage
                  ? props.dataItem.photoimage.path
                  : assetImages.defaultUser
              }
              alt=""
            />
          </span>
          {props.dataItem.name && props.dataItem.surname ? (
            <span className="d-block">
              {props.dataItem.name} {props.dataItem.surname}
            </span>
          ) : (
            <span className="d-block">{props.dataItem.email}</span>
          )}
        </p>

        {/* ----- educator name section end ------ */}
      </td>
    );
  };

  const customAvailabilityHTML = props => {
    return (
      <td className="availability">
        {props.dataItem.availability ? (
          <p className="yes">
            <span className="logged-in">●</span> Yes
          </p>
        ) : (
          <p className="no">
            <span className="logged-out">●</span> No
          </p>
        )}
      </td>
    );
  };

  // const customFieldHTML = () => {
  //   return (
  //     <>
  //       <td className="feedback">
  //         <div className="d-flex align-items-center">
  //           <span className="d-block material-symbols-outlined icon-md icon-fill text-warning">
  //             star
  //           </span>
  //           <span className="d-block material-symbols-outlined icon-md icon-fill text-warning">
  //             star
  //           </span>
  //           <span className="d-block material-symbols-outlined icon-md icon-fill text-warning">
  //             star
  //           </span>
  //           <span className="d-block material-symbols-outlined icon-md">
  //             star
  //           </span>
  //           <span className="d-block material-symbols-outlined icon-md">
  //             star
  //           </span>
  //         </div>
  //       </td>
  //     </>
  //   );
  // };

  //action button html
  const customActionHTML = props => {
    return (
      <>
        <td className="action">
          <Link
            to="#"
            className="btn btn-outline-primary btn-sm mw-unset"
            onClick={() => {
              changeEducatorHandler(props.dataItem);
            }}
          >
            {t('Select')}
          </Link>
        </td>
      </>
    );
  };

  //function for change educator
  const changeEducatorHandler = async educator => {
    if (changeReserveEducatorData) {
      if (educator.availability) {
        setPrevEducatorId(null);
        setShowWarning(false);
        try {
          let educatorData = {
            preveducator: changeReserveEducatorData.prevEducatore,
            neweducator: educator._id,
            activitycodename: changeReserveEducatorData.activitycodename,
            starttime: changeReserveEducatorData.starttime,
            endtime: changeReserveEducatorData.endtime,
            startdate: changeReserveEducatorData.startdate,
            reserveid: changeReserveEducatorData.reserveid,
          };
          let requestUrl =
            url.API_BaseUrl +
            url.API_CHANGE_EDUCATOR +
            `/${changeReserveEducatorData.reserveId}?token=${token}`;
          console.log('Url putdata======>', requestUrl);
          const response = await putData(requestUrl, educatorData);
          console.log('Response change =========>', response);
          if (response.status) {
            let offCanvasPopup = document.querySelector('#selectEducatorPopup');
            let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
            offCanvas.hide();
            setChangeReserveEducatorData(null);
            setReloadReservationList(true);
            getALlEducatorList();
            setSuccess(true);
            setNotificationString(t('Educator seleted successfully'));
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString(
            t('Something went wrong, please try again later')
          );
        }
      } else {
        setShowWarning(true);
        setPrevEducatorId(educator._id);
      }
    }
  };

  //function for change educator again
  const changeEducatorHandlerAfterWarning = async () => {
    if (changeReserveEducatorData && prevEducatorId) {
      setShowWarning(false);
      try {
        let educatorData = {
          preveducator: changeReserveEducatorData.prevEducatore,
          neweducator: prevEducatorId,
          activitycodename: changeReserveEducatorData.activitycodename,
          starttime: changeReserveEducatorData.starttime,
          endtime: changeReserveEducatorData.endtime,
          startdate: changeReserveEducatorData.startdate,
          reserveid: changeReserveEducatorData.reserveid,
        };
        let requestUrl =
          url.API_BaseUrl +
          url.API_CHANGE_EDUCATOR +
          `/${changeReserveEducatorData.reserveId}?token=${token}`;
        console.log('Url putdata======>', requestUrl);
        const response = await putData(requestUrl, educatorData);
        console.log('Response change =========>', response);
        if (response.status) {
          let offCanvasPopup = document.querySelector('#selectEducatorPopup');
          let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
          offCanvas.hide();
          setChangeReserveEducatorData(null);
          setReloadReservationList(true);
          getALlEducatorList();
          setPrevEducatorId(null);
          setSuccess(true);
          setNotificationString(t('Educator seleted successfully'));
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString(
          t('Something went wrong, please try again later')
        );
      }
    }
  };

  useEffect(() => {
    if (changeReserveEducatorData && responseReceived === false) {
      getALlEducatorList();
    }
  }, [changeReserveEducatorData, responseReceived]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="selectEducatorPopup"
      aria-labelledby="selectEducatorPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="selectEducatorPopupLabel">
          {t('Select Educator')}
        </h3>
        {/* ------- modal close button start ------ */}
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => {
            setChangeReserveEducatorData(null);
          }}
        ></button>
        {/* ------- modal close button end ------ */}
      </div>

      {/* ------ warning section start ------ */}
      {showWarning && (
        <div className="mx-4">
          <div className="alert alert-danger fade show" role="alert">
            <strong>{t('Oops!')}</strong>
            <span className="ms-1">
              {t('Educator is already booked. Are you sure to continue?')}
            </span>
            <button
              className="btn btn-primary ms-3"
              onClick={changeEducatorHandlerAfterWarning}
            >
              {' '}
              {t('Continue Anyway')}
            </button>
          </div>
        </div>
      )}
      {/* ------ warning section end ------ */}

      <div className="offcanvas-body p-4 pt-0">
        <div className="table-responsive">
          <Grid
            style={{ minWidth: '100%' }}
            data={result}
            // filterable={true}
            sortable={true}
            // groupable={true}
            onDataStateChange={onDataStateChange}
            {...dataState}
            //pageable = {true}
            //total = {products.length}
            reorderable
            // resizable
          >
            <GridColumn
              field="name"
              cell={educatorListblockHTML}
              title={t('Educator')}
              width="220px"
            />
            <GridColumn
              field="availability"
              cell={customAvailabilityHTML}
              title={t('Availability')}
            />
            <GridColumn field="rank" title={t('Rank')} />
            <GridColumn field="certificate" title={t('Certificate')} />
            <GridColumn cell={customActionHTML} title={t('Action')} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default EducatorSelectionPopup;
