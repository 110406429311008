/* eslint-disable */
import * as React from "react";
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";

import { Link } from "react-router-dom";

// getter for selection of record
import { getter } from "@progress/kendo-react-common";

// for filter ====================================
import { process } from "@progress/kendo-data-query";

import reserves from "../../data/Reserves.json";

function Datagrid() {
  // select checkbox ---------------------
  const DATA_ITEM_KEY = "ReserveId";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);

  // if we want pagination --------------------------------
  // const [dataState, setDataState] = React.useState({ skip : 0, take : 10 });
  // const [result, setResult] = React.useState(process(products, dataState));

  // without pagination ------------------------------------
  const initialDataState = {};

  //const [dataState, setDataState] = React.useState();
  const [dataState, setDataState] = React.useState(
    reserves.map((dataItem) =>
      Object.assign(
        {
          selected: false,
        },
        dataItem
      )
    )
  );

  const [selectedState, setSelectedState] = React.useState({});

  const onSelectionChange = React.useCallback(
    (event) => {
      //console.log('selectedEvent --', event.dataItem.ReserveId);

      console.log("selectedEvent --", event);

      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};

    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });

    setSelectedState(newSelectedState);
  }, []);

  React.useEffect(() => {
    console.log("selectedState -->", selectedState);
  }, [selectedState]);

  // const [result, setResult] = React.useState(process(reserves, initialDataState));

  // const onDataStateChange = (event) =>{
  //   console.log('event -->',event);
  //   setDataState(event.dataState);
  //   setResult(process(reserves, event.dataState));
  // }

  // const educatorblockHTML = (props) => {
  //   return (

  //       <td className="educator">
  //         <p className="name mb-1">{props.dataItem.Educator.EducatorName}</p>
  //         <div className="dropdown cell-dropdown">
  //           <Link className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
  //             <span className="d-block material-symbols-outlined icon-md">
  //               more_vert
  //             </span>
  //           </Link>

  //           <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
  //             <li><Link className="dropdown-item" href="#">Action</Link></li>
  //             <li><Link className="dropdown-item" href="#">Another action</Link></li>
  //             <li><Link className="dropdown-item" href="#">Something else here</Link></li>
  //           </ul>
  //         </div>
  //       </td>

  //   );
  // };

  return (
    <Grid
      //data = {result}
      data={dataState.map((item) => ({
        ...item,
        [SELECTED_FIELD]: selectedState[idGetter(item)],
      }))}
      dataItemKey={DATA_ITEM_KEY}
      selectedField={SELECTED_FIELD}
      selectable={{
        enabled: true,
        //drag: false,
        //cell: false,
        //mode: "multiple",
      }}
      onSelectionChange={onSelectionChange}
      onHeaderSelectionChange={onHeaderSelectionChange}

      // filterable = {true}
      // sortable = {true}
      // groupable = {true}
      // onDataStateChange={onDataStateChange}{...dataState}
      //pageable = {true}
      //total = {products.length}

      // reorderable
      // resizable
    >
      <GridColumn
        field={SELECTED_FIELD}
        width="50px"
        headerSelectionValue={
          dataState.findIndex((item) => !selectedState[idGetter(item)]) === -1
        }
      />

      <GridColumn field="ReserveId" title="ID" />
      <GridColumn field="Educator.EducatorName" title="Educator" />
      <GridColumn field="Code" title="Code" />
      <GridColumn field="Date" title="Date" />
      <GridColumn field="Start" title="Start Time" />
      <GridColumn field="End" title="End Time" />
      <GridColumn field="Activity.ActivityName" title="Activity" />

      <GridColumn field="Client.ClientName" title="Client" />
      <GridColumn field="Labels.LabelName" title="Labels" />
    </Grid>
  );
}

export default Datagrid;
