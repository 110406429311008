/* eslint-disable */
import { useEffect, useState, useContext } from 'react';

/* importing select from react-select */
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

/*import url and gateway methods */
import { postData, getData, putData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import context
import { GlobalProvider } from 'context/GlobalContext';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const SaveUpdateQuestionPopup = () => {
  const { t } = useTranslation(); //for translation

  const {
    editQuestionData,
    setEditQuestionData,
    setReloadQuestionList,
    resetQuestionPopup,
    setResetQuestionPopup,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const token = localStorage.getItem('token');
  // question list -
  const [allQuestionTypes, setAllQuestionTypes] = useState([]);

  /* question type selected  */
  const [selectedQuestionType, setSelectedQuestionType] = useState(null);
  const [questionTypeSelectedValue, setQuestionTypeSelectedValue] =
    useState(null);

  // options answers
  const [answerOptions, setAnswerOptions] = useState([]);
  const [optionalAnswer, setOptionalAnswer] = useState(null);

  const [questionInputs, setQuestionInputs] = useState({
    name: '',
    code: '',
    correctanswer: '',
  });

  /* hooks for validation */
  const [validation, setValidation] = useState({
    typeWarning: false,
    questionWarning: false,
  });

  // validate =========================
  const validate = () => {
    let isValid = true;

    if (questionTypeSelectedValue === null) {
      setValidation(prevState => ({ ...prevState, typeWarning: true }));
      isValid = false;
    }
    if (questionInputs.name === '') {
      setValidation(prevState => ({ ...prevState, questionWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get all user list
  const getAllQuestionTypes = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_QUESTION_TYPE +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      console.log('list of all question types -->', response);

      if (response.status) {
        setAllQuestionTypes(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for select question type
  const questionTypeSelectionHandler = val => {
    setQuestionTypeSelectedValue(val);
    setSelectedQuestionType(val.value);
    setValidation(prevState => ({
      ...prevState,
      typeWarning: false,
    }));
  };

  //function for select optional answer
  const optionalAnswerHandler = val => {
    setOptionalAnswer(val);
    const answerList = val.map(answer => {
      return answer.value;
    });
    setAnswerOptions(answerList);
  };

  //function for save question
  const saveQuestionHandler = async e => {
    e.preventDefault();
    let questionData = {
      questiontype: selectedQuestionType,
      code: questionInputs.code,
      name: questionInputs.name,
      correctanswer: questionInputs.correctanswer,
      answers: answerOptions,
    };

    if (validate()) {
      try {
        console.log('Question data=======>', questionData);

        let requestUrl =
          url.API_BaseUrl + url.API_CREATE_NEW_QUESTION + `?token=${token}`;

        const response = await postData(requestUrl, questionData);

        if (response.status) {
          resetHandler();
          setReloadQuestionList(true);
          setSuccess(true);
          setNotificationString('Question added successfully');
        }
      } catch (error) {
        console.log('error', error.message);
        setError(true);
        setNotificationString('Something went wrong, please try again later');
      }

      bootstrap.Offcanvas.getInstance(
        document.querySelector('#saveUpdateQuestionPopup')
      ).hide();
    }
  };

  //function for update question
  const updateQuestionHandler = async e => {
    e.preventDefault();

    let questionData = {
      questiontype: selectedQuestionType,
      code: questionInputs.code,
      name: questionInputs.name,
      correctanswer: questionInputs.correctanswer,
      answers: answerOptions,
    };

    if (validate()) {
      try {
        console.log('Question data=======>', questionData);

        let requestUrl =
          url.API_BaseUrl +
          url.API_UPDATE_QUESTION +
          `/${editQuestionData._id}?token=${token}`;

        const response = await putData(requestUrl, questionData);

        if (response.status) {
          resetHandler();
          setReloadQuestionList(true);
          setSuccess(true);
          setNotificationString('Question edited successfully');
        }
      } catch (error) {
        console.log('error', error.message);
        setError(true);
        setNotificationString('Something went wrong, please try again later');
      }

      bootstrap.Offcanvas.getInstance(
        document.querySelector('#saveUpdateQuestionPopup')
      ).hide();
    }
  };

  //function for reset all input
  const resetHandler = () => {
    setQuestionInputs({ name: '', code: '', correctanswer: '' });
    setSelectedQuestionType(null);
    setQuestionTypeSelectedValue(null);
    setAnswerOptions(null);
    setOptionalAnswer([]);
    setEditQuestionData(null);
  };

  useEffect(() => {
    if (editQuestionData) {
      setQuestionInputs(prevState => ({
        ...prevState,
        name: editQuestionData.name || '',
        code: editQuestionData.code || '',
        correctanswer: editQuestionData.correctanswer || '',
      }));
      if (editQuestionData.questiontype) {
        let questionTypeData = {
          label: editQuestionData.questiontype.slug,
          value: editQuestionData.questiontype._id,
        };
        questionTypeSelectionHandler(questionTypeData);
      }
      if (editQuestionData.answers) {
        const answerMapData = editQuestionData.answers.map(answer => {
          let answerData = {
            label: answer,
            value: answer,
          };
          return answerData;
        });
        optionalAnswerHandler(answerMapData);
      }
    }
  }, [editQuestionData]);

  //useeffect for reset from question nav
  useEffect(() => {
    if (resetQuestionPopup) {
      resetHandler();
      setResetQuestionPopup(false);
    }
  }, [resetQuestionPopup]);

  useEffect(() => {
    getAllQuestionTypes();
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="saveUpdateQuestionPopup"
      aria-labelledby="saveQuestionPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="saveQuestionPopupLabel">
          {t('Save Question')}
        </h3>
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <form
          onSubmit={
            editQuestionData ? updateQuestionHandler : saveQuestionHandler
          }
        >
          <div className="form-group mb-4">
            <div className="row">
              <div className="col-lg-6">
                <label htmlFor="type">{t('Type')}</label>
                <Select
                  className="nosearch_select"
                  options={allQuestionTypes}
                  value={questionTypeSelectedValue}
                  onChange={val => questionTypeSelectionHandler(val)}
                />
                {/* type warning */}
                {validation.typeWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter question type')}!</span>
                    </p>
                  </div>
                )}
              </div>

              <div className="col-lg-6 mb-4 mb-lg-0">
                <label htmlFor="code">{t('Code')}</label>
                <input
                  type="text"
                  id="code"
                  className="form-control"
                  placeholder={t('Enter Code')}
                  value={questionInputs.code}
                  onChange={val => {
                    setQuestionInputs(prevState => ({
                      ...prevState,
                      code: val.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>

          <div className="form-group mb-4">
            <label htmlFor="question">{t('Question')}</label>
            <input
              type="text"
              className="form-control"
              placeholder={t('Enter Here')}
              value={questionInputs.name}
              onChange={val => {
                setQuestionInputs(prevState => ({
                  ...prevState,
                  name: val.target.value,
                }));

                setValidation(prevState => ({
                  ...prevState,
                  questionWarning: false,
                }));
              }}
            />
            {/* type warning */}
            {validation.questionWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter question type')}!</span>
                </p>
              </div>
            )}
          </div>

          <div className="form-group mb-4">
            <label htmlFor="correctAnswers">{t('Correct Answers')}</label>
            <input
              type="text"
              id="correctAnswers"
              className="form-control"
              placeholder={t('Enter Here')}
              value={questionInputs.correctanswer}
              onChange={val => {
                setQuestionInputs(prevState => ({
                  ...prevState,
                  correctanswer: val.target.value,
                }));
              }}
            />
          </div>

          <div className="form-group mb-4">
            <label htmlFor="optionalAnswers">{t('Optional Answers')}</label>
            <CreatableSelect
              isClearable
              isCreatable
              className="nosearch_select"
              isMulti
              value={optionalAnswer}
              onChange={val => optionalAnswerHandler(val)}
            />
          </div>

          <div className="action">
            <button type="submit" className="btn btn-primary">
              {editQuestionData ? t('Update') : t('Save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveUpdateQuestionPopup;
