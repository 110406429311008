/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import context
import { GlobalProvider } from 'context/GlobalContext';

/*import url and gateway methods */
import { putData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

const ConversationHeader = ({
  setDownloadExcel,
  setSearch,
  records,
  blockRefSelected,
}) => {
  const {
    setReloadConversationList,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const token = localStorage.getItem('token'); // token

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState('');

  const { t } = useTranslation(); //for translation

  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);

    setTimeout(() => setDownloadExcel(false), 500);
  };

  //function for delete/de-activate conversation
  const deActivateConversation = e => {
    e.preventDefault();
    const checkboxes = document.querySelectorAll(
      'input[name="selectMailRecord"]'
    );

    checkboxes.forEach(async checkbox => {
      if (checkbox.checked) {
        let mailData = {
          status: '0',
        };
        try {
          let requestUrl =
            url.API_BaseUrl +
            url.API_UPDATE_MAIL +
            `/${checkbox.value}` +
            `?token=${token}`;
          const response = await putData(requestUrl, mailData);

          console.log(response);
          if (response.status) {
            setReloadConversationList(true);
            setSuccess(true);
            setNotificationString('User has been deleted successfully');
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString('Something went wrong, please try again later');
        }
      }
    });
  };

  useEffect(() => {
    !searchValue && setSearch('');
  }, [searchValue]);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2">
      {/* show columns =============================== */}
      {/* <Link to="#" className="btn btn-primary mw-unset p-2">
        <span className="d-block material-symbols-outlined icon-fill">
          visibility
        </span>
      </Link> */}

      {/* Download in xls ============================ */}
      <Link
        to="#"
        onClick={handleDownloadClick}
        className="filter-btn btn btn-primary icon-btn mw-unset"
        data-bs-placement="top"
        title="Download XLS"
      >
        <span className="d-block material-symbols-outlined">download</span>
      </Link>

      {/* Global search ============================= */}
      <div className="search-container position-relative d-none d-md-block">
        <form onSubmit={e => e.preventDefault()}>
          <input
            type="search"
            placeholder={t('Search here')}
            className="form-control bg-transparent py-2 border-0"
            onChange={e => setSearchValue(e.target.value)}
            onKeyDown={e => {
              e.key === 'Enter' && (e.preventDefault(), setSearch(searchValue));
            }}
          />
          <button
            onClick={() => setSearch(searchValue)}
            type="button"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
          >
            <span className="d-block material-symbols-outlined">search</span>
          </button>
        </form>
      </div>
      {/* ------------- showing records start ----------------- */}
      <p className="text-gray fw-medium ms-auto me-3">
        {t('Showing')}
        <span className="text-black ms-1">{records}</span> {t('Records')} -
        <span className="text-black ms-1" ref={blockRefSelected}>
          {' '}
          0{' '}
        </span>{' '}
        {t('Selected')}
      </p>
      {/* ------------- showing records end ----------------- */}

      {/* Reload ================================= */}
      <Link
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        onClick={() => {
          setReloadConversationList(true);
          setSuccess(true);
          setNotificationString('The list has been refreshed successfully');
        }}
        data-bs-placement="top"
        title="Refresh"
      >
        <span className="d-block material-symbols-outlined">refresh</span>
      </Link>

      {/* Filter ================================= */}
      <Link
        to="#filterMailCollapse"
        data-bs-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="filterCollapse"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        data-bs-placement="top"
        title="Filter"
      >
        <span className="d-block material-symbols-outlined">filter_alt</span>
      </Link>

      {/* Compose new ============================ */}
      <Link
        to="#createEmailPopup"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        data-bs-toggle="offcanvas"
        aria-controls="createEmailPopup"
        data-bs-placement="top"
        title="Create a new email"
      >
        <span className="d-block material-symbols-outlined">add</span>
      </Link>

      {/* Bulk action ============================ */}
      <div className="dropdown">
        <button
          className="btn btn-primary dropdown-toggle mw-unset"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {t('Actions')}
        </button>
        <ul className="dropdown-menu border-0 shadow-lg">
          <li>
            <Link
              to="#tags_pnl"
              data-bs-toggle="offcanvas"
              role="button"
              className="dropdown-item"
            >
              {t('Add/Remove Label')}
            </Link>
          </li>

          {/* ------- delete/deactivate mail button start ------- */}
          <li>
            <Link
              className="dropdown-item"
              to="#"
              onClick={deActivateConversation}
            >
              {t('Delete selected')}
            </Link>
          </li>
          {/* ------- delete/deactivate mail button end ------- */}
        </ul>
      </div>
    </div>
  );
};

export default ConversationHeader;
