/* eslint-disable */
import React from 'react';

// kendo react modules ==========================
import '@progress/kendo-theme-material/dist/all.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/* importing installations data  */
import installations from '../../data/Installations.json';

const InstallationsBody = ({ downloadExcel }) => {
  const { t } = useTranslation(); //for translation
  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = React.useState();
  const [result, setResult] = React.useState(
    process(installations, initialDataState)
  );

  const onDataStateChange = event => {
    console.log('event -->', event);
    setDataState(event.dataState);
    setResult(process(installations, event.dataState));
  };
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = React.createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };
  // mark all checkbox as selected ----------------
  const selectAllRecords = e => {
    document
      .querySelectorAll('input[name="selectRecord"]')
      .forEach(checkbox => {
        checkbox.checked = e.target.checked;
      });
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = props => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectRecord"
          />
        </div>
      </td>
    );
  };

  /* custom address HTML */

  const addressHTML = ({ dataItem }) => {
    return (
      <td className="">
        <p className="d-flex gap-2 align-items-center text-nowrap">
          <span className="d-block material-icons-outlined icon-md">
            pin_drop
          </span>
          <span className="d-block">{dataItem.address}</span>
        </p>
      </td>
    );
  };

  /* custom city HTML */
  const cityHTML = ({ dataItem }) => {
    return (
      <td className="">
        <p className="d-flex gap-2 align-items-center text-nowrap">
          <span className="d-block material-icons-outlined icon-md">
            pin_drop
          </span>
          <span className="d-block">{dataItem.city}</span>
        </p>
      </td>
    );
  };
  /* hook to hit  exportExcel() function*/
  React.useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);
  return (
    <div className="activity_table">
      <div className="table-responsive">
        {/* ============ download xls start ========== */}
        <ExcelExport
          data={installations}
          fileName="Installations.xlsx"
          ref={_exporter}
        ></ExcelExport>
        <ExcelExportColumn field="name" title={t('Name')} />
        <ExcelExportColumn
          field="responsibleUser"
          title={t('Responsible User')}
        />
        <ExcelExportColumn field="address" title={t('Address')} />
        <ExcelExportColumn field="city" title={t('City')} />
        <ExcelExportColumn field="latitude" title={t('Map Latitude')} />
        <ExcelExportColumn field="longitude" title={t('Map Longitude')} />
        {/* ============ download xls end ========== */}
        <Grid
          style={{ minWidth: '100%' }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn field="name" title={t('Name')} />
          <GridColumn field="responsibleUser" title={t('Responsible User')} />
          <GridColumn field="address" cell={addressHTML} title={t('Address')} />
          <GridColumn field="city" title={t('City')} cell={cityHTML} />
          <GridColumn field="latitude" title={t('Map Latitude')} />
          <GridColumn field="longitude" title={t('Map Longitude')} />
        </Grid>
      </div>
    </div>
  );
};

export default InstallationsBody;
