/* eslint-disable */
import React, { useState, useEffect, useContext, createRef } from 'react';
import { Link } from 'react-router-dom';

/*import url and gateway methods */
import { getData } from '../../../utils/Gateway';
import * as url from '../../../urlhelpers/UrlHelper';

// kendo react modules ==========================
import '@progress/kendo-theme-material/dist/all.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';

// ES2015 module syntax
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';

//import context
import { GlobalProvider } from 'context/GlobalContext';

//import images
import { assetImages } from 'constants';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const UserListArea = ({
  downloadExcel,
  search,
  setRecords,
  checkedRecordCount,
}) => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem('token');

  const {
    showUserList,
    setShowUserList,
    setEditUserData,
    setCopyUserData,
    showUserNameColumn,
    showUserSurnameColumn,
    showUserRoleColumn,
    showUserEmailColumn,
    showUserTagColumn,
    showCompanyColumn,
    showUserPhoneColumn,
    showUserCityColumn,
    showUserStateColumn,
    showUserCountryColumn,
    searchUserRoles,
    setSearchUserRoles,
    searchUserTags,
    setSearchUserTags,
    searchUserStatus,
    setSearchUserStatus,
    searchUserZipcodes,
    setSearchUserZipcodes,
    screenHeight,
  } = useContext(GlobalProvider);

  // default user data ------------------------------------
  const [userData, setUserData] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(userData, initialDataState));

  const onDataStateChange = event => {
    setDataState(event.dataState);
    setResult(process(userData, event.dataState));
  };

  //function for get all user
  const getAllUserList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl + url.API_GET_ALL_USER + `?token=${token}`;

      if (searchUserRoles.length > 0) {
        requestUrl = requestUrl + `&userrole=${searchUserRoles}`;
      }

      if (searchUserTags.length > 0) {
        requestUrl = requestUrl + `&usertag=${searchUserTags}`;
      }

      if (searchUserStatus.length > 0) {
        requestUrl = requestUrl + `&userstatus=${searchUserStatus}`;
      }

      if (searchUserZipcodes.length > 0) {
        requestUrl = requestUrl + `&userzipcode=${searchUserZipcodes}`;
      }

      console.log('user list url========>', requestUrl);

      const response = await getData(requestUrl);

      console.log('user list response------>', response);

      if (response.status) {
        setUserData(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter(item => {
      const name = item.name?.toLowerCase();
      const surname = item.surname?.toLowerCase();
      const role = item.role?.name?.toLowerCase();
      const email = item.email?.toLowerCase();
      const tagstring = item.tagstring?.toLowerCase();
      const phone = item.phone;
      const city = item.city?.toLowerCase();
      const state = item.state?.toLowerCase();
      const country = item.country?.toLowerCase();

      return (
        (name && name.includes(search.toLowerCase())) ||
        (surname && surname.includes(search.toLowerCase())) ||
        (role && role.includes(search.toLowerCase())) ||
        (email && email.includes(search.toLowerCase())) ||
        (tagstring && tagstring.includes(search.toLowerCase())) ||
        (phone && phone.includes(search)) ||
        (city && city.includes(search.toLowerCase())) ||
        (state && state.includes(search.toLowerCase())) ||
        (country && country.includes(search.toLowerCase()))
      );
    });
    setRecords(filteredData.length);
    return filteredData;
  };

  // ===== Function for action =========================================================

  // allow edit of click record --------------------
  // const showEditPopup = userData => {
  //   console.log('selected user id --->', userData._id);
  //   setEditUserData(userData);
  //   var userSavePopup = document.getElementById('saveUserPopup');
  //   var userSavecanvas = new bootstrap.Offcanvas(userSavePopup);
  //   userSavecanvas.toggle();
  // };

  // mark all checkbox as selected ----------------
  const selectAllRecords = e => {
    document
      .querySelectorAll('input[name="selectUserRecord"]')
      .forEach(checkbox => {
        checkbox.checked = e.target.checked;
      });

    handelSelectedCount();
  };

  // check the selected count and show the count in top and also selected download xls ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectUserRecord"]'
    );

    let totalSelected = 0;
    let filteredData = [];

    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = userData.find(user => user._id === checkbox.value);
        filteredUser && filteredData.push(filteredUser);
      }
    });

    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = props => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectUserRecord"
            filtername="selectedrecord"
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };

  //function for custom field in first column
  const nameHtml = props => {
    return (
      <td className="kendoCustomColumn overflow-visible">
        <div className="d-flex align-items-center gap-2">
          <p className="d-flex gap-2 align-items-center text-nowrap">
            <span className="d-block icon-user">
              <img
                src={
                  props.dataItem.photoimage
                    ? props.dataItem.photoimage.path
                    : assetImages.defaultUser
                }
                alt=""
              />
            </span>
            {props.dataItem.name ? (
              <span className="d-block">{props.dataItem.name}</span>
            ) : (
              <span className="d-block">{props.dataItem.email}</span>
            )}
          </p>

          <Link to="#">
            {props.dataItem.status == '1' ? (
              <span className="d-block material-symbols-outlined icon-md text-green">
                check_circle
              </span>
            ) : (
              <span className="d-block material-symbols-outlined icon-md text-danger">
                cancel
              </span>
            )}
          </Link>

          <div className="ms-auto d-flex align-items-center gap-1">
            <Link to="#">
              <span
                className={`d-block material-symbols-outlined icon-md ${
                  props.dataItem.zipcode ? 'text-green' : 'text-danger'
                }`}
              >
                location_on
              </span>
            </Link>
            <div className="dropdown cell-dropdown mt-2">
              <button
                className=" bg-transparent p-0 border-0"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="d-block material-symbols-outlined">
                  more_vert
                </span>
              </button>
              <ul className="dropdown-menu border-0 shadow-lg ">
                <li>
                  <Link
                    className="dropdown-item"
                    to="#saveUserPopup"
                    data-bs-toggle="offcanvas"
                    onClick={() => {
                      setEditUserData(props.dataItem);
                    }}
                  >
                    {t('Edit')}
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to={`/profile/${props.dataItem._id}`}
                  >
                    {t('View Profile')}
                  </Link>
                </li>

                <li>
                  <Link
                    className="dropdown-item"
                    to="#saveUserPopup"
                    data-bs-toggle="offcanvas"
                    onClick={() => {
                      setCopyUserData(props.dataItem);
                    }}
                  >
                    {t('Copy User')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </td>
    );
  };

  //function for role field
  // const roleHtml = (props) => {
  //   return (
  //     <td className="kendoCustomColumn">
  //       {props.dataItem.roles.map((role) => {
  //         return <span>{role.name}</span>;
  //       })}
  //     </td>
  //   );
  // };

  useEffect(() => {
    console.log('trigger 1');

    if (responseReceived === false) {
      getAllUserList();
    }
  }, [responseReceived]);

  useEffect(() => {
    console.log('trigger 2');
    if (showUserList) {
      getAllUserList();
      setShowUserList(false);
      setResult(process(userData, initialDataState));
    }
  }, [showUserList]);

  //use effect for search
  useEffect(() => {
    console.log('trigger 3');
    if (
      searchUserRoles.length > 0 ||
      searchUserTags.length > 0 ||
      searchUserStatus.length > 0 ||
      searchUserZipcodes.length > 0
    ) {
      getAllUserList();
    }
  }, [searchUserRoles, searchUserTags, searchUserStatus, searchUserZipcodes]);

  useEffect(() => {
    console.log('trigger 4');

    downloadExcel && exportExcel();
  }, [downloadExcel]);

  useEffect(() => {
    console.log('trigger 5');

    filterData();
  }, [search]);

  console.log(`downloadSelectedXLS`, handelSelectedCount().length);

  return (
    <div className="table-responsive">
      {/* ========== download xls start ================ */}
      <ExcelExport
        data={
          handelSelectedCount().length > 0 ? handelSelectedCount() : userData
        }
        fileName="User Management.xlsx"
        ref={_exporter}
      >
        <ExcelExportColumn
          field="name"
          title={t('Name')}
          locked={true}
          width={200}
        />
        <ExcelExportColumn field="surname" title={t('Surname')} />
        <ExcelExportColumn field="role.name" title={t('Role')} />
        <ExcelExportColumn field="email" title={t('Email')} />
        <ExcelExportColumn field="tagstring" title={t('Tags')} />
        <ExcelExportColumn field="company" title={t('Company')} />
        <ExcelExportColumn field="phone" title={t('Phone')} />
        <ExcelExportColumn field="city" title={t('City')} />
        <ExcelExportColumn field="state" title={t('State')} />
        <ExcelExportColumn field="country" title={t('Country')} />
      </ExcelExport>
      {/* ========== download xls end ================ */}
      {/* ============ grid view starts ============= */}

      {search ? (
        <Grid
          style={{ height: screenHeight }}
          data={filterData()}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />

          {showUserNameColumn && (
            <GridColumn
              field="name"
              title={t('Name')}
              cell={nameHtml}
              width="400px"
            />
          )}
          {showUserSurnameColumn && (
            <GridColumn
              field="surname"
              title={t('Surname')}
              // cell={nameHtml}
              width="400px"
            />
          )}
          {showUserRoleColumn && (
            <GridColumn field="role.name" title={t('Role')} width="200px" />
          )}
          {showUserEmailColumn && (
            <GridColumn field="email" title={t('Email')} width="400px" />
          )}
          {showUserTagColumn && (
            <GridColumn field="tagstring" title={t('Tags')} width="200px" />
          )}
          {showCompanyColumn && (
            <GridColumn field="company" title={t('Company')} width="200px" />
          )}
          {showUserPhoneColumn && (
            <GridColumn field="phone" title={t('Phone')} width="200px" />
          )}
          {showUserCityColumn && (
            <GridColumn field="city" title={t('City')} width="200px" />
          )}
          {showUserStateColumn && (
            <GridColumn field="state" title={t('State')} width="200px" />
          )}
          {showUserCountryColumn && (
            <GridColumn field="country" title={t('Country')} width="200px" />
          )}
        </Grid>
      ) : (
        <Grid
          style={{ height: screenHeight }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />

          {showUserNameColumn && (
            <GridColumn
              field="name"
              title={t('Name')}
              cell={nameHtml}
              width="400px"
            />
          )}

          {showUserSurnameColumn && (
            <GridColumn
              field="surname"
              title={t('Surname')}
              // cell={nameHtml}
              width="400px"
            />
          )}

          {showUserRoleColumn && (
            <GridColumn field="role.name" title={t('Role')} width="200px" />
          )}

          {showUserEmailColumn && (
            <GridColumn field="email" title={t('Email')} width="400px" />
          )}

          {showUserTagColumn && (
            <GridColumn field="tagstring" title={t('Tags')} width="200px" />
          )}

          {showCompanyColumn && (
            <GridColumn field="company" title={t('Company')} width="200px" />
          )}

          {showUserPhoneColumn && (
            <GridColumn field="phone" title={t('Phone')} width="200px" />
          )}

          {showUserCityColumn && (
            <GridColumn field="city" title={t('City')} width="200px" />
          )}

          {showUserStateColumn && (
            <GridColumn field="state" title={t('State')} width="200px" />
          )}

          {showUserCountryColumn && (
            <GridColumn field="country" title={t('Country')} width="200px" />
          )}
        </Grid>
      )}

      {/* ============ grid view ends ============= */}
    </div>
  );
};

export default UserListArea;
