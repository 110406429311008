/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

//import translation function for language translation
import { useTranslation } from 'react-i18next';
// import { read, utils } from 'xlsx';
/*import url and gateway methods */
import { putData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const ConceptHeader = ({
  setDownloadExcel,
  setSearch,
  records,
  blockRefSelected,
}) => {
  const {
    setReloadConceptList,
    setResetAddUpdateConceptPopup,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider); //context requirements

  const token = localStorage.getItem('token'); // token
  const { t } = useTranslation(); //for translation

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState('');

  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);
    setTimeout(() => setDownloadExcel(false), 500);
  };

  // const fileUploadHandler = e => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();

  //   reader.onload = event => {
  //     const data = new Uint8Array(event.target.result);
  //     const workbook = read(data, { type: 'array' });

  //     // Process the workbook data as needed
  //     const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //     const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

  //     // Use the imported data in your React component
  //     console.log(`jsonData`, jsonData);
  //   };

  //   reader.readAsArrayBuffer(file);
  // };

  //function for delete label
  const deleteConceptHandler = () => {
    const checkboxes = document.querySelectorAll(
      'input[name="selectConceptRecord"]'
    );

    checkboxes.forEach(async checkbox => {
      if (checkbox.checked) {
        try {
          let conceptData = {
            status: '0',
          };

          let requestUrl =
            url.API_BaseUrl +
            url.API_UPDATE_CONCEPT +
            `/${checkbox.value}` +
            `?token=${token}`;

          const response = await putData(requestUrl, conceptData);

          if (response.status) {
            setReloadConceptList(true);
            setSuccess(true);
            setNotificationString(t('Concept has been deleted successfully'));
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString(
            t('Something went wrong, please try again later')
          );
        }
      }
    });
  };

  /* hook to empty search when searchValue gets empty */
  useEffect(() => {
    !searchValue && setSearch('');
  }, [searchValue]);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2 border-bottom border-gray">
      {/* -------- toggle column button start -------- */}
      {/* <Link
        to="#"
        className="column-collapse btn btn-primary icon-btn mw-unset"
      >
        <span className="d-block material-symbols-outlined icon-fill">
          visibility
        </span>
      </Link> */}
      {/* -------- toggle column button end -------- */}

      {/* <Link
        to="#"
        onChange={fileUploadHandler}
        className="download btn btn-primary icon-btn mw-unset"
      >
        <span className="d-block material-symbols-outlined">upload</span>
      </Link> */}
      {/* <input
        type="file"
        onChange={fileUploadHandler}
        className="btn btn-primary"
      /> */}

      {/* -------- download button start -------- */}
      <Link
        to="#"
        onClick={handleDownloadClick}
        className="download btn btn-primary icon-btn mw-unset"
      >
        <span className="d-block material-symbols-outlined">download</span>
      </Link>
      {/* -------- download button end -------- */}

      {/* -------- search section start -------- */}
      <div className="search-container position-relative d-none d-md-block">
        <form onSubmit={e => e.preventDefault()}>
          <input
            type="search"
            placeholder={t('Search here')}
            className="form-control bg-transparent py-2 border-0"
            onChange={e => setSearchValue(e.target.value)}
            onKeyDown={e => {
              e.key === 'Enter' && (e.preventDefault(), setSearch(searchValue));
            }}
          />
          <button
            onClick={() => setSearch(searchValue)}
            type="submit"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
          >
            <span className="d-block material-symbols-outlined">search</span>
          </button>
        </form>
      </div>
      {/* -------- search section end -------- */}
      {/* ------------- showing records start ----------------- */}
      <p className="text-gray fw-medium ms-auto me-3">
        {t('Showing')}
        <span className="text-black ms-1">{records}</span> {t('Records')} -
        <span className="text-black ms-1" ref={blockRefSelected}>
          {' '}
          0{' '}
        </span>{' '}
        {t('Selected')}
      </p>
      {/* ------------- showing records end ----------------- */}

      {/* ----- refresh icon start ------ */}
      <Link
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset "
        onClick={() => {
          setReloadConceptList(true);
          setSuccess(true);
          setNotificationString(t('The list has been refreshed successfully'));
        }}
      >
        <span className="d-block material-symbols-outlined">refresh</span>
      </Link>
      {/* ----- refresh icon end ------ */}

      {/* -------- add new label popip button start -------- */}
      <Link
        to="#addUpdateConceptPopup"
        data-bs-toggle="offcanvas"
        role="button"
        aria-controls="addLabelPopup"
        className="save-reservation btn btn-primary icon-btn mw-unset"
        onClick={() => {
          setResetAddUpdateConceptPopup(true);
        }}
      >
        <span className="d-block material-symbols-outlined">add</span>
      </Link>
      {/* -------- add new label popip button end -------- */}

      {/* -------- dropdown button start -------- */}
      <div className="dropdown">
        <button
          className="btn btn-primary dropdown-toggle mw-unset"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Actions
        </button>
        <ul className="dropdown-menu border-0 shadow-lg">
          <li>
            <Link
              className="dropdown-item"
              to="#"
              onClick={deleteConceptHandler}
            >
              {t('Delete selected')}
            </Link>
          </li>
        </ul>
      </div>
      {/* -------- dropdown button end -------- */}
    </div>
  );
};

export default ConceptHeader;
