/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import context
import { GlobalProvider } from 'context/GlobalContext';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

const ReservationHeader = ({
  setDownloadExcel,
  setSearch,
  records,
  blockRefSelected,
}) => {
  const token = localStorage.getItem('token'); // token
  const {
    setReloadReservationList,
    setReloadRefresh,
    setResetReserveSaveForm,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const { t } = useTranslation(); //for translation

  //function for delete/de-activate event
  const deActivateReserves = e => {
    const checkboxes = document.querySelectorAll(
      'input[name="selectReserveRecord"]'
    );

    checkboxes.forEach(async checkbox => {
      if (checkbox.checked) {
        let reserveData = {
          status: '0',
        };

        try {
          let requestUrl =
            url.API_BaseUrl +
            url.API_UPDATE_RESERVE_EVENT +
            `/${checkbox.value}` +
            `?token=${token}`;

          const response = await putData(requestUrl, reserveData);
          if (response.status) {
            setReloadReservationList(true);
            setSuccess(true);
            setNotificationString('User has been deleted successfully');
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString('Something went wrong, please try again later');
        }
      }
    });
  };

  //function for calculate cost
  const addCostHandeler = e => {
    const checkboxes = document.querySelectorAll(
      'input[name="selectReserveRecord"]'
    );

    checkboxes.forEach(async checkbox => {
      if (checkbox.checked) {
        try {
          let requestUrl =
            url.API_BaseUrl +
            url.API_CREATE_COST +
            `/${checkbox.value}` +
            `?token=${token}`;

          console.log(requestUrl);

          const response = await getData(requestUrl);

          console.log('cost calculation response', response);

          if (response.status) {
            // show success notification
            setSuccess(true);
            setNotificationString(t('Cost calculated for reserve'));
          }
        } catch (error) {
          console.error(error.message);
        }
      }
    });
  };

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState('');

  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);
    setTimeout(() => setDownloadExcel(false), 500);
  };

  useEffect(() => {
    !searchValue && setSearch('');
  }, [searchValue]);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2">
      {/* --------- toogle column section start --------- */}
      <Link
        to="#reservation_show_columnCollapse"
        className="column-collapse btn btn-primary icon-btn mw-unset"
        data-bs-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="columnCollapse"
      >
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Show columns"
          className="d-block material-symbols-outlined icon-fill"
        >
          visibility
        </span>
      </Link>
      {/* --------- toogle column section end --------- */}

      {/* --------- download excel button start --------- */}
      <Link
        to="#"
        onClick={handleDownloadClick}
        className="download btn btn-primary icon-btn mw-unset"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Download in XLS"
      >
        <span className="d-block material-symbols-outlined">download</span>
      </Link>
      {/* --------- download excel button end --------- */}

      {/* --------- redirect mail conversation start -------- */}
      <Link
        to="/conversation"
        className="email btn btn-primary icon-btn mw-unset position-relative d-none d-md-block"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Mail conversation"
      >
        <span className="d-block material-symbols-outlined icon-fill">
          mail
        </span>
        <span className="badge d-inline-block bg-black position-absolute top-0 start-100 translate-middle">
          <span className="unread">99</span>/150
        </span>
      </Link>
      {/* --------- redirect mail conversation end -------- */}

      {/* ------- input search section start --------- */}
      <div className="search-container position-relative d-none d-md-block">
        <form onSubmit={e => e.preventDefault()}>
          <input
            type="search"
            placeholder={t('Search here')}
            className="form-control bg-transparent py-2 border-0"
            onChange={e => setSearchValue(e.target.value)}
            onKeyDown={e => {
              e.key === 'Enter' && (e.preventDefault(), setSearch(searchValue));
            }}
          />
          <button
            onClick={() => setSearch(searchValue)}
            type="button"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Search"
          >
            <span className="d-block material-symbols-outlined">search</span>
          </button>
        </form>
      </div>
      {/* ------- input search section end --------- */}

      {/* ------------- showing records start ----------------- */}
      <p className="text-gray fw-medium ms-auto me-3">
        {t('Showing')}
        <span className="text-black ms-1">{records}</span> {t('Records')} -
        <span className="text-black ms-1" ref={blockRefSelected}>
          {' '}
          0{' '}
        </span>{' '}
        {t('Selected')}
      </p>
      {/* ------------- showing records end ----------------- */}

      {/* ---------- refresh button start -------- */}
      <Link
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        onClick={() => {
          setReloadRefresh(true);
          setSuccess(true);
          setNotificationString('The list has been refreshed successfully');
        }}
      >
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Refresh"
          className="d-block material-symbols-outlined"
        >
          refresh
        </span>
      </Link>
      {/* ---------- refresh button end -------- */}

      {/* ---------- search filter button start -------- */}
      <Link
        to="#filterCollapse"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        data-bs-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="filterCollapse"
      >
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Filter"
          className="d-block material-symbols-outlined"
        >
          filter_alt
        </span>
      </Link>
      {/* ---------- search filter button end -------- */}

      {/* ---------- add new reserve form button start -------- */}
      <Link
        to="#saveReservationPopup"
        onClick={() => {
          setResetReserveSaveForm(true);
        }}
        data-bs-toggle="offcanvas"
        role="button"
        aria-controls="saveReservationPopup"
        className="save-reservation btn btn-primary icon-btn mw-unset"
      >
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={t('Save Reserve')}
          className="d-block material-symbols-outlined"
        >
          add
        </span>
      </Link>
      {/* ---------- add new reserve form button start -------- */}

      {/* -------- action dropdown section start -------- */}
      <div className="dropdown">
        <button
          className="btn btn-primary dropdown-toggle mw-unset"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {t('Actions')}
        </button>
        <ul className="dropdown-menu border-0 shadow-lg">
          {/* ------ add tag button start ------- */}
          <li>
            <Link
              to="#tags_pnl"
              data-bs-toggle="offcanvas"
              role="button"
              className="dropdown-item"
            >
              {t('Add/Remove Label')}
            </Link>
          </li>
          {/* ------ add tag button end ------- */}

          {/* ------ download excel button start ------- */}
          <li>
            <Link
              onClick={handleDownloadClick}
              className="dropdown-item"
              to="#"
            >
              {t('Download in Xls')}
            </Link>
          </li>
          {/* ------ download excel button end ------- */}

          {/* ------ send mail button start ------- */}
          <li>
            <Link
              to="#createSendNotificationEmailPopup"
              data-bs-toggle="offcanvas"
              role="button"
              aria-controls="createEmailPopup"
              className="dropdown-item"
            >
              {t('Send Mail')}
            </Link>
          </li>
          {/* ------ send mail button end ------- */}

          {/* ------ change status ------- */}
          <li>
            <Link
              to="#status_pnl"
              data-bs-toggle="offcanvas"
              role="button"
              className="dropdown-item"
            >
              {t('Change Status')}
            </Link>
          </li>
          {/* ------ change status ends------- */}

          {/* ----- Calculate Cost start ------ */}
          <li>
            <Link
              className="dropdown-item"
              to="#"
              role="button"
              aria-controls="costcalculation"
              onClick={addCostHandeler}
            >
              {t('Calculate cost')}
            </Link>
          </li>
          {/* ----- Calculate Cost end ------ */}

          {/* ------ Create sales order start ------- */}
          <li>
            <Link
              to="#createsalesorder"
              data-bs-toggle="offcanvas"
              role="button"
              className="dropdown-item"
            >
              {t('Create Sales Order')}
            </Link>
          </li>
          {/* ------ Create sales order end------- */}

          {/* ------ delete button start ------- */}
          <li>
            <Link className="dropdown-item" to="#" onClick={deActivateReserves}>
              {t('Delete selected')}
            </Link>
          </li>
          {/* ------ delete button end ------- */}
        </ul>
      </div>
      {/* -------- action dropdown section end -------- */}
    </div>
  );
};

export default ReservationHeader;
