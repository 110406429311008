import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import SalesOrderBreadCrumb from 'components/SalesOrderComponents/BreadCrumb/SalesOrderBreadCrumb';
import SalesOrderHeader from 'components/SalesOrderComponents/Header/SalesOrderHeader';
import SalesOrderList from 'components/SalesOrderComponents/SalesOrderList/SalesOrderList';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';
import ViewSalesOrderPopup from 'components/SalesOrderComponents/Popup/ViewSalesOrderPopup';
import SalesOrderFilter from 'components/SalesOrderComponents/Filter/SalesOrderFilter';
import ToggleColumn from 'components/SalesOrderComponents/ToggleColumn/ToggleColumn';
import CustomNotification from 'components/common/CustomNotification';

const SalesOrder = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);

  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly

  /* function for checking record count */
  const checkedRecordCount = selectCount => {
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };

  /* hook to search */
  const [search, setSearch] = useState('');

  useEffect(() => {
    document.title = ' Sales Order';
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="sales-order-wrapper pt-2">
        <div className="container-fluid px-4">
          <SalesOrderBreadCrumb />
          <SalesOrderHeader
            setDownloadExcel={setDownloadExcel}
            blockRefSelected={blockRefSelected}
            setSearch={setSearch}
          />
          <ToggleColumn />
          <SalesOrderFilter />
          <SalesOrderList
            search={search}
            downloadExcel={downloadExcel}
            checkedRecordCount={checkedRecordCount}
          />
        </div>
      </section>
      <ViewSalesOrderPopup />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default SalesOrder;
