/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';
//import translation function for language translation
import { useTranslation } from 'react-i18next';

/*import url and gateway methods */
import { postData, getData, putData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

/* importing select from react-select */
import Select from 'react-select';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const SaveSurveyPopup = () => {
  const { t } = useTranslation(); //for translation

  const token = localStorage.getItem('token'); //import token

  const {
    setReloadSurveyList,
    editSurveyData,
    setEditSurveyData,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider); //import context requirements

  const [questionList, setQuestionList] = useState([]);

  const [surveyName, setSurveyName] = useState('');
  const [surveyCode, setSurveyCode] = useState('');

  //form repeater requirement start
  const surveyQuestionTemplate = {
    questionValue: null,
    selectedQuestion: null,
    order: '',
  };

  //form repeater initial
  const [questionOrderForm, setQuestionOrderForm] = useState([
    surveyQuestionTemplate,
  ]);

  //function for add form
  const addNewQuestionForm = () => {
    setQuestionOrderForm([...questionOrderForm, surveyQuestionTemplate]);
  };

  /* hooks for validation */
  const [validation, setValidation] = useState({
    surveyNameWarning: false,
  });

  // validate =========================
  const validate = () => {
    let isValid = true;

    if (surveyName === '') {
      setValidation(prevState => ({ ...prevState, surveyNameWarning: true }));
      isValid = false;
    }
    return isValid;
  };

  //function delete form
  const onDeleteQuestionForm = index => {
    if (index !== 0) {
      const filteredQuestionBlock = [...questionOrderForm];
      filteredQuestionBlock.splice(index, 1);
      setQuestionOrderForm(filteredQuestionBlock);
    }
  };

  //function for get all question list
  const getAllQuestion = async e => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_QUESTION +
        `?token=${token}&isselect=${true}`;

      console.log('question list url =====>>>', requestUrl);

      const response = await getData(requestUrl);

      console.log('question list response ========>>>', response);

      if (response.data) {
        setQuestionList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for change question select
  const changeQuestionSelect = (index, field, field2, value) => {
    const updatedFormValues = questionOrderForm.map((question, i) =>
      index == i
        ? Object.assign(question, { [field]: value, [field2]: value.value })
        : question
    );
    setQuestionOrderForm(updatedFormValues);
  };

  //function for question order field
  const changeQuestionOrder = (index, field, value) => {
    const updatedFormValues = questionOrderForm.map((question, i) =>
      index == i ? Object.assign(question, { [field]: value }) : question
    );
    setQuestionOrderForm(updatedFormValues);
  };

  //function for save survey question
  const saveSurveyQuestionHandler = async e => {
    e.preventDefault();

    let surveyData = {
      surveyquestion: questionOrderForm,
      name: surveyName,
      code: surveyCode,
      status: '1',
    };

    console.log('Question survey order form=========>', surveyData);
    if (validate()) {
      try {
        let requestUrl =
          url.API_BaseUrl + url.API_CREATE_SURVEY + `?token=${token}`;

        console.log('Question survey save url =========>', requestUrl);

        const response = await postData(requestUrl, surveyData);

        console.log('Question survey order form response =========>', response);

        if (response.status) {
          resetHandler();
          setReloadSurveyList(true);
          setSuccess(true);
          setNotificationString('Survey added successfully');
        }
      } catch (error) {
        console.error(`error`, error.message);
        setError(true);
        setNotificationString('Something went wrong, please try again later');
      }
      bootstrap.Offcanvas.getInstance(
        document.querySelector('#saveSurveyPopup')
      ).hide();
    }
  };

  //function for update survey
  const updateSurveyHandler = async e => {
    e.preventDefault();

    let surveyData = {
      surveyquestion: questionOrderForm,
      name: surveyName,
      code: surveyCode,
      status: '1',
    };

    console.log('Question survey order form=========>', surveyData);

    if (validate()) {
      try {
        let requestUrl =
          url.API_BaseUrl +
          url.API_UPDATE_SURVEY +
          `/${editSurveyData._id}` +
          `?token=${token}`;

        console.log('Question survey update url =========>', requestUrl);

        const response = await putData(requestUrl, surveyData);

        console.log(
          'Question survey order update form response =========>',
          response
        );

        if (response.status) {
          resetHandler();

          setReloadSurveyList(true);
          setSuccess(true);
          setNotificationString('Survey edited successfully');
        }
      } catch (error) {
        console.error(`error`, error.message);
        setError(true);
        setNotificationString('Something went wrong, please try again later');
      }
      bootstrap.Offcanvas.getInstance(
        document.querySelector('#saveSurveyPopup')
      ).hide();
    }
  };

  //function for reset
  const resetHandler = () => {
    setSurveyName('');
    setSurveyCode('');
    setQuestionOrderForm([surveyQuestionTemplate]);
    setEditSurveyData(null);
  };

  //function for mapping form repeater data
  const updateSurveyQuestionForm = surveyQuestion => {
    let surveyQuestionArr = [];

    console.log('Question=======>', surveyQuestion);

    surveyQuestion.map(surveyQuestionData => {
      let surveyQuestionSavedData = {
        _id: surveyQuestionData._id,
        questionValue: surveyQuestionData.question,
        selectedQuestion: surveyQuestionData?.question?.value,
        order: surveyQuestionData.order,
      };
      surveyQuestionArr.push(surveyQuestionSavedData);
    });

    setQuestionOrderForm(surveyQuestionArr);
  };

  //use effect for edit survey
  useEffect(() => {
    if (editSurveyData) {
      console.log('Edit survey data==========>', editSurveyData);
      //assign survey name
      if (editSurveyData?.name) {
        setSurveyName(editSurveyData.name);
      }

      //assign survey code
      if (editSurveyData?.code) {
        setSurveyCode(editSurveyData.code);
      }

      //assign survey form repeater
      if (editSurveyData?.surveyquestion) {
        if (editSurveyData?.surveyquestion?.length > 0) {
          updateSurveyQuestionForm(editSurveyData.surveyquestion);
        }
      }
    }
  }, [editSurveyData]);

  useEffect(() => {
    getAllQuestion();
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="saveSurveyPopup"
      aria-labelledby="saveSurveyPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="saveQuestionPopupLabel">
          {editSurveyData ? t('Update Survey') : t('Save Survey')}
        </h3>
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <form
          onSubmit={
            editSurveyData ? updateSurveyHandler : saveSurveyQuestionHandler
          }
        >
          {/* -------- survey name section start -------- */}
          <div className="form-group mb-4">
            <label htmlFor="surveyName">{t('Survey Name')}</label>
            <input
              type="text"
              id="surveyName"
              className="form-control"
              placeholder={t('Enter Name')}
              value={surveyName}
              onChange={e => {
                setSurveyName(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    surveyNameWarning: false,
                  }));
              }}
            />
            {/* type warning */}
            {validation.surveyNameWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter question type')}!</span>
                </p>
              </div>
            )}
          </div>
          {/* -------- survey name section end -------- */}

          <div className="form-group mb-4">
            <div className="row">
              {/* -------- code section start -------- */}
              <div className="col-lg-12 mb-4 mb-lg-0">
                <label htmlFor="code">{t('Code')}</label>
                <input
                  type="text"
                  id="codesurvey"
                  className="form-control"
                  placeholder={t('Enter Code')}
                  value={surveyCode}
                  onChange={e => {
                    setSurveyCode(e.target.value);
                  }}
                />
              </div>
              {/* -------- code section end -------- */}

              {/* -------- module block start ------- */}
              {/* <div className="col-lg-6">
                <label htmlFor="module">{t('Module')}</label>
                <Select
                  className="nosearch_select"
                  options={moduleData}
                  value={selectedModule}
                  onChange={(val) => setSelectedModule(val)}
                />
              </div> */}
              {/* -------- module block end ------- */}
            </div>
          </div>
          {/* -------- question order form section start --------- */}
          <div className="questions mb-2">
            {questionOrderForm.map((question, index) => {
              return (
                <div
                  className="question bg-gray-500 p-3 position-relative mt-4 mb-2"
                  key={index}
                >
                  <Link
                    to="#"
                    onClick={() => {
                      onDeleteQuestionForm(index);
                    }}
                    className="remove-question text-dark position-absolute top-0 end-0 mt-2 me-2"
                  >
                    <span className="d-block material-symbols-outlined">
                      close
                    </span>
                  </Link>
                  {/* -------- question section start -------- */}
                  <div className="form-group mb-4">
                    <label htmlFor="question">{t('Question')}</label>
                    <Select
                      className="nosearch_select"
                      options={questionList}
                      value={question.questionValue}
                      onChange={val => {
                        changeQuestionSelect(
                          index,
                          'questionValue',
                          'selectedQuestion',
                          val
                        );
                      }}
                    />
                  </div>
                  {/* -------- question section end -------- */}
                  <div className="form-group">
                    <div className="row">
                      {/* -------- order section start -------- */}
                      <div className="col-lg-12 mb-4 mb-lg-0">
                        <label htmlFor="order">{t('Order')}</label>
                        <input
                          type="number"
                          id="order"
                          className="form-control bg-white"
                          placeholder={t('Enter Order Here')}
                          value={question.order}
                          onChange={e => {
                            changeQuestionOrder(index, 'order', e.target.value);
                          }}
                        />
                      </div>
                      {/* -------- order section end -------- */}
                      {/* <div className="col-lg-6">
                    <label htmlFor="allowComments">{t("Allow Comments")}</label>
                    <Select
                      className="nosearch_select"
                      options={comments}
                      value={selecctedComments}
                      onChange={(val) => setSelecctedComments(val)}
                    />
                  </div> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* -------- question order form section end --------- */}

          {/* -------- add new question order form section start --------- */}
          <div className="add-question my-3">
            <Link
              to="#"
              onClick={addNewQuestionForm}
              id="add_question"
              className="text-primary fw-medium d-flex align-items-center gap-1"
            >
              <span className="material-symbols-outlined">add</span>
              <span>{t('Add More')}</span>
            </Link>
          </div>
          {/* -------- add new question order form section start --------- */}

          <div className="action">
            <button type="submit" className="btn btn-primary">
              {editSurveyData ? t('Update') : t('Save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveSurveyPopup;
