/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const UserProfileMenu = () => {
  const { t } = useTranslation(); //for translation
  return (
    <section className="user_profile_tabsSec">
      <div className="container-fluid">
        <div className="tab_links">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <Link
                to="#personel_info"
                data-bs-toggle="tab"
                className="nav-link active show"
              >
                {t('Personal info')}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="#password" data-bs-toggle="tab" className="nav-link">
                {t('Password')}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="#privacy" data-bs-toggle="tab" className="nav-link">
                {t('Privacy')}
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="#surveyfeedback"
                data-bs-toggle="tab"
                className="nav-link"
              >
                {t('Survey Feedback')}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="#calender" data-bs-toggle="tab" className="nav-link">
                {t('Calender')}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="#activity" data-bs-toggle="tab" className="nav-link">
                {t('Activity')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default UserProfileMenu;
