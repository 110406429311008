import { assetImages } from "constants";
import React from "react";

const ViewFeedbackEmptyPopup = () => {
  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="pendingFeedbackPopup"
      aria-labelledby="pendingFeedbackPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="pendingFeedbackPopupLabel">
          View feedback
        </h3>
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <div className="pending-response h-100 d-flex flex-column align-items-center justify-content-center">
          <img
            src={assetImages.pendingFeedback}
            alt="Pending Feedback Response!"
            className="img-fluid mb-4"
          />
          <h3 className="text-secondary">Oops!</h3>
          <p className="fs-lg text-black fw-medium">
            Pending Feedback Response!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ViewFeedbackEmptyPopup;
