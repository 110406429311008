import CostFilter from 'components/CostTemplateComponents/CostFilter/CostFilter';
import CostTemplateBreadCrumb from 'components/CostTemplateComponents/BreadCrumb/CostTemplateBreadCrumb';
import CostTemplateListArea from 'components/CostTemplateComponents/CostTemplateListArea/CostTemplateListArea';
import ReactDOM from 'react-dom';
import CostToggleColumn from 'components/CostTemplateComponents/CostToggleColumns/CostToggleColumns';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';
import React, { useEffect, useState, useRef } from 'react';
import CostTemplateHeader from 'components/CostTemplateComponents/Header/CostTemplateHeader';
import ViewUpdateCostPopup from 'components/CostTemplateComponents/Popup/ViewUpdateCostPopup';
import CustomNotification from 'components/common/CustomNotification';

const CostTemplate = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);
  /* hook to get number of records */

  /* hook to search */
  const [search, setSearch] = useState('');
  /* hook to get number of records */
  const [records, setRecords] = useState(0);
  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly

  /* function for checking record count */
  const checkedRecordCount = selectCount => {
    console.log('selectCount', selectCount);
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };
  useEffect(() => {
    document.title = 'Cost'; // Update the title when Register component mounts
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="cost-wrapper pt-4">
        <div className="container-fluid px-4">
          <CostTemplateBreadCrumb />
          <CostTemplateHeader
            setDownloadExcel={setDownloadExcel}
            records={records}
            blockRefSelected={blockRefSelected}
            setSearch={setSearch}
          />
          <CostToggleColumn />
          <CostFilter />
          <CostTemplateListArea
            downloadExcel={downloadExcel}
            setRecords={setRecords}
            search={search}
            checkedRecordCount={checkedRecordCount}
          />
        </div>
      </section>
      <ViewUpdateCostPopup />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default CostTemplate;
