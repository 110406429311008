import React from 'react';
import { Link } from 'react-router-dom';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const CategoryNav = ({ setDownloadExcel }) => {
  const { t } = useTranslation(); //for translation

  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);

    setTimeout(() => setDownloadExcel(false), 500);
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="dashboard.html" className="text-secondary">
              {t('Funbrain')}
            </Link>
          </li>
          <li className="breadcrumb-item text-gray-600" aria-current="page">
            {t('Category')}
          </li>
        </ul>
      </nav>
      <div className="filter-container d-flex flex-wrap align-items-center gap-2 pb-3">
        <Link
          to="#"
          className="column-collapse btn btn-primary icon-btn mw-unset"
        >
          <span className="d-block material-symbols-outlined icon-fill">
            visibility
          </span>
        </Link>
        <Link
          to="#"
          onClick={handleDownloadClick}
          className="download btn btn-primary icon-btn mw-unset"
        >
          <span className="d-block material-symbols-outlined">download</span>
        </Link>
        <div className="search-container position-relative d-none d-md-block">
          <form action="">
            <input
              type="search"
              placeholder={t('Search here')}
              className="form-control bg-transparent py-2 border-0"
            />
            <button
              type="submit"
              className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
            >
              <span className="d-block material-symbols-outlined">search</span>
            </button>
          </form>
        </div>
        <Link
          to="#"
          className="filter-btn btn btn-primary icon-btn mw-unset ms-auto"
        >
          <span className="d-block material-symbols-outlined">filter_alt</span>
        </Link>
        <Link
          to="#addCategoryPopup"
          data-bs-toggle="offcanvas"
          role="button"
          aria-controls="addCategoryPopup"
          className="save-reservation btn btn-primary icon-btn mw-unset"
        >
          <span className="d-block material-symbols-outlined">add</span>
        </Link>
        <div className="dropdown">
          <button
            className="btn btn-primary dropdown-toggle mw-unset"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {t('Actions')}
          </button>
          <ul className="dropdown-menu border-0 shadow-lg">
            <li>
              <Link className="dropdown-item" to="#">
                {t('Rating')}
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="#">
                {t('Text')}
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="#">
                {t('Single Select')}
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="#">
                {t('Multi Select')}
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="#">
                {t('Comments')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default CategoryNav;
