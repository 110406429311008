/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';

import Select from 'react-select';
//import translation function for language translation
import {useTranslation} from 'react-i18next';
/*import url and gateway methods */
import {postData, putData, getData} from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

/* import status from json */
import statusData from '../../../../data/Prod/Status.json';

const PasswordTab = props => {
  const {t} = useTranslation(); //for translation
  const token = localStorage.getItem('token');
  const params = useParams(); //for get the user id

  const [rolesList, setRolesList] = useState([]); //for roles

  const [userPassword, setUserPassword] = useState(''); //for passowrd

  const [isUpdating, setIsUpdating] = useState(false); //for updating status

  /*----- status requirements start ------*/
  const [statusValue, setStatusValue] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  /*----- status requirements end ------*/

  /*----- role requirements start ------*/
  const [roleValue, setRoleValue] = useState(null);
  const [userRole, setUserRole] = useState(null);
  /*----- role requirements end ------*/

  // custom style for category
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: '#666666',
      fontSize: '16px',
      fontFamily: 'sans-serif',
    }),

    // Value style
    control: styles => ({
      ...styles,
      fontSize: '16px',
      padding: '5px',
      fontFamily: 'sans-serif',
      border: '1px solid #D6D6D6',
      borderRadius: '8px',
      backgroundColor: '#fff',
      height: 50,
      boxShadow: 'none',
    }),

    // placeholder style
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: '#8E8E8E',
        padding: '5px',
        fontSize: '16px',
        marginVertical: '4px',
        fontFamily: 'sans-serif',
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = {color: '#505050'};
      return Object.assign(base, changes);
    },
  };

  //function for get all role
  const getAllRoles = async () => {
    let requestUrl =
      url.API_BaseUrl + url.API_GET_ALL_ROLES + `?token=${token}`;

    const response = await getData(requestUrl);

    if (response.status) {
      setRolesList(response.data);
    }
  };

  //function for select status
  const statusSelectionHandler = val => {
    setStatusValue(val);
    setUserStatus(val.value);
  };

  //function for select status
  const roleSelectionHandler = val => {
    setRoleValue(val);
    setUserRole(val.value);
  };

  //function for update user info
  const updateUserHandler = async e => {
    e.preventDefault();
    setIsUpdating(true);
    try {
      let userData = {
        status: userStatus,
        role: userRole,
      };

      if (userPassword != '') {
        userData = {
          password: userPassword,
          status: userStatus,
          role: userRole,
        };
      }

      let requestUrl =
        url.API_BaseUrl +
        url.API_UPDATE_USER +
        `/${params.id}` +
        `?token=${token}`;

      console.log('pass tab url----->', requestUrl);

      const response = await putData(requestUrl, userData);

      console.log('response pass tab---->', response);

      if (response.status) {
        props.afterUpdatePasswordTab();
        setUserPassword('');
      }
      setIsUpdating(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (props.userData) {
      /*-------- assign status to status select start --------*/
      if (props.userData.status) {
        if (props.userData.status == '1') {
          let statusData = {
            label: 'enable',
            value: '1',
          };
          statusSelectionHandler(statusData); //set status value
          setUserStatus(props.userData.status);
        } else if (props.userData.status == '0') {
          let statusData = {
            label: 'disable',
            value: '0',
          };
          statusSelectionHandler(statusData); //set status value
          setUserStatus(props.userData.status);
        } else {
          let statusData = {
            label: 'block',
            value: '2',
          };
          statusSelectionHandler(statusData); //set status value
          setUserStatus(props.userData.status);
        }
      }
      /*-------- assign status to status select end --------*/

      /*-------- assign role to role select start --------*/
      if (props.userData.role) {
        let roleData = {
          label: props.userData.role.name,
          value: props.userData.role._id,
        };
        roleSelectionHandler(roleData);
        setUserRole(props.userData.role._id);
      }
      /*-------- assign role to role select end --------*/
    }
  }, [props.userData]);

  useEffect(() => {
    getAllRoles();
  }, []);

  return (
    <>
      {props.userData ? (
        <div className='profile_tabInnr'>
          {/* ----- breadcrumb section start ------ */}
          <div className='breadcum_area'>
            <nav aria-label='breadcrumb'>
              <ul className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to='/' className='text-secondary'>
                    {t('Dashboard')}
                  </Link>
                </li>
                <li
                  className='breadcrumb-item text-gray-600'
                  aria-current='page'>
                  {t('Change Password')}
                </li>
              </ul>
            </nav>
          </div>
          {/* ----- breadcrumb section end ------ */}

          {/* ----- heading section start ------ */}
          <div className='interest_hdng'>
            <h4>{t('Change Password')}</h4>
          </div>
          {/* ----- heading section end ------ */}

          <div className='profile_graybx_Outer'>
            <div className='profile_graybx_item'>
              <div className='profile_fillOuter wd_260'>
                {/* ----- Current Password area start ------ */}
                {/* <div className="profile_fill_row">
              <label>Current Password</label>
              <div className="input_fill">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter Passowrd"
                />
              </div>
            </div> */}
                {/* ----- Current Password area end ------ */}

                {/* ----- New Password area start ------ */}
                <div className='profile_fill_row'>
                  <label>{t('New Password')}</label>
                  <div className='input_fill'>
                    <input
                      type='password'
                      className='form-control'
                      placeholder={t('Enter New Password')}
                      value={userPassword}
                      onChange={e => setUserPassword(e.target.value)}
                    />
                  </div>
                </div>
                {/* ----- New Password area end ------ */}

                {/* <div className="profile_fill_row">
              <label>Confirm Password</label>
              <div className="input_fill">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Re-enter New Passowrd"
                />
              </div>
            </div> */}

                {/* ------- status area start ------ */}
                <div className='profile_fill_row'>
                  <label>{t('Status')}</label>
                  <div className='input_fill'>
                    <Select
                      options={statusData}
                      value={statusValue}
                      onChange={val => {
                        statusSelectionHandler(val);
                      }}
                      styles={customSelectStyle}
                    />
                  </div>
                </div>
                {/* ------- status area end ------ */}

                {/* ------- role area start ------ */}
                <div className='profile_fill_row'>
                  <label>{t('User Role')}</label>
                  <div className='input_fill'>
                    <Select
                      options={rolesList}
                      value={roleValue}
                      onChange={val => {
                        roleSelectionHandler(val);
                      }}
                      styles={customSelectStyle}
                    />
                  </div>
                </div>
                {/* ------- role area end ------ */}
              </div>
              <div className='prfile_btnOuter'>
                <div className='profile_rightBtn'>
                  <button
                    className='btn btn-primary'
                    disabled={isUpdating ? true : false}
                    style={{cursor: isUpdating ? 'not-allowed' : 'pointer'}}
                    onClick={updateUserHandler}>
                    {t('Update')}
                    {isUpdating && (
                      <div
                        className='mx-2 spinner-border spinner-border-sm'
                        role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PasswordTab;
