/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/*import url and gateway methods */
import { getData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import context
import { GlobalProvider } from 'context/GlobalContext';

/* importing schedular from kendo react schedular */
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  SchedulerItem,
} from '@progress/kendo-react-scheduler';

import baseData from '../../../data/Scheduler.json';

const HolidayCalendarBody = () => {
  const token = localStorage.getItem('token'); // token

  const {
    reloadHolidayCalendar,
    setReloadHolidayCalendar,
    setEditHolidayCalendarData,
  } = useContext(GlobalProvider);

  // default user data ------------------------------------
  const [calendarData, setCalendarData] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  // get json data about date ------------------------------
  const currentYear = new Date().getFullYear();

  const parseAdjust = eventDate => {
    console.log('eventDate >>', eventDate);

    const date = new Date(eventDate);
    console.log('eventDate Date>>', date);

    //date.setFullYear(currentYear);

    console.log('eventDate Date year>>', date);
    return date;
  };

  // function for get all calendar data
  const getAllCalendarData = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl + url.API_GET_ALL_HOLIDAY_SCHEDULE + `?token=${token}`;

      console.log('Url of holiday list========>', requestUrl);

      const response = await getData(requestUrl);

      console.log(`response in calender list ==============>`, response.data);
      if (response.status) {
        setCalendarData(response.data);
        setResponseReceived(true);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const displayDate = new Date();

  // set response to match kendo scheduler event schema --------------------------------
  const kendoCalendarData = calendarData.map(dataItem => ({
    id: dataItem._id,
    start: parseAdjust(dataItem.start, dataItem.starttime),
    end: parseAdjust(dataItem.end, dataItem.endtime),
    isAllDay: false,
    title: dataItem.title,
    description: dataItem.description,
    ownerId: dataItem.availability,
    date: dataItem.date,
    availability: dataItem.availability,
    starttime: dataItem.starttime,
    endtime: dataItem.endtime,
  }));

  /*---------- Handel Edit Calendar Schedule data ------------*/
  let scheduleItemHandler = event => {
    console.log('Clicked Event Id -->', event.target.props.dataItem);

    //assign schedule date in context
    setEditHolidayCalendarData(event.target.props.dataItem);

    const offcanvas = new window.bootstrap.Offcanvas(
      document.getElementById('addHolidaySchedulePopup')
    );
    offcanvas.show();
  };

  /*--------- function for custom schedule ---------*/
  const CustomSchedule = props => (
    <SchedulerItem
      {...props}
      onClick={scheduleItemHandler}
      style={{
        ...props.style,
        background: 'red',
      }}
    />
  );

  /* hook to reload list */
  useEffect(() => {
    if (reloadHolidayCalendar) {
      getAllCalendarData();
      setReloadHolidayCalendar(false);
    }
  }, [reloadHolidayCalendar]);

  useEffect(() => {
    if (responseReceived === false) {
      getAllCalendarData();
    }
  }, [responseReceived]);

  return (
    <>
      <Scheduler
        data={kendoCalendarData}
        // editable={{
        //   remove: true
        // }}
        defaultDate={displayDate}
        item={CustomSchedule}
      >
        <MonthView />
        <WeekView />
        <DayView />
      </Scheduler>
    </>
  );
};

export default HolidayCalendarBody;
