/* eslint-disable */
import { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
//import translation function for language translation
import { useTranslation } from 'react-i18next';

/*import url and gateway methods */
import { getData, putData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import images
import { assetImages } from 'constants';

//import options form json file
import SelectOption from 'data/SelectOptions.json';
/* ------- import currency ----------- */
import currency from 'data/Prod/Currency.json';
import CustomNotification from 'components/common/CustomNotification';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const PersonalInfoTab = props => {
  const { t } = useTranslation(); //for translation
  const params = useParams();

  const { setSuccess, setError, setNotificationString } =
    useContext(GlobalProvider);

  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  // custom style for category
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: '#666666',
      fontSize: '16px',
      fontFamily: 'sans-serif',
    }),

    // Value style
    control: styles => ({
      ...styles,
      fontSize: '16px',
      padding: '5px',
      fontFamily: 'sans-serif',
      border: '1px solid #D6D6D6',
      borderRadius: '8px',
      backgroundColor: '#fff',
      height: 50,
      boxShadow: 'none',
    }),

    // placeholder style
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: '#8E8E8E',
        padding: '5px',
        fontSize: '16px',
        marginVertical: '4px',
        fontFamily: 'sans-serif',
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: '#505050' };
      return Object.assign(base, changes);
    },
  };

  //for tag/labels use state
  const [tagList, setTagList] = useState([]);

  //service list use state
  const [serviceList, setServiceList] = useState([]);

  //use state for label/tag value
  const [tagLabelValue, setTagLabelValue] = useState(null);

  //use state for service/category value
  const [serviceValue, setServiceValue] = useState(null);

  /* useState hook to change select data */
  const [languageSelectedValue, setLanguageSelectedValue] = useState(null);
  const [currencySelectedValue, setCurrencySelectedValue] = useState(null);

  const [userName, setUserName] = useState(''); //usestate for name
  const [surname, setSurname] = useState(''); //usestate for surname
  const [position, setPosition] = useState(''); //usestate for position
  const [companyName, setCompanyName] = useState(''); //usestate for company
  const [address, setAddress] = useState(''); //usestate for location
  const [email, setEmail] = useState(''); //usestate for email
  const [country, setCountry] = useState(''); // useState for country
  const [state, setState] = useState(''); // useState for state
  const [city, setCity] = useState(''); // useState for city
  const [zipcode, setZipcode] = useState(''); //usestate for zipcode
  // const [latitude, setLatitude] = useState(''); //usestate for co lattitutde
  // const [longitude, setLongitude] = useState(''); //usestate for logitude
  const [labels, setLabels] = useState([]); //usestate for tag/labels
  const [phone, setPhone] = useState(''); //usestate for phone
  const [website, setWebsite] = useState(''); //usestate for webiste
  const [selectedLanguage, setSelectedLanguage] = useState(null); //usestate for language
  const [selectedCurrency, setSelectedCurrency] = useState(null); //usestate for currency
  const [services, setServices] = useState([]);
  const [rank, setRank] = useState(0);
  const [costPerHour, setCostPerHour] = useState(1); //use state for cose per hour
  const [isUpdating, setIsUpdating] = useState(false); //for updating status
  const [emailExistence, setEmailExistence] = useState(false);

  //function for get list of all tags/labels
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl + url.API_ALL_TAGS + `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get service/category list
  const getServiceList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_CATEGORIES +
        `?token=${token}&parent=services`;

      const response = await getData(requestUrl);

      if (response.status) {
        setServiceList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for language selection
  const languageSelectionHandler = val => {
    setLanguageSelectedValue(val);
    setSelectedLanguage(val.value);
  };

  const currencySelectionHandler = val => {
    setCurrencySelectedValue(val);
    setSelectedCurrency(val.value);
  };

  //function for select label/tag
  const labelSelectionHandler = val => {
    setTagLabelValue(val);
    const labelsArr = val.map(label => {
      return label.value;
    });
    setLabels(labelsArr);
  };

  //function for select service/category
  const serviceSelectionHandler = val => {
    setServiceValue(val);
    const serviceArr = val.map(label => {
      return label.value;
    });
    setServices(serviceArr);
  };

  /* function to fetch location from zip code */
  const getLocationFromZip = async e => {
    e.preventDefault();

    const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}&region=es&key=AIzaSyDt4dbtdHCHLj8grTzTVxpztvqUGDkvkaI`;

    if (zipcode !== '') {
      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === 'OK') {
          const results = data.results;

          if (results.length > 0) {
            // console.log(`results`, JSON.stringify(results));

            results[0]?.address_components.map(item => {
              // console.log(`item`, JSON.stringify(item));

              if (item.types[0] === 'country') {
                setCountry(item?.long_name);
              }
              if (item.types[0] === 'locality') {
                setCity(item?.long_name);
              }
              if (item.types[0] === 'administrative_area_level_1') {
                setState(item?.long_name);
              }
            });
          } else {
            console.log('No results found.');
          }
        } else {
          console.log('Request failed with status:', data.status);
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
  };

  //function for update user profile
  const updateUserHandler = async () => {
    let userData = {
      name: userName,
      company: companyName,
      surname,
      position,
      address,
      city,
      state,
      country,
      zipcode,
      tags: labels,
      email,
      phone,
      website,
      language: selectedLanguage,
      currency: selectedCurrency,
      rank,
      services,
      costperhour: costPerHour,
      currency: selectedCurrency,
    };

    console.log(`userData`, userData);

    /* ----------- to check whether email exist or not --------------- */
    // const responseEmailCheck = await fetch(
    //   `https://api.zerobounce.in/v2/validate?api_key=fee40a741bdf4cf191b78f7ebba5127b&email=${email}`
    // );
    // const data = await responseEmailCheck.json();

    // console.log('data', JSON.stringify(data));

    // if (data.status === 'valid') {
    try {
      setIsUpdating(true);
      let requestUrl =
        url.API_BaseUrl +
        url.API_UPDATE_USER +
        `/${params.id}` +
        `?token=${token}`;
      console.log('personal info tab url----->', requestUrl);
      const response = await putData(requestUrl, userData);
      console.log('response personal info tab---->', response);
      if (response.status) {
        props.afterUpdatePasswordTab();
        setSuccess(true);
        setNotificationString(
          t('Personal information has been updated successfully')
        );
      }
      setIsUpdating(false);
    } catch (error) {
      console.error(error.message);
      setError(true);
      setNotificationString(t('Something went wrong, please try again later'));
    }
    // } else {
    //   setError(true);
    //   setNotificationString(t('Email does not exist'));
    // }
  };

  useEffect(() => {
    console.log('userdata============', props.userData);
    //fetching all tag data
    getAllTags();

    //fetching all service/category list
    getServiceList();
    //check condition where user data available or not
    if (props.userData) {
      setCompanyName(props.userData.company);
      setUserName(props.userData.name);
      setSurname(props.userData.surname);
      setPosition(props.userData.position);
      setAddress(props.userData.address);
      setZipcode(props.userData.zipcode);
      setEmail(props.userData.email);
      setPhone(props.userData.phone);
      setWebsite(props.userData.website);
      setRank(props.userData.rank);
      setCostPerHour(props.userData.costperhour);
      setCountry(props.userData.country);
      setCity(props.userData.city);
      setState(props.userData.state);
      /*------ assign language start ------*/
      if (props.userData.language == 'en') {
        let langData = {
          label: 'English',
          value: 'en',
        };
        languageSelectionHandler(langData); //set language value
      } else {
        let langData = {
          label: 'Spanish',
          value: 'es',
        };
        languageSelectionHandler(langData); //set languae value
      }
      /*------ assign language end ------*/

      /*------ assign labels start -------*/
      if (props.userData.labels) {
        labelSelectionHandler(props.userData.labels);
      }
      /*------ assign labels end -------*/

      /*------ assign services start -------*/
      if (props.userData.services) {
        serviceSelectionHandler(props.userData.services);
      }
      /*------ assign services end -------*/

      /*-------- assign currency start ---------*/
      if (props.userData.currency) {
        props?.userData?.currency == 'usd'
          ? currencySelectionHandler({
              label: 'Dollar',
              value: 'usd',
              shortlabel: '$',
            })
          : currencySelectionHandler({
              label: 'Euro',
              value: 'euro',
              shortlabel: '€',
            });
      }
      /*-------- assign currency end ---------*/
    }
  }, [props.userData]);

  return (
    <div className="profile_tabInnr">
      {/* ----- breadcrumb section start ------ */}
      <div className="breadcum_area">
        <nav aria-label="breadcrumb">
          <ul className="breadcrumb list_stye_none">
            <li className="breadcrumb-item">
              <Link to="/" className="text-secondary">
                {t('Dashboard')}
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-600" aria-current="page">
              {t('Personal info')}
            </li>
          </ul>
        </nav>
      </div>
      {/* ----- breadcrumb section end ------ */}

      {/* ----- user name and image section start ------ */}
      {props.userData ? (
        <div className="personel_profile_area">
          <div className="prsnl_profile_pic">
            {/* ------ user image area start ------ */}
            <figure>
              <img
                src={
                  props.userData?.photoimage?.path ?? assetImages.defaultUser
                }
                alt=""
              />
            </figure>
            {/* ------ user image area end ------ */}

            {/* ------- image upload section start ------- */}
            <div className="upload_camera">
              <Link to="#" className="upload_files">
                <input type="file" className="upload_btn" />
                <span className="material-icons-outlined">photo_camera</span>
              </Link>
              <Link to="#" className="upload_delete">
                <span className="material-icons-outlined">delete</span>
              </Link>
            </div>
            {/* ------- image upload section end ------- */}
          </div>

          <div className="personl_profiledetails">
            <div className="profile_owner_outer">
              <div className="profile_owner_text">
                {/* ------ user name and surname ------ */}
                <h2>
                  {props.userData.name} {props.userData.surname}
                </h2>
                {/* ------ user postion ------ */}
                <h5>{props.userData.position}</h5>
                {/* <div className="edit_input input_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type name"
                  />
                </div> */}
              </div>
              {/* <div className="profile_owner_editbtn">
                <Link to="#" className="edit_pencil">
                  <span className="material-icons-outlined">edit</span>
                </Link>
                <button className="edit_send">
                  <span className="material-icons-outlined">send</span>
                </button>
              </div> */}
            </div>
            {/* <div className="profile_social">
              <ul className="list_stye_none">
                <li>
                  <Link to="#">
                    <img src={assetImages.faceboof} alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src={assetImages.gmail} alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src={assetImages.linkedin} alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src={assetImages.pinterest} alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img src="assets/img/svg/link.svg" alt="" />
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      ) : null}
      {/* ----- user name and image section end ------ */}

      <div className="profile_graybx_Outer">
        <div className="profile_graybx_item">
          <div className="profile_fillOuter">
            {/* ------ user manager section start ------ */}
            {props.userData ? (
              <div>
                {props.userData.manager ? (
                  <div className="profile_fill_row">
                    <label>{t('Manager')}</label>
                    <div className="input_fill">
                      <div className="usr_outer">
                        {/* ------- manager image section start ------- */}
                        <div className="usr_pic">
                          <img
                            src={
                              props.userData?.manager?.photoimage?.path ??
                              assetImages.defaultUser
                            }
                            alt=""
                          />
                        </div>
                        {/* ------- manager image section end ------- */}

                        {/* ------- manager name and surname section start ------- */}
                        {props.userData.manager ? (
                          <div className="usr_info">
                            <h5>
                              {props.userData.manager.name}{' '}
                              {props.userData.manager.surname}
                            </h5>
                          </div>
                        ) : null}
                        {/* ------- manager name and surname section end ------- */}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* ------ user manager section end ------ */}

            {/* ------- user name and surname section start ------- */}
            <div className="profile_fill_row">
              {/* ----- name ----- */}
              <label>{t('Name')}</label>
              <div className="input_fill me-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('name')}
                  value={userName}
                  onChange={e => setUserName(e.target.value)}
                />
              </div>

              {/* ----- surname ----- */}
              <label>{t('Surname')}</label>
              <div className="input_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('surname')}
                  value={surname}
                  onChange={e => setSurname(e.target.value)}
                />
              </div>
            </div>
            {/* ------- user name and surname section end ------- */}

            {/* ------- user email section start ------- */}
            <div className="profile_fill_row">
              <label>{t('Email')}</label>
              <div className="input_fill">
                <input
                  type="email"
                  value={email}
                  readOnly
                  onChange={e => {
                    setEmail(e.target.value), setEmailExistence(false);
                  }}
                  className="form-control"
                  placeholder={t('Enter email')}
                />
              </div>
            </div>
            {/* Email existence warning */}
            {emailExistence && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Email does not exist')}!</span>
                </p>
              </div>
            )}
            {/* ------- user email section end ------- */}

            {/* ------- user position section start ------- */}
            <div className="profile_fill_row">
              <label>{t('Position')}</label>
              <div className="input_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('Enter position')}
                  value={position}
                  onChange={e => setPosition(e.target.value)}
                />
              </div>
            </div>
            {/* ------- user position section end ------- */}

            {/* ------- user compnay section start ------- */}
            <div className="profile_fill_row">
              <label>{t('Company')}</label>
              <div className="input_fill">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('Enter company')}
                  value={companyName}
                  onChange={e => setCompanyName(e.target.value)}
                />
              </div>
            </div>
            {/* ------- user compnay section end ------- */}

            {/* ------- user address section start ------- */}
            <div className="profile_fill_row">
              <label>{t('Address')}</label>
              <div className="input_fill">
                <input
                  type="text"
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                  className="form-control"
                  placeholder={t('Enter address')}
                />
              </div>
            </div>
            {/* ------- user address section end ------- */}
            {/* ------- user zipcode section start ------- */}
            <div className="profile_fill_row">
              <label>{t('Zip Code')}</label>
              <div className="input_fill">
                <input
                  type="text"
                  value={zipcode}
                  onChange={e => setZipcode(e.target.value)}
                  className="form-control"
                  placeholder={t('Enter')}
                />
              </div>
              <button
                className="btn btn-sm btn-primary ms-2"
                onClick={getLocationFromZip}
                type="button"
              >
                {t('Fetch location by zipcode')}
              </button>
            </div>
            {/* ------- user zipcode section end ------- */}

            {/* ------- user city section start ------- */}
            <div className="profile_fill_row">
              <label>{t('City')}</label>
              <div className="input_fill">
                {/* <Select
                  options={SelectOption.city}
                  styles={customSelectStyle}
                  value={citySelectedValue}
                  onChange={val => {
                    citySelectionHandler(val);
                  }}
                /> */}
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('Enter City')}
                  value={city}
                  onChange={e => setCity(e.target.value)}
                />
              </div>
            </div>
            {/* ------- user city section end ------- */}

            {/* ------- user state section start ------- */}
            <div className="profile_fill_row">
              <label>{t('State')}</label>
              <div className="input_fill">
                {/* <Select
                  options={SelectOption.state}
                  styles={customSelectStyle}
                  value={stateSelectedValue}
                  onChange={val => {
                    stateSelectionHandler(val);
                  }}
                /> */}
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('Enter State')}
                  value={state}
                  onChange={e => setState(e.target.value)}
                />
              </div>
            </div>
            {/* ------- user state section end ------- */}

            {/* ------- user country section start ------- */}
            <div className="profile_fill_row">
              <label>{t('Country')}</label>
              <div className="input_fill">
                {/* <Select
                  options={SelectOption.country}
                  styles={customSelectStyle}
                  value={countrySelectedValue}
                  onChange={val => {
                    countrySelectionHandler(val);
                  }}
                /> */}
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('Enter Country')}
                  value={country}
                  onChange={e => setCountry(e.target.value)}
                />
              </div>
            </div>
            {/* ------- user country section end ------- */}

            {/* ------- user label/tags section start ------- */}
            {/* <div className='profile_fill_row'>
              <label>{t('labels')}</label>
              <div className='input_fill'>
                <Select
                  className='nosearch_select'
                  isMulti
                  options={tagList}
                  value={tagLabelValue}
                  onChange={val => labelSelectionHandler(val)}
                  styles={customSelectStyle}
                />
              </div>
            </div> */}
            {/* ------- user label/tags section end ------- */}

            {/* ------- user phone section start ------- */}
            <div className="profile_fill_row">
              <label>{t('Phone')}</label>
              <div className="input_fill">
                <input
                  type="number"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  className="form-control"
                  placeholder="+ 41 78920-89231"
                />
              </div>
            </div>
            {/* ------- user phone section end ------- */}

            {/* ------- user website section start ------- */}
            <div className="profile_fill_row">
              <label>{t('Website')}</label>
              <div className="input_fill">
                <input
                  type="text"
                  value={website}
                  onChange={e => setWebsite(e.target.value)}
                  className="form-control"
                  placeholder={t('Enter URL')}
                />
              </div>
            </div>
            {/* ------- user website section end ------- */}

            {/* ------- user language section start ------- */}
            <div className="profile_fill_row">
              <label>{t('Language')}</label>
              <div className="input_fill">
                <Select
                  className="nosearch_select"
                  options={SelectOption.languages}
                  value={languageSelectedValue}
                  onChange={val => {
                    languageSelectionHandler(val);
                  }}
                  styles={customSelectStyle}
                />
              </div>
            </div>
            {/* ------- user language section start ------- */}

            {/* ------- user services section start ------- */}
            <div className="profile_fill_row">
              <label>{t('Services')}</label>
              <div className="input_fill">
                <Select
                  isMulti
                  styles={customSelectStyle}
                  options={serviceList}
                  value={serviceValue}
                  onChange={val => {
                    serviceSelectionHandler(val);
                  }}
                />
              </div>
            </div>
            {/* ------- user services section end ------- */}

            {/* ------- cost per hour and currency row start ------- */}
            <div className="profile_fill_row">
              {/* ------- cost per hour section start ------- */}
              <label>{t('Cost per hour')}</label>
              <div className="input_fill">
                <input
                  type="number"
                  value={costPerHour}
                  onChange={e => setCostPerHour(e.target.value)}
                  className="form-control"
                  placeholder={t('Enter cost per hour')}
                />
              </div>
              {/* ------- cost per hour section end ------- */}

              {/* ------- currency section start ------- */}
              <label className="ms-3">{t('Currency')}</label>
              <div className="input_fill">
                <Select
                  className="nosearch_select"
                  options={currency}
                  value={currencySelectedValue}
                  onChange={val => {
                    currencySelectionHandler(val);
                  }}
                  styles={customSelectStyle}
                />
              </div>
              {/* ------- currency section end ------- */}
            </div>
            {/* ------- cost per hour and currency row end ------- */}

            {/* ------- user rank section start ------- */}
            {
              (userInfo.role.name = 'admin' && (
                <div className="profile_fill_row">
                  <label>{t('Rank')}</label>
                  <div className="input_fill">
                    <input
                      type="number"
                      value={rank}
                      onChange={e => setRank(e.target.value)}
                      className="form-control"
                      placeholder={t('Enter Rank')}
                    />
                  </div>
                </div>
              ))
            }
            {/* ------- user rank section end ------- */}
          </div>

          {/* ------ update and cacel button section start ------ */}
          <div className="prfile_btnOuter">
            <div className="profile_rightBtn">
              {/* ------- update button ------ */}
              <button
                className="btn btn-primary"
                onClick={updateUserHandler}
                style={{ cursor: isUpdating ? 'not-allowed' : 'pointer' }}
                disabled={isUpdating ? true : false}
              >
                {t('Update')}
                {isUpdating && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
              {/* ------ cancel button ------ */}
              <button className="btn btn-secondary">{t('Cancel')}</button>
            </div>
          </div>
          {/* ------ update and cacel button section end ------ */}
        </div>
      </div>
      {/* custom notification component */}
      <CustomNotification />
    </div>
  );
};

export default PersonalInfoTab;
