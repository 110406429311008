/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/*import url and gateway methods */
import { putData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const MailTemplateHeader = ({
  setDownloadExcel,
  setSearch,
  records,
  blockRefSelected,
}) => {
  const {
    setReloadMailTemplateList,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const { t } = useTranslation(); //for translation

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState('');

  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);

    setTimeout(() => setDownloadExcel(false), 500);
  };

  //function for delete selected mail
  const deleteMailTemplateHandler = e => {
    e.preventDefault();

    const token = localStorage.getItem('token'); // token

    const checkboxes = document.querySelectorAll(
      'input[name="selectMailRecord"]'
    );

    checkboxes.forEach(async checkbox => {
      if (checkbox.checked) {
        let mailData = {
          status: '0',
        };

        let requestUrl =
          url.API_BaseUrl +
          url.API_UPDATE_TEMPLATE +
          `/${checkbox.value}` +
          `?token=${token}`;

        console.log(requestUrl);

        try {
          const response = await putData(requestUrl, mailData);

          if (response.status) {
            setReloadMailTemplateList(true);
            setSuccess(true);
            setNotificationString(
              'Mail Template has been deleted successfully'
            );
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString('Something went wrong, please try again later');
        }
      }
    });
  };

  /* hook to empty search when searchValue gets empty */
  useEffect(() => {
    !searchValue && setSearch('');
  }, [searchValue]);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2 pb-1">
      {/* --------- toggle select column icon start ------- */}
      <Link
        to="#columntemplateCollapse"
        className="btn btn-primary mw-unset p-2"
        data-bs-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="columnCollapse"
      >
        <span className="d-block material-symbols-outlined icon-fill">
          visibility
        </span>
      </Link>
      {/* --------- toggle select column icon start ------- */}
      {/* -------- download in excel icon button start ------- */}
      <Link
        onClick={handleDownloadClick}
        to="#"
        className="btn btn-primary mw-unset p-2"
        data-bs-placement="top"
        title="Download XLS"
      >
        <span className="d-block material-symbols-outlined">download</span>
      </Link>
      {/* -------- download in excel icon button end ------- */}

      {/* -------- go to mail conversation link icon button start ------- */}
      <Link to="/conversation" className="btn btn-primary mw-unset p-2">
        <span
          className="d-block material-symbols-outlined icon-fill "
          data-bs-placement="top"
          title="Mail Conversation"
        >
          mail
        </span>
      </Link>
      {/* -------- go to mail conversation link icon button end ------- */}

      {/* -------- search form section start ------- */}
      <div className="search-container position-relative d-none d-md-block">
        <form onSubmit={e => e.preventDefault()}>
          <input
            type="search"
            placeholder={t('Search here')}
            className="form-control bg-transparent py-2 border-0"
            onChange={e => setSearchValue(e.target.value)}
            onKeyDown={e => {
              e.key === 'Enter' && (e.preventDefault(), setSearch(searchValue));
            }}
          />
          <button
            onClick={() => setSearch(searchValue)}
            type="button"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
          >
            <span className="d-block material-symbols-outlined">search</span>
          </button>
        </form>
      </div>
      {/* -------- search form section end ------- */}

      {/* ------------- showing records start ----------------- */}
      <p className="text-gray fw-medium ms-auto me-3">
        {t('Showing')}
        <span className="text-black ms-1">{records}</span> {t('Records')} -
        <span className="text-black ms-1" ref={blockRefSelected}>
          {' '}
          0{' '}
        </span>{' '}
        {t('Selected')}
      </p>
      {/* ------------- showing records end ----------------- */}

      {/* ----- refresh icon start ------ */}
      <Link
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset "
        onClick={() => {
          setReloadMailTemplateList(true);
          setSuccess(true);
          setNotificationString('The list has been refreshed successfully');
        }}
      >
        <span
          className="d-block material-symbols-outlined"
          data-bs-placement="top"
          title="Refresh"
        >
          refresh
        </span>
      </Link>
      {/* ----- refresh icon end ------ */}

      {/* -------- filter search icon button start ------- */}
      <Link
        to="#filterCollapse"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        data-bs-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="filterCollapse"
        data-bs-placement="top"
        title="Filter"
      >
        <span className="d-block material-symbols-outlined">filter_alt</span>
      </Link>
      {/* -------- filter search icon button end ------- */}

      {/* -------- add new mail template icon button start ------- */}
      <Link
        to="#createEmailTemplatePopup"
        data-bs-toggle="offcanvas"
        role="button"
        aria-controls="saveReservationPopup"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        data-bs-placement="top"
        title="Create mail template"
      >
        <span className="d-block material-symbols-outlined">add</span>
      </Link>
      {/* -------- add new mail template icon button end ------- */}

      {/* -------- action dropdown button start ------- */}
      <div className="dropdown">
        <button
          className="btn btn-primary dropdown-toggle mw-unset"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {t('Actions')}
        </button>
        <ul className="dropdown-menu border-0 shadow-lg">
          {/* -------- delete link start ------- */}
          <li>
            <Link
              className="dropdown-item"
              to="#tags_pnl"
              data-bs-toggle="offcanvas"
            >
              {t('Add remove Label')}
            </Link>
          </li>
          {/* -------- delete link end ------- */}
          {/* -------- delete link start ------- */}
          <li>
            <Link
              className="dropdown-item"
              to="#"
              onClick={deleteMailTemplateHandler}
            >
              {t('Delete selected')}
            </Link>
          </li>
          {/* -------- delete link end ------- */}
        </ul>
      </div>
      {/* -------- action dropdown button end ------- */}
    </div>
  );
};

export default MailTemplateHeader;
