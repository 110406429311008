/* eslint-disable */
import React, { useState, useEffect, useContext, createRef } from 'react';

// kendo react modules ==========================
import '@progress/kendo-theme-material/dist/all.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';

// Import kendo react excel
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';

// import demodata =============================
import { Link } from 'react-router-dom';

//import api requiremnts
import * as url from 'urlhelpers/UrlHelper';
import { getData, postData } from 'utils/Gateway';

//import context
import { GlobalProvider } from 'context/GlobalContext';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const ReservationList = ({
  downloadExcel,
  columns,
  refresh,
  search,
  setRecords,
  checkedRecordCount,
}) => {
  const token = localStorage.getItem('token');

  const { t } = useTranslation(); //for translation

  // console.log(`columns columns==========`, columns);

  const {
    /*------ reload requirements start -------*/
    reloadReservationList,
    setReloadReservationList,
    /*------ reload requirements end -------*/

    /* -------- reload refresh start --------- */
    reloadRefresh,
    setReloadRefresh,
    /* -------- reload refresh end --------- */

    /*------ edit requirements start -------*/
    setEditReserveData,
    setChangeReserveEducatorData,
    /*------ edit requirements end -------*/

    //for custom reseve
    setReserveIdNumber,
    setCustomReserveId,

    //for survey
    setReservePersonData,

    //for reserve emails
    setReservationEmailHistoryData,

    //for copy reseve data
    setCopyReserveData,

    /*------ search requirements start -------*/
    searchReserveDate,
    searchReserveIdList,
    searchReserveActivity,
    searchReserveLabels,
    searchReserveBenificiaries,
    searchReserveClients,
    searchReserveEducators,
    searchReserveStatus,
    setSearchReserveDate,
    setSearchReserveIdList,
    setSearchReserveActivity,
    setSearchReserveLabels,
    setSearchReserveBenificiaries,
    setSearchReserveClients,
    setSearchReserveEducators,
    setSearchReserveStatus,
    /*------ search requirements end -------*/

    screenHeight,
    setSuccess,
    setNotificationString,
    setError,
  } = useContext(GlobalProvider);

  const [reservationList, setReservationList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState(null);
  const [result, setResult] = useState(
    process(reservationList, initialDataState)
  );

  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter(item => {
      const reserveid = item?.reserveid?.toString();
      const ambreservationid = item?.ambreservationid;
      const reservedate = item?.reservedate;
      const starttime = item?.starttime;
      const endtime = item?.endtime;
      const activitycode = item?.activitycode?.code?.toLowerCase();
      const activityname = item?.activitycode?.name?.toLowerCase();
      const activitytype = item?.activitytype?.toLowerCase();
      const tagstring = item?.tagstring?.toLowerCase();
      const educator1 = item?.educator1?.toLowerCase();
      const educator2 = item?.educator2?.toLowerCase();
      const client = item?.client?.toLowerCase();
      const observer = item?.observer?.toLowerCase();
      const beneficiary = item?.beneficiary?.toLowerCase();
      const participantcount = item?.participantcount;
      const educatorcount = item?.educatorcount;
      const realparticipantcount = item?.realparticipantcount;
      const tutor = item?.tutor;
      const grade = item?.grade?.toLowerCase();
      const salesorder = item?.salesorder?.toString();
      const comments = item?.comments?.toLowerCase();
      const location = item?.location?.toLowerCase();
      const zipcode = item?.zipcode;
      const reservestatus = item?.reservestatus?.toLowerCase();

      return (
        (reserveid && reserveid.includes(search)) ||
        (ambreservationid &&
          typeof ambreservationid === 'string' &&
          ambreservationid.includes(search)) ||
        (reservedate && reservedate.includes(search)) ||
        (starttime && starttime.includes(search)) ||
        (endtime && endtime.includes(search)) ||
        (activitycode && activitycode.includes(search.toLowerCase())) ||
        (activityname && activityname.includes(search.toLowerCase())) ||
        (activitytype && activitytype.includes(search.toLowerCase())) ||
        (tagstring && tagstring.includes(search.toLowerCase())) ||
        (educator1 && educator1.includes(search.toLowerCase())) ||
        (educator2 && educator2.includes(search.toLowerCase())) ||
        (client && client.includes(search.toLowerCase())) ||
        (observer && observer.includes(search.toLowerCase())) ||
        (beneficiary && beneficiary.includes(search.toLowerCase())) ||
        (participantcount && participantcount.toString().includes(search)) ||
        (educatorcount && educatorcount.toString().includes(search)) ||
        (realparticipantcount &&
          realparticipantcount.toString().includes(search)) ||
        (comments && comments.includes(search.toLowerCase())) ||
        (tutor && tutor.includes(search)) ||
        (grade && grade.includes(search.toLowerCase())) ||
        (salesorder && salesorder.includes(search)) ||
        (location && location.includes(search.toLowerCase())) ||
        (zipcode && zipcode.includes(search)) ||
        (reservestatus && reservestatus.includes(search.toLowerCase()))
      );
    });
    setRecords(filteredData.length);
    return filteredData;
  };

  // fetch data from webservice
  // var webServiceUrl = "http://localhost/funbrain/?token=ZnVuYnJhaW4=";

  const getAllReservationList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl + url.API_GET_ALL_RESERVE_EVENTS + `?token=${token}`;

      if (searchReserveDate != '') {
        requestUrl = requestUrl + `&eventdate=${searchReserveDate}`;
      }

      if (searchReserveIdList.length > 0) {
        requestUrl = requestUrl + `&reserveids=${searchReserveIdList}`;
      }

      if (searchReserveActivity.length > 0) {
        requestUrl = requestUrl + `&reserveactivity=${searchReserveActivity}`;
      }

      if (searchReserveLabels.length > 0) {
        requestUrl = requestUrl + `&reservelabels=${searchReserveLabels}`;
      }

      if (searchReserveBenificiaries.length > 0) {
        requestUrl =
          requestUrl + `&reservebenificiary=${searchReserveBenificiaries}`;
      }
      if (searchReserveClients.length > 0) {
        requestUrl = requestUrl + `&reserveclients=${searchReserveClients}`;
      }

      if (searchReserveEducators.length > 0) {
        requestUrl = requestUrl + `&reserveeducators=${searchReserveEducators}`;
      }

      if (searchReserveStatus.length > 0) {
        requestUrl = requestUrl + `&reservestatus=${searchReserveStatus}`;
      }

      console.log('reserve list url =====>>>', requestUrl);

      const response = await getData(requestUrl);

      console.log('reserve list response ========>>>', response);

      if (response.data) {
        setReservationList(response.data);
        setResponseReceived(true);

        // dataState
        //   ? setResult(process(response.data, dataState))
        //   : setResult(process(response.data, initialDataState));

        if (dataState) {
          setResult(process(response.data, dataState));
          // console.log(`response datastate====>>>`, response.data);
          console.log(`result length`, result.length);
        } else {
          setResult(process(response.data, initialDataState));
          // console.log(`response initialDataState====>>>`, response.data.length);
          setRecords(response.data.length);
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const onDataStateChange = event => {
    // console.log('event.dataState -->', event.dataState);
    setDataState(event.dataState);
    setResult(process(reservationList, event.dataState));
  };

  /* hooks to display delected columns start*/
  const [showIdColumn, setShowIdColumn] = useState(true);
  const [showAmbIdColumn, setShowAmbIdColumn] = useState(true);
  const [showDateColumn, setShowDateColumn] = useState(true);
  const [showStartTimeColumn, setShowStartTimeColumn] = useState(true);
  const [showEndTimeColumn, setShowEndTimeColumn] = useState(true);
  const [showCodeColumn, setShowCodeColumn] = useState(true);
  const [showActivityColumn, setShowActivityColumn] = useState(true);
  const [showActivityTypeColumn, setShowActivityTypeColumn] = useState(true);
  const [showTagsColumn, setShowTagsColumn] = useState(true);
  const [showEducator1Column, setShowEducator1Column] = useState(true);
  const [showEducator2Column, setShowEducator2Column] = useState(true);
  const [showSalesOrderClientColumn, setShowSalesOrderClientColumn] =
    useState(true);
  const [showObserverColumn, setShowObserverColumn] = useState(true);
  const [showBeneficiaryColumn, setShowBeneficiaryColumn] = useState(true);
  const [showNumParticipantsColumn, setShowNumParticipantsColumn] =
    useState(true);
  const [showNumEducatorColumn, setShowNumEducatorColumn] = useState(true);
  const [showNumParticipantsRealColumn, setShowNumParticipantsRealColumn] =
    useState(true);
  const [showTutorColumn, setShowTutorColumn] = useState(true);
  const [showGradeColumn, setShowGradeColumn] = useState(true);
  const [showSalesOrderColumn, setShowSalesOrderColumn] = useState(true);
  const [showCommentsColumn, setShowCommentsColumn] = useState(true);
  const [showLocationColumn, setShowLocationColumn] = useState(true);
  const [showZipCodeColumn, setShowZipCodeColumn] = useState(true);
  const [showStatusColumn, setShowStatusColumn] = useState(true);

  const [selectAll, setSelectAll] = useState(false);

  /* hooks to display delected columns ends*/
  /* function to show selected columns */
  const selectColumnsHandler = () => {
    if (columns.length > 0) {
      const columnMapping = {
        ID: setShowIdColumn,
        'AMB ID': setShowAmbIdColumn,
        Date: setShowDateColumn,
        'Start Time': setShowStartTimeColumn,
        'End Time': setShowEndTimeColumn,
        Code: setShowCodeColumn,
        Activity: setShowActivityColumn,
        'Activity Type': setShowActivityTypeColumn,
        Tags: setShowTagsColumn,
        'Educator 1': setShowEducator1Column,
        'Educator 2': setShowEducator2Column,
        Client: setShowSalesOrderClientColumn,
        Observer: setShowObserverColumn,
        Beneficiary: setShowBeneficiaryColumn,
        'No of Participants': setShowNumParticipantsColumn,
        'No of Educator': setShowNumEducatorColumn,
        'No Participants Real': setShowNumParticipantsRealColumn,
        'Sales Order': setShowSalesOrderColumn,
        Tutor: setShowTutorColumn,
        Grade: setShowGradeColumn,
        Comments: setShowCommentsColumn,
        Location: setShowLocationColumn,
        Zipcode: setShowZipCodeColumn,
        Status: setShowStatusColumn,
      };

      Object.values(columnMapping).forEach(func => func(false));

      columns.forEach(column => {
        if (columnMapping[column]) {
          columnMapping[column](true);
        }
      });
    }
  };

  /* function to refresh columns */
  const refreshColumns = () => {
    setShowIdColumn(true);
    setShowAmbIdColumn(true);
    setShowDateColumn(true);
    setShowStartTimeColumn(true);
    setShowEndTimeColumn(true);
    setShowCodeColumn(true);
    setShowActivityColumn(true);
    setShowActivityTypeColumn(true);
    setShowTagsColumn(true);
    setShowEducator1Column(true);
    setShowEducator2Column(true);
    setShowSalesOrderClientColumn(true);
    setShowObserverColumn(true);
    setShowBeneficiaryColumn(true);
    setShowNumParticipantsColumn(true);
    setShowNumEducatorColumn(true);
    setShowNumParticipantsRealColumn(true);
    setShowCommentsColumn(true);
    setShowLocationColumn(true);
    setShowStatusColumn(true);
  };

  const mailToBeneficiary = async(dataItem) => {
    console.log(dataItem);

    let beneficiaryMailsArray = [];

    if(dataItem.beneficiaries){

      dataItem.beneficiaries.map((beneficiaryData)=>{

        beneficiaryMailsArray.push(beneficiaryData.email);

      })

      console.log('beneficiaryMailsArray', beneficiaryMailsArray);

      const mailData = {
        reserve : dataItem._id,
        template : 'reserve-info',
        receivermailarray : beneficiaryMailsArray
      }

      try {

        let requestUrl =
          url.API_BaseUrl + url.API_SEND_MESSAGE_BY_TEMPLATE + `?token=${token}`;

        const response = await postData(requestUrl, mailData);

        if (response.status) {
          console.log(response);
          setNotificationString('Mail sent to beneficiary');
          setSuccess(true);
        }

      } catch (error) {
        console.error(error.message);
      }



    }
    
    

      


  };

  // Primary Block
  const primaryBlockHtml = props => {
    //function for download material

    /* function to download file */
    const materialDownloadHandler = async () => {
      const uploads = props.dataItem.activitycode.uploads;
      const beneficiaryUploads = props.dataItem.activitycode.beneficiaryuploads;

      const mergedFiles = uploads.concat(beneficiaryUploads);

      if (mergedFiles.length > 0) {
        for (const file of mergedFiles) {
          try {
            const response = await fetch(file.path);
            const blob = await response.blob();
            const fileName = file.name;

            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = fileName;
              link.click();
              window.URL.revokeObjectURL(url);
            }
          } catch (error) {
            console.error('Error occurred while downloading the file:', error);
          }
        }
      }
    };

    return (
      <td className="kendoCustomColumn overflow-visible">
        <div className="d-flex align-items-center gap-2">
          {/* ------- custom reserve id start ------- */}
          <p className="me-2">{props.dataItem.reserveid}</p>
          {/* ------- custom reserve id end ------- */}

          <span
            className={`d-block material-symbols-outlined icon-md me-2 ${
              props.dataItem.zipcode ? 'text-green' : 'text-danger'
            }`}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="location zipcode"
          >
            location_on
          </span>

          {/* --------- reserve history with icon start ---------- */}
          <Link
            to="#historyReservePopup"
            className="text-black"
            data-bs-toggle="offcanvas"
            role="button"
            aria-controls="historyReservePopup"
            onClick={() => {
              setReserveIdNumber(props.dataItem.reserveid);
            }}
          >
            <span
              className="d-block material-symbols-outlined icon-md"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="History of modification"
            >
              history
            </span>
          </Link>
          {/* --------- reserve history with icon end ---------- */}

          {/* --------- edit reserve with icon start ---------- */}
          <Link
            to="#saveReservationPopup"
            className="text-black ms-2"
            data-bs-toggle="offcanvas"
            role="button"
            aria-controls="saveReservationPopup"
            onClick={() => {
              setEditReserveData(props.dataItem);
            }}
          >
            <span
              className="d-block material-symbols-outlined icon-md"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="update reserve data"
            >
              edit
            </span>
          </Link>
          {/* --------- edit reserve with icon end ---------- */}

          {/* -------- dropdown section start -------- */}
          <div className="dropdown cell-dropdown ms-auto mt-2">
            {/* ------ more vert icon start ------ */}
            <button
              className=" bg-transparent p-0 border-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="d-block material-symbols-outlined">
                more_vert
              </span>
            </button>
            {/* ------ more vert icon end ------ */}

            {/* ------ dropdown list start ------ */}
            <ul className="dropdown-menu border-0 shadow-lg">
              {/* ----- download material start ------ */}
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={materialDownloadHandler}
                >
                  {t('Download Materials')}
                </Link>
              </li>
              {/* ----- download material end ------ */}

              {/* ----- Send Activity start ------ */}
              <li>
                {/* <Link
                  className="dropdown-item"
                  to={`/app/reservedetails?reserveid=${props.dataItem.reserveid}`}
                  target="_blank"
                > */}
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={() => {
                    mailToBeneficiary(props.dataItem);
                  }}
                >
                  {t('Send Activity to beneficiary')}
                </Link>
              </li>
              {/* ----- Send Activity end ------ */}

              {/* ----- view feedback start ------ */}
              <li>
                <Link
                  className="dropdown-item"
                  to="#allTotalReserveSurveyPopup"
                  data-bs-toggle="offcanvas"
                  role="button"
                  aria-controls="saveReservationPopup"
                  onClick={() => setCustomReserveId(props.dataItem.reserveid)}
                >
                  {t('View Feedback')}
                </Link>
              </li>
              {/* ----- view feedback end ------ */}

              {/* ----- Copy reserve start ------ */}
              <li>
                <Link
                  className="dropdown-item"
                  to="#saveReservationPopup"
                  data-bs-toggle="offcanvas"
                  role="button"
                  aria-controls="saveReservationPopup"
                  onClick={() => {
                    setCopyReserveData(props.dataItem);
                  }}
                >
                  {t('Copy reserve')}
                </Link>
              </li>
              {/* ----- Copy reserve end ------ */}

              {/* ----- Calculate Cost start ------ */}
              {/* {props.dataItem.iscostcalculated == '1' ? null : (
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    role="button"
                    aria-controls="costcalculation"
                    onClick={createCostHandler}
                  >
                    {t('Calculate cost')}
                  </Link>
                </li>
              )} */}
              {/* ----- Calculate Cost end ------ */}
            </ul>
            {/* ------ dropdown list end ------ */}
          </div>
          {/* -------- dropdown section end -------- */}
        </div>
      </td>
    );
  };

  // Educator 1 Block
  const educatorOneblockHTML = props => {
    //function for change eductor set data in context
    const changeEducatorHandler = () => {
      let reserveData = {};

      if (props.dataItem.educators.length > 0) {
        reserveData = {
          startdate: props.dataItem.startdate,
          starttime: props.dataItem.starttime,
          endtime: props.dataItem.endtime,
          reserveId: props.dataItem._id,
          prevEducatore: props.dataItem.educators[0]._id,
          activitycodename: props.dataItem.activitycode
            ? props.dataItem.activitycode.code +
              ' : ' +
              props.dataItem.activitycode.name
            : ' ',
          reserveid: props.dataItem.reserveid,
          activitycode: props.dataItem.activity.value,
        };
      } else {
        reserveData = {
          startdate: props.dataItem.startdate,
          starttime: props.dataItem.starttime,
          endtime: props.dataItem.endtime,
          reserveId: props.dataItem._id,
          prevEducatore: null,
          activitycodename: props.dataItem.activitycode
            ? props.dataItem.activitycode.code +
              ' : ' +
              props.dataItem.activitycode.name
            : ' ',
          reserveid: props.dataItem.reserveid,
          activitycode: props.dataItem.activity.value,
        };
      }

      setChangeReserveEducatorData(reserveData);
    };

    //function for set person survey data in context
    const surveyHandler = () => {
      if (props.dataItem.educators.length > 0) {
        const reserveEvent = props.dataItem._id;
        const surveyPerson = props.dataItem.educators[0];
        const reserveid = props.dataItem.reserveid;
        let reserveSurveyData = {
          reserveEvent,
          surveyPerson,
          reserveid,
        };
        setReservePersonData(reserveSurveyData);
      } else {
        setReservePersonData(null);
      }
    };

    //function for mail history
    const mailHistory = () => {
      if (props.dataItem.educators.length > 0) {
        const reserveEvent = props.dataItem._id;
        const reserveId = props.dataItem.reserveid;
        const mailperson = props.dataItem.educators[0]._id;
        let reserveMailData = {
          reserveEvent,
          reserveId,
          mailperson,
        };
        setReservationEmailHistoryData(reserveMailData);
      } else {
        setReservationEmailHistoryData(null);
      }
    };

    return (
      <td className="educator">
        {/* ----- educator name section start ------ */}
        <p className="name mb-1">
          <Link
            to="#email_history_popup"
            data-bs-toggle="offcanvas"
            className="text-dark"
            onClick={mailHistory}
          >
            {props.dataItem.educator1}
          </Link>
        </p>
        {/* ----- educator name section end ------ */}

        {/* ----- educator icon section start ------ */}
        <div className="action d-flex gap-3 align-items-center">
          {props.dataItem.educator1 && (
            <p className="d-flex gap-1">
              {props.dataItem.educatoronememberrsponsedate == '' ? null : (
                <Link
                  to="#"
                  role="button"
                  aria-controls="historyOfEmailPopup"
                  className="text-dark"
                >
                  {props.dataItem.educatoronememberrsponsedate}
                </Link>
              )}
              {props.dataItem.educatoronememberstatus == '0' ? (
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Mail send"
                  className="d-block cursor-p material-symbols-outlined icon-md text-green"
                >
                  done_all
                </span>
              ) : props.dataItem.educatoronememberstatus == '2' ? (
                <span
                  className="d-block material-symbols-outlined icon-md icon-fill"
                  style={{ color: '#D22A13' }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Rejected"
                >
                  thumb_Down
                </span>
              ) : props.dataItem.educatoronememberstatus == '1' ? (
                <span
                  className="d-block material-symbols-outlined icon-md icon-fill"
                  style={{ color: '#0FCE89' }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Accepted"
                >
                  thumb_up
                </span>
              ) : null}
            </p>
          )}

          <div className="d-flex gap-2 align-items-center">
            {/* ----- educator survey popup link start ------ */}

            {props.dataItem.educator1 && (
              <Link
                to={
                  props.dataItem.educators.length > 0
                    ? '#allReserveSurveyPopup'
                    : '#'
                }
                role="button"
                className="text-gray-700"
                data-bs-toggle="offcanvas"
                onClick={surveyHandler}
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="All survey"
                  className="d-block material-symbols-outlined icon-md"
                >
                  forum
                </span>
              </Link>
            )}
            {/* ----- educator survey popup link end ------ */}

            {/* ----- educator replace popup link start ------ */}
            {props.dataItem.educatorcount > 0 && (
              <Link
                to="#selectEducatorPopup"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="saveReservationPopup"
                className="text-black"
                onClick={changeEducatorHandler}
              >
                <span
                  className="d-block material-symbols-outlined icon-md"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Select educator"
                >
                  {props.dataItem.educator1 ? 'edit' : 'add'}
                </span>
              </Link>
            )}
            {/* ----- educator replace popup link end ------ */}
          </div>
        </div>
        {/* ----- educator icon section end ------ */}
      </td>
    );
  };

  // Educator 2 Block
  const educatorTwoblockHTML = props => {
    //function for change eductor set data in context
    const changeEducatorHandler = () => {
      let reserveData = {};

      if (props.dataItem.educators.length > 1) {
        reserveData = {
          startdate: props.dataItem.startdate,
          starttime: props.dataItem.starttime,
          endtime: props.dataItem.endtime,
          reserveId: props.dataItem._id,
          prevEducatore: props.dataItem.educators[1]._id,
          activitycodename: props.dataItem.activitycode
            ? props.dataItem.activitycode.code +
              ' : ' +
              props.dataItem.activitycode.name
            : ' ',
          reserveid: props.dataItem.reserveid,
          activitycode: props.dataItem.activity.value,
        };
      } else {
        reserveData = {
          startdate: props.dataItem.startdate,
          starttime: props.dataItem.starttime,
          endtime: props.dataItem.endtime,
          reserveId: props.dataItem._id,
          prevEducatore: null,
          activitycodename: props.dataItem.activitycode
            ? props.dataItem.activitycode.code +
              ' : ' +
              props.dataItem.activitycode.name
            : ' ',
          reserveid: props.dataItem.reserveid,
          activitycode: props.dataItem.activity.value,
        };
      }

      setChangeReserveEducatorData(reserveData);
    };

    //function for set person survey data in context
    const surveyHandler = () => {
      if (props.dataItem.educators.length > 1) {
        const reserveEvent = props.dataItem._id;
        const surveyPerson = props.dataItem.educators[1];
        const reserveid = props.dataItem.reserveid;
        let reserveSurveyData = {
          reserveEvent,
          surveyPerson,
          reserveid,
        };
        setReservePersonData(reserveSurveyData);
      } else {
        setReservePersonData(null);
      }
    };

    //function for mail history
    const mailHistory = () => {
      if (props.dataItem.educators.length > 1) {
        const reserveEvent = props.dataItem._id;
        const reserveId = props.dataItem.reserveid;
        const mailperson = props.dataItem.educators[1]._id;
        let reserveMailData = {
          reserveEvent,
          reserveId,
          mailperson,
        };
        setReservationEmailHistoryData(reserveMailData);
      } else {
        setReservationEmailHistoryData(null);
      }
    };

    return (
      <td className="educator2">
        <p className="name mb-1">
          <Link
            to="#email_history_popup"
            data-bs-toggle="offcanvas"
            className="text-dark"
            onClick={mailHistory}
          >
            {props.dataItem.educator2}
          </Link>
        </p>

        {/* ----- educator icon section start ------ */}
        <div className="action d-flex gap-3 align-items-center">
          {props.dataItem.educator2 && (
            <p className="d-flex gap-1">
              {props.dataItem.educatortwomemberresponsedate == '' ? null : (
                <Link
                  to="#"
                  role="button"
                  aria-controls="historyOfEmailPopup"
                  className="text-dark"
                >
                  {props.dataItem.educatortwomemberresponsedate}
                </Link>
              )}
              {props.dataItem.educatortwomemberstatus == '0' ? (
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Mail send"
                  className="d-block cursor-p material-symbols-outlined icon-md text-green"
                >
                  done_all
                </span>
              ) : props.dataItem.educatortwomemberstatus == '2' ? (
                <span
                  className="d-block material-symbols-outlined icon-md icon-fill"
                  style={{ color: '#D22A13' }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Rejected"
                >
                  thumb_Down
                </span>
              ) : props.dataItem.educatortwomemberstatus == '1' ? (
                <span
                  className="d-block material-symbols-outlined icon-md icon-fill"
                  style={{ color: '#0FCE89' }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Accepted"
                >
                  thumb_up
                </span>
              ) : null}
            </p>
          )}

          <div className="action d-flex gap-2 align-items-center">
            {props.dataItem.educator2 && (
              <Link
                onClick={surveyHandler}
                data-bs-toggle="offcanvas"
                to={
                  props.dataItem.educators.length > 1
                    ? '#allReserveSurveyPopup'
                    : '#'
                }
                role="button"
                className="text-green"
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="All survey"
                  className="d-block material-symbols-outlined icon-md"
                >
                  forum
                </span>
              </Link>
            )}

            {props.dataItem.educatorcount > 1 && (
              <Link
                to="#selectEducatorPopup"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="saveReservationPopup"
                className="text-black"
                onClick={changeEducatorHandler}
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Select educator"
                  className="d-block material-symbols-outlined icon-md"
                >
                  {props.dataItem.educator2 ? 'edit' : 'add'}
                </span>
              </Link>
            )}
          </div>
        </div>
      </td>
    );
  };

  // Client Block
  const clientBlockHTML = props => {
    //function for set person survey data in context
    const surveyHandler = () => {
      if (props.dataItem.clients.length > 0) {
        const reserveEvent = props.dataItem._id;
        const surveyPerson = props.dataItem.clients[0];
        const reserveid = props.dataItem.reserveid;

        let reserveSurveyData = {
          reserveEvent,
          surveyPerson,
          reserveid,
        };
        setReservePersonData(reserveSurveyData);
      } else {
        setReservePersonData(null);
      }
    };

    //function for mail history
    const mailHistory = () => {
      if (props.dataItem.clients.length > 0) {
        const reserveEvent = props.dataItem._id;
        const reserveId = props.dataItem.reserveid;
        const mailperson = props.dataItem.clients[0]._id;
        let reserveMailData = {
          reserveEvent,
          reserveId,
          mailperson,
        };
        setReservationEmailHistoryData(reserveMailData);
      } else {
        setReservationEmailHistoryData(null);
      }
    };

    return (
      <td className="clients">
        <p className="name mb-1">
          <Link
            to="#email_history_popup"
            data-bs-toggle="offcanvas"
            className="text-dark"
            onClick={mailHistory}
          >
            {props.dataItem.client}
          </Link>
        </p>
        {props.dataItem.client && (
          <Link
            onClick={surveyHandler}
            to="#allReserveSurveyPopup"
            data-bs-toggle="offcanvas"
            className="text-gray-700"
          >
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="All Survey"
              className="d-block material-symbols-outlined icon-md"
            >
              forum
            </span>
          </Link>
        )}
      </td>
    );
  };

  // Observer Block
  const observerHTML = props => {
    //function for set person survey data in context
    const surveyHandler = () => {
      if (props.dataItem.observers.length > 0) {
        const reserveEvent = props.dataItem._id;
        const surveyPerson = props.dataItem.observers[0];
        const reserveid = props.dataItem.reserveid;

        let reserveSurveyData = {
          reserveEvent,
          surveyPerson,
          reserveid,
        };
        setReservePersonData(reserveSurveyData);
      } else {
        setReservePersonData(null);
      }
    };

    //function for mail history
    const mailHistory = () => {
      if (props.dataItem.observers.length > 0) {
        const reserveEvent = props.dataItem._id;
        const reserveId = props.dataItem.reserveid;
        const mailperson = props.dataItem.observers[0]._id;
        let reserveMailData = {
          reserveEvent,
          reserveId,
          mailperson,
        };
        setReservationEmailHistoryData(reserveMailData);
      } else {
        setReservationEmailHistoryData(null);
      }
    };

    return (
      <td className="observer">
        <p className="name mb-1">
          <Link
            to="#email_history_popup"
            data-bs-toggle="offcanvas"
            className="text-dark"
            onClick={mailHistory}
          >
            {props.dataItem.observer}
          </Link>
        </p>
        {props.dataItem.observer && (
          <Link
            onClick={surveyHandler}
            to="#allReserveSurveyPopup"
            data-bs-toggle="offcanvas"
            className="text-gray-700"
          >
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="All Survey"
              className="d-block material-symbols-outlined icon-md"
            >
              forum
            </span>
          </Link>
        )}
      </td>
    );
  };

  // Benificiary Block
  const benificiaryHTML = props => {
    //function for set person survey data in context
    const surveyHandler = () => {
      if (props.dataItem.beneficiaries.length > 0) {
        const reserveEvent = props.dataItem._id;
        const surveyPerson = props.dataItem.beneficiaries[0];
        const reserveid = props.dataItem.reserveid;

        let reserveSurveyData = {
          reserveEvent,
          surveyPerson,
          reserveid,
        };
        setReservePersonData(reserveSurveyData);
      } else {
        setReservePersonData(null);
      }
    };

    //function for mail history
    const mailHistory = () => {
      if (props.dataItem.beneficiaries.length > 0) {
        const reserveEvent = props.dataItem._id;
        const reserveId = props.dataItem.reserveid;
        const mailperson = props.dataItem.beneficiaries[0]._id;
        let reserveMailData = {
          reserveEvent,
          reserveId,
          mailperson,
        };
        setReservationEmailHistoryData(reserveMailData);
      } else {
        setReservationEmailHistoryData(null);
      }
    };

    return (
      <td className="beneficiary">
        <p className="name mb-1">
          <Link
            to="#email_history_popup"
            data-bs-toggle="offcanvas"
            className="text-dark"
            onClick={mailHistory}
          >
            {props.dataItem.beneficiary}
          </Link>
        </p>
        {props.dataItem.beneficiary && (
          <Link
            onClick={surveyHandler}
            to="#allReserveSurveyPopup"
            data-bs-toggle="offcanvas"
            className="text-gray-700"
          >
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="All Survey"
              className="d-block material-symbols-outlined icon-md"
            >
              forum
            </span>
          </Link>
        )}
      </td>
    );
  };

  // Location Block
  const locationHTML = props => {
    return (
      <td className="location">
        <p className="d-flex gap-2 align-items-center text-nowrap">
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={props.dataItem.location}
            className="d-block material-symbols-outlined icon-md text-black"
          >
            location_on
          </span>
          <span className="d-block">{props.dataItem.location}</span>
        </p>
      </td>
    );
  };

  // Status Block
  const statusHTML = props => {
    return (
      <td className="status">
        <p className="d-flex gap-2 align-items-center">
          <span className="d-block">
            {props.dataItem.reservestatus === '1'
              ? 'Confirmed'
              : props.dataItem.reservestatus === '3'
              ? 'Pending Confirmation'
              : props.dataItem.reservestatus === '0'
              ? 'Delete reserve'
              : 'Cancelled at the moment'}
          </span>
          <span
            className={`d-block material-symbols-outlined icon-md ${
              props.dataItem.reservestatus === '1'
                ? 'text-green'
                : 'text-danger'
            }`}
          >
            {props.dataItem.reservestatus === '1'
              ? 'check_circle'
              : props.dataItem.reservestatus === '3'
              ? 'pending'
              : 'clear'}
          </span>
        </p>
      </td>
    );
  };

  // check the selected count and show the count in top ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectReserveRecord"]'
    );

    let totalSelected = 0;
    let filteredData = [];

    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = reservationList.find(
          user => user._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });

    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = e => {
    document
      .querySelectorAll('input[name="selectReserveRecord"]')
      .forEach(checkbox => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onChange={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = props => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectReserveRecord"
            filtername="selectedrecord"
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };

  /* custom comments html */
  const commentHtml = ({ dataItem }) => {
    return (
      <td className="activity">
        <span>
          {dataItem && dataItem.comments
            ? dataItem.comments.length > 20
              ? dataItem.comments.slice(0, 20) + '...'
              : dataItem.comments
            : ''}
        </span>
      </td>
    );
  };

  /* hook to reload list */
  useEffect(() => {
    if (reloadReservationList) {
      getAllReservationList();
      setReloadReservationList(false);
    }
  }, [reloadReservationList]);

  /* ---- hook to reload list on refresh ----- */
  useEffect(() => {
    if (reloadRefresh) {
      setDataState(null);
      getAllReservationList();
      setReloadRefresh(false);
    } else {
      getAllReservationList();
    }
  }, [reloadRefresh]);

  useEffect(() => {
    if (responseReceived === false) {
      getAllReservationList();
    }
  }, [responseReceived]);

  /*------ use effect for search -------*/
  useEffect(() => {
    if (
      searchReserveDate != '' ||
      searchReserveIdList.length > 0 ||
      searchReserveActivity.length > 0 ||
      searchReserveLabels.length > 0 ||
      searchReserveBenificiaries.length > 0 ||
      searchReserveClients.length > 0 ||
      searchReserveEducators.length > 0 ||
      searchReserveStatus.length > 0
    ) {
      getAllReservationList();
    }
  }, [
    searchReserveDate,
    searchReserveIdList,
    searchReserveActivity,
    searchReserveLabels,
    searchReserveBenificiaries,
    searchReserveClients,
    searchReserveEducators,
    searchReserveStatus,
  ]);

  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);

  useEffect(() => {
    selectColumnsHandler();
  }, [columns]);

  useEffect(() => {
    refreshColumns();
  }, [refresh]);

  useEffect(() => {
    filterData();
  }, [search]);

  useEffect(() => {
    setRecords(result.data.length);
  }, [dataState]);

  return (
    <div className="table-responsive">
      {/* ============ download xls start ========== */}
      <ExcelExport
        data={
          handelSelectedCount().length > 0
            ? handelSelectedCount()
            : reservationList
        }
        fileName="Reservation List.xlsx"
        ref={_exporter}
      >
        <ExcelExportColumn field="reserveid" title={t('ID')} />
        <ExcelExportColumn field="ambreservationid" title={t('AMB Id')} />
        <ExcelExportColumn field="startdate" title={t('Date')} />
        <ExcelExportColumn field="starttime" title={t('Start Time')} />
        <ExcelExportColumn field="endtime" title={t('End Time')} />
        <ExcelExportColumn field="activitycode.code" title={t('Code')} />
        <ExcelExportColumn field="activitycode.name" title={t('Activity')} />
        <ExcelExportColumn field="activitytype" title={t('Activity Type')} />
        <ExcelExportColumn field="tagstring" title={t('Tags')} />
        <ExcelExportColumn field="educator1" title={t('Educator 1')} />
        <ExcelExportColumn field="educator2" title={t('Educator 2')} />
        <ExcelExportColumn field="client" title={t('Client')} />
        <ExcelExportColumn field="observer" title={t('Observer')} />
        <ExcelExportColumn field="beneficiary" title={t('Beneficiary')} />
        <ExcelExportColumn
          field="participantcount"
          title={t('No of Participants')}
        />
        <ExcelExportColumn field="educatorcount" title={t('No of Educator')} />
        <ExcelExportColumn
          field="realparticipantcount"
          title={t('No Participants Real')}
        />
        <ExcelExportColumn field="salesorder" title={t('Sales order')} />
        <ExcelExportColumn field="tutor" title={t('Tutor')} />
        <ExcelExportColumn field="grade" title={t('Grade')} />
        <ExcelExportColumn field="comments" title={t('Comments')} />
        <ExcelExportColumn field="location" title={t('Location')} />
        <ExcelExportColumn field="reservestatus" title={t('Status')} />
      </ExcelExport>
      {/* ============ download xls start ========== */}

      {search ? (
        <Grid
          style={{ height: screenHeight }}
          data={filterData()}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />

          <GridColumn
            field="reserveid"
            title={t('ID')}
            cell={primaryBlockHtml}
            width="200px"
          />

          {showAmbIdColumn && (
            <GridColumn
              field="ambreservationid"
              title={t('AMB Id')}
              width="200px"
            />
          )}
          {showDateColumn && (
            <GridColumn field="reservedate" title={t('Date')} width="200px" />
          )}

          {showStartTimeColumn && (
            <GridColumn
              field="starttime"
              title={t('Start Time')}
              width="200px"
            />
          )}

          {showEndTimeColumn && (
            <GridColumn field="endtime" title={t('End Time')} width="200px" />
          )}
          {showCodeColumn && (
            <GridColumn
              field="activitycode.code"
              title={t('Code')}
              width="200px"
            />
          )}
          {showActivityColumn && (
            <GridColumn
              field="activitycode.name"
              title={t('Activity')}
              width="300px"
            />
          )}

          {showActivityTypeColumn && (
            <GridColumn
              field="activitytype"
              title={t('Activity Type')}
              width="200px"
            />
          )}
          {showTagsColumn && (
            <GridColumn
              field="tagstring"
              title={t('Tags')}
              width="200px"
              //cell={reserveTagBlockHtml}
            />
          )}
          {showEducator1Column && (
            <GridColumn
              field="educator1"
              title={t('Educator 1')}
              cell={educatorOneblockHTML}
              width="210px"
            />
          )}
          {showEducator2Column && (
            <GridColumn
              field="educator2"
              title={t('Educator 2')}
              cell={educatorTwoblockHTML}
              width="200px"
            />
          )}
          {showSalesOrderClientColumn && (
            <GridColumn
              field="client"
              title={t('Client')}
              cell={clientBlockHTML}
              width="200px"
            />
          )}

          {showObserverColumn && (
            <GridColumn
              field="observer"
              title={t('Observer')}
              cell={observerHTML}
              width="200px"
            />
          )}

          {showBeneficiaryColumn && (
            <GridColumn
              field="beneficiary"
              title={t('Beneficiary')}
              cell={benificiaryHTML}
              width="200px"
            />
          )}

          {showNumParticipantsColumn && (
            <GridColumn
              field="participantcount"
              title={t('No of Participants')}
              width="200px"
            />
          )}

          {showNumEducatorColumn && (
            <GridColumn
              field="educatorcount"
              title={t('No of Educator')}
              width="200px"
            />
          )}
          {showNumParticipantsRealColumn && (
            <GridColumn
              field="realparticipantcount"
              title={t('No Participants Real')}
              width="200px"
            />
          )}
          {showTutorColumn && (
            <GridColumn field="tutor" title={t('Tutor')} width="180px" />
          )}
          {showGradeColumn && (
            <GridColumn field="grade" title={t('Grade')} width="180px" />
          )}
          {showSalesOrderColumn && (
            <GridColumn
              field="salesorder"
              title={t('Sales order')}
              width="180px"
            />
          )}
          {showCommentsColumn && (
            <GridColumn
              field="comments"
              title={t('Comments')}
              cell={commentHtml}
              width="200px"
            />
          )}
          {showLocationColumn && (
            <GridColumn
              field="location"
              title={t('Location')}
              cell={locationHTML}
              width="200px"
            />
          )}
          {showZipCodeColumn && (
            <GridColumn field="zipcode" title={t('Zipcode')} width="200px" />
          )}
          {showStatusColumn && (
            <GridColumn
              cell={statusHTML}
              field="reservestatus"
              title={t('Status')}
              width="200px"
            />
          )}
        </Grid>
      ) : (
        <Grid
          style={{ height: screenHeight }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />

          {showIdColumn && (
            <GridColumn
              field="reserveid"
              title={t('ID')}
              cell={primaryBlockHtml}
              width="200px"
            />
          )}
          {showAmbIdColumn && (
            <GridColumn
              field="ambreservationid"
              title={t('AMB Id')}
              width="200px"
            />
          )}
          {showDateColumn && (
            <GridColumn field="reservedate" title={t('Date')} width="200px" />
          )}

          {showStartTimeColumn && (
            <GridColumn
              field="starttime"
              title={t('Start Time')}
              width="200px"
            />
          )}
          {showEndTimeColumn && (
            <GridColumn field="endtime" title={t('End Time')} width="200px" />
          )}
          {showCodeColumn && (
            <GridColumn
              field="activitycode.code"
              title={t('Code')}
              width="200px"
            />
          )}
          {showActivityColumn && (
            <GridColumn
              field="activitycode.name"
              title={t('Activity')}
              width="300px"
            />
          )}

          {showActivityTypeColumn && (
            <GridColumn
              field="activitytype"
              title={t('Activity Type')}
              width="200px"
            />
          )}
          {showTagsColumn && (
            <GridColumn
              field="tagstring"
              title={t('Tags')}
              width="200px"
              //cell={reserveTagBlockHtml}
            />
          )}
          {showEducator1Column && (
            <GridColumn
              field="educator1"
              title={t('Educator 1')}
              cell={educatorOneblockHTML}
              width="210px"
            />
          )}
          {showEducator2Column && (
            <GridColumn
              field="educator2"
              title={t('Educator 2')}
              cell={educatorTwoblockHTML}
              width="200px"
            />
          )}
          {showSalesOrderClientColumn && (
            <GridColumn
              field="client"
              title={t('Client')}
              cell={clientBlockHTML}
              width="200px"
            />
          )}

          {showObserverColumn && (
            <GridColumn
              field="observer"
              title={t('Observer')}
              cell={observerHTML}
              width="200px"
            />
          )}

          {showBeneficiaryColumn && (
            <GridColumn
              field="beneficiary"
              title={t('Beneficiary')}
              cell={benificiaryHTML}
              width="200px"
            />
          )}

          {showNumParticipantsColumn && (
            <GridColumn
              field="participantcount"
              title={t('No of Participants')}
              width="200px"
            />
          )}

          {showNumEducatorColumn && (
            <GridColumn
              field="educatorcount"
              title={t('No of Educator')}
              width="200px"
            />
          )}
          {showNumParticipantsRealColumn && (
            <GridColumn
              field="realparticipantcount"
              title={t('No Participants Real')}
              width="200px"
            />
          )}
          {showTutorColumn && (
            <GridColumn field="tutor" title={t('Tutor')} width="180px" />
          )}
          {showGradeColumn && (
            <GridColumn field="grade" title={t('Grade')} width="180px" />
          )}
          {showSalesOrderColumn && (
            <GridColumn
              field="salesorder"
              title={t('Sales order')}
              width="180px"
            />
          )}
          {showCommentsColumn && (
            <GridColumn
              field="comments"
              title={t('Comments')}
              cell={commentHtml}
              width="200px"
            />
          )}
          {showLocationColumn && (
            <GridColumn
              field="location"
              title={t('Location')}
              cell={locationHTML}
              width="200px"
            />
          )}
          {showZipCodeColumn && (
            <GridColumn field="zipcode" title={t('Zipcode')} width="200px" />
          )}
          {showStatusColumn && (
            <GridColumn
              cell={statusHTML}
              field="reservestatus"
              title={t('Status')}
              width="200px"
            />
          )}
        </Grid>
      )}
    </div>
  );
};

export default ReservationList;
