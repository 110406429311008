/*=========== general URL start here ============*/

/*---------------- Dev server url ------------------- */
export const API_BaseUrl = "http://5.56.62.67:4000/api";
export const API_BACKEND_URL = "http://5.56.62.67:4000/";

/*--------------- local server url ------------------- */
// export const API_BaseUrl = "http://localhost:4000/api";
// export const API_BACKEND_URL = "http://localhost:4000/";

// URL to upload Image in Text Editor (AskQuestions.js) component.
export const URL_TEXT_EDITOR_IMAGE_UPLOAD =
  "https://dev.lobees.com/web/uploads/documents/";

export const URL_TEXT_EDITOR_IMAGE_UPLOAD_2 =
  "https://dev.lobees.com/web/uploads/reports/";

export const API_FILE_UPLOAD = "/upload/singleupload"; //for single file upload

export const API_FILE_UPLOAD_MULTIPLE = "/upload/multiupload"; //for multiple file upload

// API Tags (to fetch all the tags and display in AddTagsModal.js)
export const API_ALL_TAGS = "/tag/getalltags";

//API Categories
export const API_GET_CATEGORIES = "/category/getcategories";

//API get all roles
export const API_GET_ALL_ROLES = "/roles/getroles";

// API for list of all champion skills
export const API_ALL_SKILLS = "";

// API to create new tag
export const API_CREATE_NEW_TAG = "";

// API to submit a rating for a course
export const API_REVIEW_RATING = "";

// API to fetch all the reviews for a course
export const API_VIEW_REVIEWS = "";

/*--------- blog api start ----------*/
//Api for get blog category
export const API_BLOG_CATEGORY = "";

//Api for all blog lists
export const API_BLOG_LISTS = "";

//API for blog details
export const API_BLOG_DETAILS = "";
/*--------- blog api end ----------*/

/*-------- login and registration url start ----------*/
// API registration
export const API_REGISTARTION = "/auth/register";

// API forgot password
export const API_FORGOT_PASSWORD = "";

// API login url
export const API_LOGINURL = "/auth/login";

// API update userinfo
export const API_UPDATE_USERINFO = "/useraccount/updateuser";

// API validate user
export const API_VALIDATE_OTP = "/auth/validateotp";

// url for get logged in user details
export const API_GET_USER_DETAILS = "/useraccount/userdetails";

// API for contact Us slider
export const API_CONTACT_US = "";

/*-------- login and registration url end ----------*/

/*-------- userlist url start --------*/
//add new user
export const API_ADD_NEW_USER = "/useraccount/addnewuser";

//update user
export const API_UPDATE_USER = "/useraccount/updateuser";

//get all user list url
export const API_GET_ALL_USER = "/useraccount/userlists";

//add tag to user
export const API_ADD_TAG_TO_USER = "/useraccount/addtagtouser";

//remove tag from user
export const API_REMOVE_TAG_FROM_USER = "/useraccount/removetagfromuser";

/*-------- userlist url end --------*/

/*-------- event url start ----------*/

//get all list of events
export const API_GET_ALL_EVENTS = "/event/getallevents";

/*-------- event url end ----------*/

/*-------- reserve course url start --------*/
//add new course
export const API_ADD_NEW_COURSE = "/reservecourse/createcourse";

//get all course
export const API_GET_ALL_COURSE = "/reservecourse/getallcourse";

//update a course
export const API_UPDATE_COURSE = "/reservecourse/updatecourse";

//delete a course
export const API_DELETE_COURSE = "/reservecourse/deletecourse";
/*-------- reserve course url end --------*/

/*-------- material kit url start --------*/
//add new material kit
export const API_ADD_NEW_MATERIAL_KIT = "/materialkit/addmaterialkit";

//get all material kit
export const API_GET_ALL_MATERIAL_KIT = "/materialkit/getallmaterialkit";

//update material kit
export const API_UPDATE_MATERIAL_KIT = "/materialkit/updatekit";

//delete material kit
export const API_DELETE_MATERIAL_KIT = "/materialkit/deletekit";

/*-------- material kit url end --------*/

/*-------- activity url start --------*/
//save activity
export const API_ADD_ACTIVITY = "/reserveactivity/addactivity";

//get all activity
export const API_GET_ALL_ACTIVITY = "/reserveactivity/getallactivity";

//update activity
export const API_UPDATE_ACTIVITY = "/reserveactivity/updateactivity";

//delete activity
export const API_DELETE_ACTIVITY = "/reserveactivity/deleteactivity";

/*-------- activity url end --------*/

/*-------- reserve event url start --------*/
//add event
export const API_ADD_RESERVE_EVENT = "/reserveevent/createevent";

//get all event
export const API_GET_ALL_RESERVE_EVENTS = "/reserveevent/getallevent";

//get event details
export const API_GET_RESERVE_DETAILS = "/reserveevent/geteventdetails";

//get all activity event
export const API_GET_ALL_ACTIVITY_EVENTS = "/reserveevent/getallactivityevents";

//get event histroy
export const API_GET_EVENT_HISTORY = "/reserveevent/geteventhistory";

//get all user email by events
export const API_GET_USER_EMAIL_BY_EVENT = "/reserveevent/getalluseremails";

//update event
export const API_UPDATE_RESERVE_EVENT = "/reserveevent/updateevent";

//change educator
export const API_CHANGE_EDUCATOR = "/reserveevent/changeeducator";

//delete event
export const API_DELETE_RESERVE_EVENT = "/reserveevent/deleteevent";

//add tag to event
export const API_ADD_TAG_TO_EVENT = "/reserveevent/addtagtoevent";

//add tag to event
export const API_UPDATE_EVENT_STATUS = "/reserveevent/updatestatus";

/*-------- reserve event url end --------*/

/*-------- reserve tag/label url start --------*/
//create label
export const API_ADD_TAG = "/tag/createtag";

//get all label
export const API_GET_ALL_TAG = "/tag/getalltags";

//update label
export const API_UPDATE_TAG = "/tag/updatetag";

//delete label
export const API_DELETE_TAG = "/tag/deletetag";

/*-------- reserve tag/label url end --------*/

/*-------- schedule url start --------*/
//add new schedule
export const API_ADD_NEW_SCHEDULE = "/schedule/addschedule";

//get all schedule
export const API_GET_ALL_SCHEDULE = "/schedule/getallschedule";

//update schedule
export const API_UPDATE_SCHEDULE = "/schedule/updateschedule";

//delete schedule
export const API_DELETE_SCHEDULE = "/schedule/deleteschedule";

//get all educator with schedule
export const GET_ALL_EDUCATOR = "/schedule/geteducatorschedule";

/*-------- schedule url end --------*/

/*-------- holiday schedule url start --------*/
//add new schedule
export const API_ADD_NEW_HOLIDAY_SCHEDULE =
  "/holidayschedule/addholidayschedule";

//get all schedule
export const API_GET_ALL_HOLIDAY_SCHEDULE =
  "/holidayschedule/getallholidayschedule";

//update schedule
export const API_UPDATE_HOLIDAY_SCHEDULE =
  "/holidayschedule/updateholidayschedule";

//delete schedule
export const API_DELETE_HOLIDAY_SCHEDULE =
  "/holidayschedule/deleteholidayschedule";

/*-------- holiday schedule url end --------*/

/*--------- question type url start ---------*/

//get all question type
export const API_GET_ALL_QUESTION_TYPE = "/questiontype/getallquestiontype";
/*--------- question type url end ---------*/

/*--------- question url start ---------*/
//add question
export const API_CREATE_NEW_QUESTION = "/question/creatquestion";

//get all question
export const API_GET_ALL_QUESTION = "/question/getallquestions";

//update question
export const API_UPDATE_QUESTION = "/question/updatequestion";

//delete question
export const API_DELETE_QUESTION = "/question/deletequestion";
/*--------- question url end ---------*/

/*--------- survey url start ---------*/
//create survey
export const API_CREATE_SURVEY = "/survey/createsurvey";

//get all survey
export const API_GET_ALL_SURVEY = "/survey/getallsurvey";

//update survey
export const API_UPDATE_SURVEY = "/survey/updatesurvey";

//delete survey
export const API_DELETE_SURVEY = "/survey/deletesurvey";

/*--------- survey url end ---------*/

/*-------- mail template start --------*/
//create
export const API_ADD_TEMPLATE = "/template/create";

//get all
export const API_GET_ALL_TEMPLATE = "/template/getall";

//update
export const API_UPDATE_TEMPLATE = "/template/update";

//delete
export const API_DELETE_TEMPLATE = "/template/delete";

//add remove tag
export const API_ADD_REMOVE_TAG_TEMPLATE = "/template/addtagtotemplate";

/*-------- mail template end --------*/

/*-------- mail start --------*/
//create
export const API_ADD_MESSAGE = "/message/savemail";

//get all
export const API_GET_ALL_MESSAGE = "/message/getallmails";

//get all reserve mail
export const API_GET_ALL_RESERVE_MESSAGE = "/message/getallreservemail";

//update mail
export const API_UPDATE_MAIL = "/message/updatemail";

//delete
export const API_DELETE_MESSAGE = "/message/deletemail";

//add tag to CONVERSATION
export const API_ADD_TAG_TO_CONVERSATION = "/message/addtagtomessage";

//mail by template
export const API_SEND_MESSAGE_BY_TEMPLATE = "/message/savemailbytemplate";

/*-------- mail end --------*/

/*-------- surveyfeedback url start ---------*/
//get all surveyfeedback
export const API_GET_ALL_SURVEY_FEEDBACK =
  "/surveyfeedback/getallsurveyfeedback";

//get a surveyfeedback
export const API_GET_SURVEY_FEEDBACK = "/surveyfeedback/surveyfeedbackdetails";

//update survey
export const API_UPDATE_SURVEY_FEEDBACK =
  "/surveyfeedback/updatesurveyfeedback";

/*-------- surveyfeedback url end ---------*/

/*---------url for event membership start ----------*/
//save membership
export const API_SAVE_EVENT_MEMBERSHIP =
  "/eventmembership/saveupdatemembership";

/*---------url for event membership end ----------*/

/*-------- url for cost start --------*/
//create cost
export const API_CREATE_COST = "/cost/addreservecost";

//get all cost
export const API_GET_ALL_COST = "/cost/getreservecost";

//update cost
export const API_UPDATE_COST = "/cost/updatereservecost";

//delete cost
export const API_DELETE_COST = "/cost/deletereservecost";

/*-------- url for cost end --------*/

/*-------- url for sales order start --------*/
//create sales order
export const API_CREATE_SALES_ORDER = "/salesorder/createsalesorder";

//get all sales order
export const API_GET_ALL_SALES_ORDER = "/salesorder/getallsalesorders";

//update sales order
export const API_UPDATE_SALES_ORDER = "/salesorder/updatesalesorder";

//delete sales order
export const API_DELETE_SALES_ORDER = "/salesorder/deletesalesorder";

//create note
export const API_CREATE_NOTE = "/salesorder/createnote";

//get all note
export const API_GET_ALL_NOTE = "/salesorder/getallnotes";

/*-------- url for sales order end --------*/

/*-------- url for concept start --------*/
//create sales order
export const API_ADD_NEW_CONCEPT = "/concept/createconcept";

//get all sales order
export const API_GET_ALL_CONCEPT = "/concept/getallconcepts";

//update sales order
export const API_UPDATE_CONCEPT = "/concept/updateconcept";

//delete sales order
export const API_DELETE_CONCEPT = "/concept/deleteconcept";

/*-------- url for concept order end --------*/

/*-------- schedulematerialkit url start --------*/
//add new schedule
export const API_ADD_NEW_SCHEDULE_MATERIALKIT =
  "/schedulematerialkit/addschedulematerialkit";

//get all schedule
export const API_GET_ALL_SCHEDULE_MATERIALKIT =
  "/schedulematerialkit/getallschedulematerialkit";

//update schedule
export const API_UPDATE_SCHEDULE_MATERIALKIT =
  "/schedulematerialkit/updateschedulematerialkit";

//delete schedule
export const API_DELETE_SCHEDULE_MATERIALKIT =
  "/schedulematerialkit/deleteschedulematerialkit";

/*-------- schedulematerialkit url end --------*/
