/* eslint-disable */
import React, { useState, createRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

// kendo react modules ==========================
import '@progress/kendo-theme-material/dist/all.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';

// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import api requiremnts
import * as url from 'urlhelpers/UrlHelper';
import { getData, postData } from 'utils/Gateway';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const CostTemplateListArea = ({
  downloadExcel,
  setRecords,
  search,
  checkedRecordCount,
}) => {
  const { t } = useTranslation(); //for translation

  const token = localStorage.getItem('token');

  const {
    screenHeight,
    setEditCostData,
    reloadCostList,
    setReloadCostList,
    searchCostDate,
    searchCostEducators,
    searchCostClients,
  } = useContext(GlobalProvider);

  const [costList, setCostList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(costList, initialDataState));

  const onDataStateChange = event => {
    console.log('event -->', event);
    setDataState(event.dataState);
    setResult(process(costList, event.dataState));
  };
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();

  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  //function for get all cost
  const getAllCost = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl + url.API_GET_ALL_COST + `?token=${token}`;

      if (searchCostDate.length > 0) {
        requestUrl = requestUrl + `&costdate=${searchCostDate}`;
      }

      if (searchCostEducators.length > 0) {
        requestUrl = requestUrl + `&costeducators=${searchCostEducators}`;
      }

      if (searchCostClients.length > 0) {
        requestUrl = requestUrl + `&costclients=${searchCostClients}`;
      }

      console.log('request url of cost =====>', requestUrl);

      const response = await getData(requestUrl);

      console.log('response in cost =======>', response);

      if (response.status) {
        setCostList(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // check the selected count and show the count in top and also selected download xls ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectCostRecord"]'
    );

    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = costList.find(user => user._id === checkbox.value);
        filteredUser && filteredData.push(filteredUser);
      }
    });

    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = e => {
    document
      .querySelectorAll('input[name="selectCostRecord"]')
      .forEach(checkbox => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = props => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectCostRecord"
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };

  /* educator name html */
  const educatorBlock = ({ dataItem }) => {
    return (
      <td className="title">
        <div className="d-flex align-items-center gap-2">
          <span>{dataItem.educatorname}</span>
          <Link
            to="#viewUpdateCostPopup"
            data-bs-toggle="offcanvas"
            className="ml-auto edit_icon"
          >
            <span
              className="d-block material-symbols-outlined icon-md"
              onClick={() => {
                setEditCostData(dataItem);
              }}
            >
              edit
            </span>
          </Link>
        </div>
      </td>
    );
  };

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter(item => {
      const educatorname = item?.educatorname?.toLowerCase() || '';
      const costdate = item?.costdate || '';
      const customreserveid = item?.customreserveid?.toString() || '';
      const activityname = item?.activityname?.toLowerCase() || '';
      const clientname = item?.clientname?.toLowerCase() || '';
      const costlocation = item?.costlocation?.toLowerCase() || '';
      const starttime = item?.starttime || '';
      const endtime = item?.endtime || '';
      const minutes = item?.minutes || '';
      const arrivaltime = item?.arrivaltime || '';
      const returntime = item?.returntime || '';
      const preparationtime = item?.preparationtime || '';
      const mountingtime = item?.mountingtime || '';
      const dismantletime = item?.dismantletime || '';
      const additionaltime = item?.additionaltime || '';
      const totalcosttime = item?.totalcosttime || '';
      const educatorcost = item?.educatorcost?.toString() || '';
      const totalfinalcost = item?.totalfinalcost?.toString() || '';

      return (
        (educatorname && educatorname.includes(search.toLowerCase())) ||
        (costdate && costdate.includes(search)) ||
        (customreserveid && customreserveid.includes(search)) ||
        (activityname && activityname.includes(search.toLowerCase())) ||
        (clientname && clientname.includes(search.toLowerCase())) ||
        (costlocation && costlocation.includes(search.toLowerCase())) ||
        (starttime && starttime.includes(search)) ||
        (endtime && endtime.includes(search)) ||
        (minutes && minutes.includes(search)) ||
        (arrivaltime && arrivaltime.includes(search)) ||
        (returntime && returntime.includes(search)) ||
        (preparationtime && preparationtime.includes(search)) ||
        (mountingtime && mountingtime.includes(search)) ||
        (dismantletime && dismantletime.includes(search)) ||
        (additionaltime && additionaltime.includes(search)) ||
        (totalcosttime && totalcosttime.includes(search)) ||
        (educatorcost && educatorcost.includes(search)) ||
        (totalfinalcost && totalfinalcost.includes(search))
      );
    });
    setRecords(filteredData.length);
    return filteredData;
  };

  useEffect(() => {
    if (
      searchCostDate.length > 0 ||
      searchCostEducators.length > 0 ||
      searchCostClients.length > 0
    ) {
      getAllCost();
    }
  }, [searchCostDate, searchCostEducators, searchCostClients]);

  // request API to get COST data -----------------------------
  useEffect(() => {
    if (reloadCostList || !responseReceived) {
      getAllCost();
      setResult(process(costList, initialDataState));
      if (reloadCostList) {
        setReloadCostList(false);
      }
    }
  }, [reloadCostList, responseReceived]);

  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);
  /* hook to hit filterData function on search */

  useEffect(() => {
    filterData();
  }, [search]);

  return (
    <div className="table-responsive">
      {/* ================ download excel start ====================== */}
      <ExcelExport
        data={
          handelSelectedCount().length > 0 ? handelSelectedCount() : costList
        }
        fileName="cost.xlsx"
        ref={_exporter}
      >
        <ExcelExportColumn
          field="educatorname"
          title={t('Educator')}
          cell={educatorBlock}
        />

        <ExcelExportColumn
          field="educatorname"
          title={t('Educator')}
          cell={educatorBlock}
        />
        <ExcelExportColumn field="costdate" title={t('Date')} />
        <ExcelExportColumn field="customreserveid" title={t('Reservation')} />
        <ExcelExportColumn field="activityname" title={t('Activity')} />
        <ExcelExportColumn field="clientname" title={t('Client')} />
        <ExcelExportColumn field="costlocation" title={t('Location')} />
        <ExcelExportColumn field="starttime" title={t('Start')} />
        <ExcelExportColumn field="endtime" title={t('End')} />
        <ExcelExportColumn field="minutes" title={t('Activity Time')} />
        <ExcelExportColumn field="arrivaltime" title={t('Arrival time')} />
        <ExcelExportColumn field="returntime" title={t('Return time')} />
        <ExcelExportColumn
          field="preparationtime"
          title={t('Preparation time')}
        />
        <ExcelExportColumn field="mountingtime" title={t('Mounting time')} />
        <ExcelExportColumn field="dismantletime" title={t('Dismantle time')} />
        <ExcelExportColumn field="additionaltime" title={t('Concept time')} />
        <ExcelExportColumn field="totalcosttime" title={t('Total time')} />
        <ExcelExportColumn field="educatorcost" title={t('Educator Cost')} />
        <ExcelExportColumn field="totalfinalcost" title={t('Total Cost')} />
      </ExcelExport>

      {/* ================ download excel end ====================== */}

      {/* =========== grid view start =================== */}

      {search ? (
        <Grid
          data={filterData()}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
          style={{ height: screenHeight }}
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            field="educatorname"
            title={t('Educator')}
            cell={educatorBlock}
            width="200px"
          />
          <GridColumn field="costdate" title={t('Date')} width="200px" />
          <GridColumn
            field="customreserveid"
            title={t('Reservation')}
            width="200px"
          />

          <GridColumn
            field="activityname"
            title={t('Activity')}
            width="200px"
          />
          <GridColumn field="clientname" title={t('Client')} width="200px" />
          <GridColumn
            field="costlocation"
            title={t('Location')}
            width="200px"
          />
          <GridColumn field="starttime" title={t('Start')} width="200px" />
          <GridColumn field="endtime" title={t('End')} width="200px" />
          <GridColumn
            field="minutes"
            title={t('Activity Time')}
            width="200px"
          />
          <GridColumn
            field="arrivaltime"
            title={t('Arrival time')}
            width="200px"
          />
          <GridColumn
            field="returntime"
            title={t('Return time')}
            width="200px"
          />
          <GridColumn
            field="preparationtime"
            title={t('Preparation time')}
            width="200px"
          />
          <GridColumn
            field="mountingtime"
            title={t('Mounting time')}
            width="200px"
          />
          <GridColumn
            field="dismantletime"
            title={t('Dismantle time')}
            width="200px"
          />
          <GridColumn
            field="additionaltime"
            title={t('Concept time')}
            width="200px"
          />
          <GridColumn
            field="totalcosttime"
            title={t('Total time')}
            width="200px"
          />
          <GridColumn
            field="educatorcost"
            title={t('Educator Cost')}
            width="200px"
          />
          <GridColumn
            field="totalfinalcost"
            title={t('Total Cost')}
            width="200px"
          />
        </Grid>
      ) : (
        <Grid
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
          style={{ height: screenHeight }}
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          <GridColumn
            field="educatorname"
            title={t('Educator')}
            cell={educatorBlock}
            width="200px"
          />
          <GridColumn field="costdate" title={t('Date')} width="200px" />
          <GridColumn
            field="customreserveid"
            title={t('Reservation')}
            width="200px"
          />

          <GridColumn
            field="activityname"
            title={t('Activity')}
            width="200px"
          />
          <GridColumn field="clientname" title={t('Client')} width="200px" />
          <GridColumn
            field="costlocation"
            title={t('Location')}
            width="200px"
          />
          <GridColumn field="starttime" title={t('Start')} width="200px" />
          <GridColumn field="endtime" title={t('End')} width="200px" />
          <GridColumn
            field="minutes"
            title={t('Activity Time')}
            width="200px"
          />
          <GridColumn
            field="arrivaltime"
            title={t('Arrival time')}
            width="200px"
          />
          <GridColumn
            field="returntime"
            title={t('Return time')}
            width="200px"
          />
          <GridColumn
            field="preparationtime"
            title={t('Preparation time')}
            width="200px"
          />
          <GridColumn
            field="mountingtime"
            title={t('Mounting time')}
            width="200px"
          />
          <GridColumn
            field="dismantletime"
            title={t('Dismantle time')}
            width="200px"
          />
          <GridColumn
            field="additionaltime"
            title={t('Concept time')}
            width="200px"
          />
          <GridColumn
            field="totaltime"
            title={t('Total time') + ' (Min)'}
            width="200px"
          />
          <GridColumn
            field="totalcost.totaltime"
            title={t('Time by date') + ' (Min)'}
            width="200px"
          />
          <GridColumn
            field="totalhours"
            title={t('Time by date') + ' (Hrs)'}
            width="200px"
          />
          <GridColumn
            field="educatorcost"
            title={t('Cost per hour')}
            width="200px"
          />
          <GridColumn
            field="totalcost.totalcost"
            title={t('Cost by date')}
            width="200px"
          />
        </Grid>
      )}

      {/* =========== grid view end =================== */}
    </div>
  );
};

export default CostTemplateListArea;
