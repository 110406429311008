import React from 'react';
import { Link } from 'react-router-dom';
import { assetImages } from '../../constants';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const DashboardBody = () => {
  const { t } = useTranslation(); //for translation
  return (
    <section className="dashboard_sec">
      <div className="container-fluid">
        <div className="dashboard_outer">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="dashboard_bxItem">
                <div className="bx_pic">
                  <img src={assetImages.userMangement} alt="" />
                </div>
                <div className="bx_info">
                  <h3>{t('Users Management')}</h3>
                  <Link to="/usermanagement" className="btn btn-primary">
                    {t('Open Now')}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="dashboard_bxItem">
                <div className="bx_pic">
                  <img src={assetImages.reservation} alt="" />
                </div>
                <div className="bx_info">
                  <h3>{t('Reservations')}</h3>
                  <Link to="/reservation" className="btn btn-primary">
                    {t('Open Now')}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="dashboard_bxItem">
                <div className="bx_pic">
                  <img src={assetImages.conversation} alt="" />
                </div>
                <div className="bx_info">
                  <h3>{t('Conversations')}</h3>
                  <Link to="/conversation" className="btn btn-primary">
                    {t('Open Now')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardBody;
