/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import context
import { GlobalProvider } from 'context/GlobalContext';

/*import url and gateway methods */
import { putData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

const ActivityHeader = ({
  setDownloadExcel,
  setSearch,
  records,
  blockRefSelected,
}) => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem('token');

  const {
    setActivityFormReset,
    setReloadActivityList,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState('');

  //function for deactivate/update user
  const deleteActivity = e => {
    e.preventDefault();
    const checkboxes = document.querySelectorAll(
      'input[name="selectActivityRecord"]'
    );

    checkboxes.forEach(async checkbox => {
      if (checkbox.checked) {
        let activityData = {
          status: '0',
        };
        try {
          let requestUrl =
            url.API_BaseUrl +
            url.API_UPDATE_ACTIVITY +
            `/${checkbox.value}` +
            `?token=${token}`;

          const response = await putData(requestUrl, activityData);

          if (response.status) {
            setReloadActivityList(true);
            setSuccess(true);
            setNotificationString('Activity has been deleted successfully');
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString('Something went wrong, please try again later');
        }
      }
    });
  };

  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);

    setTimeout(() => setDownloadExcel(false), 500);
  };

  /* hook to empty search when searchValue gets empty */
  useEffect(() => {
    !searchValue && setSearch('');
  }, [searchValue]);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2">
      <Link
        to="#activitycolumnCollapse"
        className="btn btn-primary mw-unset p-2"
        data-bs-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="columnCollapse"
      >
        <span className="d-block material-symbols-outlined icon-fill">
          visibility
        </span>
      </Link>
      <Link
        to="#"
        onClick={handleDownloadClick}
        className="btn btn-primary mw-unset p-2"
      >
        <span className="d-block material-symbols-outlined">download</span>
      </Link>
      {/* <Link to="#" className="btn btn-primary mw-unset p-2">
        <span className="d-block material-symbols-outlined icon-fill">
          mail
        </span>
      </Link> */}
      <div className="search-container position-relative d-none d-md-block">
        <form onSubmit={e => e.preventDefault()}>
          <input
            type="search"
            placeholder={t('Search here')}
            className="form-control bg-transparent py-2 border-0"
            onChange={e => setSearchValue(e.target.value)}
            onKeyDown={e => {
              e.key === 'Enter' && (e.preventDefault(), setSearch(searchValue));
            }}
          />
          <button
            onClick={() => setSearch(searchValue)}
            type="submit"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
          >
            <span className="d-block material-symbols-outlined">search</span>
          </button>
        </form>
      </div>
      {/* ------------- showing records start ----------------- */}
      <p className="text-gray fw-medium ms-auto me-3">
        {t('Showing')}
        <span className="text-black ms-1">{records}</span> {t('Records')} -
        <span className="text-black ms-1" ref={blockRefSelected}>
          {' '}
          0{' '}
        </span>{' '}
        {t('Selected')}
      </p>
      {/* ------------- showing records end ----------------- */}
      {/* ---------- refresh button start -------- */}
      <Link
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        onClick={() => {
          setReloadActivityList(true);
          setSuccess(true);
          setNotificationString('The list has been refreshed successfully');
        }}
      >
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Refresh"
          className="d-block material-symbols-outlined"
        >
          refresh
        </span>
      </Link>
      {/* ---------- refresh button end -------- */}
      <Link
        to="#filterCollapse"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        data-bs-toggle="collapse"
        role="button"
        aria-expanded="false"
        aria-controls="filterCollapse"
      >
        <span className="d-block material-symbols-outlined">filter_alt</span>
      </Link>
      <Link
        to="#saveActivityPopup"
        data-bs-toggle="offcanvas"
        role="button"
        aria-controls="saveReservationPopup"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        onClick={() => {
          setActivityFormReset(true);
        }}
      >
        <span className="d-block material-symbols-outlined">add</span>
      </Link>
      <div className="dropdown">
        <button
          className="btn btn-primary dropdown-toggle mw-unset"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {t('Actions')}
        </button>
        <ul className="dropdown-menu border-0 shadow-lg">
          {/* <li>
            <Link className="dropdown-item" to="#">
              {t("Add Label")}
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="#">
              {t("Download in Xls")}
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="#">
              {t("Upload xls function")}
            </Link>
          </li> */}
          {/* <li>
            <Link className="dropdown-item" to="#">
              {t("Change Status")}
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="#">
              {t("Send Notification")}
            </Link>
          </li> */}
          <li>
            <Link
              className="dropdown-item"
              to="#"
              onClick={e => {
                deleteActivity(e);
              }}
            >
              {t('Delete selected')}
            </Link>
          </li>
          {/* <li>
            <Link className="dropdown-item" to="#">
              {t("Upload Holiday Calendar")}
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to="#">
              {t("Duplicate selected")}
            </Link>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default ActivityHeader;
