import AddCategoryPopup from 'components/CategoryComponents/AddCategoryPopup';
import CategoryBody from 'components/CategoryComponents/CategoryBody';
import CategoryNav from 'components/CategoryComponents/CategoryNav';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';
import React from 'react';

const Category = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = React.useState(false);
  React.useEffect(() => {
    document.title = 'Category'; // Update the title when Register component mounts
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="mail-wrapper py-4">
        <div className="container-fluid px-4">
          <CategoryNav setDownloadExcel={setDownloadExcel} />
          <CategoryBody downloadExcel={downloadExcel} />
        </div>
      </section>

      <AddCategoryPopup />
    </>
  );
};

export default Category;
