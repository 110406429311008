/* eslint-disable */

import DashBoard from 'pages/Dashboard/DashBoard';
import EmailVerification from 'pages/EmailVerification/EmailVerification';
import Login from 'pages/Login/Login';
import Conversation from 'pages/Conversation/Conversation';
import Register from 'pages/Signup/Register';

import UserProfile from 'pages/UserProfile/UserProfile';

// Kendo React Demo ***** Remove these in production
import Datagrid from 'pages/KendoReactDemo/Datagrid';
import Schedule from 'pages/KendoReactDemo/Schedule';

import Survey from 'pages/Survey/Survey';
import Reservation from 'pages/Reservation/Reservation';
import Logout from 'pages/Login/Logout';
import UserManagement from 'pages/UserManagement/UserManagement';
import Activity from 'pages/Activity/Activity';
import Category from 'pages/Category/Category';
import MailTemplate from 'pages/MailTemplate/MailTemplate';
import EventRole from 'pages/EventRole/EventRole';
import Installations from 'pages/Installations/Installations';
import MaterialKit from 'pages/MaterialKit/MaterialKit';
import Questions from 'pages/Questions/Questions';
import AddRolePrivilage from 'pages/AddRolePrivilage/AddRolePrivilage';
import SpecialNeeds from 'pages/SpecialNeeds/SpecialNeeds';
import SpecificAudience from 'pages/SpecificAudience/SpecificAudience';
import Groups from 'pages/Groups/Groups';
import Course from 'pages/Course/Course';
import Label from 'pages/Label/Label';
import HolidayCalendar from 'pages/HolidayCalendar/HolidayCalendar';
import SurveyFeedback from 'pages/SurveyFeedback/SurveyFeedback';
import EventMemberShipProcess from 'pages/EventMemberShipProcess/EventMemberShipProcess';
import ReservationDetails from 'pages/ReservationDetails/ReservationDetails';
import CostTemplate from 'pages/CostTemplate/CostTemplate';
import MaterialCalendar from 'pages/MaterialCalendar/MaterialCalendar';
import SalesOrder from 'pages/SalesOrder/SalesOrder';
import FeedbackResponses from 'pages/FeedbackResponses/FeedbackResponses';
import Concept from 'pages/Concept/Concept';

/*======= public routes =======*/
const publicRoutes = [
  { path: '/', component: Login }, //login page,
  { path: '/register', component: Register }, //register page
  { path: '/emailverification', component: EmailVerification },
  { path: '/survey/feedback/:id', component: SurveyFeedback },
  { path: '/reservation/response', component: EventMemberShipProcess },
  { path: '/app/reservedetails', component: ReservationDetails },
];
/*======= protected routes =======*/
const protectedRoutes = [
  { path: '/logout', component: Logout },
  { path: '/dashboard', component: DashBoard },
  { path: '/conversation', component: Conversation },
  { path: '/profile/:id', component: UserProfile },
  { path: '/survey', component: Survey },
  { path: '/feedbackresponses/:id', component: FeedbackResponses },
  { path: '/reservation', component: Reservation },
  { path: '/usermanagement', component: UserManagement },
  { path: '/activity', component: Activity },
  { path: '/category', component: Category },
  { path: '/mailtemplate', component: MailTemplate },
  { path: '/eventrole', component: EventRole },
  { path: '/installations', component: Installations },
  { path: '/materialkit', component: MaterialKit },
  { path: '/materialcalendar/:id', component: MaterialCalendar },
  { path: '/questions', component: Questions },
  { path: '/addroleprivilage', component: AddRolePrivilage },
  { path: '/course', component: Course },
  { path: '/specialneeds', component: SpecialNeeds },
  { path: '/specificaudience', component: SpecificAudience },
  { path: '/groups', component: Groups },
  { path: '/label', component: Label },
  { path: '/holidaycalendar', component: HolidayCalendar },
  { path: '/survey/feedback/:id', component: SurveyFeedback },
  { path: '/reservation/response', component: EventMemberShipProcess },
  { path: '/cost', component: CostTemplate },
  { path: '/app/reservedetails', component: ReservationDetails },
  { path: '/salesorder', component: SalesOrder },
  { path: '/concept', component: Concept },

  // kendo react demo urls ==========================
  { path: '/demo/datagrid', component: Datagrid },
  { path: '/demo/schedule', component: Schedule },
];

export { publicRoutes, protectedRoutes };
