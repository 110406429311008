import MaterialKitBreadCrumb from 'components/MaterialKitComponents/BreadCrumb/MaterialKitBreadCrumb';
import MaterialKitHeader from 'components/MaterialKitComponents/Header/MaterialKitHeader';
import MaterialKitList from 'components/MaterialKitComponents/MaterialKitList/MaterialKitList';
import SaveMaterialKitPopup from 'components/MaterialKitComponents/Popup/SaveMaterialKitPopup';
import AddLabel from 'components/common/AddLabel';
import CustomNotification from 'components/common/CustomNotification';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const MaterialKit = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);
  /* hook to search */
  const [search, setSearch] = useState('');

  /* hook to get number of records */
  const [records, setRecords] = useState(0);

  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly

  /* function for checking record count */
  const checkedRecordCount = selectCount => {
    console.log('selectCount', selectCount);
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };

  /* Hook to show title */
  useEffect(() => {
    document.title = 'Material';
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="mail-wrapper pt-2">
        <div className="container-fluid px-4">
          <MaterialKitBreadCrumb />
          <MaterialKitHeader
            setDownloadExcel={setDownloadExcel}
            setSearch={setSearch}
            records={records}
            blockRefSelected={blockRefSelected}
          />

          <MaterialKitList
            downloadExcel={downloadExcel}
            search={search}
            setRecords={setRecords}
            checkedRecordCount={checkedRecordCount}
          />
        </div>
      </section>

      <SaveMaterialKitPopup />
      <AddLabel module={'materialkit'} />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default MaterialKit;
