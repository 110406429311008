/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/* Importing react select */
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

//import options from json file
import ReserveStatus from 'data/Prod/ReserveStatus.json';

//import context
import { GlobalProvider } from 'context/GlobalContext';

/*import url and gateway methods */
import { getData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

const Filter = () => {
  const token = localStorage.getItem('token'); // token
  const { t } = useTranslation(); //for translation

  const todayValue = new Date().toISOString().split('T')[0];

  const {
    setSearchReserveDate,
    setSearchReserveIdList,
    setSearchReserveActivity,
    setSearchReserveLabels,
    setSearchReserveBenificiaries,
    setSearchReserveClients,
    setSearchReserveEducators,
    setSearchReserveStatus,
    setReloadReservationList,
  } = useContext(GlobalProvider);

  const [selectedChecked, setSelectedChecked] = useState('');

  const [fromDate, setFromDate] = useState(todayValue);
  const [toDate, setToDate] = useState(todayValue);
  const [selectedDate, setSelectedDate] = useState(todayValue);

  const [labelList, setlabelList] = useState([]); // for label list
  const [activityList, setActivityList] = useState([]); // for activity list

  const [educatorList, setEducatorList] = useState([]); //educator option
  const [clientList, setClientList] = useState([]); //client option
  const [benificiaryList, setBenificiaryList] = useState([]); //benificiary option

  const [selectedOptions, setSelectedOptions] = useState({
    idValue: null,
    idData: [],
    reserveStatusValue: null,
    reserveStatusOption: [],
    mailValue: null,
    mailOptions: null,
    benificiaryValue: null,
    benificiaryOptions: [],
    activityValue: null,
    activityOptions: [],
    labelValue: null,
    labelOptions: [],
    clientValue: null,
    clientOptions: [],
    educatorValue: null,
    educatorOptions: [],
  });
  /* selecting options ends */

  //function for select radio
  const radioCheckHandler = event => {
    setSelectedChecked(event.target.value);
    setDateWarning(false);
  };

  /* function to get all activity */
  const getAllActivity = async () => {
    let requestUrl =
      url.API_BaseUrl +
      url.API_GET_ALL_ACTIVITY +
      `?token=${token}&isselect=${true}`;

    const response = await getData(requestUrl);

    if (response.status) {
      setActivityList(response.data);
    }
  };

  /* function to get all client list */
  const getALlUserList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        //eductor list
        let filteredEducator = response.data.filter(user => {
          let userData;
          if (user.role) {
            if (user.role.name == 'educator') {
              userData = user;
            }
          }
          return userData;
        });

        setEducatorList(filteredEducator);

        //client list
        let filteredClient = response.data.filter(user => {
          let userData;
          if (user.role) {
            if (user.role.name == 'client' || user.role.name == 'beneficiary') {
              userData = user;
            }
          }
          return userData;
        });

        setClientList(filteredClient);

        //beificiary list
        let filteredBeneficiary = response.data.filter(user => {
          let userData;
          if (user.role) {
            if (user.role.name == 'beneficiary') {
              userData = user;
            }
          }
          return userData;
        });

        setBenificiaryList(filteredBeneficiary);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  /* function to get all labels */
  const getAllLabels = async () => {
    let requestUrl =
      url.API_BaseUrl +
      url.API_GET_ALL_TAG +
      `?token=${token}&isselect=${true}`;

    const response = await getData(requestUrl);

    if (response.status) {
      setlabelList(response.data);
    }
  };

  // Hook for date compare validation
  const [dateWarning, setDateWarning] = useState(false);

  //function for search
  const searchHandler = () => {
    /*===== switch statement for date input radio start ======*/
    switch (selectedChecked) {
      case 'daterange':
        if (fromDate < toDate) {
          setSearchReserveDate(fromDate + ',' + toDate);
        } else {
          setDateWarning(true);
        }
        break;

      case 'todayradio':
        setSearchReserveDate(todayValue);
        break;

      case 'eventdate':
        setSearchReserveDate(selectedDate);
        break;

      default:
        setSearchReserveDate('');
        break;
    }
    /*===== switch statement for date input radio end ======*/

    //assign data for search
    selectedOptions.idData.length > 0 &&
      setSearchReserveIdList(selectedOptions.idData);

    selectedOptions.activityOptions.length > 0 &&
      setSearchReserveActivity(selectedOptions.activityOptions);

    selectedOptions.labelOptions.length > 0 &&
      setSearchReserveLabels(selectedOptions.labelOptions);

    selectedOptions.benificiaryOptions.length > 0 &&
      setSearchReserveBenificiaries(selectedOptions.benificiaryOptions);

    selectedOptions.clientOptions.length > 0 &&
      setSearchReserveClients(selectedOptions.clientOptions);

    selectedOptions.educatorOptions.length > 0 &&
      setSearchReserveEducators(selectedOptions.educatorOptions);

    selectedOptions.reserveStatusOption.length > 0 &&
      setSearchReserveStatus(selectedOptions.reserveStatusOption);
  };

  //function for reset
  const resetHandler = () => {
    setSelectedChecked('');
    setFromDate(todayValue);
    setToDate(todayValue);
    setSelectedDate(todayValue);
    setSelectedOptions({
      idValue: null,
      idData: [],
      reserveStatusValue: null,
      reserveStatusOption: [],
      mailValue: null,
      mailOptions: null,
      benificiaryValue: null,
      benificiaryOptions: [],
      activityValue: null,
      activityOptions: [],
      labelValue: null,
      labelOptions: [],
      clientValue: null,
      clientOptions: [],
      educatorValue: null,
      educatorOptions: [],
    });
    setSearchReserveDate('');
    setSearchReserveIdList([]);
    setSearchReserveActivity([]);
    setSearchReserveLabels([]);
    setSearchReserveBenificiaries([]);
    setSearchReserveClients([]);
    setSearchReserveEducators([]);
    setSearchReserveStatus([]);
    setReloadReservationList(true);
  };

  useEffect(() => {
    getAllLabels();
    getAllActivity();
    getALlUserList();
  }, []);

  return (
    <div className="collapse mt-3" id="filterCollapse">
      <div className="p-3 bg-gray-200">
        <h4 className="mb-3">{t('Filter')}</h4>
        {/* ------- search form section start ------- */}
        <form onSubmit={e => e.preventDefault()}>
          <div className="form-group mb-3">
            <div className="row align-items-center">
              {/* -------- date range section start -------- */}
              <div className="col-lg mb-3 mb-lg-0">
                <div className="d-flex align-items-center">
                  {/* -------- date input radio section start -------- */}
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="daterange"
                      id="dateRange"
                      value="daterange"
                      checked={selectedChecked === 'daterange'}
                      onChange={radioCheckHandler}
                    />
                  </div>
                  {/* -------- date input radio section end -------- */}

                  {/* -------- date selection section start -------- */}
                  <div className="field-container d-sm-flex border flex-fill">
                    {/* ------- from date input start ------- */}
                    <input
                      type="date"
                      id="from_date"
                      className="form-control bg-white border-0 mb-3 mb-sm-0"
                      placeholder={t('From date')}
                      value={fromDate}
                      onChange={e => {
                        setFromDate(e.target.value), setDateWarning(false);
                      }}
                    />
                    {/* ------- from date input end ------- */}

                    {/* ------- to date input start ------- */}
                    <input
                      type="date"
                      id="to_date"
                      className="form-control bg-white border-0"
                      placeholder={t('To date')}
                      value={toDate}
                      onChange={e => {
                        setToDate(e.target.value), setDateWarning(false);
                      }}
                    />
                    {/* ------- to date input end ------- */}
                  </div>

                  {/* -------- date selection section end -------- */}
                </div>
                {/* date compare warning */}
                {dateWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>
                        {t('End date should be greater than start date')}!
                      </span>
                    </p>
                  </div>
                )}
              </div>
              {/* -------- date range section end -------- */}

              {/* --------- today section start --------- */}
              <div className="col-lg-auto mb-3 mb-lg-0">
                <div className="form-check mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="todayradio"
                    id="today"
                    value="todayradio"
                    checked={selectedChecked === 'todayradio'}
                    onChange={radioCheckHandler}
                  />
                  <label className="form-check-label mb-0" htmlFor="today">
                    {t('Today')}
                  </label>
                </div>
              </div>
              {/* --------- today section end --------- */}

              {/* --------- date section start --------- */}
              <div className="col-lg">
                <div className="d-flex align-items-center">
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="eventdate"
                      id="multipleDates"
                      value="eventdate"
                      checked={selectedChecked === 'eventdate'}
                      onChange={radioCheckHandler}
                    />
                  </div>
                  <input
                    type="date"
                    id="to_date"
                    className="form-control bg-white"
                    placeholder={t('Select Multiple Dates')}
                    value={selectedDate}
                    onChange={e => setSelectedDate(e.target.value)}
                  />
                </div>
              </div>
              {/* --------- date section end --------- */}
            </div>
          </div>
          <div className="form-group mb-3">
            <div className="row">
              {/* ------ id selection section start -------- */}
              <div className="col-lg-2 mb-3 mb-lg-0">
                <CreatableSelect
                  isMulti
                  placeholder={t('Reserve ID')}
                  value={selectedOptions.idValue}
                  onChange={val =>
                    setSelectedOptions(prevData => ({
                      ...prevData,
                      idValue: val,
                      idData: val.map(label => label.value),
                    }))
                  }
                />
              </div>
              {/* ------ id selection section end -------- */}

              {/* ------ activity selection section start -------- */}
              <div className="col-lg-5 mb-3 mb-lg-0">
                <div className="field-container position-relative">
                  <Select
                    placeholder={t('Select activity')}
                    className="nosearch_select"
                    options={activityList}
                    isMulti
                    value={selectedOptions.activityValue}
                    onChange={val =>
                      setSelectedOptions(prevData => ({
                        ...prevData,
                        activityValue: val,
                        activityOptions: val.map(label => label.value),
                      }))
                    }
                  />
                </div>
              </div>
              {/* ------ activity selection section end -------- */}

              {/* ------ label selection section start -------- */}
              <div className="col-lg-5">
                <div className="field-container position-relative">
                  <Select
                    placeholder={t('Select label')}
                    className="nosearch_select"
                    options={labelList}
                    isMulti
                    value={selectedOptions.labelValue}
                    onChange={val =>
                      setSelectedOptions(prevData => ({
                        ...prevData,
                        labelValue: val,
                        labelOptions: val.map(label => label.value),
                      }))
                    }
                  />
                </div>
              </div>
              {/* ------ label selection section end -------- */}
            </div>
          </div>
          <div className="form-group mb-3">
            <div className="row">
              {/* ------ benificiary selection section start -------- */}
              <div className="col-lg-4 mb-3 mb-lg-0">
                <div className="field-container position-relative">
                  <Select
                    placeholder={t('Select beneficiary')}
                    className="nosearch_select"
                    options={benificiaryList}
                    isMulti
                    value={selectedOptions.benificiaryValue}
                    onChange={val =>
                      setSelectedOptions(prevData => ({
                        ...prevData,
                        benificiaryValue: val,
                        benificiaryOptions: val.map(
                          benificiary => benificiary.value
                        ),
                      }))
                    }
                  />
                </div>
              </div>
              {/* ------ benificiary selection section end -------- */}

              {/* ------ client selection section start -------- */}
              <div className="col-lg-4 mb-3 mb-lg-0">
                <div className="field-container position-relative">
                  <Select
                    className="nosearch_select"
                    placeholder={t('Select clients')}
                    isMulti
                    options={clientList}
                    value={selectedOptions.clientValue}
                    onChange={val =>
                      setSelectedOptions(prevData => ({
                        ...prevData,
                        clientValue: val,
                        clientOptions: val.map(client => client.value),
                      }))
                    }
                  />
                </div>
              </div>
              {/* ------ client selection section end -------- */}

              {/* ------ educator selection section start -------- */}
              <div className="col-lg-4">
                <div className="field-container position-relative">
                  <Select
                    className="nosearch_select"
                    placeholder={t('Select educators')}
                    isMulti
                    options={educatorList}
                    value={selectedOptions.educatorValue}
                    onChange={val =>
                      setSelectedOptions(prevData => ({
                        ...prevData,
                        educatorValue: val,
                        educatorOptions: val.map(educator => educator.value),
                      }))
                    }
                  />
                </div>
              </div>
              {/* ------ educator selection section end -------- */}
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  {/* ------ status selection section start -------- */}
                  <div className="col-lg-4 mb-3 mb-lg-0">
                    <Select
                      placeholder={t('Status')}
                      className="nosearch_select"
                      options={ReserveStatus}
                      isMulti
                      value={selectedOptions.reserveStatusValue}
                      onChange={val =>
                        setSelectedOptions(prevData => ({
                          ...prevData,
                          reserveStatusValue: val,
                          reserveStatusOption: val.map(status => status.value),
                        }))
                      }
                    />
                  </div>
                  {/* ------ status selection section end -------- */}
                </div>
              </div>
              <div className="col-lg-6">
                {/* ------ button section start -------- */}
                <div className="action d-flex gap-3 align-items-center justify-content-end">
                  {/* ------- reset button start ------- */}
                  <Link
                    to="#"
                    className="d-flex gap-1 align-items-center text-black"
                    onClick={resetHandler}
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      sync
                    </span>
                    <span className="d-block fw-medium">{t('Refresh')}</span>
                  </Link>
                  {/* ------- reset button end ------- */}

                  {/* ------- search button start ------- */}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={searchHandler}
                  >
                    {t('Filter')}
                  </button>
                  {/* ------- search button start ------- */}
                </div>
                {/* ------ button section end -------- */}
              </div>
            </div>
          </div>
        </form>
        {/* ------- search form section end ------- */}
      </div>
    </div>
  );
};

export default Filter;
