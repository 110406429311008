/* eslint-disable */
import { useState, useEffect, useContext } from "react";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { postData, putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const SaveMaterialKitPopup = () => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token"); // getting token from local storage

  const {
    setReloadMaterialList,
    editMaterialData,
    setEditMaterialData,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const [name, setName] = useState(""); // for name
  const [unitPerDay, setUnitPerDay] = useState(""); //for unit per day
  const [unitAvailablePerCourse, setUnitAvailablePerCourse] = useState(""); //for unit per course
  /* hook for form validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    perDayWarning: false,
    perCourseWarning: false,
  });

  /* function for validation */
  const formValidation = () => {
    let isValid = true;
    setValidation((prevState) => ({
      ...prevState,
      nameWarning: name === "",
      perDayWarning: unitPerDay === "",
      perCourseWarning: unitAvailablePerCourse === "",
    }));
    isValid = !(
      name === "" ||
      unitPerDay === "" ||
      unitAvailablePerCourse === ""
    );
    return isValid;
  };

  //function for submit material kit data to api
  const saveMaterialKitHandler = async (e) => {
    e.preventDefault();

    if (formValidation()) {
      try {
        const materialData = {
          name,
          unitsperday: unitPerDay,
          unitspercourse: unitAvailablePerCourse,
        };

        let requestUrl =
          url.API_BaseUrl + url.API_ADD_NEW_MATERIAL_KIT + `?token=${token}`;

        const response = await postData(requestUrl, materialData);

        console.log(response);
        if (response.status) {
          setReloadMaterialList(true);
          setSuccess(true);
          setNotificationString("Material kit added successfully");
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString("Something went wrong, please try again later");
      }
      resetHandler();
      let offCanvasPopup = document.querySelector("#saveMaterialPopup");
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    }
  };

  //function for update material kit
  const updateMaterialKit = async (e) => {
    e.preventDefault();

    if (formValidation()) {
      try {
        const materialData = {
          name,
          unitsperday: unitPerDay,
          unitspercourse: unitAvailablePerCourse,
        };

        let requestUrl =
          url.API_BaseUrl +
          url.API_UPDATE_MATERIAL_KIT +
          `/${editMaterialData._id}` +
          `?token=${token}`;

        const response = await putData(requestUrl, materialData);

        if (response.status) {
          setReloadMaterialList(true);
          setSuccess(true);
          setNotificationString("Material kit edited successfully");
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString("Something went wrong, please try again later");
      }
      resetHandler();
      let offCanvasPopup = document.querySelector("#saveMaterialPopup");
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    }
  };

  //function for reset form
  const resetHandler = () => {
    setEditMaterialData(null);
    setName("");
    setUnitPerDay("");
    setUnitAvailablePerCourse("");
    setValidation({
      nameWarning: false,
      perDayWarning: false,
      perCourseWarning: false,
    });
  };

  //use effect for edit
  useEffect(() => {
    console.log(editMaterialData);
    if (editMaterialData) {
      /*------- assign name start -------*/
      editMaterialData.name && setName(editMaterialData.name);
      /*------- assign name end -------*/

      /*------- assign unit per day start -------*/
      editMaterialData.unitsperday &&
        setUnitPerDay(editMaterialData.unitsperday);
      /*------- assign unit per day end -------*/

      /*------- assign unit per course start -------*/
      editMaterialData.unitspercourse &&
        setUnitAvailablePerCourse(editMaterialData.unitspercourse);
      /*------- assign unit per course end -------*/
    }
  }, [editMaterialData]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="saveMaterialPopup"
      aria-labelledby="saveReservationPopupLabel"
    >
      <div className="offcanvas-header position-relative align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t("Save Material Kit")}</span>
        </h3>
        {/* ----- pop up dismiss button start ----- */}
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
        {/* ----- pop up dismiss button end ----- */}
      </div>
      <div className="offcanvas-body position-relative p-4 pt-0">
        {/* ----- material kit form section start ------ */}
        <form
          onSubmit={
            editMaterialData ? updateMaterialKit : saveMaterialKitHandler
          }
        >
          {/* ------ name section start ------ */}
          <div className="form-group mb-4">
            <label>{t("Name")}</label>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value), setValidation({ nameWarning: false });
              }}
              className="form-control"
              placeholder={t("Enter Here")}
            />
            {/* name warning */}
            {validation.nameWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter name")}!</span>
                </p>
              </div>
            )}
          </div>

          {/* ------ name section end ------ */}

          {/* ------ unit per day section start ------ */}
          <div className="form-group mb-4">
            <label>{t("Unit Available Per Day")}</label>
            <input
              type="text"
              value={unitPerDay}
              onChange={(e) => {
                setUnitPerDay(e.target.value),
                  setValidation({ perDayWarning: false });
              }}
              className="form-control"
              placeholder={t("Enter Here")}
            />
            {/* units per day  warning */}
            {validation.perDayWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter units per day")}!</span>
                </p>
              </div>
            )}
          </div>

          {/* ------ unit per day section end ------ */}

          {/* ------ unit per course section start ------ */}
          <div className="form-group mb-4">
            <label>{t("Units Available Per Course")}</label>
            <input
              type="text"
              value={unitAvailablePerCourse}
              onChange={(e) => {
                setUnitAvailablePerCourse(e.target.value),
                  setValidation({ perCourseWarning: false });
              }}
              className="form-control"
              placeholder={t("Enter Here")}
            />
            {validation.perCourseWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter units per course")}!</span>
                </p>
              </div>
            )}
          </div>

          {/* ------ unit per course section end ------ */}

          {/* ------ submit section start ------ */}
          <div className="action d-flex align-items-center gap-2">
            <button type="submit" className="btn btn-primary">
              {editMaterialData ? t("Update") : t("Save")}
            </button>
          </div>
          {/* ------ submit section end ------ */}
        </form>
        {/* ----- material kit form section end ------ */}
      </div>
    </div>
  );
};

export default SaveMaterialKitPopup;
