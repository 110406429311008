import React from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";
import { assetImages } from "constants";

const LeftMenu = () => {
  const { t } = useTranslation(); //for translation
  return (
    <div className="menu_leftpnl d-flex flex-column">
      {/* ----- menu icon section start ----- */}
      <div className="menu_hdng">
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            document.body.classList.remove("add_menupnl");
          }}
        >
          <i className="material-icons-outlined">arrow_back</i>
        </Link>
        <span>{t("Menu")}</span>
      </div>
      {/* ----- menu icon section end ----- */}

      {/* ----- all module links section start ----- */}
      <div className="menu_list flex-fill overflow-auto">
        <ul className="list_stye_none">
          {/* ------ dashboard link start ------ */}
          <li>
            <Link
              to="/dashboard"
              onClick={() => {
                document.body.classList.remove("add_menupnl");
              }}
            >
              <i className="material-icons-outlined">space_dashboard</i>
              <span>{t("Dashboard")}</span>
            </Link>
          </li>
          {/* ------ dashboard link end ------ */}

          {/* ------ administration dropdown link start ------ */}
          <li className="dropdown">
            {/*------ administration button start ------*/}
            <Link to="#" data-bs-toggle="dropdown">
              <i className="material-icons-outlined">manage_accounts</i>
              <span>{t("Administration")}</span>
              <span className="drop_arw">
                <img src={assetImages.whiteArrow} alt="downarrow" />
              </span>
            </Link>
            {/*------ administration button end ------*/}
            <ul className="list_stye_none dropdown-menu">
              {/*------ mail template link start ------*/}
              <li>
                <Link
                  to="/mailtemplate"
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                >
                  {t("Mail Template")}
                </Link>
              </li>
              {/*------ mail template link end ------*/}

              {/*------ question link start ------*/}
              <li>
                <Link
                  to="/questions"
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                >
                  {t("Questions")}
                </Link>
              </li>
              {/*------ question link end ------*/}

              {/*------ holidaycalendar link start ------*/}
              <li>
                <Link
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                  to="/holidaycalendar"
                >
                  {t("Holiday Calendar")}
                </Link>
              </li>
              {/*------ holidaycalendar link end ------*/}

              {/*------ activity link start ------*/}
              <li>
                <Link
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                  to="/activity"
                >
                  {t("Activity")}
                </Link>
              </li>
              {/*------ activity link end ------*/}

              {/*------ label link start ------*/}
              <li>
                <Link
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                  to="/label"
                >
                  {t("Label")}
                </Link>
              </li>
              {/*------ label link end ------*/}

              {/*------ survey link start ------*/}
              <li>
                <Link
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                  to="/survey"
                >
                  {t("Survey")}
                </Link>
              </li>
              {/*------ survey link end ------*/}

              {/*------ cost link start ------*/}
              <li>
                <Link
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                  to="/cost"
                >
                  {t("Cost")}
                </Link>
              </li>
              {/*------ cost link end ------*/}

              {/*------ materialkit link start ------*/}
              <li>
                <Link
                  to="/materialkit"
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                >
                  {t("Material Kit")}
                </Link>
              </li>
              {/*------ materialkit link end ------*/}

              {/*------ salesorder link start ------*/}
              <li>
                <Link
                  to="/salesorder"
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                >
                  {t("Sales Order")}
                </Link>
              </li>
              {/*------ salesorder link end ------*/}

              {/*------ concept link start ------*/}
              <li>
                <Link
                  to="/concept"
                  onClick={() => {
                    document.body.classList.remove("add_menupnl");
                  }}
                >
                  {t("Concept")}
                </Link>
              </li>
              {/*------ concept link end ------*/}
            </ul>
          </li>
          {/* ------ administration dropdown link end ------ */}

          {/* ------ users link start ------ */}
          <li>
            <Link
              to="/usermanagement"
              onClick={() => {
                document.body.classList.remove("add_menupnl");
              }}
            >
              <i className="material-icons-outlined">school</i>
              <span>{t("Users")}</span>
            </Link>
          </li>
          {/* ------ users link end ------ */}

          {/* ------ reservation link start ------ */}
          <li>
            <Link
              to="/reservation"
              onClick={() => {
                document.body.classList.remove("add_menupnl");
              }}
            >
              <i className="material-icons-outlined">book_online</i>
              <span>{t("Reservations")}</span>
            </Link>
          </li>
          {/* ------ reservation link end ------ */}

          {/* ------ conversation link start ------ */}
          <li>
            <Link
              to="/conversation"
              onClick={() => {
                document.body.classList.remove("add_menupnl");
              }}
            >
              <i className="material-icons-outlined">sms</i>
              <span>{t("Conversation")}</span>
            </Link>
          </li>
          {/* ------ conversation link end ------ */}
        </ul>
      </div>
      {/* ----- all module links section end ----- */}
    </div>
  );
};

export default LeftMenu;
