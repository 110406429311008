/* eslint-disable */
import React, { useEffect, useState } from 'react';

import QuestionListArea from 'components/QuestionsComponents/QuestionListArea';
import LeftMenu from 'components/common/LeftMenu';
import SurveyQuestionHeader from 'components/common/SurveyQuestionHeader/SurveyQuestionHeader';
import UserMenu from 'components/common/UserMenu';
import SaveUpdateQuestionPopup from 'components/QuestionsComponents/Popup/SaveUpdateQuestionPopup';
import CustomNotification from 'components/common/CustomNotification';

const Questions = () => {
  useEffect(() => {
    document.title = 'Questions';
  }, []);

  return (
    <>
      {/* ------------- menu section start ------------- */}
      <UserMenu />
      <LeftMenu />
      {/* ------------- menu section end ------------- */}

      <section className="survey-question-wrapper">
        {/* -------- header section start ------------- */}
        <SurveyQuestionHeader page="question" />
        {/* -------- header section end ------------- */}
        {/* ---------- question list area start ---------------- */}
        <QuestionListArea />
        {/* ---------- question list area end ---------------- */}
      </section>
      {/* ----------- save/update quetion popup start -------------- */}
      <SaveUpdateQuestionPopup />
      {/* ----------- save/update quetion popup end -------------- */}
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default Questions;
