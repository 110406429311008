/* eslint-disable */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const ToggleColumn = ({ setColumns, setRefresh }) => {
  const { t } = useTranslation(); //for translation
  const fieldOption = [
    { label: t('ID'), value: 'ID' },
    { label: t('AMB ID'), value: 'AMB ID' },
    { label: t('Date'), value: 'Date' },
    { label: t('Start Time'), value: 'Start Time' },
    { label: t('End Time'), value: 'End Time' },
    { label: t('Code'), value: 'Code' },
    { label: t('Activity'), value: 'Activity' },
    { label: t('Activity Type'), value: 'Activity Type' },
    { label: t('Tags'), value: 'Tags' },
    { label: t('Educator 1'), value: 'Educator 1' },
    { label: t('Educator 2'), value: 'Educator 2' },
    { label: t('Client'), value: 'Client' },
    { label: t('Observer'), value: 'Observer' },
    { label: t('Beneficiary'), value: 'Beneficiary' },
    { label: t('No of Participants'), value: 'No of Participants' },
    { label: t('No of Educator'), value: 'No of Educator' },
    { label: t('No Participants Real'), value: 'No Participants Real' },
    { label: t('Tutor'), value: 'Tutor' },
    { label: t('Grade'), value: 'Grade' },
    { label: t('Sales Order'), value: 'Sales Order' },
    { label: t('Comments'), value: 'Comments' },
    { label: t('Location'), value: 'Location' },
    { label: t('Zipcode'), value: 'Zipcode' },
    { label: t('Status'), value: 'Status' },
  ];

  const [field, setField] = useState(null);

  const fieldSelectionHandler = e => {
    e.preventDefault();
    if (field) {
      const selectedColumnValues = field.map(item => item.value);
      setColumns(selectedColumnValues);
    } else {
      setColumns([]);
    }
  };

  /* function to handle refresh  */

  const refreshHandler = () => {
    setField(null);
    setRefresh(true);
    setTimeout(() => setRefresh(false), 200);
  };

  return (
    <div className="collapse mt-3" id="reservation_show_columnCollapse">
      <div className="p-3 bg-gray-200">
        <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
          <h4 className="mb-0">{t('Show Columns')}</h4>
          <Link
            to="#"
            onClick={refreshHandler}
            className="d-flex gap-1 align-items-center text-black"
          >
            <span className="d-block material-symbols-outlined icon-md">
              sync
            </span>
            <span className="d-block fw-medium">{t('Refresh')}</span>
          </Link>
        </div>
        <form onSubmit={fieldSelectionHandler}>
          <div className="form-container d-flex flex-wrap gap-2">
            <div className="form-group flex-fill">
              <Select
                className="nosearch_select"
                isMulti
                options={fieldOption}
                value={field}
                onChange={val => setField(val)}
              />
            </div>
            <div className="action">
              <button type="submit" className="btn btn-primary mw-unset">
                {t('Show')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ToggleColumn;
