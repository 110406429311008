import React from "react";
import { Link } from "react-router-dom";

const MaterialCalendarHeader = ({ setListSchedularToggle }) => {
  return (
    <div className="calender_srch_top">
      {/* ------- arrow back icon section start ------- */}
      <div className="calender_addBtn mb-2 d-flex">
        <Link
          to="/materialkit"
          className="column-collapse btn btn-primary icon-btn mw-unset"
        >
          <span className="d-block material-symbols-outlined icon-fill">
            arrow_back
          </span>
        </Link>
      </div>
      {/* ------- arrow back icon section end ------- */}

      {/* -------  add icon section start ------- */}
      <div className="calender_addBtn mb-2 d-flex">
        <Link
          to="#saveMaterialSchedulePopup"
          data-bs-toggle="offcanvas"
          className="btn btn-primary"
        >
          <i className="material-icons-outlined">add</i>
        </Link>
      </div>
      {/* -------  add icon section end ------- */}
    </div>
  );
};

export default MaterialCalendarHeader;
