/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';

/* Importing react select */
import Select from 'react-select';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import api requiremnts
import * as url from 'urlhelpers/UrlHelper';
import { getData, putData } from 'utils/Gateway';

//import context
import { GlobalProvider } from 'context/GlobalContext';
import { Link } from 'react-router-dom';

const ViewSurveyFeedbackPopup = () => {
  const { t } = useTranslation(); //for translation
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const { reserveSurveyData, setReserveSurveyData } =
    useContext(GlobalProvider);

  const [surveyQuestionList, setSurveyQuestionList] = useState([]);
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isUser, setIsUser] = useState(false);

  //function for get surveyfeedback
  const getSurveyFeedback = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_SURVEY_FEEDBACK +
        `?surveyid=${reserveSurveyData._id}`;

      const response = await getData(requestUrl);

      console.log(response);
      if (response.status) {
        if (response.data) {
          console.log(response.data.user);
          console.log(userInfo._id);
          if (response.data.user == userInfo._id) {
            setIsUser(true);
          } else {
            setIsUser(false);
          }
          if (response.data.surveyresponsestatus == '1') {
            setSurveyAnswers(response.data.survey.questions);
            setSurveyQuestionList([]);
            setIsSubmited(true);
          } else {
            const updatedQuestions = response.data.survey.questions.map(
              question => ({
                ...question,
                answer: '',
                value: '',
              })
            );
            setSurveyQuestionList(updatedQuestions);
            setSurveyAnswers([]);
            setIsSubmited(false);
          }
        } else {
          setSurveyQuestionList([]);
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for submit survey
  const surveySubmitHandler = async () => {
    try {
      let surveyFeedbackData = {
        user: surveyUser,
        surveyfeedbackanswers: surveyQuestionList,
      };

      console.log('surveyFeedbackData=========>', surveyFeedbackData);

      let requestUrl =
        url.API_BaseUrl + url.API_UPDATE_SURVEY_FEEDBACK + `/${surveyData._id}`;

      const response = await putData(requestUrl, surveyFeedbackData);

      console.log('response submit feedback =====>', response);

      if (response.status) {
        let offCanvasPopup = document.querySelector(
          '#view_reserve_survey_feedback_popup'
        );
        let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
        offCanvas.hide();

        let myModal = new bootstrap.Modal(
          document.getElementById('submitReserveSurveyFeedback')
        );
        myModal.show();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const resetHandler = () => {
    setReserveSurveyData(null);
  };

  useEffect(() => {
    if (reserveSurveyData) {
      getSurveyFeedback();
    }
  }, [reserveSurveyData]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      id="view_reserve_survey_feedback_popup"
    >
      <div className="offcanvas-header align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t('Survey Feedback')}</span>
        </h3>

        <Link
          to="#allReserveSurveyPopup"
          data-bs-toggle="offcanvas"
          role="button"
          aria-controls="viewFeedbackPopup"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          onClick={resetHandler}
        ></Link>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <form onSubmit={e => e.preventDefault()}>
          {surveyQuestionList.length > 0 ? (
            <div className="bg-white p-0">
              {surveyQuestionList.map((surveyQuestion, index) => {
                return (
                  <div className="form-group mb-4" key={index}>
                    {/* ----- question section start ------ */}
                    <label htmlFor="intern">
                      {surveyQuestion ? surveyQuestion.question : ''}
                    </label>
                    {/* ----- question section end ------ */}

                    {/* ----- question type start ------ */}
                    {surveyQuestion.type == 'Rating' ? (
                      <div className="feedback_rating">
                        <ul className="list_stye_none">
                          {['1', '2', '3', '4', '5'].map((star, index2) => {
                            const isActive =
                              surveyQuestion.answer !== '' &&
                              Number(star) <= Number(surveyQuestion.answer);
                            return (
                              <li
                                key={index2}
                                className={
                                  isActive
                                    ? 'active k-cursor-pointer'
                                    : 'k-cursor-pointer'
                                }
                                onClick={() => {
                                  const value = star;
                                  setSurveyQuestionList(prevList => {
                                    const updatedList = [...prevList];
                                    updatedList[index].answer = value;
                                    return updatedList;
                                  });
                                }}
                              >
                                {isActive ? (
                                  <span className="material-icons-outlined">
                                    star
                                  </span>
                                ) : (
                                  <span className="material-icons-outlined">
                                    star_outline
                                  </span>
                                )}
                              </li>
                            );
                          })}
                          {/* <li className="active k-cursor-pointer">
                        <span className="material-icons-outlined">star</span>
                      </li>
                      <li className="k-cursor-pointer">
                        <span className="material-icons-outlined">
                          star_outline
                        </span>
                      </li>
                      <li className="k-cursor-pointer">
                        <span className="material-icons-outlined">
                          star_outline
                        </span>
                      </li>
                      <li className="k-cursor-pointer">
                        <span className="material-icons-outlined">
                          star_outline
                        </span>
                      </li> */}
                        </ul>
                      </div>
                    ) : surveyQuestion.type == 'Text' ? (
                      <input
                        type="text"
                        id="participating"
                        className="form-control"
                        placeholder="Answer"
                        value={surveyQuestion.answer}
                        onChange={e => {
                          const value = e.target.value;
                          setSurveyQuestionList(prevList => {
                            const updatedList = [...prevList];
                            updatedList[index].answer = value;
                            return updatedList;
                          });
                        }}
                      />
                    ) : surveyQuestion.type == 'Single Select' ? (
                      <Select
                        className="nosearch_select"
                        options={surveyQuestion.options}
                        value={surveyQuestion.value}
                        onChange={selectedOption => {
                          setSurveyQuestionList(prevList => {
                            const updatedList = [...prevList];
                            updatedList[index].value = selectedOption;
                            updatedList[index].answer = selectedOption.value;
                            return updatedList;
                          });
                        }}
                      />
                    ) : surveyQuestion.type == 'Multi Select' ? (
                      <Select
                        isMulti
                        className="nosearch_select"
                        options={surveyQuestion.options}
                        value={surveyQuestion.value}
                        onChange={selectedOptions => {
                          const values = selectedOptions.map(
                            option => option.value
                          );
                          const convertedString = values.join(', ');
                          setSurveyQuestionList(prevList => {
                            const updatedList = [...prevList];
                            updatedList[index].value = selectedOptions;
                            updatedList[index].answer = convertedString;
                            return updatedList;
                          });
                        }}
                      />
                    ) : surveyQuestion.type == 'Comments' ? (
                      <textarea
                        name="comment"
                        id="comment"
                        cols="30"
                        rows="4"
                        className="form-control"
                        placeholder={t('Give comment')}
                        value={surveyQuestion.answer}
                        onChange={e => {
                          const value = e.target.value;
                          setSurveyQuestionList(prevList => {
                            const updatedList = [...prevList];
                            updatedList[index].answer = value;
                            return updatedList;
                          });
                        }}
                      ></textarea>
                    ) : null}
                    {/* ----- question type end ------ */}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="bg-white p-3">
              {surveyAnswers.map((surveyAnswer, index) => {
                return (
                  <div className="form-group mb-4" key={index}>
                    {/* ----- question section start ------ */}
                    <label htmlFor="participating">
                      {surveyAnswer ? surveyAnswer.question : ''}
                    </label>
                    {surveyAnswer.type == 'Rating' ? (
                      <div className="feedback_rating ">
                        <ul className="list_stye_none">
                          {['1', '2', '3', '4', '5'].map((star, index2) => {
                            const isActive =
                              surveyAnswer.answer !== '' &&
                              Number(star) <= Number(surveyAnswer.answer);
                            return (
                              <li
                                key={index2}
                                className={
                                  isActive ? 'active pe-none' : 'pe-none'
                                }
                              >
                                {isActive ? (
                                  <span className="material-icons-outlined">
                                    star
                                  </span>
                                ) : (
                                  <span className="material-icons-outlined">
                                    star_outline
                                  </span>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : (
                      <p className="form-control">{surveyAnswer.answer}</p>
                    )}
                    {/* ----- question section end ------ */}
                  </div>
                );
              })}
            </div>
          )}

          {/* --------- submit button start -------- */}
          {isSubmited || !isUser ? null : (
            <div className="action d-flex align-items-center justify-content-end gap-2">
              <button
                type="button"
                className="btn btn-primary"
                // data-bs-toggle="modal"
                // data-bs-target="#thanks_modal"
                onClick={surveySubmitHandler}
              >
                {t('Submit Survey')}
              </button>
            </div>
          )}
          {/* --------- submit button end -------- */}
        </form>
      </div>
    </div>
  );
};

export default ViewSurveyFeedbackPopup;
