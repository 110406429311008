import React, { useState } from "react";

// Importing select
import Select from "react-select";
// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

/* importing dummy data */
import feedbackData from "../../../data/FeedbackRule.json";
import { process } from "@progress/kendo-data-query";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const FeedBackRulePopup = () => {
  const { t } = useTranslation(); //for translation

  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(feedbackData, initialDataState));

  const onDataStateChange = (event) => {
    console.log("event -->", event);
    setDataState(event.dataState);
    setResult(process(feedbackData, event.dataState));
  };

  /* Custom Action HTML */
  const actionHTML = () => {
    return (
      <>
        <td className="action">
          <Link to="#" className="text-dark">
            <span className="d-block material-symbols-outlined icon-fill">
              delete
            </span>
          </Link>
        </td>
      </>
    );
  };

  /* Selecting Role starts */

  const roles = [
    { label: "Role 1", value: "Role 1" },
    { label: "Role 2", value: "Role 2" },
    { label: "Role 3", value: "Role 3" },
    { label: "Role 4", value: "Role 4" },
    { label: "Role 5", value: "Role 5" },
  ];

  const [selectedRole, setSelectedRole] = useState(null);
  /* Selecting Role ends */

  /* Selecting survey starts */

  const survey = [
    { label: "Survey 1", value: "Survey 1" },
    { label: "Survey 2", value: "Survey 2" },
    { label: "Survey 3", value: "Survey 3" },
    { label: "Survey 4", value: "Survey 4" },
    { label: "Survey 5", value: "Survey 5" },
  ];

  const [selectedSurvey, setSelectedSurvey] = useState(null);
  /* Selecting survey ends */

  /* selecting Template Starts */

  const template = [
    { label: "Template 1", value: "Template 1" },
    { label: "Template 2", value: "Template 2" },
    { label: "Template 3", value: "Template 3" },
    { label: "Template 4", value: "Template 4" },
  ];
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  /* selecting Template ends */

  return (
    <>
      <div
        className="offcanvas offcanvas-end border-0"
        tabIndex="-1"
        id="ruleConfigurationPopup"
        aria-labelledby="ruleConfigurationPopupLabel"
      >
        <div className="offcanvas-header align-items-start p-4">
          <h3 className="text-secondary mb-0" id="ruleConfigurationPopupLabel">
            {t("Feedback Rule Configuration")}
          </h3>
          {/* <button
            type="button"
            className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
            data-bs-dismiss="offcanvas"
            aria-label="Close"

           
          ></button> */}
          <Link
            to="#saveReservationPopup"
            data-bs-toggle="offcanvas"
            role="button"
            aria-controls="saveReservationPopup"
            className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          />
        </div>
        <div className="offcanvas-body p-4 pt-0">
          <ul className="list_stye_none d-flex flex-column gap-2 mb-3">
            <li>
              <span className="text-gray">{t("Activity")}: </span>
              <span className="text-primary">
                AC01 - Educator survey for participating
              </span>
            </li>
            <li>
              <span className="text-gray">{t("Date")} : </span>
              <span className="text-primary">25.08.2022</span>
            </li>
            <li>
              <span className="text-gray">{t("Time")} : </span>
              <span className="text-primary">09:23 PM - 11:23 PM</span>
            </li>
          </ul>
          <form action="">
            <div className="form-container d-flex align-items-end gap-3 mb-3">
              <div className="form-group flex-fill mb-3 mb-md-0">
                <label htmlFor="survey">{t("Template")}</label>
                <Select
                  className="nosearch_select"
                  options={template}
                  value={selectedTemplate}
                  onChange={(val) => setSelectedTemplate(val)}
                />
              </div>
              {/* Selecting Role starts */}
              <div className="form-group flex-fill">
                <label htmlFor="role">{t("Role")}</label>
                <Select
                  className="nosearch_select"
                  options={roles}
                  value={selectedRole}
                  onChange={(val) => setSelectedRole(val)}
                />
              </div>
              {/* Selecting Role ends */}
              {/* Selecting survey starts  */}
              <div className="form-group flex-fill">
                <label htmlFor="survey">{t("Survey")}</label>
                <Select
                  className="nosearch_select"
                  options={survey}
                  value={selectedSurvey}
                  onChange={(val) => setSelectedSurvey(val)}
                />
              </div>
              {/* Selecting survey ends  */}

              <div className="action">
                <button type="submit" className="btn btn-primary mw-unset">
                  {t("Save")}
                </button>
              </div>
            </div>
          </form>
          {/*================ Role, survey action data starts========= */}
          <div className="table-responsive">
            <Grid
              data={result}
              filterable={false}
              sortable={true}
              // groupable={true}
              onDataStateChange={onDataStateChange}
              {...dataState}
              //pageable = {true}
              //total = {products.length}
              rowHeight={50}
              reorderable
              // resizable
            >
              <GridColumn field="Template" title={t("Template")} />
              <GridColumn field="Role" title={t("Role")} />
              <GridColumn field="Survey" title={t("Survey")} />
              <GridColumn cell={actionHTML} title={t("Action")} />
            </Grid>
          </div>
          {/*================ Role, survey action data ends ========= */}
        </div>
      </div>
    </>
  );
};

export default FeedBackRulePopup;
