/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import Select from 'react-select';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/*import url and gateway methods */
import { putData, deleteData, getData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import context
import { GlobalProvider } from 'context/GlobalContext';

//import option for availibility
import availibilityOptions from 'data/Prod/CalendarAvailability.json';

const EditSchedulePopup = () => {
  const token = localStorage.getItem('token'); // token

  /* ========== get user id from url params ========= */
  const params = useParams();

  const { t } = useTranslation(); //for translation

  const { editCalendarEvents, setEditCalendarEvents, setReloadCalendarEvents } =
    useContext(GlobalProvider);

  const [clientList, setClientList] = useState([]);
  const [conceptList, setConceptList] = useState([]);
  const [reserveList, setReserveList] = useState([]);

  const [clientValue, setClientValue] = useState(null);
  const [conceptValue, setConceptValue] = useState(null);
  const [reserveValue, setReserveValue] = useState(null);

  /* useState hook for selected Availability */
  const [availabilityValue, setAvailabilityValue] = useState(null);
  const [availabilityData, setAvailabilityData] = useState(null);

  /* Hook for validation start */
  const [validation, setValidation] = useState({
    timeWarning: false,
  });

  /* function for validation starts */
  const formValidation = () => {
    let isValid = true;

    if (userInputs.starttime > userInputs.endtime) {
      setValidation(prevState => ({ ...prevState, timeWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  /* useState hook for input fields */
  const [userInputs, setUserInputs] = useState({
    user: params.id,
    name: '',
    date: '',
    starttime: '',
    endtime: '',
    description: '',
  });
  const [isEditing, setIsEditing] = useState({
    startTimeEdit: false,
    endTimeEdit: false,
  });

  //function for get all user list
  const getAllUserList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        //client list
        let filteredClient = response.data.filter(user => {
          let userData;
          if (user.role) {
            if (user.role.name == 'client' || user.role.name == 'beneficiary') {
              userData = user;
            }
          }
          return userData;
        });

        setClientList(filteredClient);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all concept
  const getAllConceptList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl + url.API_GET_ALL_CONCEPT + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setConceptList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all reserve
  const getAllReserveList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_RESERVE_EVENTS +
        `?token=${token}&isselect=${true}&scheduleuser=${params.id}`;

      console.log(
        'Reserve list url for profile schedule tab ========>',
        requestUrl
      );

      const response = await getData(requestUrl);

      console.log(
        'Reserve list response for profile schedule tab ========>',
        response
      );

      if (response.status) {
        setReserveList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for Availability selection
  const availabilitySelectionHandler = val => {
    setAvailabilityValue(val);
    setAvailabilityData(val.value);
  };

  /* function to edit calender event */
  const editScheduleHandler = async e => {
    e.preventDefault();

    let scheduleData = {
      user: params.id,
      name: userInputs.name,
      date: userInputs.date,
      starttime: userInputs.starttime,
      endtime: userInputs.endtime,
      description: userInputs.description,
      availability: availabilityData,
      conceptid: conceptValue?.value,
      clientid: clientValue?.value,
      reserveid: reserveValue?.value,
    };

    console.log(`Schedule Data===========`, scheduleData);

    if (formValidation()) {
      try {
        let requestUrl =
          url.API_BaseUrl +
          url.API_UPDATE_SCHEDULE +
          `/${editCalendarEvents.id}` +
          `?token=${token}`;

        console.log('Request url of edit schedule =========>', requestUrl);

        const response = await putData(requestUrl, scheduleData);

        console.log(`response updating schedule data ========>>`, response);

        if (response.status) {
          resetHandler();
          bootstrap.Offcanvas.getInstance(
            document.querySelector('#editSchedulePopup')
          ).hide();
          setReloadCalendarEvents(true);
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  //function for delete schedule
  const deleteScheduleHandler = async e => {
    e.preventDefault();

    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_DELETE_SCHEDULE +
        `/${editCalendarEvents.id}` +
        `?token=${token}`;

      console.log('Request url of delete schedule =========>', requestUrl);

      const response = await deleteData(requestUrl);

      console.log(`response deleting schedule data ========>>`, response);

      if (response.status) {
        resetHandler();
        bootstrap.Offcanvas.getInstance(
          document.querySelector('#editSchedulePopup')
        ).hide();
        setReloadCalendarEvents(true);
      }
    } catch (error) {
      console.log('Error in delete schedule=========>', error.message);
    }
  };

  /* reset handler */
  const resetHandler = () => {
    setUserInputs({
      name: '',
      date: '',
      starttime: '',
      endtime: '',
      description: '',
    });
    setAvailabilityData(null);
    setAvailabilityValue(null);
    setClientValue(null);
    setConceptValue(null);
    setReserveValue(null);
    setEditCalendarEvents(null);
    setValidation({
      timeWarning: false,
    });
  };

  /* Hook to assign edit data */
  useEffect(() => {
    console.log('Edit calender data=============>', editCalendarEvents);
    getAllConceptList();
    getAllReserveList();
    getAllUserList();
    if (editCalendarEvents) {
      setUserInputs(prevState => ({
        ...prevState,
        name: editCalendarEvents.title || '',
        date: editCalendarEvents.date || '',
        starttime: editCalendarEvents.starttime || '',
        endtime: editCalendarEvents.endtime || '',
        description: editCalendarEvents.description || '',
      }));

      /*--------- assign availability start -----------*/
      if (editCalendarEvents.availability) {
        if (editCalendarEvents.availability == '1') {
          let availabilitySelectData = {
            label: 'available',
            value: '1',
          };
          availabilitySelectionHandler(availabilitySelectData);
        } else if (editCalendarEvents.availability == '0') {
          let availabilitySelectData = {
            label: 'reserved',
            value: '0',
          };
          availabilitySelectionHandler(availabilitySelectData);
        } else {
          let availabilitySelectData = {
            label: 'holiday',
            value: '2',
          };
          availabilitySelectionHandler(availabilitySelectData);
        }
      }
      /*--------- assign availability end -----------*/

      /*------- assign client start -------*/
      if (editCalendarEvents.clientid) {
        setClientValue(editCalendarEvents.clientid);
      }
      /*------- assign client end -------*/

      /*------- assign concept start -------*/
      if (editCalendarEvents.conceptid) {
        setConceptValue(editCalendarEvents.conceptid);
      }
      /*------- assign conceptid end -------*/

      /*------- assign reserve start -------*/
      if (editCalendarEvents.reserveid) {
        setReserveValue(editCalendarEvents.reserveid);
      }
      /*------- assign reserve end -------*/
    }
  }, [editCalendarEvents]);

  return (
    <div className="offcanvas offcanvas-end border-0" id="editSchedulePopup">
      <div className="offcanvas-header align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t('Update Calendar Record')}</span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <div className="calendar_data_item">
          <div className="form-group mb-4">
            <label>{t('Title')} *</label>
            <input
              type="text"
              className="form-control"
              placeholder={t('Title')}
              value={userInputs.name}
              onChange={val => {
                setUserInputs(prevState => ({
                  ...prevState,
                  name: val.target.value,
                }));
                // setValidation((prevState) => ({
                //   ...prevState,
                //   labelWarning: false,
                // }));
              }}
            />
            {/* Responsible name warning */}
            {/* {validation.responsileNameWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t('Please enter responsible name')}!</span>
                  </p>
                </div>
              )} */}
          </div>

          {/* ========== date/time section start ============ */}
          <div className="form-group mb-4">
            <div className="row">
              <div className="col-lg-4">
                <label htmlFor="date">{t('Date')} *</label>
                <input
                  type="date"
                  id="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  value={userInputs.date}
                  onChange={val => {
                    setUserInputs(prevState => ({
                      ...prevState,
                      date: val.target.value,
                    }));
                  }}
                />
                {/* start date warning */}
                {/* {validation.startDateWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter date')}!</span>
                      </p>
                    </div>
                  )} */}
                {/* start date warning */}
                {/* {validation.startDateWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter start date')}!</span>
                      </p>
                    </div>
                  )} */}
              </div>
            </div>
          </div>

          {/* ========== date/time section start ============ */}
          <div className="form-group mb-4">
            <div className="row">
              <div className="col-lg-4">
                <label htmlFor="startTime">{t('Start Time')} *</label>
                <input
                  type={isEditing.startTimeEdit ? 'time' : 'text'}
                  id="startTime"
                  className="form-control"
                  placeholder="HH : MM"
                  onClick={() =>
                    setIsEditing(prevState => ({
                      ...prevState,
                      startTimeEdit: true,
                    }))
                  }
                  onBlur={() =>
                    setIsEditing(prevState => ({
                      ...prevState,
                      startTimeEdit: false,
                    }))
                  }
                  value={userInputs.starttime}
                  onChange={val => {
                    setUserInputs(prevState => ({
                      ...prevState,
                      starttime: val.target.value,
                    }));
                    setValidation(prevState => ({
                      ...prevState,
                      timeWarning: false,
                    }));
                  }}
                />

                {/* time compare warning */}
                {validation.timeWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>
                        {t('End time should be greater than start time')}!
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <label htmlFor="endTime">{t('End Time')} *</label>
                <input
                  type={isEditing.endTimeEdit ? 'time' : 'text'}
                  id="endTime"
                  className="form-control"
                  placeholder="HH : MM"
                  onClick={() =>
                    setIsEditing(prevState => ({
                      ...prevState,
                      endTimeEdit: true,
                    }))
                  }
                  onBlur={() =>
                    setIsEditing(prevState => ({
                      ...prevState,
                      endTimeEdit: false,
                    }))
                  }
                  value={userInputs.endtime}
                  onChange={val => {
                    setUserInputs(prevState => ({
                      ...prevState,
                      endtime: val.target.value,
                    }));
                    setValidation(prevState => ({
                      ...prevState,
                      timeWarning: false,
                    }));
                  }}
                />
                {/* start time warning */}
                {/* {validation.endTimeWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter end time')}!</span>
                      </p>
                    </div>
                  )} */}
              </div>

              {/* ------ Availability section start ----- */}
              <div className="col-lg-4">
                <label htmlFor="endTime">{t('Set record as')} *</label>
                <Select
                  options={availibilityOptions}
                  value={availabilityValue}
                  onChange={val => {
                    availabilitySelectionHandler(val);
                  }}
                />
              </div>
              {/* ------ Availability section end ----- */}
            </div>
          </div>
          {/* ========== date/time section end ============ */}

          {/* ------- select concept section start ------ */}
          <div className="form-group mb-4">
            <label htmlFor="conceptselect">{t('Select Concept')}</label>
            <Select
              options={conceptList}
              placeholder={t('Select concept')}
              value={conceptValue}
              onChange={val => {
                setConceptValue(val);
              }}
            />
          </div>
          {/* ------- select concept section end ------ */}

          {/* ------- select client section start ------ */}
          <div className="form-group mb-4">
            <label htmlFor="clientselect">{t('Select Client')}</label>
            <Select
              options={clientList}
              placeholder={t('Select client')}
              value={clientValue}
              onChange={val => {
                setClientValue(val);
              }}
            />
          </div>
          {/* ------- select client section end ------ */}

          {/* ------- select reserve section start ------ */}
          <div className="form-group mb-4">
            <label htmlFor="reserveselect">{t('Select Reserve')}</label>
            <Select
              options={reserveList}
              placeholder={t('Select reserve')}
              value={reserveValue}
              onChange={val => {
                setReserveValue(val);
              }}
            />
          </div>
          {/* ------- select reserve section end ------ */}

          {/* =============== description section starts ============= */}
          <div className="form-group mb-4">
            <label htmlFor="educatorComments">{t('Description')}</label>
            <textarea
              name="educatorComments"
              id="educatorComments"
              cols="30"
              rows="4"
              className="form-control"
              placeholder={t('Enter Description')}
              value={userInputs.description}
              onChange={val => {
                setUserInputs(prevState => ({
                  ...prevState,
                  description: val.target.value,
                }));
              }}
            ></textarea>
            {/* comments warning */}
            {/* {validation.commentsWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t('Please enter comments')}!</span>
                  </p>
                </div>
              )} */}
          </div>
          {/* =============== description section ends ============= */}
        </div>

        <div className="action d-flex align-items-center gap-2">
          <button className="btn btn-primary" onClick={editScheduleHandler}>
            {t('Save')}
          </button>

          <button className="btn btn-danger" onClick={deleteScheduleHandler}>
            {t('Delete')}
          </button>

          {/* <p className="warring">
              <i className="material-icons-outlined">warning</i>
              <span>{t('Fill up all fields. Please Try again!')}</span>
            </p> */}
        </div>
      </div>
    </div>
  );
};

export default EditSchedulePopup;
