import MaterialCalendarBody from 'components/MaterialCalendarComponents/MaterialCalendarBody/MaterialCalendarBody';
import MaterialCalendarBreadCrump from 'components/MaterialCalendarComponents/MaterialCalendarBreadCrump/MaterialCalendarBreadCrump';
import MaterialCalendarHeader from 'components/MaterialCalendarComponents/MaterialCalendarHeader/MaterialCalendarHeader';
import AddNewMaterialSchedulePopup from 'components/MaterialCalendarComponents/Popup/AddNewMaterialSchedulePopup';
import EditMaterialSchedulePopup from 'components/MaterialCalendarComponents/Popup/EditMaterialSchedulePopup';
import CustomNotification from 'components/common/CustomNotification';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';
import React, { useEffect } from 'react';

const MaterialCalendar = () => {
  useEffect(() => {
    document.title = ' Material Calendar';
  }, []);
  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="mail-wrapper py-4">
        <div className="container-fluid px-4">
          <MaterialCalendarBreadCrump />
          <MaterialCalendarHeader />
          <MaterialCalendarBody />
        </div>
      </section>
      <AddNewMaterialSchedulePopup />
      <EditMaterialSchedulePopup />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default MaterialCalendar;
