import React from 'react';
import { Link } from 'react-router-dom';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const AddRolePrivilageNav = () => {
  const { t } = useTranslation(); //for translation
  return (
    <>
      <nav aria-label="breadcrumb">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link href="dashboard.html" className="text-secondary">
              {t('Funbrain')}
            </Link>
          </li>
          <li className="breadcrumb-item text-gray-600" aria-current="page">
            {t('Manage Role Access')}
          </li>
        </ul>
      </nav>
      <div className="filter-container d-flex flex-wrap align-items-center gap-2 pb-3 border-bottom border-gray">
        <div className="search-container position-relative d-none d-md-block">
          <form action="">
            <input
              type="search"
              placeholder={t('Search here')}
              className="form-control bg-transparent py-2 border-0"
            />
            <button
              type="submit"
              className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
            >
              <span className="d-block material-symbols-outlined">search</span>
            </button>
          </form>
        </div>
        <Link to="#" className="filter-btn btn btn-primary mw-unset ms-auto">
          {t('Update')}
        </Link>
      </div>
    </>
  );
};

export default AddRolePrivilageNav;
