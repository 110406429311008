/* eslint-disable */
import React, { useState, createRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

// kendo react modules ==========================
import '@progress/kendo-theme-material/dist/all.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/* import invoice data */
import invoiceData from '../../../data/invoice.json';

//import api requiremnts
import * as url from 'urlhelpers/UrlHelper';
import { getData, postData } from 'utils/Gateway';

// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';
import { GlobalProvider } from 'context/GlobalContext';

const ViewSalesOrderPopup = () => {
  const token = localStorage.getItem('token');

  //today value
  const todayValue = new Date().toISOString().split('T')[0];

  const { salesOrderData, setSalesOrderData } = useContext(GlobalProvider);

  const [salesOrderItems, setSalesOrderItems] = useState([]);

  // without pagination ------------------------------------
  const initialDataState = {};

  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(
    process(salesOrderItems, initialDataState)
  );

  /* hook to download xls */
  const [downloadXls, setDownloadXls] = useState(false);

  const [noteList, setNoteList] = useState([]);

  const [noteData, setNoteData] = useState('');

  const [isNoteSending, setIsNoteSending] = useState(false);

  const { t } = useTranslation(); //for translation

  const onDataStateChange = event => {
    setDataState(event.dataState);
    setResult(process(salesOrderItems, event.dataState));
  };

  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();

  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  //function for get all notes
  const getAllNotes = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_NOTE +
        `?token=${token}&reserveid=${salesOrderData._id}`;

      console.log('url for all notes========>', requestUrl);

      const response = await getData(requestUrl);

      console.log('response for all notes========>', response);

      if (response.status) {
        setNoteList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save note
  const saveNoteHandler = async () => {
    try {
      setIsNoteSending(true);

      let saveNoteData = {
        notedate: todayValue.split('-').reverse().join('/'),
        notedata: noteData,
        salesorderid: salesOrderData._id,
      };

      setNoteData('');

      console.log('Notedata------->', saveNoteData);

      let requestUrl =
        url.API_BaseUrl + url.API_CREATE_NOTE + `?token=${token}`;

      console.log('url for save note=========>', requestUrl);

      const response = await postData(requestUrl, saveNoteData);

      console.log('response in save note========>', response);

      if (response.status) {
        setIsNoteSending(false);
        getAllNotes();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (salesOrderData) {
      console.log('sales order item=========>', salesOrderData.salesorderitems);
      setSalesOrderItems(salesOrderData.salesorderitems);
      setResult(process(salesOrderData.salesorderitems, initialDataState));
      getAllNotes();
    }
  }, [salesOrderData]);

  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadXls && exportExcel();
  }, [downloadXls]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="viewsalesOrderPopup"
      aria-labelledby="salesOrderPopupLabel"
    >
      <div className="offcanvas-header p-4">
        {/* -------- sales order heading start ------- */}
        <h3 className="text-secondary mb-0" id="salesOrderPopupLabel">
          {t('Sales Order')}
        </h3>
        {/* -------- sales order heading end ------- */}

        {/* -------- sales order button start ------- */}
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => {
            setSalesOrderData(null);
            setNoteList([]);
          }}
        ></button>
        {/* -------- sales order button end ------- */}
      </div>
      <div className="offcanvas-body p-4 pt-0">
        {/* -------- invoice heading section start ------- */}
        <h2 className="invoice-heading bg-primary text-center fw-bold lh-1 mb-4">
          <span className="d-inline-block text-secondary px-2 bg-white">
            {t('INVOICE')}
          </span>
        </h2>
        {/* -------- invoice heading section end ------- */}

        <div className="order-info d-flex align-items-center bg-gray-500 border border-gray mb-4">
          <div className="flex-fill fs-sm text-gray fw-medium p-3 border-end border-gray">
            <h6 className="fw-bold mb-3">{t('CUSTOMER INFORMATION')}</h6>
            {/* -------- client section start ------- */}
            <p className="mb-1">{salesOrderData?.clientfullname}</p>
            <p>
              {salesOrderData?.client?.address}, {salesOrderData?.client?.city}
              {salesOrderData?.client?.state},{salesOrderData?.client?.country},
              {salesOrderData?.client?.zipcode}
            </p>
            {/* -------- client section end ------- */}
          </div>
          <div className="flex-fill fs-sm text-gray fw-medium p-3 border-end border-gray">
            {/* -------- invoice section start ------- */}
            <h6 className="fw-bold mb-3">{t('ORDER INFORMATION')}</h6>
            <p className="d-flex mb-1">
              <span className="d-inline-block w-50 text-secondary fw-medium">
                {t('Invoice id')}:
              </span>
              <span className="d-inline-block w-50">
                #{salesOrderData?.invoiceid}
              </span>
            </p>
            {/* -------- invoice section end ------- */}

            {/* -------- order sate section start ------- */}
            <p className="d-flex">
              <span className="d-inline-block w-50 text-secondary fw-medium">
                {t('Order Date')}:
              </span>
              <span className="d-inline-block w-50">
                {salesOrderData?.invoicedate}
              </span>
            </p>
            {/* -------- order sate section end ------- */}
          </div>
          <div className="action flex-fill p-3">
            <div className="d-flex justify-content-center gap-2">
              <Link
                to="#"
                className="d-flex align-items-center justify-content-center bg-secondary text-white rounded-circle"
              >
                <span className="d-block material-symbols-outlined icon-md">
                  print
                </span>
              </Link>
              <Link
                to="#"
                onClick={() => setDownloadXls(true)}
                className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle"
              >
                <span className="d-block material-symbols-outlined icon-md">
                  sim_card_download
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="table-responsive mb-4">
          <div
            id="salesOrders"
            className="sales-orders table align-middle table-without-separator border-top border-gray mb-0"
          >
            {/* ============ download xls start ========== */}
            <ExcelExport
              data={salesOrderItems}
              fileName="Invoice.xlsx"
              ref={_exporter}
            >
              <ExcelExportColumn field="slno" title={t('Sl No')} />
              <ExcelExportColumn field="activityname" title={t('Activity')} />
              <ExcelExportColumn field="reservedate" title={t('Date')} />
              <ExcelExportColumn
                field="activitycost"
                title={t('Total Amount')}
                width="120px"
              />
              <ExcelExportColumn
                field="discountpercent"
                title={t('Discount')}
              />
              <ExcelExportColumn
                field="totalprice"
                title={t('payable Amount')}
              />
            </ExcelExport>
            {/* ============ download xls end ========== */}

            {/* ================= grid view start ====================== */}
            <Grid
              style={{ minWidth: '100%' }}
              data={result}
              // filterable={true}
              sortable={true}
              // groupable={true}
              onDataStateChange={onDataStateChange}
              {...dataState}
              //pageable = {true}
              //total = {products.length}
              reorderable
              // resizable
            >
              <GridColumn field="slno" title={t('Sl No')} width="70px" />
              <GridColumn
                field="activityname"
                title={t('Activity')}
                width="200px"
              />
              <GridColumn field="reservedate" title={t('Date')} width="120px" />
              <GridColumn
                field="activitycost"
                title={t('Total Amount')}
                width="120px"
              />
              <GridColumn
                field="discountpercent"
                title={t('Discount')}
                width="100px"
              />
              <GridColumn
                field="totalprice"
                title={t('payable Amount')}
                width="150px"
              />
            </Grid>
            {/* ================= grid view end ====================== */}
          </div>
        </div>
        <div className="total d-flex bg-gray-500 border border-gray">
          {/* ----- note submit section start ------ */}
          <div className="flex-fill text-gray fw-medium p-3 border-end border-gray">
            {noteList.length > 0 ? (
              <div className="mb-2">
                {noteList.map((note, index) => {
                  return (
                    <p className="mb-1" key={index}>
                      {note.notedate} : {note.note}
                    </p>
                  );
                })}
              </div>
            ) : null}

            <form onSubmit={e => e.preventDefault()}>
              <label htmlFor="notes" className="text-secondary fw-bold">
                {t('Notes')}:
              </label>
              {/* ----- note input section start ------ */}
              <textarea
                name="notes"
                id="notes"
                cols="30"
                rows="3"
                className="form-control bg-white mb-3"
                placeholder={t('Enter Notes')}
                value={noteData}
                onChange={e => setNoteData(e.target.value)}
              ></textarea>
              {/* ----- note input section end ------ */}
              <button
                type="submit"
                className="btn btn-primary btn-sm mw-unset"
                onClick={saveNoteHandler}
                disabled={isNoteSending ? true : false}
                style={{ cursor: isNoteSending ? 'not-allowed' : 'pointer' }}
              >
                {t('Save')}
                {isNoteSending && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </form>
          </div>
          {/* ----- note submit section end ------ */}

          {/* ----- tax and other amount section start ------ */}
          <div className="d-flex align-items-center justify-content-end flex-fill text-gray fw-medium p-3">
            <ul className="list_stye_none text-end">
              <li className="mb-2">
                <span className="d-inline-block text-secondary">
                  {t('Total')} :
                </span>
                <span className="ms-1 d-inline-block">
                  {salesOrderData?.totalactivitycost}
                </span>
              </li>
              {/* <li className="mb-2">
                <span className="d-inline-block text-secondary">
                  {t("Taxes")}:
                </span>
                <span className="d-inline-block">48 Euro</span>
              </li> */}
              <li className="total pt-2 border-top">
                <span className="d-inline-block text-secondary">
                  {t('Payable')} :
                </span>
                <span className="ms-1 d-inline-block">
                  {salesOrderData?.totalpaybleprice}
                </span>
              </li>
            </ul>
          </div>
          {/* ----- tax and other amount section end ------ */}
        </div>
      </div>
    </div>
  );
};

export default ViewSalesOrderPopup;
