import HolidayCalenderBreadCrumb from 'components/HolidayCalendarComponents/BreadCrumb/HolidayCalenderBreadCrumb';
import HolidayCalenderHeader from 'components/HolidayCalendarComponents/Header/HolidayCalenderHeader';
import HolidayCalendarBody from 'components/HolidayCalendarComponents/HolidayCalendayBody/HolidayCalendarBody';
import AddNewHolidayPopup from 'components/HolidayCalendarComponents/Popup/AddNewHolidayPopup';
import CustomNotification from 'components/common/CustomNotification';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';
import React from 'react';

const HolidayCalendar = () => {
  React.useEffect(() => {
    document.title = 'Holiday Calendar';
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="mail-wrapper py-4">
        <div className="container-fluid px-4">
          <HolidayCalenderBreadCrumb />
          <HolidayCalenderHeader />
          <HolidayCalendarBody />
        </div>
      </section>
      <AddNewHolidayPopup />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default HolidayCalendar;
