/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import Select from 'react-select';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/*import url and gateway methods */
import { postData, getData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import option for availibility
import availibilityOptions from 'data/Prod/CalendarAvailability.json';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const SaveSchedulePopup = ({ reloadCalender, setReloadCalender }) => {
  const token = localStorage.getItem('token'); // token

  /* ========== get user id from url params ========= */
  const params = useParams();

  const { t } = useTranslation(); //for translation

  const { setReloadCalendarEvents } = useContext(GlobalProvider);

  const [clientList, setClientList] = useState([]);
  const [conceptList, setConceptList] = useState([]);
  const [reserveList, setReserveList] = useState([]);

  /* useState hook for selected Availability */
  const [availabilityValue, setAvailabilityValue] = useState(null);
  const [availabilityData, setAvailabilityData] = useState(null);

  const [clientValue, setClientValue] = useState(null);
  const [conceptValue, setConceptValue] = useState(null);
  const [reserveValue, setReserveValue] = useState(null);

  const [selectedDays, setSelectedDays] = useState([]);

  const today = new Date().toISOString().split('T')[0];

  //function for get current time and set by defualt
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    const timenow = `${hours}:${minutes}`;

    return timenow;
  };

  /* useState hook for input fields */
  const [scheduleInputs, setScheduleInputs] = useState({
    name: 'Available',
    date: today,
    starttime: getCurrentTime(),
    endtime: getCurrentTime(),
    description: '',
    enddate: '',
  });

  //function for get all user list
  const getAllUserList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        //client list
        let filteredClient = response.data.filter(user => {
          let userData;
          if (user.role) {
            if (user.role.name == 'client' || user.role.name == 'beneficiary') {
              userData = user;
            }
          }
          return userData;
        });

        setClientList(filteredClient);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all concept
  const getAllConceptList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl + url.API_GET_ALL_CONCEPT + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setConceptList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all reserve
  const getAllReserveList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_RESERVE_EVENTS +
        `?token=${token}&isselect=${true}&scheduleuser=${params.id}`;

      console.log(
        'Reserve list url for profile schedule tab ========>',
        requestUrl
      );

      const response = await getData(requestUrl);

      console.log(
        'Reserve list response for profile schedule tab ========>',
        response
      );

      if (response.status) {
        setReserveList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for Availability selection
  const availabilitySelectionHandler = val => {
    setAvailabilityValue(val);
    setAvailabilityData(val.value);
  };

  //function for day checkbox value
  const dayCheckboxChange = event => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedDays(prevSelectedDays => [...prevSelectedDays, value]);
    } else {
      setSelectedDays(prevSelectedDays =>
        prevSelectedDays.filter(day => day !== value)
      );
    }
  };
  /* Hook for validation start */
  const [validation, setValidation] = useState({
    dateWarning: false,
    timeWarning: false,
  });

  /* function for validation starts */
  const formValidation = () => {
    let isValid = true;

    if (
      scheduleInputs.enddate !== '' &&
      scheduleInputs.date > scheduleInputs.enddate
    ) {
      setValidation(prevState => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }
    if (scheduleInputs.starttime > scheduleInputs.endtime) {
      setValidation(prevState => ({ ...prevState, timeWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  /* function for validation ends */

  /* function to add calender event */
  const addScheduleHandler = async e => {
    e.preventDefault();

    let scheduleData = {
      user: params.id,
      name: scheduleInputs.name,
      date: scheduleInputs.date,
      starttime: scheduleInputs.starttime,
      endtime: scheduleInputs.endtime,
      description: scheduleInputs.description,
      enddate: scheduleInputs.enddate,
      availability: availabilityData,
      days: selectedDays || [],
      conceptid: conceptValue,
      clientid: clientValue,
      reserveid: reserveValue,
    };

    console.log(`Schedule Data===========`, scheduleData);

    if (formValidation()) {
      try {
        let requestUrl =
          url.API_BaseUrl + url.API_ADD_NEW_SCHEDULE + `?token=${token}`;

        const response = await postData(requestUrl, scheduleData);

        console.log(`response submiting schedule data ========>>`, response);

        if (response.status) {
          resetHandler();
          bootstrap.Offcanvas.getInstance(
            document.querySelector('#saveSchedulePopup')
          ).hide();
          setReloadCalendarEvents(true);
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  /* reset handler */
  const resetHandler = () => {
    setScheduleInputs({
      name: 'Available',
      date: today,
      starttime: getCurrentTime(),
      endtime: getCurrentTime(),
      description: '',
      enddate: '',
    });
    let availibilityData = { label: 'available', value: '1' };
    availabilitySelectionHandler(availibilityData);
    setSelectedDays([]);
    setClientValue(null);
    setConceptValue(null);
    setReserveValue(null);
    setValidation({
      dateWarning: false,
      timeWarning: false,
    });
  };

  /* hook to reload list */
  useEffect(() => {
    if (reloadCalender) {
      getAllUserList();
      getAllConceptList();
      getAllReserveList();
      let availibilityData = { label: 'available', value: '1' };
      availabilitySelectionHandler(availibilityData);
      setReloadCalender(false);
    }
  }, [reloadCalender]);

  //use effect for set default value of avilibility
  useEffect(() => {
    getAllUserList();
    getAllConceptList();
    getAllReserveList();
    let availibilityData = { label: 'available', value: '1' };
    availabilitySelectionHandler(availibilityData);
  }, []);

  return (
    <div className="offcanvas offcanvas-end border-0" id="saveSchedulePopup">
      <div className="offcanvas-header align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t('Add Calendar Record')}</span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <form onSubmit={addScheduleHandler}>
          <div className="calendar_data_item">
            {/* ========== title section start ============ */}
            <div className="form-group mb-4">
              <label>{t('Title')} *</label>
              <input
                type="text"
                className="form-control"
                placeholder={t('Title')}
                value={scheduleInputs.name}
                onChange={val => {
                  setScheduleInputs(prevState => ({
                    ...prevState,
                    name: val.target.value,
                  }));
                }}
              />
            </div>
            {/* ========== title section end ============ */}

            {/* ========== date section start ============ */}
            <div className="form-group mb-4">
              <div className="row">
                {/* -------- start date section start --------- */}
                <div className="col-lg-6">
                  <label htmlFor="date">{t('Start Date')} *</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="DD/MM/YYYY"
                    value={scheduleInputs.date}
                    onChange={val => {
                      setScheduleInputs(prevState => ({
                        ...prevState,
                        date: val.target.value,
                      }));

                      setValidation(prevState => ({
                        ...prevState,
                        dateWarning: false,
                      }));
                    }}
                  />
                  {/* date compare warning */}
                  {validation.dateWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>
                          {t('End date should be greater than start date')}!
                        </span>
                      </p>
                    </div>
                  )}
                </div>
                {/* -------- start date section end --------- */}

                {/* -------- end date section start --------- */}
                <div className="col-lg-6">
                  <label htmlFor="date">{t('End Date')}</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="DD/MM/YYYY"
                    value={scheduleInputs.enddate}
                    onChange={val => {
                      setScheduleInputs(prevState => ({
                        ...prevState,
                        enddate: val.target.value,
                      }));
                      setValidation(prevState => ({
                        ...prevState,
                        dateWarning: false,
                      }));
                    }}
                  />
                </div>
                {/* -------- end date section end --------- */}
              </div>
            </div>
            {/* ========== date section end ============ */}

            <div className="form-group mb-4">
              {/* =============== select days start ==================  */}
              {scheduleInputs.enddate == '' ||
              scheduleInputs.date == '' ? null : (
                <div className="row">
                  <div className="col">
                    <label htmlFor="repeatDays">{t('Repeat On')}</label>
                    <div className="repeat-days-container">
                      <div className="d-flex overflow-auto py-2">
                        {/* ------- sunday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            // checked={selectedSunday}
                            // onChange={(e) => {
                            //   setSelectedSunday(e.target.checked);
                            // }}
                            value="sunday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Sunday
                          </label>
                        </div>
                        {/* ------- sunday section end ------ */}

                        {/* ------- monday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedMonday}
                            // onChange={(e) => {
                            //   setSelectedMonday(e.target.checked);
                            // }}
                            value="monday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Monday
                          </label>
                        </div>
                        {/* ------- monday section end ------ */}

                        {/* ------- tuesday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedTuesday}
                            // onChange={(e) => {
                            //   setSelectedTuesday(e.target.checked);
                            // }}
                            value="tuesday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Tuesday
                          </label>
                        </div>
                        {/* ------- tuesday section end ------ */}

                        {/* ------- Wednesday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedWednesday}
                            // onChange={(e) => {
                            //   setSelectedWednesday(e.target.checked);
                            // }}
                            value="wednesday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Wednesday
                          </label>
                        </div>
                        {/* ------- Wednesday section end ------ */}

                        {/* ------- Thursday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            // checked={selectedThursday}
                            // onChange={(e) => {
                            //   setSelectedThursday(e.target.checked);
                            // }}
                            value="thursday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Thursday
                          </label>
                        </div>
                        {/* ------- Thursday section end ------ */}

                        {/* ------- Friday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedFriday}
                            // onChange={(e) => {
                            //   setSelectedFriday(e.target.checked);
                            // }}
                            value="friday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Friday
                          </label>
                        </div>
                        {/* ------- Friday section end ------ */}

                        {/* ------- Saturday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedSaturday}
                            // onChange={(e) => {
                            //   setSelectedSaturday(e.target.checked);
                            // }}
                            value="saturday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Saturday
                          </label>
                        </div>
                        {/* ------- Saturday section end ------ */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* =============== select days end ==================  */}
            </div>

            {/* ============== time section starts =================== */}
            <div className="form-group mb-4">
              <div className="row">
                {/* ------ start time section start ----- */}
                <div className="col-lg-4">
                  <label htmlFor="startTime">{t('Start Time')} *</label>
                  <input
                    type="time"
                    className="form-control"
                    placeholder="HH : MM"
                    value={scheduleInputs.starttime}
                    onChange={val => {
                      setScheduleInputs(prevState => ({
                        ...prevState,
                        starttime: val.target.value,
                      }));
                      setValidation(prevState => ({
                        ...prevState,
                        timeWarning: false,
                      }));
                    }}
                  />
                  {/* time compare warning */}
                  {validation.timeWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>
                          {t('End time should be greater than start time')}!
                        </span>
                      </p>
                    </div>
                  )}
                </div>
                {/* ------ start time section end ----- */}

                {/* ------ end time section start ----- */}
                <div className="col-lg-4">
                  <label htmlFor="endTime">{t('End Time')} *</label>
                  <input
                    type="time"
                    className="form-control"
                    placeholder="HH : MM"
                    value={scheduleInputs.endtime}
                    onChange={val => {
                      setScheduleInputs(prevState => ({
                        ...prevState,
                        endtime: val.target.value,
                      }));
                      setValidation(prevState => ({
                        ...prevState,
                        timeWarning: false,
                      }));
                    }}
                  />
                </div>
                {/* ------ end time section start ----- */}

                {/* ------ Availability section start ----- */}
                <div className="col-lg-4">
                  <label htmlFor="endTime">{t('Set record as')} *</label>
                  <Select
                    options={availibilityOptions}
                    value={availabilityValue}
                    onChange={val => {
                      availabilitySelectionHandler(val);
                    }}
                  />
                </div>
                {/* ------ Availability section end ----- */}
              </div>
            </div>
            {/* ============== time section ends =================== */}

            {/* ------- select concept section start ------ */}
            <div className="form-group mb-4">
              <label htmlFor="conceptselect">{t('Select Concept')}</label>
              <Select
                options={conceptList}
                placeholder={t('Select concept')}
                value={conceptValue}
                onChange={val => {
                  setConceptValue(val);
                }}
              />
            </div>
            {/* ------- select concept section end ------ */}

            {/* ------- select client section start ------ */}
            <div className="form-group mb-4">
              <label htmlFor="clientselect">{t('Select Client')}</label>
              <Select
                options={clientList}
                placeholder={t('Select client')}
                value={clientValue}
                onChange={val => {
                  setClientValue(val);
                }}
              />
            </div>
            {/* ------- select client section end ------ */}

            {/* ------- select reserve section start ------ */}
            <div className="form-group mb-4">
              <label htmlFor="reserveselect">{t('Select Reserve')}</label>
              <Select
                options={reserveList}
                placeholder={t('Select reserve')}
                value={reserveValue}
                onChange={val => {
                  setReserveValue(val);
                }}
              />
            </div>
            {/* ------- select reserve section end ------ */}

            {/* =============== description section starts ============= */}
            <div className="form-group mb-4">
              <label htmlFor="educatorComments">{t('Description')}</label>
              <textarea
                name="educatorComments"
                id="educatorComments"
                cols="30"
                rows="4"
                className="form-control"
                placeholder={t('Enter Description')}
                value={scheduleInputs.description}
                onChange={val => {
                  setScheduleInputs(prevState => ({
                    ...prevState,
                    description: val.target.value,
                  }));
                }}
              ></textarea>
            </div>
            {/* =============== description section ends ============= */}
          </div>

          <div className="action d-flex align-items-center gap-2">
            <button type="submit" className="btn btn-primary">
              {t('Save')}
            </button>
            {/* <p className="warring">
              <i className="material-icons-outlined">warning</i>
              <span>{t("Fill up all fields. Please Try again!")}</span>
            </p> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveSchedulePopup;
