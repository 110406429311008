/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/* Importing react select */
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

//import options from json file
import ReserveStatus from 'data/Prod/ReserveStatus.json';

//import context
import { GlobalProvider } from 'context/GlobalContext';

/*import url and gateway methods */
import { getData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

const SalesOrderFilter = () => {
  const token = localStorage.getItem('token'); // token
  const { t } = useTranslation(); //for translation

  const todayValue = new Date().toISOString().split('T')[0];

  const {
    setReloadSalesOrderList,
    setSearchSalesOrderDate,
    setSearchSalesOrderDateType,
    setSearchSalesOrderClients,
    setsearchSalesOrderGroupType,
    setSearchSalesOrderDiscount,
  } = useContext(GlobalProvider);

  const [selectedChecked, setSelectedChecked] = useState('');

  const [fromDate, setFromDate] = useState(todayValue);
  const [toDate, setToDate] = useState(todayValue);
  const [selectedDate, setSelectedDate] = useState(todayValue);

  const [clientList, setClientList] = useState([]); //client option

  const [dateSelectedValue, setDateSelectedValue] = useState(null);
  const [selectedClientValue, setSelectedClientValue] = useState(null);
  const [selectedClientIds, setSelectedClientIds] = useState([]);
  const [selectedGroupType, setSelectedGroupType] = useState(null);
  const [discountRate, setDiscountRate] = useState(0);

  /* function to get all client list */
  const getALlUserList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        //client list
        let filteredClient = response.data.filter(user => {
          let userData;
          if (user.role) {
            if (user.role.name == 'client' || user.role.name == 'beneficiary') {
              userData = user;
            }
          }
          return userData;
        });

        setClientList(filteredClient);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //option for selct date type
  const dateTypeOptions = [
    {
      label: t('Invoice date'),
      value: 'Invoice',
    },
    {
      label: t('Created date'),
      value: 'Created',
    },
  ];

  //option for select individual or group
  const groupOption = [
    {
      label: t('Individual'),
      value: '0',
    },
    {
      label: t('Group'),
      value: '1',
    },
  ];

  //function for select radio
  const radioCheckHandler = event => {
    setSelectedChecked(event.target.value);
    setDateWarning(false);
    setDateWarning(false);
  };

  // Hook for date compare validation
  const [dateWarning, setDateWarning] = useState(false);

  //function for search
  const searchHandler = () => {
    /*===== switch statement for date input radio start ======*/
    switch (selectedChecked) {
      case 'daterange':
        if (fromDate < toDate) {
          setSearchSalesOrderDate(
            fromDate.split('-').reverse().join('/') +
              ',' +
              toDate.split('-').reverse().join('/')
          );
        } else {
          setDateWarning(true);
        }
        break;

      case 'todayradio':
        setSearchSalesOrderDate(todayValue.split('-').reverse().join('/'));

        break;

      case 'salesdate':
        setSearchSalesOrderDate(selectedDate.split('-').reverse().join('/'));
        break;

      default:
        setSearchSalesOrderDate('');
        break;
    }
    /*===== switch statement for date input radio end ======*/

    //assign data for search
    dateSelectedValue != null &&
      setSearchSalesOrderDateType(dateSelectedValue?.value);

    selectedClientIds.length > 0 &&
      setSearchSalesOrderClients(selectedClientIds);

    selectedGroupType != null &&
      setsearchSalesOrderGroupType(selectedGroupType?.value);

    discountRate != 0 && setSearchSalesOrderDiscount(discountRate);
  };

  //function for reset
  const resetHandler = () => {
    setSelectedChecked('');
    setFromDate(todayValue);
    setToDate(todayValue);
    setSelectedDate(todayValue);
    setDateSelectedValue({
      label: 'Invoice date',
      value: 'Invoice',
    });
    setSelectedClientValue(null);
    setSelectedGroupType(null);
    setDiscountRate(0);
    setSearchSalesOrderDate('');
    setSearchSalesOrderDateType(null);
    setSearchSalesOrderClients([]);
    setsearchSalesOrderGroupType(null);
    setSearchSalesOrderDiscount(0);
    setReloadSalesOrderList(true);
  };

  useEffect(() => {
    getALlUserList();
    setDateSelectedValue({
      label: 'Invoice date',
      value: 'Invoice',
    });
  }, []);

  return (
    <div className="collapse mt-3" id="filterSalesOrderCollapse">
      <div className="p-3 bg-gray-200">
        <h4 className="mb-3">{t('Filter')}</h4>
        {/* ------- search form section start ------- */}
        <form onSubmit={e => e.preventDefault()}>
          <div className="form-group mb-3">
            <div className="row align-items-center">
              {/* -------- date range section start -------- */}
              <div className="col-lg mb-3 mb-lg-0">
                <div className="d-flex align-items-center">
                  {/* -------- date input radio section start -------- */}
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="daterange"
                      id="dateRange"
                      value="daterange"
                      checked={selectedChecked === 'daterange'}
                      onChange={radioCheckHandler}
                    />
                  </div>
                  {/* -------- date input radio section end -------- */}

                  {/* -------- date selection section start -------- */}
                  <div className="field-container d-sm-flex border flex-fill">
                    {/* ------- from date input start ------- */}
                    <input
                      type="date"
                      id="from_date"
                      className="form-control bg-white border-0 mb-3 mb-sm-0"
                      placeholder={t('From date')}
                      value={fromDate}
                      onChange={e => {
                        setFromDate(e.target.value), setDateWarning(false);
                      }}
                    />
                    {/* ------- from date input end ------- */}

                    {/* ------- to date input start ------- */}
                    <input
                      type="date"
                      id="to_date"
                      className="form-control bg-white border-0"
                      placeholder={t('To date')}
                      value={toDate}
                      onChange={e => {
                        setToDate(e.target.value), setDateWarning(false);
                      }}
                    />
                    {/* ------- to date input end ------- */}
                  </div>
                  {/* -------- date selection section end -------- */}
                </div>
                {/* date compare warning */}
                {dateWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>
                        {t('End date should be greater than start date')}!
                      </span>
                    </p>
                  </div>
                )}
              </div>
              {/* -------- date range section end -------- */}

              {/* --------- today section start --------- */}
              <div className="col-lg-auto mb-3 mb-lg-0">
                <div className="form-check mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="todayradio"
                    id="today"
                    value="todayradio"
                    checked={selectedChecked === 'todayradio'}
                    onChange={radioCheckHandler}
                  />
                  <label className="form-check-label mb-0" htmlFor="today">
                    {t('Today')}
                  </label>
                </div>
              </div>
              {/* --------- today section end --------- */}

              {/* --------- date section start --------- */}
              <div className="col-lg">
                <div className="d-flex align-items-center">
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="salesdate"
                      id="multipleDates"
                      value="salesdate"
                      checked={selectedChecked === 'salesdate'}
                      onChange={radioCheckHandler}
                    />
                  </div>
                  <input
                    type="date"
                    id="to_date"
                    className="form-control bg-white"
                    placeholder={t('Select Multiple Dates')}
                    value={selectedDate}
                    onChange={e => setSelectedDate(e.target.value)}
                  />
                </div>
              </div>
              {/* --------- date section end --------- */}

              {/* ------ select for create date or invoice date start ------- */}
              <div className="col-lg">
                <Select
                  placeholder={t('Select date type')}
                  options={dateTypeOptions}
                  value={dateSelectedValue}
                  onChange={val => {
                    setDateSelectedValue(val);
                  }}
                />
              </div>
              {/* ------ select for create date or invoice date start ------- */}
            </div>
          </div>

          {/* ------- clinet,grouped,discount and button section start ------- */}
          <div className="form-group mb-3">
            <div className="row">
              {/* ------ client selection section start -------- */}
              <div className="col-lg-3 mb-3 mb-lg-0">
                <div className="field-container position-relative">
                  <Select
                    className="nosearch_select"
                    placeholder={t('Select clients')}
                    isMulti
                    options={clientList}
                    value={selectedClientValue}
                    onChange={val => {
                      setSelectedClientValue(val);
                      setSelectedClientIds(val.map(client => client.value));
                    }}
                  />
                </div>
              </div>
              {/* ------ client selection section end -------- */}

              {/* ------ group selection section start -------- */}
              <div className="col-lg-3 mb-3 mb-lg-0">
                <div className="field-container position-relative">
                  <Select
                    placeholder={t('Select group type')}
                    options={groupOption}
                    value={selectedGroupType}
                    onChange={val => {
                      setSelectedGroupType(val);
                    }}
                  />
                </div>
              </div>
              {/* ------ group selection section end -------- */}

              {/* ------ discount selection section start -------- */}
              <div className="col-lg-3">
                <div className="field-container position-relative">
                  <input
                    type="number"
                    className="form-control bg-white"
                    placeholder={t('Discount')}
                    value={discountRate}
                    onChange={e => setDiscountRate(e.target.value)}
                  />
                </div>
              </div>
              {/* ------ discount selection section end -------- */}

              {/* ------ button section start -------- */}
              <div className="col-lg-3">
                <div className="action d-flex gap-3 align-items-center justify-content-end">
                  {/* ------- reset button start ------- */}
                  <Link
                    to="#"
                    className="d-flex gap-1 align-items-center text-black"
                    onClick={resetHandler}
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      sync
                    </span>
                    <span className="d-block fw-medium">{t('Refresh')}</span>
                  </Link>
                  {/* ------- reset button end ------- */}

                  {/* ------- search button start ------- */}
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={searchHandler}
                  >
                    {t('Filter')}
                  </button>
                  {/* ------- search button start ------- */}
                </div>
              </div>
              {/* ------ button section end -------- */}
            </div>
          </div>
          {/* ------- clinet,grouped,discount and button section start ------- */}
        </form>
        {/* ------- search form section end ------- */}
      </div>
    </div>
  );
};

export default SalesOrderFilter;
