/* eslint-disable */
import React, {
  useState,
  useContext,
  useEffect,
  createRef,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
// kendo react modules ==========================
import '@progress/kendo-theme-material/dist/all.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';
//import translation function for language translation
import { useTranslation } from 'react-i18next';

/* importing survey list data */
// import surveyListData from '../../data/SurveyListData.json';

/*import url and gateway methods */
import { postData, getData, putData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import context
import { GlobalProvider } from 'context/GlobalContext';
import { Link } from 'react-router-dom';
import SurveyHeader from './Header/SurveyHeader';
import SurveyBreadCrumb from './BreadCrumb/SurveyBreadCrumb';

const SurveyListArea = () => {
  const { t } = useTranslation(); //for translation

  const token = localStorage.getItem('token'); //import token

  const {
    reloadSurveyList,
    setReloadSurveyList,
    setEditSurveyData,
    screenHeight,
  } = useContext(GlobalProvider);

  //use state for survey list
  const [surveyList, setSurveyList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);
  /* hook to get number of records */
  const [records, setRecords] = useState(0);

  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly

  /* function for checking record count */
  const checkedRecordCount = selectCount => {
    console.log('selectCount', selectCount);
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(surveyList, initialDataState));

  /* hook to search */
  const [search, setSearch] = useState('');

  const onDataStateChange = event => {
    console.log('event -->', event);
    setDataState(event.dataState);
    setResult(process(surveyList, event.dataState));
  };
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  //function for get survey list
  const getAllSurvey = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl + url.API_GET_ALL_SURVEY + `?token=${token}`;

      console.log('survey list url =====>>>', requestUrl);

      const response = await getData(requestUrl);

      console.log('survey list response ========>>>', response);

      if (response.data) {
        setSurveyList(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // check the selected count and show the count in top and also selected download xls ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectSurveyRecord"]'
    );

    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = surveyList.find(
          user => user._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });

    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };
  // mark all checkbox as selected ----------------
  const selectAllRecords = e => {
    document
      .querySelectorAll('input[name="selectSurveyRecord"]')
      .forEach(checkbox => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            name="selectAllSurveyRecord"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = props => {
    return (
      <td className="form-check py-3">
        <input
          className="form-check-input "
          type="checkbox"
          value={props.dataItem._id}
          name="selectSurveyRecord"
          onClick={handelSelectedCount}
        />
      </td>
    );
  };

  /* custom name html */
  const nameHTML = ({ dataItem }) => {
    return (
      <td className="kendoCustomColumn overflow-visible">
        <div className="d-flex align-items-center gap-2">
          <p className="d-flex gap-2 align-items-center text-nowrap">
            <span className="d-block">{dataItem.name}</span>
          </p>

          <Link
            className="ml-auto edit_icon"
            type="button"
            to="#saveSurveyPopup"
            data-bs-toggle="offcanvas"
            aria-expanded="false"
            data-bs-placement="top"
            title="Edit Survey"
          >
            <span
              className="d-block material-symbols-outlined icon-md"
              onClick={() => {
                setEditSurveyData(dataItem);
              }}
            >
              edit
            </span>
          </Link>

          <Link
            to={'/feedbackresponses/' + dataItem._id}
            className="text-gray-700 bg-transparent border-0"
            data-bs-placement="top"
            title="Feedback Responses"
          >
            <span className="d-block material-symbols-outlined icon-md">
              forum
            </span>
          </Link>
        </div>
      </td>
    );
  };

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter(item => {
      const title = item?.name?.toLowerCase() || '';
      const code = item?.code?.toLowerCase() || '';
      const surveydate = item?.surveydate || '';

      return (
        (title && title.includes(search.toLowerCase())) ||
        (code && code.includes(search.toLowerCase())) ||
        (surveydate && surveydate.includes(search))
      );
    });

    return filteredData;
  };

  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);

  useEffect(() => {
    if (responseReceived === false) {
      getAllSurvey();
    }
  }, [responseReceived]);

  useEffect(() => {
    if (reloadSurveyList) {
      getAllSurvey();
      setReloadSurveyList(false);
      setResult(process(surveyList, initialDataState));
    }
  }, [reloadSurveyList]);

  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);

  // Call filterData and update the state separately
  useEffect(() => {
    const filteredData = filterData();
    setRecords(filteredData.length);
  }, [search]);

  return (
    <div className="container-fluid px-4">
      {/* ----- survey breadcrumb ------ */}
      <SurveyBreadCrumb />

      {/* ------ survey header ------ */}
      <SurveyHeader
        setSearch={setSearch}
        setDownloadExcel={setDownloadExcel}
        records={records}
        blockRefSelected={blockRefSelected}
      />

      <div className="table-responsive">
        {/* ============ download xls start ========== */}
        <ExcelExport
          data={
            handelSelectedCount().length > 0
              ? handelSelectedCount()
              : surveyList
          }
          fileName="Survey.xlsx"
          ref={_exporter}
        >
          <ExcelExportColumn field="name" title={t('Title')} />
          <ExcelExportColumn field="code" title={t('Code')} />
          <ExcelExportColumn
            field="feedbackcount"
            title={t('Feedback Count')}
          />
          <ExcelExportColumn field="surveydate" title={t('Date')} />
        </ExcelExport>
        {/* ============ download xls end ========== */}

        {search ? (
          <Grid
            style={{ height: screenHeight - 50 }}
            data={filterData()}
            filterable={true}
            sortable={true}
            groupable={true}
            onDataStateChange={onDataStateChange}
            {...dataState}
            //pageable = {true}
            //total = {products.length}
            reorderable
            // resizable
          >
            <GridColumn
              field="id"
              headerCell={SelectRecordHeader}
              cell={SelectRecordRow}
              filterable={false}
              sortable={false}
              width="70px"
            />
            <GridColumn field="name" title={t('Title')} cell={nameHTML} />
            <GridColumn field="code" title={t('Code')} />
            <GridColumn field="feedbackcount" title={t('Feedback Count')} />
            <GridColumn field="surveydate" title={t('Date')} />
          </Grid>
        ) : (
          <Grid
            style={{ height: screenHeight - 50 }}
            data={result}
            filterable={true}
            sortable={true}
            groupable={true}
            onDataStateChange={onDataStateChange}
            {...dataState}
            //pageable = {true}
            //total = {products.length}
            reorderable
            // resizable
          >
            <GridColumn
              field="id"
              headerCell={SelectRecordHeader}
              cell={SelectRecordRow}
              filterable={false}
              sortable={false}
              width="70px"
            />
            <GridColumn field="name" title={t('Title')} cell={nameHTML} />
            <GridColumn field="code" title={t('Code')} />
            <GridColumn field="feedbackcount" title={t('Feedback Count')} />
            <GridColumn field="surveydate" title={t('Date')} />
          </Grid>
        )}
      </div>
    </div>
  );
};

export default SurveyListArea;
