/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';

/* importing link for navigation */
import { Link } from 'react-router-dom';

/* Importing react select */
import Select from 'react-select';

// Import CKEditor here (both required).
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/*import url and gateway methods */
import { postData, getData, uploadMultipleFile } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import json data
import RolesData from 'data/Prod/RolesData.json';
import { GlobalProvider } from 'context/GlobalContext';

const SendNotificationCreateMailPopup = () => {
  const token = localStorage.getItem('token'); // token

  const { t } = useTranslation(); //for translation

  //context data
  const {
    /*------ edit requirements start -------*/
    viewMailTemplateData,
    setViewMailTemplateData,
    /*------ edit requirements end -------*/

    /* -------- notification start ------------ */
    setSuccess,
    setError,
    setNotificationString,
    /* -------- notification end ------------ */
  } = useContext(GlobalProvider);

  // loggedin user information
  const currentUser = JSON.parse(localStorage.getItem('userInfo'));

  /*---- CKEditor classic editor configuration----- */
  const editorConfig = {
    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList'],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t('Type here...'),
  };
  const [editorData, setEditorData] = useState('');

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateList, setTemplateList] = useState([]);
  const [userByReserveList, setUserByReserveList] = useState([]);

  const [isUploading, setIsUploading] = useState(false);
  const [fileUploadIds, setFileUploadIds] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  /* hooks for inputs */
  const [userInputs, setUserInputs] = useState({
    selectRole: null,
    roles: [],
    receivermails: '',

    reservations: [],
    messagetemplate: '',
    templatebyroles: [],
    subject: '',
    description: '',
  });

  /*=========== form repeater section start ===========*/

  //role template block
  const roleTemplate = {
    rolevalue: '',
    roledata: '',
    templatevalue: '',
    templatedata: '',
  };

  //requirement for role template block
  const [roleTemplateBlock, setRoleTemplateBlock] = useState([]);

  //function for add role template section
  const addRoleTemplateBlock = () => {
    setRoleTemplateBlock([...roleTemplateBlock, roleTemplate]);
  };

  //function for delete role template
  const onDeleteRoleTemplateBlock = index => {
    const filteredRoleTemplateBlock = [...roleTemplateBlock];
    filteredRoleTemplateBlock.splice(index, 1);
    setRoleTemplateBlock(filteredRoleTemplateBlock);
  };

  //function for change survey select
  const changeRoleTemplateSelectInputs = (index, field, field2, value) => {
    const updatedFormValues = roleTemplateBlock.map((block, i) =>
      index == i
        ? Object.assign(block, { [field]: value, [field2]: value.value })
        : block
    );
    setRoleTemplateBlock(updatedFormValues);
  };

  /*=========== form repeater section end ===========*/

  // show wait message
  const [showWaitMessage, setShowWaitMessage] = useState(false);

  const [eventIds, setEventIds] = useState([]);

  //function for get all templates from mail template api
  const getAllTemplates = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_TEMPLATE +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      console.log(
        'template response reservation sendnotification ==>',
        response
      );

      if (response.status) {
        setTemplateList(response.data);
      }
    } catch (error) {}
  };

  //function for get email id by roles
  const getAllEmailByRoles = async e => {
    e.preventDefault();

    // check if any record selected --------------
    const checkboxes = document.querySelectorAll(
      'input[name="selectReserveRecord"]'
    );

    let eventIds = [];

    checkboxes.forEach(async checkbox => {
      if (checkbox.checked) {
        eventIds.push(checkbox.value);
      }
    });

    if (eventIds.length > 0) {
      if (userInputs.roles.length > 0) {
        try {
          let requestUrl =
            url.API_BaseUrl +
            url.API_GET_USER_EMAIL_BY_EVENT +
            `?token=${token}&events=${eventIds}&roles=${userInputs.roles}`;

          const response = await getData(requestUrl);

          console.log(requestUrl);

          console.log('response in get email=======>', response);

          if (response.status) {
            setUserInputs(prevState => ({
              ...prevState,
              receivermails: response.data.emails,
            }));
            setUserByReserveList(response.data.eventsByUserData);
          }
        } catch (error) {
          console.error(error.message);
        }
      } else {
        alert('Please select roles to fetch respective mail ids');
      }
    } else {
      alert('Please select an event to continue');
    }
  };

  //function for file upload
  const uploadHandler = async e => {
    try {
      setIsUploading(true);
      let requestUrl =
        url.API_BaseUrl + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, e.target.files);

      if (response.status) {
        console.log(
          `response in file upload mail template------>`,
          response.data
        );

        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedFiles.length == 0) {
          setUploadedFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedFiles.concat(uploadedFileValues);
          setUploadedFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setIsUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (fileUploadIds.length == 0) {
          setFileUploadIds(fileIds);
        } else {
          let fileArr = fileUploadIds.concat(fileIds);
          setFileUploadIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
      resetFile();
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for delete file from upload stack
  const closeFileHandler = index => {
    const closedFile = [...uploadedFiles];
    const removedFileId = [...fileUploadIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedFiles(closedFile);
    setFileUploadIds(removedFileId);

    resetFile();
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById('uploadedReserveMailFile');
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for select template
  const templateSelectionHandler = val => {
    //assign template value for select
    setSelectedTemplate(val);

    //set value for send mail
    setUserInputs(prevState => ({
      ...prevState,
      subject: val.subject,
      description: val.description,
    }));

    //assign uploaded files from mail template
    const uploadedFileValues = val.uploads.map(({ name, path, _id }) => ({
      name,
      path,
      _id,
    }));

    if (uploadedFiles.length == 0) {
      setUploadedFiles(uploadedFileValues);
    } else {
      let fileArr = uploadedFiles.concat(uploadedFileValues);
      setUploadedFiles(fileArr);
    }

    //assign uploaded file id for sending mail
    const fileIds = val.uploads.map(({ _id, ...other }) => ({ _id }));

    if (fileUploadIds.length == 0) {
      setFileUploadIds(fileIds);
    } else {
      let fileArr = fileUploadIds.concat(fileIds);
      setFileUploadIds(fileArr);
    }
  };

  //function for validation
  const validate = () => {
    let isValid = true;
    return isValid;
  };

  //function for send mail
  const sendMailHandler = async e => {
    e.preventDefault();

    setShowWaitMessage(true);

    let templateId = selectedTemplate ? selectedTemplate.value : null;

    let mailData = {
      userbyreserve: userByReserveList,
      receivermails: userInputs.receivermails,
      subject: userInputs.subject,
      sender: currentUser._id,
      description: editorData,
      messagetemplate: templateId,
      roletemplate: roleTemplateBlock,
      uploads: fileUploadIds,
    };

    console.log('mailData=========>', mailData);

    //return true;

    if (validate()) {
      try {
        let requestUrl =
          url.API_BaseUrl + url.API_ADD_MESSAGE + `?token=${token}`;

        const response = await postData(requestUrl, mailData);

        console.log('mailData response ==========>', response);

        if (response.status) {
          const checkboxes = document.querySelectorAll(
            'input[name="selectReserveRecord"]'
          );

          checkboxes.forEach(checkbox => {
            checkbox.checked = false;
          });

          resetHandler();
          setShowWaitMessage(false);
          /* ------ for notification success-------- */
          setSuccess(true);
          setNotificationString(t('Mail send successfully'));
        }
      } catch (error) {
        console.error(error.message);
        /* ------for notification error ------- */
        setError(true);
        setNotificationString(
          t('Something went wrong, please try again later')
        );
      }

      window.bootstrap.Offcanvas.getInstance(
        document.querySelector('#createSendNotificationEmailPopup')
      ).hide();
    }
  };

  //function for reset form
  const resetHandler = () => {
    resetFile();
    setSelectedTemplate(null);
    setUserInputs({
      selectRole: null,
      roles: [],
      receivermails: '',
      reservations: [],
      messagetemplate: '',
      subject: '',
      description: '',
    });
    setUploadedFiles([]);
    setFileUploadIds([]);
  };

  // get template id and fetch data on click in template view
  const showTemplatePreview = async templateId => {
    console.log(templateId);
  };

  /* function to download file */
  const fileDownloadHandler = async file => {
    try {
      const response = await fetch(file.path);
      const blob = await response.blob();
      const fileName = file.name;

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error occurred while downloading the file:', error);
    }
  };

  useEffect(() => {
    getAllTemplates();
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="createSendNotificationEmailPopup"
      aria-labelledby="createEmailPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="createEmailPopupLabel">
          {t('Send Mail')}
        </h3>

        {/* <Link
          to="#email_history_popup"
          data-bs-toggle="offcanvas"
          role="button"
          aria-controls="email_history_popup"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
        /> */}
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        {/* -------- mail form section start -------- */}
        <form onSubmit={e => e.preventDefault()}>
          {/* ========== select role section start ============ */}
          <div className="form-group mb-3">
            <label htmlFor="selectRole">{t('Select Roles')}</label>
            <Select
              className="nosearch_select"
              options={RolesData}
              isMulti
              value={userInputs.selectRole}
              onChange={val =>
                setUserInputs(prevState => ({
                  ...prevState,
                  selectRole: val,
                  roles: val.map(role => role.value),
                }))
              }
            />
          </div>
          {/* ========== select role section end ============ */}

          {/* ---------- reciever with email section start ---------- */}
          <div className="form-group mb-3">
            <label htmlFor="selectReceiver">{t('Add Receiver')}</label>

            {/* --------- get email by role button start -------- */}
            <div className="mb-1">
              <Link
                className="text-primary"
                to="#"
                onClick={getAllEmailByRoles}
              >
                {t('Click to get all receivers by role for selected events')}{' '}
              </Link>
            </div>
            {/* --------- get email by role button end -------- */}

            {/* --------- email input section start -------- */}
            <input
              type="text"
              id="email"
              className="form-control"
              placeholder={t('Enter mail address')}
              value={userInputs.receivermails}
              onChange={val => {
                setUserInputs(prevState => ({
                  ...prevState,
                  receivermails: val.target.value,
                }));
              }}
            />
            {/* --------- email input section end -------- */}
          </div>
          {/* ---------- reciever with email section end ---------- */}

          {/* ----------- mail template section section start ----------- */}
          <div className="form-group mb-3">
            <label htmlFor="selectMailTemplate">
              {t('Set Default Mail Template')}
            </label>
            <Select
              className="nosearch_select"
              options={templateList}
              value={selectedTemplate}
              onChange={val => {
                templateSelectionHandler(val);
              }}
            />
          </div>
          {/* ----------- mail template section section end ----------- */}

          {/* ------- add template by role -------- */}
          <div className="form-group mb-3">
            <label htmlFor="">Set Mail Template By Roles</label>

            {/* ------- form repeater sction for action link start -------- */}
            {roleTemplateBlock.map((block, index) => {
              return (
                <div className="fields-container d-flex gap-3 mb-2" key={index}>
                  {/* select role */}
                  <div className="form-group flex-fill">
                    <Select
                      className="nosearch_select"
                      placeholder={t('Select Role')}
                      options={RolesData}
                      value={block.rolevalue}
                      onChange={value =>
                        changeRoleTemplateSelectInputs(
                          index,
                          'rolevalue',
                          'roledata',
                          value
                        )
                      }
                    />
                  </div>

                  {/* Select template */}
                  <div className="form-group flex-fill">
                    <Select
                      className="nosearch_select"
                      placeholder={t('Select Template')}
                      options={templateList}
                      value={block.templatevalue}
                      onChange={value =>
                        changeRoleTemplateSelectInputs(
                          index,
                          'templatevalue',
                          'templatedata',
                          value
                        )
                      }
                    />
                  </div>

                  {/* View the template content */}
                  <div className="action">
                    <span
                      className="text-black pointer"
                      onClick={() => {
                        console.log('block.templatevalue', block.templatevalue);
                        setViewMailTemplateData(block.templatevalue);
                      }}
                    >
                      <i className="material-symbols-outlined">preview</i>
                    </span>
                  </div>

                  {/* Delete Block */}
                  <div className="delete">
                    <span
                      className="text-secondary mb-2 pointer"
                      onClick={() => {
                        onDeleteRoleTemplateBlock(index);
                      }}
                    >
                      <i className="material-symbols-outlined">close</i>
                    </span>
                  </div>
                </div>
              );
            })}
            {/* ------- form repeater sction for action link end -------- */}

            <div className="fields-container d-flex gap-3">
              <span
                onClick={addRoleTemplateBlock}
                //to="#"
                className="d-flex align-items-center gap-1 text-primary fw-medium pointer"
              >
                <i className="d-block material-symbols-outlined">add</i>
                <p>{t('Add template by roles')}</p>
              </span>
            </div>
          </div>

          {/* ----------- mail template by roles ----------- */}

          {/* ----------- subject section section start ----------- */}
          <div className="form-group mb-3">
            <label htmlFor="mailsubject">{t('Subject')}</label>
            <input
              type="text"
              id="subject"
              className="form-control"
              placeholder={t('Subject')}
              value={userInputs.subject}
              onChange={val => {
                setUserInputs(prevState => ({
                  ...prevState,
                  subject: val.target.value,
                }));
              }}
            />
          </div>
          {/* ----------- subject section section end ----------- */}

          {/* ----------- mail description section section start ----------- */}
          <div className="form-group mb-3">
            <CKEditor
              className="form-control"
              editor={ClassicEditor}
              config={editorConfig}
              data={userInputs.description}
              onReady={editor => {
                editor.editing.view.change(writer => {
                  writer.setStyle(
                    'height',
                    '300px',
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                let editorContent = editor.getData();
                setEditorData(editorContent);
              }}
            />
          </div>
          {/* ----------- mail description section section end ----------- */}

          {/* ========= file upload start ========== */}
          <div className="form-group mb-4">
            {isUploading ? (
              <div className="d-flex align-items-center gap-1 mb-1">
                <p className="fw-bold">{t('Please wait while uploading')}</p>{' '}
                {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                  return (
                    <div
                      key={index}
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      style={{ height: '6px', width: '6px' }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <label>{t('Attachments')}</label>
            )}

            <input
              type="file"
              className="form-control"
              id="uploadedReserveMailFile"
              multiple
              onChange={uploadHandler}
            />

            {uploadedFiles.length > 0 && (
              <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                {uploadedFiles.map((file, index) => {
                  return (
                    <li
                      className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                      key={index}
                    >
                      <Link to="#" onClick={() => fileDownloadHandler(file)}>
                        <span className="d-block material-symbols-outlined">
                          download
                        </span>
                      </Link>
                      <p className="fw-medium">{file.name}</p>
                      <Link to="#" onClick={() => closeFileHandler(index)}>
                        <span className="d-block material-symbols-outlined">
                          close
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          {/* ========= file upload end ===========*/}

          {/* ----------- send mail and display wait message button start ----------- */}
          <div className="action d-flex align-items-center gap-2">
            {/* ------- display wait message section start -------- */}
            {showWaitMessage && (
              <div className="d-flex align-items-center">
                <p className="me-1 text-dark">
                  <b>{t('Please wait while we send your mail to recepient')}</b>
                </p>
                {[1, 2, 3, 4, 5, 6].map((count, index) => {
                  return (
                    <div
                      key={index}
                      className="spinner-grow spinner-grow-sm text-dark me-1"
                      role="status"
                      style={{ height: '7px', width: '7px' }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  );
                })}
              </div>
            )}
            {/* ------- display wait message section end -------- */}

            {/* ------- send button start -------- */}
            {!showWaitMessage && (
              <button
                type="submit"
                className="btn btn-primary icon-btn mw-unset"
                onClick={sendMailHandler}
              >
                <span className="d-block material-symbols-outlined icon-fill">
                  send
                </span>
              </button>
            )}
            {/* ------- send button end -------- */}
          </div>
          {/* ----------- send mail and display wait message button start ----------- */}
        </form>
        {/* -------- mail form section end -------- */}
      </div>
    </div>
  );
};

export default SendNotificationCreateMailPopup;
