import React from 'react';
//import translation function for language translation
import { useTranslation } from 'react-i18next';

const AddCategoryPopup = () => {
  const { t } = useTranslation(); //for translation
  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="addCategoryPopup"
      aria-labelledby="addCategoryPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="addCategoryPopupLabel">
          {t('Add Category')}
        </h3>
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <form action="">
          <div className="form-group mb-4">
            <label htmlFor="name">{t('Label Name')}</label>
            <input
              type="text"
              id="name"
              placeholder={t('Enter Here')}
              className="form-control"
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="slug">{t('Slug')}</label>
            <input
              type="text"
              id="slug"
              placeholder={t('Enter Here')}
              className="form-control"
            />
          </div>
          <div className="form-check mb-4">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label
              className="form-check-label text-primary"
              for="flexCheckDefault"
            >
              {t('Set as Root Category')}
            </label>
          </div>
          <div className="form-group select-parents mb-4">
            <label htmlFor="selectparents">{t('Select parents')}</label>
            <div className="field-container p-3 bg-gray-500 border border-gray">
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  <div className="search-field bg-white border border-gray position-relative mb-3">
                    <input type="search" className="form-control" />
                  </div>
                  <select
                    name=""
                    id=""
                    multiple
                    className="form-select border-0"
                  >
                    <option value="alphabet">Alphabet</option>
                    <option value="course">Course</option>
                    <option value="design" selected>
                      Design
                    </option>
                    <option value="development">Development</option>
                    <option value="event">Event</option>
                    <option value="federation">Federation</option>
                    <option value="design">Design</option>
                  </select>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="search-field bg-white border border-gray position-relative mb-3">
                    <input type="search" className="form-control" />
                  </div>
                  <select
                    name=""
                    id=""
                    multiple
                    className="form-select border-0"
                  >
                    <option value="adobeXD">Adobe XD</option>
                    <option value="figma" selected>
                      Figma
                    </option>
                    <option value="photoshop">Photoshop</option>
                    <option value="sketch">Sketch</option>
                    <option value="webflow">Webflow</option>
                    <option value="zeplin">Zeplin</option>
                  </select>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="search-field bg-white border border-gray position-relative mb-3">
                    <input type="search" className="form-control" />
                  </div>
                  <select
                    name=""
                    id=""
                    multiple
                    className="form-select border-0"
                  >
                    <option value="autoLayout">Auto-Layout</option>
                    <option value="comment">Comment</option>
                    <option value="component">Component</option>
                    <option value="inspect">Inspect</option>
                    <option value="prototype">Prototype</option>
                    <option value="screens">Screens</option>
                    <option value="typography">Typography</option>
                  </select>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="search-field bg-white border border-gray position-relative mb-3">
                    <input type="search" className="form-control" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="action">
            <button type="submit" className="btn btn-primary mw-unset">
              {t('Save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCategoryPopup;
