/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';

/* Importing react select */
import Select from 'react-select';

import { Link } from 'react-router-dom';

/*import url and gateway methods */
import {
  getData,
  postData,
  uploadFile,
  putData,
  uploadMultipleFile,
} from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import context
import { GlobalProvider } from 'context/GlobalContext';

//import options form json file
import SelectOption from 'data/SelectOptions.json';

//import images
// import { assetImages } from 'constants';

//import translation function for language translation
import { useTranslation } from 'react-i18next';
import CustomNotification from 'components/common/CustomNotification';

const UserFormPopup = () => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem('token');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const {
    setShowUserList,
    editUserData,
    copyUserData,
    setEditUserData,
    setSuccess,
    setError,
    setNotificationString,
    setUserFormReset,
    userFormReset,
  } = useContext(GlobalProvider);

  //use state for manager list
  const [managerList, setManagerList] = useState([]);

  const [company, setCompany] = useState(''); //usestate for company
  const [manager, setManager] = useState(null); //usestate for company
  const [userName, setUserName] = useState(''); //usestate for name
  const [surname, setSurname] = useState(''); //usestate for surname
  const [email, setEmail] = useState(''); //usestate for email
  const [selectedLanguage, setSelectedLanguage] = useState(null); //usestate for language
  const [phone, setPhone] = useState(''); //usestate for phone
  const [contactEmails, setContactEmails] = useState(''); //usestate for contact email
  const [location, setLocation] = useState(''); //usestate for location
  const [country, setCountry] = useState(''); // useState for country
  const [state, setState] = useState(''); // useState for state
  const [city, setCity] = useState(''); // useState for city
  const [zipcode, setZipcode] = useState(''); //usestate for zipcode
  const [costPerHour, setCostPerHour] = useState(1); //use state for cose per hour
  const [mapCoordinate, setMapCoordinate] = useState(''); //usestate for co ordinate
  const [selectedStatus, setSelectedStatus] = useState(null); //usestate for status
  const [uploadedPreviewImage, setUploadedPreviewImage] = useState('');
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedUserFiles, setUploadedUserFiles] = useState([]); //uploaded user files
  const [isUserFilesUploading, setIsUserFilesUploading] = useState(false); //
  const [uploadedUserFilesIds, setUploadedUserFilesIds] = useState([]); //uploaded file ids

  /* useState hook to change select data */
  const [languageSelectedValue, setLanguageSelectedValue] = useState(null);
  const [managerValue, setManagerValue] = useState(null);
  const [statusSelectedValue, setStatusSelectedValue] = useState(null);

  /* hook for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    emailWarning: false,
    emailExistWarning: false,
    statuswarning: false,
    zipWarning: false,
  });

  // custom style for category
  const customSelectStyle = {
    // options style
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: '#666666',
      fontSize: '14px',
      fontFamily: 'sans-serif',
    }),

    // Value style
    control: styles => ({
      ...styles,
      fontSize: '14px',
      padding: '5px',
      fontFamily: 'sans-serif',
      border: '1px solid #D6D6D6',
      backgroundColor: '#f4f4f4',
      height: 50,
      boxShadow: 'none',
    }),

    // placeholder style
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: '#8E8E8E',
        padding: '5px',
        fontSize: '14px',
        marginVertical: '4px',
        fontFamily: 'sans-serif',
      };
    },

    // Indicator style
    dropdownIndicator: (base, state) => {
      let changes = { color: '#505050' };
      return Object.assign(base, changes);
    },
  };

  //function for get all user list
  const getALlUserList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);
      console.log('list of manager -->', response);
      if (response.status) {
        //eductor list
        let filterManager = response.data.filter(user => {
          let userData = null;
          if (user.role) {
            if (user.role.name == 'manager') {
              userData = user;
            }
          }
          return userData;
        });
        setManagerList(filterManager);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for image upload
  const imageUploadHandler = async e => {
    const file = e.target.files[0];
    console.log(file);
    setIsUploading(true);
    try {
      const requestUrl =
        url.API_BaseUrl + url.API_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadFile(requestUrl, file);

      console.log(response);

      if (response.status) {
        setIsUploading(false);
        setUploadedPreviewImage(response.data.path);
        setUploadedImageId(response.data._id);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for user files upload
  const filesUploadHandler = async e => {
    try {
      setIsUserFilesUploading(true);
      let requestUrl =
        url.API_BaseUrl + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, e.target.files);

      if (response.status) {
        console.log(`response`, response.data);

        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedUserFiles.length == 0) {
          setUploadedUserFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedUserFiles.concat(uploadedFileValues);
          setUploadedUserFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setIsUserFilesUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedUserFilesIds.length == 0) {
          setUploadedUserFilesIds(fileIds);
        } else {
          let fileArr = uploadedUserFilesIds.concat(fileIds);
          setUploadedUserFilesIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
      resetUserFiles();
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for clear educator file value
  const resetUserFiles = () => {
    const userFile = document.getElementById('uploadedUserFiles');
    if (userFile) {
      userFile.value = null;
    } else {
      return;
    }
  };

  //function for delete file from upload stack
  const closeUserFileHandler = index => {
    const closedFile = [...uploadedUserFiles];
    const removedFileId = [...uploadedUserFilesIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedUserFiles(closedFile);
    setUploadedUserFilesIds(removedFileId);

    resetUserFiles();
  };

  /* function to download file */
  const fileDownloadHandler = async file => {
    try {
      const response = await fetch(file.path);
      const blob = await response.blob();
      const fileName = file.name;

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error occurred while downloading the file:', error);
    }
  };

  /* function for validation */
  const validateForm = () => {
    let isValid = true;
    console.log(`validation.statuswarning`, validation.statuswarning);
    if (userName === '') {
      setValidation(prevState => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (email === '') {
      setValidation(prevState => ({ ...prevState, emailWarning: true }));
      isValid = false;
    }

    if (selectedStatus === null) {
      setValidation(prevState => ({ ...prevState, statuswarning: true }));
      isValid = false;
    }

    if (zipcode === '') {
      setValidation(prevState => ({ ...prevState, zipWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for manager selection
  const managerSelectionHandler = val => {
    setManagerValue(val);
    setManager(val.value);
  };

  //function for language selection
  const languageSelectionHandler = val => {
    setLanguageSelectedValue(val);
    setSelectedLanguage(val.value);
  };

  //function for status selection
  const statusSelectionHandler = val => {
    setStatusSelectedValue(val);
    setSelectedStatus(val.value);
    setValidation(prevState => ({
      ...prevState,
      statuswarning: false,
    }));
  };

  /* function to fetch location from zip code */
  const getLocationFromZip = async e => {
    e.preventDefault();
    const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}&region=es&key=AIzaSyDt4dbtdHCHLj8grTzTVxpztvqUGDkvkaI`;

    if (zipcode !== '') {
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.status === 'OK') {
          const results = data.results;
          if (results.length > 0) {
            results[0]?.address_components.map(item => {
              // console.log(`item`, JSON.stringify(item));

              if (item.types[0] === 'country') {
                setCountry(item?.long_name);
              }
              if (item.types[0] === 'locality') {
                setCity(item?.long_name);
              }
              if (item.types[0] === 'administrative_area_level_1') {
                setState(item?.long_name);
              }
            });
            setMapCoordinate(
              `https://maps.google.com/maps?q=${results[0]?.geometry?.location?.lat},${results[0]?.geometry?.location?.lng}`
            );
          } else {
            console.log('No results found.');
          }
        } else {
          console.log('Request failed with status:', data.status);
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    } else {
      setValidation(prevState => ({ ...prevState, zipWarning: true }));
    }
  };

  //function for save user
  const saveUserHandler = async e => {
    e.preventDefault();

    const userData = {
      company: company,
      manager: manager || userInfo._id,
      photoimage: uploadedImageId,
      name: userName,
      surname,
      email,
      language: selectedLanguage,
      phone,
      address: location,
      city: city,
      state: state,
      zipcode,
      country: country,
      status: selectedStatus,
      mapcoordinate: mapCoordinate,
      costperhour: costPerHour,
      uploads: uploadedUserFilesIds,
    };

    if (validateForm()) {
      try {
        let requestUrl =
          url.API_BaseUrl + url.API_ADD_NEW_USER + `?token=${token}`;

        console.log('save user url----->', requestUrl);

        const response = await postData(requestUrl, userData);

        console.log('response save---->', response);

        if (response.status) {
          resetUserData();
          setNotificationString('User added successfully');
          setSuccess(true);
          setShowUserList(true);
          let offCanvasPopup = document.querySelector('#saveUserPopup');
          let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
          offCanvas.hide();
        } else {
          setValidation(prevState => ({
            ...prevState,
            emailExistWarning: true,
          }));
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString('Something went wrong, please try again later');
        resetUserData();
        let offCanvasPopup = document.querySelector('#saveUserPopup');
        let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
        offCanvas.hide();
      }
    }
  };

  //function for update user
  const updateUserHandler = async e => {
    e.preventDefault();

    let userData = {
      company: company,
      manager: manager || userInfo._id,
      photoimage: uploadedImageId,
      name: userName,
      surname,
      email,
      language: selectedLanguage,
      phone,
      address: location,
      city: city,
      state: state,
      zipcode,
      country: country,
      status: selectedStatus,
      mapcoordinate: mapCoordinate,
      costperhour: costPerHour,
      uploads: uploadedUserFilesIds,
    };
    if (validateForm()) {
      try {
        let requestUrl =
          url.API_BaseUrl +
          url.API_UPDATE_USER +
          `/${editUserData._id}` +
          `?token=${token}`;
        console.log('save user url----->', requestUrl);

        const response = await putData(requestUrl, userData);

        console.log('response save---->', response);

        if (response.status) {
          resetUserData();
          setSuccess(true);
          setNotificationString('User edited successfully');
          setShowUserList(true);
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString('Something went wrong, please try again later');
        resetUserData();
      }
      let offCanvasPopup = document.querySelector('#saveUserPopup');
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    }
  };

  //function for reset user data
  const resetUserData = () => {
    setEditUserData(null);
    resetFile();
    resetUserFiles();
    setUploadedUserFiles([]);
    setUploadedUserFilesIds([]);
    setUploadedImageId(null);
    setCompany('');
    setUserName('');
    setSurname('');
    setEmail('');
    setSelectedLanguage(null);
    setPhone('');
    setContactEmails('');
    setLocation('');
    setManagerValue(null);
    setManager(null);
    setZipcode('');
    setMapCoordinate('');
    setLanguageSelectedValue(null);
    setCostPerHour(1);
    setCountry('');
    setState(''); // useState for state
    setCity(''); // useState for city

    let statusData = {
      label: 'enable',
      value: '1',
    };
    //set status value
    statusSelectionHandler(statusData);
    setUploadedPreviewImage('');
    setValidation({
      nameWarning: false,
      emailWarning: false,
    });
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById('uploadedImageFile');
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //useeffect for edit data
  useEffect(() => {
    if (editUserData) {
      /*------ image assign start ------*/
      if (editUserData.company) {
        setCompany(editUserData.company);
      }
      /*---- asign manager start ----*/
      if (editUserData.manager) {
        managerSelectionHandler(editUserData.manager);
      }
      /*---- asign manager end ----*/
      if (editUserData.photoimage) {
        setUploadedImageId(editUserData.photoimage._id);
        setUploadedPreviewImage(editUserData.photoimage.path);
      } else {
        setUploadedPreviewImage('');
      }
      /*------ image assign end ------*/

      setUserName(editUserData.name); //assign name
      setSurname(editUserData.surname); //assign surname
      setEmail(editUserData.email); //assign email
      setPhone(editUserData.phone); //assign phone
      setLocation(editUserData.address); //assign address/location
      setZipcode(editUserData.zipcode); //assign zipcode
      setMapCoordinate(editUserData.mapcoordinate); //assing mapcoordinate
      setCountry(editUserData.country); // assign country
      setState(editUserData.state); // assign state
      setCity(editUserData.city); // assign city
      setCostPerHour(editUserData.costperhour); //assign cost per hour

      /*------ assign language start ------*/
      if (editUserData.language == 'en') {
        let langData = {
          label: 'English',
          value: 'en',
        };
        languageSelectionHandler(langData); //set language value
      } else {
        let langData = {
          label: 'Spanish',
          value: 'es',
        };
        languageSelectionHandler(langData); //set languae value
      }
      /*------ assign language end ------*/

      /*------ assign status start ------*/
      if (editUserData.status == '1') {
        let statusData = {
          label: 'enable',
          value: '1',
        };
        statusSelectionHandler(statusData); //set status value
      } else if (editUserData.status == '0') {
        let statusData = {
          label: 'disable',
          value: '0',
        };
        statusSelectionHandler(statusData); //set status value
      } else {
        let statusData = {
          label: 'block',
          value: '2',
        };
        statusSelectionHandler(statusData); //set status value
      }
      /*------ assign status end ------*/

      /* =============== assign user uploads starts ================= */
      if (editUserData.uploads) {
        setUploadedUserFiles(editUserData.uploads);
        const editedFilesIds = editUserData.uploads.map(
          ({ _id, ...other }) => ({ _id })
        );
        setUploadedUserFilesIds(editedFilesIds);
      }
      /* =============== assign user ends ================= */
    }
  }, [editUserData]);

  /* ------ useEffect for copy data ------ */
  useEffect(() => {
    if (copyUserData) {
      console.log(`copyUserData`, copyUserData);

      /*------ image assign start ------*/
      if (copyUserData.company) {
        setCompany(copyUserData.company);
      }
      /*---- asign manager start ----*/
      if (copyUserData.manager) {
        managerSelectionHandler(copyUserData.manager);
      }
      /*---- asign manager end ----*/
      // if (copyUserData.photoimage) {
      //   setUploadedImageId(copyUserData.photoimage._id);
      //   setUploadedPreviewImage(copyUserData.photoimage.path);
      // } else {
      //   setUploadedPreviewImage('');
      // }
      /*------ image assign end ------*/

      setUserName(copyUserData.name); //assign name
      setSurname(copyUserData.surname); //assign surname
      setEmail(copyUserData.email); //assign email
      setPhone(copyUserData.phone); //assign phone
      setLocation(copyUserData.address); //assign address/location
      setZipcode(copyUserData.zipcode); //assign zipcode
      setMapCoordinate(copyUserData.mapcoordinate); //assing mapcoordinate
      setCountry(copyUserData.country); // assign country
      setState(copyUserData.state); // assign state
      setCity(copyUserData.city); // assign city
      setCostPerHour(copyUserData.costperhour); //assign cost per hour

      /*------ assign language start ------*/
      if (copyUserData.language == 'en') {
        let langData = {
          label: 'English',
          value: 'en',
        };
        languageSelectionHandler(langData); //set language value
      } else {
        let langData = {
          label: 'Spanish',
          value: 'es',
        };
        languageSelectionHandler(langData); //set languae value
      }
      /*------ assign language end ------*/

      /*------ assign status start ------*/
      if (copyUserData.status == '1') {
        let statusData = {
          label: 'enable',
          value: '1',
        };
        statusSelectionHandler(statusData); //set status value
      } else if (copyUserData.status == '0') {
        let statusData = {
          label: 'disable',
          value: '0',
        };
        statusSelectionHandler(statusData); //set status value
      } else {
        let statusData = {
          label: 'block',
          value: '2',
        };
        statusSelectionHandler(statusData); //set status value
      }
      /*------ assign status end ------*/

      /* =============== assign user uploads starts ================= */
      //  if (copyUserData.uploads) {
      //    setUploadedUserFiles(copyUserData.uploads);
      //    const editedFilesIds = copyUserData.uploads.map(
      //      ({ _id, ...other }) => ({ _id })
      //    );
      //    setUploadedUserFilesIds(editedFilesIds);
      //  }
      /* =============== assign user ends ================= */
    }
  }, [copyUserData]);

  //use effect for reset
  useEffect(() => {
    if (userFormReset) {
      resetUserData();
      setUserFormReset(false);
    }
  }, [userFormReset]);

  useEffect(() => {
    getALlUserList();
  }, []);

  return (
    <div className="offcanvas offcanvas-end border-0" id="saveUserPopup">
      <div className="offcanvas-header position-relative align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t('User Form')}</span>
        </h3>

        {/* ------ pop close button start ------ */}
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetUserData}
        ></button>
        {/* ------ pop close button end ------ */}
      </div>
      <div className="offcanvas-body position-relative p-4 pt-0">
        <form onSubmit={editUserData ? updateUserHandler : saveUserHandler}>
          <div className="user_formOuter">
            <div className="row">
              {/*-------- company section start --------*/}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label>{t('Company')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('Enter company')}
                    value={company}
                    onChange={e => setCompany(e.target.value)}
                  />
                </div>
              </div>
              {/*-------- company section end --------*/}

              {/*-------- manager section start --------*/}

              <div className="col-lg-6">
                {userInfo.role.name == 'admin' ? (
                  <div className="form-group mb-4">
                    <label>{t('Manager')}</label>
                    <Select
                      options={managerList}
                      value={managerValue}
                      onChange={val => {
                        managerSelectionHandler(val);
                      }}
                    />
                  </div>
                ) : null}
              </div>

              {/*-------- manager section end --------*/}

              <div className="col-lg-6">
                {/*-------- Name section start --------*/}
                <div className="form-group mb-4">
                  <label>{t('Name')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('Enter name')}
                    value={userName}
                    onChange={e => {
                      setUserName(e.target.value),
                        setValidation(prevState => ({
                          ...prevState,
                          nameWarning: false,
                        }));
                    }}
                  />
                  {/* email warning */}
                  {validation.nameWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter your name')}!</span>
                      </p>
                    </div>
                  )}
                </div>

                {/*-------- Name section end --------*/}
              </div>

              <div className="col-lg-6">
                {/*-------- Surname section start --------*/}
                <div className="form-group mb-4">
                  <label>{t('Surname')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('Enter surname')}
                    value={surname}
                    onChange={e => setSurname(e.target.value)}
                  />
                </div>
                {/*-------- Surname section end --------*/}
              </div>

              <div className="col-lg-6">
                {/*-------- Email section start -------*/}
                <div className="form-group mb-4">
                  <label>{t('Email')}</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t('Enter email')}
                    value={email}
                    readOnly={editUserData ? true : false}
                    onChange={e => {
                      setEmail(e.target.value),
                        setValidation(prevState => ({
                          ...prevState,
                          emailWarning: false,
                        }));
                    }}
                  />
                  {/* email warning */}
                  {validation.emailWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter a valid email')}!</span>
                      </p>
                    </div>
                  )}
                  {/* email exist warning */}
                  {validation.emailExistWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Email already exists')}!</span>
                      </p>
                    </div>
                  )}
                </div>

                {/*-------- Email section end --------*/}
              </div>

              {/* -------- zip code section start ------- */}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label>{t('Zip Code')}</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={t('Enter zip code')}
                    value={zipcode}
                    onChange={e => {
                      setZipcode(e.target.value),
                        setValidation(prevState => ({
                          ...prevState,
                          zipWarning: false,
                        }));
                    }}
                  />
                  <button
                    className="btn btn-sm btn-primary my-1"
                    onClick={getLocationFromZip}
                    type="button"
                  >
                    Fetch location by zipcode
                  </button>
                  {/* zipcode warning */}
                  {validation.zipWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter a valid zip code')}!</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/* -------- zip code section end ------- */}

              {/* ========= select country starts======== */}
              <div className="col-lg-4">
                <div className="form-group mb-4">
                  <label>{t('Country')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('Enter Country')}
                    value={country}
                    onChange={e => setCountry(e.target.value)}
                  />
                </div>
              </div>
              {/* ========= select country ends======== */}

              {/* ========= select state starts======== */}
              <div className="col-lg-4">
                <div className="form-group mb-4">
                  <label>{t('State')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('Enter State')}
                    value={state}
                    onChange={e => setState(e.target.value)}
                  />
                </div>
              </div>

              {/* ========= select state ends======== */}

              {/* ========= select city starts======== */}
              <div className="col-lg-4">
                <div className="form-group mb-4">
                  <label>{t('City')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('Enter City')}
                    value={city}
                    onChange={e => setCity(e.target.value)}
                  />
                </div>
              </div>
              {/* ========= select city ends======== */}

              {/* ------ phone section start ------ */}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label>{t('Phone')}</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={t('Enter phone number')}
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                  />
                </div>
              </div>
              {/* ------ phone section end ------ */}

              {/* ------ email section start ------ */}
              <div className="col-lg-6">
                <div className="form-group mb-4">
                  <label>{t('Contact Emails')}</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t('Enter email')}
                    value={contactEmails}
                    onChange={e => setContactEmails(e.target.value)}
                  />
                </div>
              </div>
              {/* ------ email section end ------ */}

              {/* ------ location section start ------ */}
              <div className="col-lg-12">
                <div className="form-group mb-4">
                  <label htmlFor="location">{t('Location')}</label>
                  <div className="field-container position-relative">
                    <input
                      type="text"
                      id="location"
                      className="form-control"
                      placeholder={t('Enter Address')}
                      value={location}
                      onChange={e => setLocation(e.target.value)}
                    />
                    <span className="d-block material-symbols-outlined icon-md text-gray position-absolute top-50 end-0 translate-middle-y me-3">
                      my_location
                    </span>
                  </div>
                </div>
              </div>
              {/* ------ location section end ------ */}

              {/* ========= select language starts======== */}
              <div className="col-lg-3">
                <div className="form-group mb-4">
                  <label>{t('Language')}</label>
                  <Select
                    className="nosearch_select"
                    options={SelectOption.languages}
                    value={languageSelectedValue}
                    onChange={val => {
                      languageSelectionHandler(val);
                    }}
                    styles={customSelectStyle}
                  />
                </div>
              </div>
              {/* ========= select language ends======== */}

              {/* -------- cost per hour section start ------- */}
              <div className="col-lg-5">
                <div className="form-group mb-4">
                  <label>{t('Cost per hour')}</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={t('Cost per hour')}
                    value={costPerHour}
                    onChange={e => setCostPerHour(e.target.value)}
                  />
                </div>
              </div>
              {/* -------- cost per hour section end ------- */}

              {/* ========= select status starts======== */}
              <div className="col-lg-4">
                <div className="form-group mb-4">
                  <label>{t('Status')}</label>
                  <Select
                    className="nosearch_select"
                    options={SelectOption.status}
                    value={statusSelectedValue}
                    onChange={val => {
                      statusSelectionHandler(val);
                    }}
                    styles={customSelectStyle}
                  />
                  {/* status warning */}

                  {console.log(`statuswarning`, validation.statuswarning)}
                  {validation.statuswarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter status')}!</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/* ========= select status ends======== */}
            </div>

            {/* -------- map section start ------- */}
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-4">
                  <label>{t('Map Coordinate')}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t('Enter GPS Position')}
                    value={mapCoordinate}
                    onChange={e => setMapCoordinate(e.target.value)}
                  />
                  <button
                    className="btn btn-sm btn-primary my-1"
                    onClick={() => window.open(mapCoordinate, '_blank')}
                    type="button"
                  >
                    Go to maps
                  </button>
                </div>
              </div>
            </div>
            {/* -------- map section end ------- */}
          </div>

          {/* ========= user file upload start ========== */}
          <div className="form-group mb-4">
            {isUserFilesUploading ? (
              <div className="d-flex align-items-center gap-1 mb-1">
                <p className="fw-bold">{t('Please wait while uploading')}</p>{' '}
                {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                  return (
                    <div
                      key={index}
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      style={{ height: '6px', width: '6px' }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <label>{t('Uploads')}</label>
            )}

            <input
              type="file"
              className="form-control"
              id="uploadedUserFiles"
              multiple
              onChange={filesUploadHandler}
            />

            {uploadedUserFiles.length > 0 && (
              <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                {uploadedUserFiles.map((file, index) => {
                  return (
                    <li
                      className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                      key={index}
                    >
                      <Link to="#" onClick={() => fileDownloadHandler(file)}>
                        <span className="d-block material-symbols-outlined">
                          download
                        </span>
                      </Link>
                      <p className="fw-medium">{file.name}</p>
                      <Link to="#" onClick={() => closeUserFileHandler(index)}>
                        <span className="d-block material-symbols-outlined">
                          close
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          {/* ========= user file upload end ===========*/}

          {/* ========= User profile image file upload starts ========== */}
          <div className="col-lg-12">
            <div className="form-group mb-4">
              <label>{t('Profile picture')}</label>
              <div className="d-flex align-items-center gap-3">
                <div className="img-preview border border-2 border-gray rounded-1 overflow-hidden">
                  <img
                    src={
                      uploadedPreviewImage == ''
                        ? 'https://placehold.co/150x150'
                        : uploadedPreviewImage
                    }
                    alt="user"
                    className="w-100 h-100 object-cover object-center"
                  />
                </div>

                <div className="w-100">
                  {isUploading && (
                    <div className="d-flex align-items-center gap-1 mb-1">
                      <p className="fw-bold">
                        {t('Please wait while uploading')}
                      </p>{' '}
                      {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                        return (
                          <div
                            key={index}
                            className="spinner-grow spinner-grow-sm"
                            role="status"
                            style={{ height: '5px', width: '5px' }}
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <input
                    type="file"
                    className="form-control"
                    id="uploadedImageFile"
                    onChange={imageUploadHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* ========= User profile image file upload ends ===========*/}

          {/* ------ save/update button section start ------ */}
          <div className="action d-flex align-items-center gap-2">
            <button type="submit" className="btn btn-primary">
              {editUserData ? t('Update') : t('Save')}
            </button>
          </div>

          {/* ------ save/update button section end ------ */}
        </form>
      </div>
    </div>
  );
};

export default UserFormPopup;
