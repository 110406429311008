/* eslint-disable */
import { useState, useEffect, useContext, createRef, useRef } from 'react';
import ReactDOM from 'react-dom';
// kendo react modules ==========================
import '@progress/kendo-theme-material/dist/all.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import api requiremnts
import * as url from 'urlhelpers/UrlHelper';
import { getData } from 'utils/Gateway';

/* importing questions data */
// import questions from "../../data/Questions.json";
import { Link } from 'react-router-dom';

//import context
import { GlobalProvider } from 'context/GlobalContext';
import QuestionHeader from './Header/QuestionHeader';

const QuestionListArea = () => {
  const {
    setEditQuestionData,
    reloadQuestionList,
    setReloadQuestionList,
    screenHeight,
  } = useContext(GlobalProvider);

  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem('token');
  // without pagination ------------------------------------
  const [questions, setQuestions] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);
  /* hook to get number of records */
  const [records, setRecords] = useState(0);

  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(questions, initialDataState));

  /* hook to search */
  const [search, setSearch] = useState('');

  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly

  /* function for checking record count */
  const checkedRecordCount = selectCount => {
    console.log('selectCount', selectCount);
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };

  // check the selected count and show the count in top and also selected download xls ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectQuestionRecord"]'
    );

    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = questions.find(
          user => user._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });

    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };

  const onDataStateChange = event => {
    console.log('event -->', event);
    setDataState(event.dataState);
    setResult(process(questions, event.dataState));
  };
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();

  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter(item => {
      const question = item?.name?.toLowerCase() || '';
      const type = item?.questiontype?.slug?.toLowerCase() || '';
      const code = item?.code?.toLowerCase() || '';
      const questiondate = item?.questiondate || '';

      return (
        (question && question.includes(search.toLowerCase())) ||
        (type && type.includes(search.toLowerCase())) ||
        (code && code.includes(search.toLowerCase())) ||
        (questiondate && questiondate.includes(search))
      );
    });

    return filteredData;
  };

  // Call filterData and update the state separately
  useEffect(() => {
    const filteredData = filterData();
    setRecords(filteredData.length);
  }, [search]);

  // mark all checkbox as selected ----------------
  const selectAllRecords = e => {
    document
      .querySelectorAll('input[name="selectQuestionRecord"]')
      .forEach(checkbox => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            name="selectAllQuestionRecord"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = props => {
    return (
      <td className="form-check">
        <input
          className="form-check-input "
          type="checkbox"
          value={props.dataItem._id}
          name="selectQuestionRecord"
          onClick={handelSelectedCount}
        />
      </td>
    );
  };

  /* custom name html */
  const nameHTML = ({ dataItem }) => {
    return (
      <td className="kendoCustomColumn overflow-visible">
        <div className="d-flex align-items-center gap-2">
          <p className="d-flex gap-2 align-items-center">
            <span className="d-block">{dataItem.name}</span>
          </p>

          <Link
            className="ml-auto edit_icon"
            type="button"
            to="#saveUpdateQuestionPopup"
            data-bs-toggle="offcanvas"
            aria-expanded="false"
          >
            <span
              className="d-block material-symbols-outlined icon-md"
              onClick={() => {
                setEditQuestionData(dataItem);
              }}
            >
              edit
            </span>
          </Link>
        </div>
      </td>
    );
  };

  //function for get all question
  const getAllQuestion = async e => {
    try {
      let requestUrl =
        url.API_BaseUrl + url.API_GET_ALL_QUESTION + `?token=${token}`;

      console.log('question list url =====>>>', requestUrl);

      const response = await getData(requestUrl);

      console.log('question list response ========>>>', response);

      if (response.data) {
        setQuestions(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setRecords(response.data.length);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);

  useEffect(() => {
    if (responseReceived === false) {
      getAllQuestion();
    }
  }, [responseReceived]);

  useEffect(() => {
    if (reloadQuestionList) {
      getAllQuestion();
      setReloadQuestionList(false);
      setResult(process(questions, initialDataState));
    }
  }, [reloadQuestionList]);

  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);
  // Call filterData and update the state separately
  useEffect(() => {
    const filteredData = filterData();
    setRecords(filteredData.length);
  }, [search]);

  return (
    <div className="tab-content" id="surveyTabContent">
      <div className="container-fluid px-4">
        <QuestionHeader
          setSearch={setSearch}
          setDownloadExcel={setDownloadExcel}
          records={records}
          blockRefSelected={blockRefSelected}
        />

        <div className="table-responsive">
          {/* ============ download xls start ========== */}
          <ExcelExport
            data={
              handelSelectedCount().length > 0
                ? handelSelectedCount()
                : questions
            }
            fileName="Questions.xlsx"
            ref={_exporter}
          >
            <ExcelExportColumn field="name" title={t('Question')} />
            <ExcelExportColumn field="questiontype.slug" title={t('Type')} />
            <ExcelExportColumn field="code" title={t('Code')} />
            <ExcelExportColumn
              field="questiondate"
              title={t('Question Date')}
            />
          </ExcelExport>
          {/* ============ download xls end ========== */}

          {/* ========= kendo react grid coloumn start ======= */}
          {search ? (
            <Grid
              style={{ height: screenHeight - 50 }}
              data={filterData()}
              filterable={true}
              sortable={true}
              groupable={true}
              onDataStateChange={onDataStateChange}
              {...dataState}
              //pageable = {true}
              //total = {products.length}
              reorderable
              // resizable
            >
              <GridColumn
                field="id"
                headerCell={SelectRecordHeader}
                cell={SelectRecordRow}
                filterable={false}
                sortable={false}
                width="70px"
              />
              <GridColumn field="name" title={t('Question')} cell={nameHTML} />
              <GridColumn field="questiontype.slug" title={t('Type')} />
              <GridColumn field="code" title={t('Code')} />
              <GridColumn field="questiondate" title={t('Question Date')} />
            </Grid>
          ) : (
            <Grid
              style={{ height: screenHeight - 50 }}
              data={result}
              filterable={true}
              sortable={true}
              groupable={true}
              onDataStateChange={onDataStateChange}
              {...dataState}
              //pageable = {true}
              //total = {products.length}
              reorderable
              // resizable
            >
              <GridColumn
                field="id"
                headerCell={SelectRecordHeader}
                cell={SelectRecordRow}
                filterable={false}
                sortable={false}
                width="70px"
              />
              <GridColumn field="name" title={t('Question')} cell={nameHTML} />
              <GridColumn field="questiontype.slug" title={t('Type')} />
              <GridColumn field="code" title={t('Code')} />
              <GridColumn field="questiondate" title={t('Question Date')} />
            </Grid>
          )}

          {/* ========= kendo react grid coloumn end ======= */}
        </div>
      </div>
    </div>
  );
};

export default QuestionListArea;
