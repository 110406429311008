/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';

// import * as ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  SchedulerItem,
} from '@progress/kendo-react-scheduler';

import { guid } from '@progress/kendo-react-common';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

// import baseData from 'data/Scheduler.json';
import { Link } from 'react-router-dom';

/*import url and gateway methods */
import { getData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const CalenderTab = ({ reloadCalender, setReloadCalender }) => {
  /* ========== get user id from url params ========= */
  const params = useParams();
  const token = localStorage.getItem('token'); // for token

  //import conext
  const {
    reloadCalendarEvents,
    setReloadCalendarEvents,
    setEditCalendarEvents,
  } = useContext(GlobalProvider);

  // default user data ------------------------------------
  const [calendarData, setCalendarData] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  const { t } = useTranslation(); //for translation

  // get json data about date ------------------------------
  const currentYear = new Date().getFullYear();

  const parseAdjust = eventDate => {
    const date = new Date(eventDate);
    date.setFullYear(currentYear);
    return date;
  };

  // function for get all calendar data
  const getAllCalendarData = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_SCHEDULE +
        `?token=${token}&user=${params.id}`;

      console.log('Url of calender list========>', requestUrl);

      const response = await getData(requestUrl);

      console.log(`response in calender list ==============>`, response.data);
      if (response.status) {
        setCalendarData(response.data);
        setResponseReceived(true);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // set response to match kendo scheduler event schema --------------------------------
  const kendoCalendarData = calendarData.map(dataItem => ({
    id: dataItem._id,
    start: parseAdjust(dataItem.start, dataItem.starttime),
    end: parseAdjust(dataItem.end, dataItem.endtime),
    isAllDay: false,
    title: dataItem.title,
    description: dataItem.description,
    ownerId: dataItem.availability,
    date: dataItem.date,
    availability: dataItem.availability,
    starttime: dataItem.starttime,
    endtime: dataItem.endtime,
    clientid: dataItem.clientid,
    conceptid: dataItem.conceptid,
    reserveid: dataItem.reserveid,
  }));

  const displayDate = new Date();

  /*---------- Handel Edit Calendar Schedule data ------------*/
  let scheduleItemHandler = event => {
    //assign schedule date in context
    setEditCalendarEvents(event.target.props.dataItem);

    const offcanvas = new window.bootstrap.Offcanvas(
      document.getElementById('editSchedulePopup')
    );
    offcanvas.show();
  };

  /*--------- function for custom schedule ---------*/
  const CustomSchedule = props => (
    <SchedulerItem
      {...props}
      onClick={scheduleItemHandler}
      style={{
        ...props.style,
        background:
          props.dataItem.availability == '1'
            ? '#32CD32'
            : props.dataItem.availability == '0'
            ? '#FF0000'
            : '#FA05E1',
      }}
    />
  );

  /* hook to reload list */
  useEffect(() => {
    if (reloadCalendarEvents) {
      getAllCalendarData();
      setReloadCalendarEvents(false);
    }
  }, [reloadCalendarEvents]);

  useEffect(() => {
    if (responseReceived === false) {
      getAllCalendarData();
    }
  }, [responseReceived]);

  return (
    <div className="profile_tabInnr">
      <div className="breadcum_area">
        <nav aria-label="breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/" className="text-secondary">
                {t('Dashboard')}
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-600" aria-current="page">
              {t('Calender')}
            </li>
          </ul>
        </nav>
        <div className="calender_srch_top">
          <div className="calender_addBtn ms-auto mb-2">
            <Link
              to="#saveSchedulePopup"
              data-bs-toggle="offcanvas"
              className="btn btn-primary"
            >
              <i className="material-icons-outlined">add</i>
            </Link>
          </div>
        </div>

        <Scheduler
          data={kendoCalendarData}
          // editable={{
          //   remove: true
          // }}
          defaultDate={displayDate}
          item={CustomSchedule}
        >
          <MonthView />
          <WeekView />
          <DayView />
        </Scheduler>
      </div>
    </div>
  );
};

export default CalenderTab;
