/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

const SurveyHeader = ({
  setDownloadExcel,
  setSearch,
  records,
  blockRefSelected,
}) => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token"); // token
  const { setReloadSurveyList, setSuccess, setError, setNotificationString } =
    useContext(GlobalProvider);

  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState("");

  /* function to click download xls*/
  const handleDownloadClick = () => {
    setDownloadExcel(true);

    setTimeout(() => setDownloadExcel(false), 500);
  };

  //function for deactivate/update survey
  const deleteSurvey = () => {
    const checkboxes = document.querySelectorAll(
      'input[ name="selectSurveyRecord"]'
    );

    checkboxes.forEach(async (checkbox) => {
      if (checkbox.checked) {
        let surveyData = {
          status: "0",
        };
        try {
          let requestUrl =
            url.API_BaseUrl +
            url.API_UPDATE_SURVEY +
            `/${checkbox.value}` +
            `?token=${token}`;
          const response = await putData(requestUrl, surveyData);
          if (response.status) {
            setReloadSurveyList(true);
            setSuccess(true);
            setNotificationString("Survey has been deleted successfully");
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString("Something went wrong, please try again later");
        }
      }
    });
  };

  /* hook to empty search when searchValue gets empty */
  useEffect(() => {
    !searchValue && setSearch("");
  }, [searchValue]);

  return (
    <div className="filter-container d-flex flex-wrap align-items-center gap-2 pb-1 border-bottom border-gray">
      {/* ------ download button start ------ */}
      <Link
        to="#"
        onClick={handleDownloadClick}
        className="download btn btn-primary icon-btn mw-unset"
        data-bs-placement="top"
        title="Download XLS"
      >
        <span className="d-block material-symbols-outlined">download</span>
      </Link>
      {/* ------ download button end ------ */}

      {/* ------ search section start ------ */}
      <div className="search-container position-relative d-none d-md-block">
        <form onSubmit={(e) => e.preventDefault()}>
          <input
            type="search"
            placeholder={t("Search here")}
            className="form-control bg-transparent py-2 border-0"
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              e.key === "Enter" && (e.preventDefault(), setSearch(searchValue));
            }}
          />
          <button
            onClick={() => setSearch(searchValue)}
            type="button"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
          >
            <span className="d-block material-symbols-outlined">search</span>
          </button>
        </form>
      </div>
      {/* ------ search section end ------ */}

      {/* ------------- showing records start ----------------- */}
      <p className="text-gray fw-medium ms-auto me-3">
        {t("Showing")}
        <span className="text-black ms-1">{records}</span> {t("Records")} -
        <span className="text-black ms-1" ref={blockRefSelected}>
          {" "}
          0{" "}
        </span>{" "}
        {t("Selected")}
      </p>
      {/* ------------- showing records end ----------------- */}

      {/* ----- refresh icon start ------ */}
      <Link
        to="#"
        className="filter-btn btn btn-primary icon-btn mw-unset"
        onClick={() => {
          setReloadSurveyList(true);
          setSuccess(true);
          setNotificationString("The list has been refreshed successfully");
        }}
      >
        <span
          className="d-block material-symbols-outlined"
          data-bs-placement="top"
          title="Refresh"
        >
          refresh
        </span>
      </Link>
      {/* ----- refresh icon end ------ */}

      {/* ------ add new survey offcanvas trigger button start ------ */}
      <Link
        to="#saveSurveyPopup"
        data-bs-toggle="offcanvas"
        role="button"
        aria-controls="saveSurveyPopup"
        className="save-reservation btn btn-primary icon-btn mw-unset"
      >
        <span
          className="d-block material-symbols-outlined"
          data-bs-placement="top"
          title="Save Survey"
        >
          add
        </span>
      </Link>
      {/* ------ add new survey offcanvas trigger button end ------ */}

      {/* ------ action button start ------ */}
      <div className="dropdown">
        <button
          className="btn btn-primary dropdown-toggle mw-unset"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {t("Actions")}
        </button>
        <ul className="dropdown-menu border-0 shadow-lg">
          {/* ------ delete button start ------ */}
          <li>
            <Link onClick={deleteSurvey} className="dropdown-item" to="#">
              {t("Delete selected")}
            </Link>
          </li>
          {/* ------ delete button end ------ */}
        </ul>
      </div>
      {/* ------ action button end ------ */}
    </div>
  );
};

export default SurveyHeader;
