import AddRolePrivilageNav from 'components/AddRolePrivilage/AddRolePrivilageNav';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';
import React from 'react';
import AddRolePrivilageBody from '../../components/AddRolePrivilage/AddRolePrivilageBody';

const AddRolePrivilage = () => {
  React.useEffect(() => {
    document.title = 'Manage Role Access'; // Update the title when Register component mounts
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="manage-role-wrapper py-4">
        <div className="container-fluid px-4">
          <AddRolePrivilageNav />
          <AddRolePrivilageBody />
        </div>
      </section>
    </>
  );
};

export default AddRolePrivilage;
