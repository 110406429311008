/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import option for availibility
import availibilityOptions from 'data/Prod/CalendarAvailability.json';

/* Importing react select */
import Select from 'react-select';

/*import url and gateway methods */
import { postData, getData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import context
import { GlobalProvider } from 'context/GlobalContext';
import { useParams } from 'react-router-dom';

const AddNewMaterialSchedulePopup = () => {
  const token = localStorage.getItem('token'); // token

  /* ========== get user id from url params ========= */
  const params = useParams();

  const {
    setReloadScheduleMaterial,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const { t } = useTranslation(); //for translation

  const [selectedDays, setSelectedDays] = useState([]);

  const today = new Date().toISOString().split('T')[0];

  //function for get current time and set by defualt
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    const timenow = `${hours}:${minutes}`;

    return timenow;
  };

  /* useState hook for input fields */
  const [scheduleInputs, setScheduleInputs] = useState({
    name: 'Available',
    date: today,
    enddate: '',
    materialkitcount: 0,
  });

  /* useState hook for selected Availability */
  const [availabilityValue, setAvailabilityValue] = useState(null);
  const [availabilityData, setAvailabilityData] = useState(null);

  //function for Availability selection
  const availabilitySelectionHandler = val => {
    setAvailabilityValue(val);
    setAvailabilityData(val.value);
  };

  //function for day checkbox value
  const dayCheckboxChange = event => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedDays(prevSelectedDays => [...prevSelectedDays, value]);
    } else {
      setSelectedDays(prevSelectedDays =>
        prevSelectedDays.filter(day => day !== value)
      );
    }
  };

  /* --- hook for date comparison warning --- */
  const [dateComparisonWarning, setDateComparisonWarning] = useState(false);

  /* function to add calender event */
  const addScheduleHandler = async e => {
    e.preventDefault();

    let scheduleData = {
      materialkit: params.id,
      name: scheduleInputs.name,
      date: scheduleInputs.date,
      enddate: scheduleInputs.enddate,
      availability: availabilityData,
      days: selectedDays || [],
      materialkitcount: scheduleInputs.materialkitcount,
    };

    console.log(`Schedule Data===========`, scheduleData);

    if (scheduleData.date < scheduleData.enddate) {
      try {
        let requestUrl =
          url.API_BaseUrl +
          url.API_ADD_NEW_SCHEDULE_MATERIALKIT +
          `?token=${token}`;

        const response = await postData(requestUrl, scheduleData);

        console.log(`response submiting schedule data ========>>`, response);

        if (response.status) {
          resetHandler();
          setReloadScheduleMaterial(true);
          setSuccess(true);
          setNotificationString('Calendar record added successfully');
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString('Something went wrong, please try again later');
      }
      bootstrap.Offcanvas.getInstance(
        document.querySelector('#saveMaterialSchedulePopup')
      ).hide();
    } else {
      setDateComparisonWarning(true);
    }
  };

  console.log(`dateComparisonWarning`, dateComparisonWarning);

  /* reset handler */
  const resetHandler = () => {
    setScheduleInputs({
      name: 'Available',
      date: today,
      starttime: getCurrentTime(),
      endtime: getCurrentTime(),
      description: '',
      enddate: '',
      materialkitcount: 0,
    });
    let availibilityData = { label: 'available', value: '1' };
    availabilitySelectionHandler(availibilityData);
    setSelectedDays([]);
    setDateComparisonWarning(false);
  };

  //use effect for set default value of avilibility
  useEffect(() => {
    let availibilityData = { label: 'available', value: '1' };
    availabilitySelectionHandler(availibilityData);
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      id="saveMaterialSchedulePopup"
    >
      <div className="offcanvas-header align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t('Add Calendar Record')}</span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <form onSubmit={addScheduleHandler}>
          <div className="calendar_data_item">
            <div className="form-group mb-4">
              <label>{t('Title')} *</label>
              <input
                type="text"
                className="form-control bg-white"
                placeholder={t('Title')}
                value={scheduleInputs.name}
                onChange={val => {
                  setScheduleInputs(prevState => ({
                    ...prevState,
                    name: val.target.value,
                  }));
                }}
              />
            </div>

            {/* ========== date section start ============ */}
            <div className="form-group mb-4">
              <div className="row">
                {/* -------- start date section start --------- */}
                <div className="col-lg-6">
                  <label htmlFor="date">{t('Start Date')} *</label>
                  <input
                    type="date"
                    className="form-control bg-white"
                    placeholder="DD/MM/YYYY"
                    value={scheduleInputs.date}
                    onChange={val => {
                      setScheduleInputs(prevState => ({
                        ...prevState,
                        date: val.target.value,
                      }));
                      setDateComparisonWarning(false);
                    }}
                  />
                </div>
                {/* -------- start date section end --------- */}

                {/* -------- end date section start --------- */}
                <div className="col-lg-6">
                  <label htmlFor="date">{t('End Date')}</label>
                  <input
                    type="date"
                    className="form-control bg-white"
                    placeholder="DD/MM/YYYY"
                    value={scheduleInputs.enddate}
                    onChange={val => {
                      setScheduleInputs(prevState => ({
                        ...prevState,
                        enddate: val.target.value,
                      }));
                      setDateComparisonWarning(false);
                    }}
                  />
                </div>
                {/* -------- end date section end --------- */}
              </div>
              {/* time compare warning */}
              {dateComparisonWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>
                      {t('End time should be greater than start time')}!
                    </span>
                  </p>
                </div>
              )}
            </div>
            {/* ========== date section end ============ */}

            <div className="form-group mb-4">
              {/* =============== select days start ==================  */}
              {scheduleInputs.enddate == '' ||
              scheduleInputs.date == '' ? null : (
                <div className="row">
                  <div className="col">
                    <label htmlFor="repeatDays">{t('Repeat On')}</label>
                    <div className="repeat-days-container">
                      <div className="d-flex overflow-auto py-2">
                        {/* ------- sunday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            // checked={selectedSunday}
                            // onChange={(e) => {
                            //   setSelectedSunday(e.target.checked);
                            // }}
                            value="sunday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Sunday
                          </label>
                        </div>
                        {/* ------- sunday section end ------ */}

                        {/* ------- monday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedMonday}
                            // onChange={(e) => {
                            //   setSelectedMonday(e.target.checked);
                            // }}
                            value="monday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Monday
                          </label>
                        </div>
                        {/* ------- monday section end ------ */}

                        {/* ------- tuesday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedTuesday}
                            // onChange={(e) => {
                            //   setSelectedTuesday(e.target.checked);
                            // }}
                            value="tuesday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Tuesday
                          </label>
                        </div>
                        {/* ------- tuesday section end ------ */}

                        {/* ------- Wednesday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedWednesday}
                            // onChange={(e) => {
                            //   setSelectedWednesday(e.target.checked);
                            // }}
                            value="wednesday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Wednesday
                          </label>
                        </div>
                        {/* ------- Wednesday section end ------ */}

                        {/* ------- Thursday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            // checked={selectedThursday}
                            // onChange={(e) => {
                            //   setSelectedThursday(e.target.checked);
                            // }}
                            value="thursday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Thursday
                          </label>
                        </div>
                        {/* ------- Thursday section end ------ */}

                        {/* ------- Friday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedFriday}
                            // onChange={(e) => {
                            //   setSelectedFriday(e.target.checked);
                            // }}
                            value="friday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Friday
                          </label>
                        </div>
                        {/* ------- Friday section end ------ */}

                        {/* ------- Saturday section start ------ */}
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="checkbox" // checked={selectedSaturday}
                            // onChange={(e) => {
                            //   setSelectedSaturday(e.target.checked);
                            // }}
                            value="saturday"
                            onChange={dayCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Saturday
                          </label>
                        </div>
                        {/* ------- Saturday section end ------ */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* =============== select days end ==================  */}
            </div>

            {/* ============== time section starts =================== */}
            <div className="form-group mb-4">
              <div className="row">
                {/* ------ Availability section start ----- */}
                <div className="col-lg-4">
                  <label htmlFor="endTime">{t('Set record as')} *</label>
                  <Select
                    options={availibilityOptions}
                    value={availabilityValue}
                    onChange={val => {
                      availabilitySelectionHandler(val);
                    }}
                  />
                </div>
                {/* ------ Availability section end ----- */}

                {/* ------- kit count section start ----- */}
                <div className="col-lg-4">
                  <label htmlFor="Kitcount">
                    {t('Number of kit available')} *
                  </label>
                  <input
                    type="number"
                    className="form-control bg-white"
                    value={scheduleInputs.materialkitcount}
                    onChange={val => {
                      setScheduleInputs(prevState => ({
                        ...prevState,
                        materialkitcount: val.target.value,
                      }));
                    }}
                  />
                </div>
                {/* ------- kit count section end ----- */}
              </div>
            </div>
            {/* ============== time section ends =================== */}
          </div>

          <div className="action d-flex align-items-center gap-2">
            <button type="submit" className="btn btn-primary">
              {t('Save')}
            </button>
            {/* <p className="warring">
              <i className="material-icons-outlined">warning</i>
              <span>{t("Fill up all fields. Please Try again!")}</span>
            </p> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewMaterialSchedulePopup;
