/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import LabelBreadCrumb from 'components/LabelComponents/BreadCrumb/LabelBreadCrumb';
import LabelHeader from 'components/LabelComponents/Header/LabelHeader';
import AddUpdateLabelPopup from 'components/LabelComponents/Popup/AddUpdateLabelPopup';
import LeftMenu from 'components/common/LeftMenu';
import UserMenu from 'components/common/UserMenu';
import LabelList from 'components/LabelComponents/LabelList/LabelList';
import CustomNotification from 'components/common/CustomNotification';

const Label = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);

  /* hook to search */
  const [search, setSearch] = useState('');
  /* hook to get number of records */
  const [records, setRecords] = useState(0);

  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly

  /* function for checking record count */
  const checkedRecordCount = selectCount => {
    console.log('selectCount', selectCount);
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };
  useEffect(() => {
    document.title = 'Label'; // Update the title when Login component mounts
  }, []);

  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="mail-wrapper pt-2">
        <div className="container-fluid px-4">
          <LabelBreadCrumb />
          <LabelHeader
            setDownloadExcel={setDownloadExcel}
            setSearch={setSearch}
            records={records}
            blockRefSelected={blockRefSelected}
          />
          <LabelList
            downloadExcel={downloadExcel}
            search={search}
            setRecords={setRecords}
            checkedRecordCount={checkedRecordCount}
          />
        </div>
      </section>
      <AddUpdateLabelPopup />
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default Label;
