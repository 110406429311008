/* eslint-disable */
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

/*import url and gateway methods */
import { postData, getData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const ConversationFilter = () => {
  const {
    setSearchMailReciever,
    setSearchMailSender,
    setSearchMailSubject,
    setSearchMailDate,
    setSearchMailTemplateSelect,
    setSearchMailLabelSelect,
    setReloadConversationList,
  } = useContext(GlobalProvider);

  const { t } = useTranslation(); //for translation
  const todayValue = new Date().toISOString().split('T')[0];
  const token = localStorage.getItem('token'); // token

  const [selectedChecked, setSelectedChecked] = useState('');

  //date range
  const [fromDate, setFromDate] = useState(todayValue);
  const [toDate, setToDate] = useState(todayValue);

  //single date
  const [selectedDate, setSelectedDate] = useState(todayValue);

  const [labelList, setLabelList] = useState([]); // for label list
  const [templateList, setTemplateList] = useState([]); //for template list

  const [recieverName, setRecieverName] = useState('');
  const [senderName, setSenderName] = useState('');

  //use state for sender and reciever select
  const [recieverList, setRecieverList] = useState([]);
  const [senderList, setSenderList] = useState([]);

  const [recieverValue, setRecieverValue] = useState(null);
  const [senderValue, setSenderValue] = useState(null);

  const [mailSubject, setmailSubject] = useState('');

  const [templateValue, setTemplateValue] = useState(null);
  const [searchedTemplate, setSearchedTemplate] = useState([]);

  const [labelValue, setLabelValue] = useState(null);
  const [searchedLabel, setSearchedLabel] = useState([]);

  /* Template selection */
  const getAllTemplates = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_TEMPLATE +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      console.log('template response ==>', response);

      if (response.status) {
        setTemplateList(response.data);
      }
    } catch (error) {}
  };

  //function for get all label list
  const getAllLabels = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_TAG +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setLabelList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for select radio
  const radioCheckHandler = event => {
    setSelectedChecked(event.target.value);
    setDateWarning(false);
  };

  //function for get all user list
  const getAllRecieverList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&username=${recieverName}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setRecieverList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all user list
  const getAllSenderList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}&username=${senderName}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setSenderList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for reciever selection
  const recieverSelectionHandler = val => {
    setRecieverValue(val);
  };

  //function for sender selection
  const senderSelectionHandler = val => {
    setSenderValue(val);
  };

  //function for template selection
  const templateSectionHandler = val => {
    setTemplateValue(val);
    const templateData = val.map(data => data.value);
    setSearchedTemplate(templateData);
  };

  //function for label selection
  const labelSectionHandler = val => {
    setLabelValue(val);
    const labelData = val.map(data => data.value);
    setSearchedLabel(labelData);
  };

  //function for reset
  const resetHandler = () => {
    setRecieverName('');
    setSenderName('');
    setRecieverList([]);
    setSenderList([]);
    setRecieverValue(null);
    setSenderValue(null);
    setmailSubject('');
    setSelectedChecked('');
    setFromDate(todayValue);
    setToDate(todayValue);
    setSelectedDate(todayValue);
    setTemplateValue(null);
    setSearchedTemplate([]);
    setLabelValue(null);
    setSearchedLabel([]);
    setSearchMailReciever(null);
    setSearchMailSender(null);
    setSearchMailSubject('');
    setSearchMailDate('');
    setSearchMailTemplateSelect([]);
    setSearchMailLabelSelect([]);
    setReloadConversationList(true);
  };
  // Hook for date compare validation
  const [dateWarning, setDateWarning] = useState(false);

  //function for search
  const searchHandler = () => {
    //assign data for search
    recieverValue && setSearchMailReciever(recieverValue?.value);
    senderValue && setSearchMailSender(senderValue?.value);
    setSearchMailSubject(mailSubject);
    setSearchMailTemplateSelect(searchedTemplate);
    setSearchMailLabelSelect(searchedLabel);

    /*===== switch statement for date input radio start ======*/
    switch (selectedChecked) {
      case 'daterange':
        if (fromDate < toDate) {
          setSearchMailDate(fromDate + ',' + toDate);
        } else {
          setDateWarning(true);
        }
        break;

      case 'todayradio':
        setSearchMailDate(todayValue);
        break;

      case 'eventdate':
        setSearchMailDate(selectedDate);
        break;

      default:
        setSearchMailDate('');
        break;
    }
    /*===== switch statement for date input radio end ======*/
  };

  //use effect for search receiver
  useEffect(() => {
    if (recieverName.length >= 3) {
      getAllRecieverList();
    }
  }, [recieverName]);

  //use effect for search sender
  useEffect(() => {
    if (senderName.length >= 3) {
      getAllSenderList();
    }
  }, [senderName]);

  useEffect(() => {
    getAllTemplates();
    getAllLabels();
  }, []);

  return (
    <div className="collapse mt-3" id="filterMailCollapse">
      <div className="p-3 bg-gray-200">
        <h4 className="mb-3">{t('Filter')}</h4>
        <form onSubmit={e => e.preventDefault()}>
          <div className="form-group mb-3">
            {/* -------- all date row start -------- */}
            <div className="row align-items-center mb-3">
              {/* -------- date range section start -------- */}
              <div className="col-lg mb-3 mb-lg-0">
                <div className="d-flex align-items-center">
                  {/* -------- date input radio section start -------- */}
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="daterange"
                      id="dateRange"
                      value="daterange"
                      checked={selectedChecked === 'daterange'}
                      onChange={radioCheckHandler}
                    />
                  </div>
                  {/* -------- date input radio section end -------- */}

                  {/* -------- date selection section start -------- */}
                  <div className="field-container d-sm-flex border flex-fill">
                    {/* ------- from date input start ------- */}
                    <input
                      type="date"
                      id="from_date"
                      className="form-control bg-white border-0 mb-3 mb-sm-0"
                      placeholder={t('From date')}
                      value={fromDate}
                      onChange={e => {
                        setFromDate(e.target.value), setDateWarning(false);
                      }}
                    />
                    {/* ------- from date input end ------- */}

                    {/* ------- to date input start ------- */}
                    <input
                      type="date"
                      id="to_date"
                      className="form-control bg-white border-0"
                      placeholder={t('To date')}
                      value={toDate}
                      onChange={e => {
                        setToDate(e.target.value), setDateWarning(false);
                      }}
                    />
                    {/* ------- to date input end ------- */}
                  </div>
                  {/* -------- date selection section end -------- */}
                </div>
                {/* date compare warning */}
                {dateWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>
                        {t('End date should be greater than start date')}!
                      </span>
                    </p>
                  </div>
                )}
              </div>
              {/* -------- date range section end -------- */}

              {/* --------- today section start --------- */}
              <div className="col-lg-auto mb-3 mb-lg-0">
                <div className="form-check mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="todayradio"
                    id="today"
                    value="todayradio"
                    checked={selectedChecked === 'todayradio'}
                    onChange={radioCheckHandler}
                  />
                  <label className="form-check-label mb-0" htmlFor="today">
                    {t('Today')}
                  </label>
                </div>
              </div>
              {/* --------- today section end --------- */}

              {/* --------- date section start --------- */}
              <div className="col-lg">
                <div className="d-flex align-items-center">
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="eventdate"
                      id="multipleDates"
                      value="eventdate"
                      checked={selectedChecked === 'eventdate'}
                      onChange={radioCheckHandler}
                    />
                  </div>
                  <input
                    type="date"
                    id="to_date"
                    className="form-control bg-white"
                    placeholder={t('Select Multiple Dates')}
                    value={selectedDate}
                    onChange={e => setSelectedDate(e.target.value)}
                  />
                </div>
              </div>
              {/* --------- date section end --------- */}
            </div>
            {/* -------- all date row end -------- */}

            {/* -------- template, label selcetion row section start -------- */}
            <div className="row align-items-center">
              {/* ------- template selection section start ------- */}
              <div className="col-lg-6 mb-3 mb-lg-0">
                <Select
                  isMulti
                  placeholder={t('Select template')}
                  options={templateList}
                  value={templateValue}
                  onChange={val => {
                    templateSectionHandler(val);
                  }}
                />
              </div>
              {/* ------- template selection section end ------- */}

              {/* ------- label selection section start ------- */}
              <div className="col-lg-6 mb-3 mb-lg-0">
                <Select
                  isMulti
                  placeholder={t('Select Label')}
                  options={labelList}
                  value={labelValue}
                  onChange={val => {
                    labelSectionHandler(val);
                  }}
                />
              </div>
              {/* ------- label selection section end ------- */}
            </div>
            {/* -------- template, label selcetion row section end -------- */}
          </div>

          {/* -------- reciever, sender and subject row start -------- */}
          <div className="row mb-3">
            {/* ------- reciever section start ------- */}
            <div className="col-lg-3 mb-3 mb-lg-0">
              <div className="field-container">
                <Select
                  placeholder={t('Receiver')}
                  options={recieverList}
                  value={recieverValue}
                  onChange={val => {
                    recieverSelectionHandler(val);
                  }}
                  onInputChange={val => {
                    setRecieverName(val);
                  }}
                />
              </div>
            </div>
            {/* ------- reciever section end ------- */}

            {/* ------- sender section start ------- */}
            <div className="col-lg-3 mb-3 mb-lg-0">
              <div className="field-container">
                <Select
                  options={senderList}
                  placeholder={t('Sender')}
                  value={senderValue}
                  onChange={val => {
                    senderSelectionHandler(val);
                  }}
                  onInputChange={val => {
                    setSenderName(val);
                  }}
                />
              </div>
            </div>
            {/* ------- sender section end ------- */}

            {/* -------- subject section start -------- */}
            <div className="col-lg mb-3 mb-lg-0">
              <div className="field-container position-relative">
                <input
                  type="text"
                  placeholder={t('Subject')}
                  className="form-control bg-white"
                  value={mailSubject}
                  onChange={e => setmailSubject(e.target.value)}
                />
                <span className="d-block text-gray icon-md material-symbols-outlined position-absolute top-50 translate-middle-y">
                  search
                </span>
              </div>
            </div>
            {/* -------- subject section end -------- */}
          </div>
          {/* -------- reciever, sender and subject row end -------- */}

          {/* -------- search and reset button section start -------- */}
          <div className="action d-flex gap-3 align-items-center justify-content-end">
            {/* ------- refresh/reset button start ------- */}
            <Link
              to="#"
              className="d-flex gap-1 align-items-center text-black"
              onClick={resetHandler}
            >
              <span className="d-block material-symbols-outlined icon-md">
                sync
              </span>
              <span className="d-block fw-medium">{t('Refresh')}</span>
            </Link>
            {/* ------- refresh/reset button end ------- */}

            {/* ------- search button start ------- */}
            <button className="btn btn-primary" onClick={searchHandler}>
              {t('Filter')}
            </button>
            {/* ------- search button end ------- */}
          </div>
          {/* -------- search and reset button section end -------- */}
        </form>
      </div>
    </div>
  );
};

export default ConversationFilter;
