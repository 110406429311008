/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

// kendo react modules ==========================
import '@progress/kendo-theme-material/dist/all.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import api requiremnts
import * as url from 'urlhelpers/UrlHelper';
import { getData } from 'utils/Gateway';

import SurveyFeedbackPopup from '../Popup/SurveyFeedbackPopup';
import SurveyFeedbackThanksModal from '../Modal/SurveyFeedbackThanksModal';

const SurveyFeedbackTab = () => {
  const token = localStorage.getItem('token');
  const params = useParams();

  const { t } = useTranslation(); //for translation

  const [surveyList, setSurveyList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);
  /* hook to search */
  const [search, setSearch] = useState('');
  /* hook to get the search value */
  const [searchValue, setSearchValue] = useState('');
  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(surveyList, initialDataState));

  const [surveyData, setSurveyData] = useState(null);

  const onDataStateChange = event => {
    console.log('event -->', event);
    setDataState(event.dataState);
    setResult(process(surveyList, event.dataState));
  };

  //function for get all survey list
  const getAllSurveyList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_SURVEY_FEEDBACK +
        `?token=${token}&user=${params.id}`;

      console.log('Url in profile survey list=======>', requestUrl);

      const response = await getData(requestUrl);

      console.log('Response in profile survey list=======>', response);

      if (response.status) {
        setSurveyList(response.data);
        setResponseReceived(true);
        setResult(process(response.data, initialDataState));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter(item => {
      const survey = item?.survey?.name?.toLowerCase() || '';
      const surveydate = item?.surveydate || '';
      const surveyresponse = item?.surveyresponse?.toLowerCase() || '';
      const submittedon = item?.surveydate || '';

      return (
        (survey && survey.includes(search.toLowerCase())) ||
        (surveydate && surveydate.includes(search)) ||
        (surveyresponse && surveyresponse.includes(search.toLowerCase())) ||
        (submittedon && submittedon.includes(search))
      );
    });

    return filteredData;
  };

  //html for edit button
  const chevronRight = ({ dataItem }) => {
    const surveyPopupHandler = () => {
      setSurveyData(dataItem);
    };
    return (
      <td className="kendoCustomColumn overflow-visible">
        <div className="code">
          <Link
            to="#profile_survey_feedback_popup"
            data-bs-toggle="offcanvas"
            onClick={surveyPopupHandler}
          >
            <span className="material-icons-outlined">chevron_right</span>
          </Link>
        </div>
      </td>
    );
  };

  const statusHTML = props => {
    const textColor =
      props.dataItem.surveyresponsestatus == '1' ? 'green_text' : 'text-danger';

    return (
      <td className={`start-time ${textColor}`}>
        <span>{props.dataItem.surveyresponse}</span>
      </td>
    );
  };
  // mark all checkbox as selected ----------------
  const selectAllRecords = e => {
    document
      .querySelectorAll('input[name="selectAllSurveyRecord"]')
      .forEach(checkbox => {
        checkbox.checked = e.target.checked;
      });
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = props => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectAllSurveyRecord"
          />
        </div>
      </td>
    );
  };

  //function for after modal close
  const closeModalHandler = () => {
    getAllSurveyList();
  };

  useEffect(() => {
    if (responseReceived === false) {
      getAllSurveyList();
    }
  }, [responseReceived]);

  useEffect(() => {
    if (params.id) {
      getAllSurveyList();
    }
  }, [params.id]);
  /* hook to empty search when searchValue gets empty */
  useEffect(() => {
    !searchValue && setSearch('');
  }, [searchValue]);
  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);

  return (
    <div className="profile_tabInnr">
      <div className="breadcum_area">
        <nav aria-label="breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/" className="text-secondary">
               {t('Dashboard')}
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-600" aria-current="page">
              {t('Survey')}
            </li>
          </ul>
        </nav>
      </div>
      <div className="search-container">
        <form onSubmit={e => e.preventDefault()}>
          <input
            type="search"
            placeholder={t('Search here')}
            className="form-control bg-transparent py-2 border-0"
            onChange={e => setSearchValue(e.target.value)}
            onKeyDown={e => {
              e.key === 'Enter' && (e.preventDefault(), setSearch(searchValue));
            }}
          />
          <button
            onClick={() => setSearch(searchValue)}
            type="submit"
            className="bg-transparent text-dark p-0 border-0 position-absolute top-50 translate-middle-y"
          >
            <span className="d-block material-icons-outlined">search</span>
          </button>
        </form>
      </div>

      <div className="table-responsive py-2 mt-2">
        {search ? (
          <Grid
            data={filterData()}
            filterable={true}
            sortable={true}
            groupable={true}
            onDataStateChange={onDataStateChange}
            {...dataState}
            //pageable = {true}
            //total = {products.length}
            rowHeight={100}
            reorderable
            // resizable
          >
            <GridColumn
              field="id"
              headerCell={SelectRecordHeader}
              cell={SelectRecordRow}
              filterable={false}
              sortable={false}
              width="70px"
            />
            <GridColumn
              field="survey.name"
              title={t('Survey')}
              // title={<ActivityTitle />}
              // cell={senderBlockHtml}
              href="#EmailDetailsPopup"
              data-bs-toggle="offcanvas"
              role="button"
              aria-controls="EmailDetailsPopup"
            />
            <GridColumn field="surveydate" title={t('Survey Date')} />

            <GridColumn
              field="surveyresponse"
              cell={statusHTML}
              title={t('Status')}
            />
            <GridColumn field="surveydate" title={t('Submitted on')} />
            <GridColumn
              field="Sender.Name"
              cell={chevronRight}
              title={t('Action')}
            />
            {/* <GridColumn field="Receiver.Name" title="Receiver" /> */}
            {/* <GridColumn field="Labels.LabelName" title="Labels" /> */}
          </Grid>
        ) : (
          <Grid
            data={result}
            filterable={true}
            sortable={true}
            groupable={true}
            onDataStateChange={onDataStateChange}
            {...dataState}
            //pageable = {true}
            //total = {products.length}
            rowHeight={100}
            reorderable
            // resizable
          >
            <GridColumn
              field="id"
              headerCell={SelectRecordHeader}
              cell={SelectRecordRow}
              filterable={false}
              sortable={false}
              width="70px"
            />
            <GridColumn
              field="survey.name"
              title={t('Survey')}
              // title={<ActivityTitle />}
              // cell={senderBlockHtml}
              href="#EmailDetailsPopup"
              data-bs-toggle="offcanvas"
              role="button"
              aria-controls="EmailDetailsPopup"
            />
            <GridColumn field="surveydate" title={t('Survey Date')} />

            <GridColumn
              field="surveyresponse"
              cell={statusHTML}
              title={t('Status')}
            />
            <GridColumn field="surveydate" title={t('Submitted on')} />
            <GridColumn
              field="Sender.Name"
              cell={chevronRight}
              title={t('Action')}
            />
            {/* <GridColumn field="Receiver.Name" title="Receiver" /> */}
            {/* <GridColumn field="Labels.LabelName" title="Labels" /> */}
          </Grid>
        )}
      </div>
      <SurveyFeedbackPopup
        surveyData={surveyData}
        setSurveyData={setSurveyData}
      />
      <SurveyFeedbackThanksModal closeModalHandler={closeModalHandler} />
    </div>
  );
};

export default SurveyFeedbackTab;
