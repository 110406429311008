/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

/* Importing react select */
import Select from 'react-select';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/*import url and gateway methods */
import { getData, postData, putData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import context
import { GlobalProvider } from 'context/GlobalContext';

import StatusOptions from 'data/Prod/ReserveStatus.json';

// Manage Tags or Label for all modules =============================
// ==================================================================

const ReserveStatusChange = params => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem('token');

  const {
    setReloadReservationList,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const [changeSelect, setChangeSelect] = useState({
    selectedStatus: null,
  });

  const [currentStatus, setCurrentStatus] = useState();

  //function for status selection
  const statusSelectionHandler = val => {
    setChangeSelect(prevState => ({
      ...prevState,
      selectedStatus: val,
    }));

    setCurrentStatus(val.value);
  };

  //function for update status ------------------------------------
  const updateStatus = async action => {
    const checkboxes = document.querySelectorAll(
      'input[filtername="selectedrecord"]'
    );

    // run a loop for all selected components or checkboxId
    const statusUpdateAction = checkboxes.forEach(async (checkbox, index) => {
      // if checked
      if (checkbox.checked) {
        // get the checked components
        let selectedComponentId = checkbox.value;

        let requestUrl = url.API_BaseUrl + url.API_UPDATE_EVENT_STATUS;

        requestUrl =
          requestUrl +
          `?token=${token}` +
          `&event=${selectedComponentId}` +
          `&state=${currentStatus}`;

        console.log('submit reserve status', requestUrl);

        try {
          const response = await getData(requestUrl);
          console.log(`response`, JSON.stringify(response));
          setSuccess(true);

          response.data.reservestatus === '1'
            ? setNotificationString('Reservation has been Confirmed')
            : response.data.reservestatus === '0'
            ? setNotificationString('Reservation has been deleted')
            : setNotificationString(
                'Reservation has been cancelled at the moment'
              );
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString('Something went wrong, please try again later');
        }
      }
    });

    //await Promise.all(statusUpdateAction);

    //close the offcanvas of addLabel
    let offCanvasPopup = document.querySelector('#status_pnl');
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();

    setCurrentStatus(null);

    setReloadReservationList(true);
  };

  return (
    <div
      className="offcanvas offcanvas-end border-0 status_offcanvas"
      id="status_pnl"
      aria-labelledby="status_pnl"
    >
      <div className="offcanvas-header position-relative align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">Update Status</span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body position-relative p-4 pt-0">
        <form action="">
          <div className="form-group mb-4">
            <div className="row">
              {/* ======== select status starts =========*/}
              <div className="col-lg-12">
                <label htmlFor="status">{t('Status')}</label>
                <Select
                  className="nosearch_select"
                  options={StatusOptions}
                  value={changeSelect.selectedStatus}
                  onChange={val => {
                    statusSelectionHandler(val);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="tags_btns d-flex align-items-center gap-2">
            <Link
              to="#"
              className="btn  btn-primary d-flex align-items-center justify-content-center gap-1 w-100"
              role="button"
              onClick={() => {
                updateStatus();
              }}
            >
              <i className="material-icons-round">check_circle</i>
              <span>Update</span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReserveStatusChange;
