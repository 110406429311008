import React from 'react';

/* Importing react select */
import Select from 'react-select';

// kendo react modules ==========================
import '@progress/kendo-theme-material/dist/all.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/* importing group members data */
import groupMembers from '../../../../data/GroupMembersData.json';
import { Link } from 'react-router-dom';

/* import status data */
import status from '../../../../data/Prod/Status.json';

const GroupMembersTab = () => {
  const { t } = useTranslation(); //for translation
  const data = {
    members: [
      { label: 'Member 1', value: 'Member 1' },
      { label: 'Member 2', value: 'Member 2' },
      { label: 'Member 3', value: 'Member 3' },
      { label: 'Member 4', value: 'Member 4' },
    ],
    // status: [
    //   { label: 'Status 1', value: 'Status 1' },
    //   { label: 'Status 2', value: 'Status 2' },
    //   { label: 'Status 3', value: 'Status 3' },
    //   { label: 'Status 4', value: 'Status 4' },
    //   { label: 'Status 5', value: 'Status 5' },
    // ],
  };

  const [changeSelect, setChangeSelect] = React.useState({
    selectMembers: null,
    selectStatus: null,
  });

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = React.useState();
  const [result, setResult] = React.useState(
    process(groupMembers, initialDataState)
  );

  const onDataStateChange = event => {
    console.log('event -->', event);
    setDataState(event.dataState);
    setResult(process(groupMembers, event.dataState));
  };

  /* custom name cell html */
  const nameHTML = ({ dataItem }) => {
    return (
      <td className="members">
        <div className="profile d-flex align-items-center gap-2 mb-1">
          <div className="img rounded-circle overflow-hidden">
            <img
              src="assets/img/victor-m-herrero.png"
              alt="Victor M. herrero"
              className="w-100 h-100 object-cover object-center"
            />
          </div>
          <p className="fw-medium">{dataItem.name}</p>
        </div>
        <Link
          to="mailto:richard.hawail@mail.com"
          className="d-flex align-items-center gap-1 text-dark"
        >
          <span className="d-block material-symbols-outlined icon-sm">
            mail
          </span>
          <span className="d-block">{dataItem.email}</span>
        </Link>
      </td>
    );
  };

  /* custom action cell html */
  const actionHTML = () => {
    return (
      <td className="action">
        <Link to="#" className="text-black">
          <span className="d-block material-symbols-outlined icon-md icon-fill">
            edit
          </span>
        </Link>
      </td>
    );
  };

  return (
    <div
      className="tab-pane fade"
      id="group-member-tab-pane"
      role="tabpanel"
      aria-labelledby="group-member-tab"
      tabIndex="0"
    >
      <form action="">
        <div className="form-container d-md-flex align-items-end gap-3 mb-3">
          <div className="form-group flex-fill mb-3 mb-md-0">
            <label htmlFor="members">{t('Members')}</label>
            <Select
              className="nosearch_select"
              options={data.members}
              value={changeSelect.selectMembers}
              onChange={val => setChangeSelect({ selectMembers: val })}
            />
          </div>
          <div className="form-group flex-fill mb-3 mb-md-0">
            <label htmlFor="status">{t('Status')}</label>
            <Select
              className="nosearch_select"
              options={status}
              value={changeSelect.selectStatus}
              onChange={val => setChangeSelect({ selectStatus: val })}
            />
          </div>
          <div className="action">
            <button type="submit" className="btn btn-primary mw-unset">
              {t('Save')}
            </button>
          </div>
        </div>
      </form>
      <div className="table-responsive">
        <Grid
          style={{ minWidth: '100%' }}
          data={result}
          // filterable={true}
          sortable={true}
          // groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            // field="name"
            cell={nameHTML}
            title={t('Members')}
            width="250px"
          />
          <GridColumn field="status" title={t('Status')} />
          <GridColumn field="date" title={t('Date')} />
          <GridColumn field="status" cell={actionHTML} title={t('Action')} />
        </Grid>
      </div>
    </div>
  );
};

export default GroupMembersTab;
