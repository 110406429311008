import React from 'react';

//import translation function for language translation
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SalesOrderBreadCrumb = () => {
  const { t } = useTranslation(); //for translation
  return (
    <nav aria-label="breadcrumb">
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="dashboard.html" className="text-secondary">
            {t('Dashboard')}
          </Link>
        </li>
        <li className="breadcrumb-item text-gray-600" aria-current="page">
          {t('Sales Order')}
        </li>
      </ul>
    </nav>
  );
};

export default SalesOrderBreadCrumb;
