/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';

/* Importing react select */
import Select from 'react-select';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/*import url and gateway methods */
import { postData, getData, putData, uploadMultipleFile } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import options from json file
import typeOfActivity from 'data/Prod/ActivityType.json';
import scopeOption from 'data/Prod/Scope.json';
import batchLotOption from 'data/Prod/Batch.json';
import specificAudience from 'data/Prod/SpecialAudience.json';
import levelOption from 'data/Prod/Level.json';
import CoordinatingEntity from 'data/Prod/CoordinatingEntity.json';
import StatusOptions from 'data/Prod/Status.json';

//import context
import { GlobalProvider } from 'context/GlobalContext';
import { Link } from 'react-router-dom';

const ActivitySaveRecord = () => {
  const {
    setReloadActivityList,
    setEditActivityData,
    editActivityData,
    activityFormReset,
    setActivityFormReset,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const dateOfToday = new Date().toISOString().split('T')[0];

  const token = localStorage.getItem('token');
  const { t } = useTranslation(); //for translation

  const [courseList, setCourseList] = useState([]); //course option
  const [materialKitList, setMaterialKitList] = useState([]); //materila kit option
  const [educatorList, setEducatorList] = useState([]); //educator option

  //  Hooks used for React select start
  const [selectCourses, setSelectCourses] = useState(null);
  const [selectTypeOfActivity, setSelectTypeOfActivity] = useState(null);
  const [selectedScope, setSelectedScope] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedLot, setSelectedLot] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [selectedEducators, setSelectedEducators] = useState(null);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [selectedMaterialKit, setSelectedMaterialKit] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    label: 'Enable',
    value: '1',
  });

  //  Hooks used for React select end

  /* hooks for input fields start */
  const [courseValue, setCourseValue] = useState(null);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [typeOfActivityValue, setTypeOfActivityValue] = useState(null);
  const [duration, setDuration] = useState('');
  const [scopeValues, setScopeValues] = useState([]);
  const [coordinatingEntity, setCoordinatingEntity] = useState([]);
  const [batchLots, setBatchLots] = useState([]);
  const [levels, setLevels] = useState([]);
  const [educators, setEducators] = useState([]);
  const [specificAudienceValue, setSpecificAudienceValue] = useState(null);
  const [activityNumberPerCourse, setActivityNumberPerCourse] = useState('');
  const [preparationTime, setPreparationTime] = useState('');
  const [mountingTime, setMountingTime] = useState('');
  const [dismantleTime, setDismantleTime] = useState('');
  const [combinedOrder, setCombinedOrder] = useState('');
  const [minParticipants, setMinParticipants] = useState('');
  const [maxParticipants, setMaxParticipants] = useState('');
  const [groups, setGroups] = useState('');
  const [groupRatio, setGroupRatio] = useState('');
  const [materialKits, setMaterialKits] = useState([]);
  const [observations, setObservations] = useState('');
  const [educatorFileUploadIds, setEducatorFileUploadIds] = useState([]);
  const [beneficiaryFileUploadIds, setBeneficiaryFileUploadIds] = useState([]);
  const [startDate, setStartDate] = useState(dateOfToday); // for start date
  const [endDate, setEndDate] = useState(dateOfToday); // for end date
  const [activityCost, setActivityCost] = useState(1);
  const [statusValue, setStatusValue] = useState(null);
  const [isEducatorFileUploading, setIsEducatorFileUploading] = useState(false);
  const [uploadedEducatorFiles, setUploadedEducatorFiles] = useState([]);
  const [isBeneficiaryFileUploading, setIsBeneficiaryFileUploading] =
    useState(false);
  const [uploadedBeneficiaryFiles, setUploadedBeneficiaryFiles] = useState([]);

  /* hooks for input fields end */

  /* Hook for validation start */
  const [validation, setValidation] = useState({
    courseWarning: false,
    nameWarning: false,
    codeWarning: false,
    zipCodeWarning: false,
    statusWarning: false,
    durationWarning: false,
    dateCompareWarning: false,
  });
  /* Hook for validation end */

  /* function for validation starts */
  const formValidation = () => {
    let isValid = true;

    if (selectCourses === null) {
      setValidation(prevState => ({ ...prevState, courseWarning: true }));
      isValid = false;
    }
    if (name === '') {
      setValidation(prevState => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }
    if (code === '') {
      setValidation(prevState => ({ ...prevState, codeWarning: true }));
      isValid = false;
    }
    if (activityCost === '') {
      setValidation(prevState => ({ ...prevState, zipCodeWarning: true }));
      isValid = false;
    }
    if (selectedStatus === null) {
      setValidation(prevState => ({ ...prevState, statusWarning: true }));
      isValid = false;
    }

    if (duration === '') {
      setValidation(prevState => ({ ...prevState, durationWarning: true }));
      isValid = false;
    }

    if (startDate !== endDate && startDate > endDate) {
      setValidation(prevState => ({ ...prevState, dateCompareWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  /* function for validation ends */

  //function for get course list
  const getAllCourseList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_COURSE +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setCourseList(response.data);
        courseSelectionHandler(response.data[response.data.length - 1]);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get material kit list
  const getAllMaterialKitList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_MATERIAL_KIT +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setMaterialKitList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get user/educator list
  const getAllEducatorList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        let filteredEducator = response.data.filter(user => {
          let userData;
          if (user.role) {
            if (user.role.name == 'educator') {
              userData = user;
            }
          }
          return userData;
        });
        setEducatorList(filteredEducator);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for file upload
  const educatorFileUploadHandler = async e => {
    try {
      setIsEducatorFileUploading(true);
      let requestUrl =
        url.API_BaseUrl + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, e.target.files);

      if (response.status) {
        console.log(`response`, response.data);

        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedEducatorFiles.length == 0) {
          setUploadedEducatorFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedEducatorFiles.concat(uploadedFileValues);
          setUploadedEducatorFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setIsEducatorFileUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (educatorFileUploadIds.length == 0) {
          setEducatorFileUploadIds(fileIds);
        } else {
          let fileArr = educatorFileUploadIds.concat(fileIds);
          setEducatorFileUploadIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/

        // setValidation(prevState => ({
        //   ...prevState,
        //   uploadsWarning: false,
        // }));
        //setWarningIndex(1);
      }
      resetEducatorFile();
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for file upload
  const beneficiaryFileUploadHandler = async e => {
    try {
      setIsBeneficiaryFileUploading(true);
      let requestUrl =
        url.API_BaseUrl + url.API_FILE_UPLOAD_MULTIPLE + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, e.target.files);

      if (response.status) {
        console.log(`response`, response.data);

        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedBeneficiaryFiles.length == 0) {
          setUploadedBeneficiaryFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedBeneficiaryFiles.concat(uploadedFileValues);
          setUploadedBeneficiaryFiles(fileArr);
        }

        /*----- assign uploaded files with path for display end ------*/

        setIsBeneficiaryFileUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (beneficiaryFileUploadIds.length == 0) {
          setBeneficiaryFileUploadIds(fileIds);
        } else {
          let fileArr = beneficiaryFileUploadIds.concat(fileIds);
          setBeneficiaryFileUploadIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/

        // setValidation(prevState => ({
        //   ...prevState,
        //   uploadsWarning: false,
        // }));
        //setWarningIndex(1);
      }
      resetBeneficiaryFile();
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for delete file from upload stack
  const closeEducatorFileHandler = index => {
    const closedFile = [...uploadedEducatorFiles];
    const removedFileId = [...educatorFileUploadIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedEducatorFiles(closedFile);
    setEducatorFileUploadIds(removedFileId);

    resetEducatorFile();
  };

  //function for delete file from upload stack
  const closeBeneficiaryFileHandler = index => {
    const closedFile = [...uploadedBeneficiaryFiles];
    const removedFileId = [...beneficiaryFileUploadIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedBeneficiaryFiles(closedFile);
    setBeneficiaryFileUploadIds(removedFileId);

    resetBeneficiaryFile();
  };

  //function for course selection
  const courseSelectionHandler = val => {
    setSelectCourses(val);
    setCourseValue(val.value);
  };

  //function for activity type selection
  const typeActivitySelectionHandler = val => {
    setSelectTypeOfActivity(val);
    setTypeOfActivityValue(val.value);
  };

  //function for scope selection
  const scopeSelectionHandler = val => {
    setSelectedScope(val);
    const scopeValue = val.map(item => {
      return item.value;
    });
    setScopeValues(scopeValue);
  };

  //function for entity selection
  const entitySelectionHandler = val => {
    setSelectedEntity(val);
    const entityValue = val.map(item => {
      return item.value;
    });
    setCoordinatingEntity(entityValue);
  };

  //function for lot selcetion
  const lotSelectionHandler = val => {
    setSelectedLot(val);
    const lotValue = val.map(item => {
      return item.value;
    });
    setBatchLots(lotValue);
  };

  //function for level selection
  const levelSelectionHandler = val => {
    setSelectedLevel(val);
    const levelValue = val.map(item => {
      return item.value;
    });
    setLevels(levelValue);
  };

  //function for educator selection
  const educatorSelectionHandler = val => {
    setSelectedEducators(val);
    const educatorsValue = val.map(item => {
      return item.value;
    });
    setEducators(educatorsValue);
  };

  //function for audience selection
  const audienceSelectionHandler = val => {
    setSelectedAudience(val);
    setSpecificAudienceValue(val.value);
  };

  //function for select material kit
  const materialSelectionHandler = val => {
    setSelectedMaterialKit(val);
    const kitValue = val.map(item => {
      return item.value;
    });
    setMaterialKits(kitValue);
  };

  //function for select status
  const statusSelectionHandler = val => {
    setSelectedStatus(val);
    setStatusValue(val.value);
  };

  //function for save activity
  const saveActivityHandler = async e => {
    e.preventDefault();

    // const validate = formValidation();
    // console.log(`validate============>>`, formValidation());
    let acitvityData = {
      name,
      course: courseValue,
      code,
      activitytype: typeOfActivityValue,
      activitypercourse: activityNumberPerCourse,
      scope: scopeValues,
      batch: batchLots,
      coordinatingentity: coordinatingEntity,
      levels,
      // Installation,
      duration,
      educators,
      specialaudience: specificAudienceValue,
      preparationtime: preparationTime,
      mountingtime: mountingTime,
      dismantletime: dismantleTime,
      combinedorder: combinedOrder,
      maxparticipantspergroup: maxParticipants,
      minparticipantspergroup: minParticipants,
      maxgroup: groups,
      ratiopergroup: groupRatio,
      materialkits: materialKits,
      observation: observations,
      startdate: startDate,
      enddate: endDate,
      beneficiaryuploads: beneficiaryFileUploadIds,
      uploads: educatorFileUploadIds,
      status: statusValue,
      cost: activityCost,
    };
    console.log('acitvityData------->', acitvityData);

    if (formValidation()) {
      try {
        let requestUrl =
          url.API_BaseUrl + url.API_ADD_ACTIVITY + `?token=${token}`;
        const response = await postData(requestUrl, acitvityData);
        console.log(response);

        if (response.status) {
          resetHandler();
          setReloadActivityList(true);
          setSuccess(true);
          setNotificationString('Activity added successfully');
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString('Something went wrong, please try again later');
      }
      let offCanvasPopup = document.querySelector('#saveActivityPopup');
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    }
  };

  //function for edit activity
  const editActivityHandler = async e => {
    e.preventDefault();
    let acitvityData = {
      name,
      course: courseValue,
      code,
      activitytype: typeOfActivityValue,
      activitypercourse: activityNumberPerCourse,
      scope: scopeValues,
      batch: batchLots,
      coordinatingentity: coordinatingEntity,
      levels,
      // Installation,
      duration,
      educators,
      specialaudience: specificAudienceValue,
      combinedorder: combinedOrder,
      preparationtime: preparationTime,
      mountingtime: mountingTime,
      dismantletime: dismantleTime,
      maxparticipantspergroup: maxParticipants,
      minparticipantspergroup: minParticipants,
      maxgroup: groups,
      ratiopergroup: groupRatio,
      materialkits: materialKits,
      observation: observations,
      startdate: startDate,
      enddate: endDate,
      beneficiaryuploads: beneficiaryFileUploadIds,
      uploads: educatorFileUploadIds,
      status: statusValue,
      cost: activityCost,
    };

    console.log('acitvityData======>', acitvityData);
    if (formValidation()) {
      try {
        let requestUrl =
          url.API_BaseUrl +
          url.API_UPDATE_ACTIVITY +
          `/${editActivityData._id}` +
          `?token=${token}`;

        const response = await putData(requestUrl, acitvityData);

        console.log(response);

        if (response.status) {
          resetHandler();
          setSuccess(true);
          setNotificationString('Activity edited successfully');
          setReloadActivityList(true);
        }
      } catch (error) {
        console.error(error.message);
        setError(true);
        setNotificationString('Something went wrong, please try again later');
      }

      let offCanvasPopup = document.querySelector('#saveActivityPopup');
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    }
  };

  //function for clear educator file value
  const resetEducatorFile = () => {
    const educatorFile = document.getElementById(
      'uploadedEducatorActivityFile'
    );
    if (educatorFile) {
      educatorFile.value = null;
    } else {
      return;
    }
  };

  //function for clear benificiary file value
  const resetBeneficiaryFile = () => {
    const beneficiaryFile = document.getElementById(
      'uploadedBeneficiaryActivityFile'
    );
    if (beneficiaryFile) {
      beneficiaryFile.value = null;
    } else {
      return;
    }
  };

  //function for reset all field
  const resetHandler = () => {
    setEditActivityData(null);
    resetEducatorFile();
    resetBeneficiaryFile();
    setValidation({
      courseWarning: false,
      nameWarning: false,
      codeWarning: false,
      activitytypeWarning: false,
      durationWarning: false,
      scopeWarning: false,
      entityWarning: false,
      lotWarning: false,
      levelWarning: false,
      educatorsWarning: false,
      audienceWarning: false,
      activitiesPerCourseWarning: false,
      preparationTimeWarning: false,
      mountingTimeWarning: false,
      dismantleTimeWarning: false,
      combinedOrderWarning: false,
      minParticipantsWarning: false,
      maxParticipantsWarning: false,
      maxGroupWarning: false,
      ratioWarning: false,
      materialKitWarning: false,
      observationsWarning: false,
      startDateWarning: false,
      endDateWarning: false,
      statusWarning: false,
      uploadsWarning: false,
      zipCodeWarning: false,
      dateCompareWarning: false,
    });
    setSelectCourses(null);
    setSelectTypeOfActivity(null);
    setSelectedScope(null);
    setSelectedEntity(null);
    setSelectedLot(null);
    setSelectedLevel(null);
    setSelectedEducators(null);
    setSelectedAudience(null);
    setSelectedMaterialKit(null);
    statusSelectionHandler({
      label: 'Enable',
      value: '1',
    });
    setCourseValue(null);
    setName('');
    setCode('');
    setTypeOfActivityValue(null);
    setDuration('');
    setScopeValues([]);
    setCoordinatingEntity([]);
    setBatchLots([]);
    setLevels([]);
    setEducators([]);
    setSpecificAudienceValue(null);
    setActivityNumberPerCourse('');
    setCombinedOrder('');
    setMinParticipants('');
    setMaxParticipants('');
    setGroups('');
    setGroupRatio('');
    setMaterialKits([]);
    setObservations('');
    setEducatorFileUploadIds([]);
    setBeneficiaryFileUploadIds([]);
    setStartDate(dateOfToday); // for start date
    setEndDate(dateOfToday); // for end date
    setStatusValue(null);
    setActivityCost(1);
    setUploadedEducatorFiles([]);
    setUploadedBeneficiaryFiles([]);
  };

  /* function to download file */
  const fileDownloadHandler = async file => {
    try {
      const response = await fetch(file.path);
      const blob = await response.blob();
      const fileName = file.name;

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error occurred while downloading the file:', error);
    }
  };

  //use effect for get
  useEffect(() => {
    getAllCourseList();
    getAllMaterialKitList();
    getAllEducatorList();
  }, []);

  /* hook for edit */

  useEffect(() => {
    if (editActivityData) {
      console.log(editActivityData);
      /* ======== assign course start ========*/
      if (editActivityData.course) {
        let courseData = {
          label: editActivityData.course.name,
          value: editActivityData.course._id,
        };
        courseSelectionHandler(courseData);
      }
      /* =========== assign course end ============*/

      /*========= assign name start ===================  */
      editActivityData?.name && setName(editActivityData.name);
      /*========= assign name end ===================  */

      /* ========== assign code start ================ */
      editActivityData?.code && setCode(editActivityData.code);
      /* ========== assign code end ================ */

      /* ============ Assign type of activity start =============== */
      if (editActivityData.activitytype) {
        let activityData = {
          label: editActivityData.activitytype,
          value: editActivityData.activitytype,
        };
        typeActivitySelectionHandler(activityData);
      }
      /* ============ Assign type of activity end =============== */

      /* ============= assign duration starts ================ */
      editActivityData?.duration && setDuration(editActivityData.duration);
      /* ============= assign duration ends ================ */

      /* =============== assign scope starts ================= */
      if (editActivityData.scope) {
        let scopeValue = editActivityData.scope.map(scopeitem => {
          let scopeData = {
            label: scopeitem,
            value: scopeitem,
          };
          return scopeData;
        });
        scopeSelectionHandler(scopeValue);
      }
      /* =============== assign scope ends ================= */

      /* =========== assign coordination entity start ============= */
      if (editActivityData.coordinatingentity) {
        let entityValue = editActivityData.coordinatingentity.map(
          entityItem => {
            let entityData = {
              label: entityItem,
              value: entityItem,
            };
            return entityData;
          }
        );
        entitySelectionHandler(entityValue);
      }
      /* =========== assign coordination entity ends ============= */

      /* ========== assign lot starts ============== */
      if (editActivityData.batch) {
        let batchValue = editActivityData.batch.map(lotItem => {
          let lotBatchData = {
            label: lotItem,
            value: lotItem,
          };
          return lotBatchData;
        });
        lotSelectionHandler(batchValue);
      }
      /* ========== assign lot ends ============== */

      /* =========== assign level starts =============== */
      if (editActivityData.levels) {
        let levelValue = editActivityData.levels.map(levelItems => {
          let levelData = {
            label: levelItems,
            value: levelItems,
          };
          return levelData;
        });
        levelSelectionHandler(levelValue);
      }
      /* =========== assign level ends =============== */

      /* =============== assign educators starts ============= */
      if (editActivityData.educators) {
        let educatorValue = editActivityData.educators.map(educator => {
          let educatorData = {
            label: educator.name + ' ' + educator.surname,
            value: educator._id,
          };
          return educatorData;
        });
        educatorSelectionHandler(educatorValue);
      }
      /* =============== assign educators ends ============= */

      /*============== assign specific audience starts =============== */
      if (editActivityData.specialaudience) {
        let audienceData = {
          label: editActivityData.specialaudience,
          value: editActivityData.specialaudience,
        };
        audienceSelectionHandler(audienceData);
      }
      /*============== assign specific audience ends =============== */

      /* ============= assign limits per activities start ================= */
      editActivityData?.activitypercourse &&
        setActivityNumberPerCourse(editActivityData.activitypercourse);
      /* ============= assign limits per activities ends ================= */

      /* =========== assign combine order starts ================= */
      editActivityData?.combinedorder &&
        setCombinedOrder(editActivityData.combinedorder);
      /* =========== assign combine order ends ================= */

      /* =======assign min number of participants starts ============*/
      editActivityData?.minparticipantspergroup &&
        setMinParticipants(editActivityData.minparticipantspergroup);
      /* =======assign min number of participants ends ============*/

      /* =========== assign max number of participants starts ============== */
      editActivityData?.maxparticipantspergroup &&
        setMaxParticipants(editActivityData.maxparticipantspergroup);
      /* =========== assign max number of participants ends ============== */

      /* ========== assign max group starts ================== */
      editActivityData?.maxgroup && setGroups(editActivityData.maxgroup);
      /* ========== assign max group ends ================== */

      /* ================= assign responsible ratio starts ================= */
      editActivityData?.ratiopergroup &&
        setGroupRatio(editActivityData.ratiopergroup);
      /* ================= assign responsible ratio ends ================= */

      /* ================ assign material kit starts =================== */
      if (editActivityData.materialkits) {
        let kitValue = editActivityData.materialkits.map(kit => {
          let kitData = {
            label: kit.name,
            value: kit._id,
          };
          return kitData;
        });
        materialSelectionHandler(kitValue);
      }
      /* ================ assign material kit ends =================== */

      /* ==================== assign observation starts ========================= */
      editActivityData?.observation &&
        setObservations(editActivityData.observation);
      /* ==================== assign observation ends ========================= */

      /* ===================== assign start date starts ======================== */
      editActivityData?.startdate && setStartDate(editActivityData.startdate);
      /* ===================== assign start date ends ======================== */

      /* ================== assign end date starts ======================== */
      editActivityData?.enddate && setEndDate(editActivityData.enddate);
      /* ================== assign end date ends ======================== */

      /* ========== assign code start ================ */
      editActivityData?.cost && setActivityCost(editActivityData.cost);
      /* ========== assign code end ================ */

      /* ============ assign status starts ================== */
      if (editActivityData.status == '1') {
        let statusData = {
          label: 'Enable',
          value: '1',
        };
        statusSelectionHandler(statusData); //set status value
      } else if (editActivityData.status == '0') {
        let statusData = {
          label: 'Disable',
          value: '0',
        };
        statusSelectionHandler(statusData); //set status value
      } else {
        let statusData = {
          label: 'Restrict Access',
          value: '2',
        };
        statusSelectionHandler(statusData); //set status value
      }
      /* ============ assign status ends ================== */

      /* =============== assign beneficairy uploads starts ================= */
      if (editActivityData.beneficiaryuploads) {
        setUploadedBeneficiaryFiles(editActivityData.beneficiaryuploads);
        const editedFilesIds = editActivityData.beneficiaryuploads.map(
          ({ _id, ...other }) => ({ _id })
        );
        setBeneficiaryFileUploadIds(editedFilesIds);
      }
      /* =============== assign uploads ends ================= */

      /* =============== assign uploads starts ================= */
      if (editActivityData.uploads) {
        setUploadedEducatorFiles(editActivityData.uploads);
        const editedFilesIds = editActivityData.uploads.map(
          ({ _id, ...other }) => ({ _id })
        );
        setEducatorFileUploadIds(editedFilesIds);
      }
      /* =============== assign uploads ends ================= */
    }
  }, [editActivityData]);

  useEffect(() => {
    if (activityFormReset) {
      resetHandler();
      setActivityFormReset(false);
    }
  }, [activityFormReset]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="saveActivityPopup"
      aria-labelledby="saveReservationPopupLabel"
    >
      <div className="offcanvas-header position-relative align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t('Activity Save Record')}</span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body position-relative p-4 pt-0">
        <form
          onSubmit={
            editActivityData ? editActivityHandler : saveActivityHandler
          }
        >
          {/* select courses start */}
          <div className="form-group mb-4">
            <label>{t('Course')}</label>

            <Select
              className="nosearch_select"
              options={courseList}
              value={selectCourses}
              onChange={val => {
                courseSelectionHandler(val),
                  setValidation(prevState => ({
                    ...prevState,
                    courseWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* course warning */}
            {validation.courseWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter course')}!</span>
                </p>
              </div>
            )}
          </div>
          {/* select courses end */}

          {/* description start */}
          <div className="form-group mb-4">
            <label>{t('Name')}</label>
            <input
              type="text"
              value={name}
              onChange={e => {
                setName(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    nameWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t('Enter Here')}
            />
            {/* name warning */}
            {validation.nameWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter name')}!</span>
                </p>
              </div>
            )}
          </div>
          {/* description end */}

          {/* Select Code start */}
          <div className="form-group mb-4">
            <label>{t('Code')}</label>

            <input
              type="text"
              value={code}
              onChange={e => {
                setCode(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    codeWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t('Enter Here')}
            />
            {/* code warning */}
            {validation.codeWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter code')}!</span>
                </p>
              </div>
            )}
          </div>

          {/* Select Code end */}

          {/* Type of Activity Start */}
          <div className="form-group mb-4">
            <label>{t('Type of Activity')}</label>
            <Select
              className="nosearch_select"
              options={typeOfActivity}
              value={selectTypeOfActivity}
              onChange={val => {
                typeActivitySelectionHandler(val),
                  setValidation(prevState => ({
                    ...prevState,
                    activitytypeWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* Type of activity warning */}
            {/* {validation.activitytypeWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter type of Activity')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* Type of Activity end */}

          {/* ========== Duration start ========== */}
          <div className="form-group mb-4">
            <label>{t('Duration in Minutes')}</label>
            <input
              type="number"
              value={duration}
              onChange={e => {
                setDuration(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    durationWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t('Enter')}
            />
            {/* duration warning */}
            {validation.durationWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter duration in minutes')}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ========== Duration end ========== */}

          {/* =========== Preparation Time start ============ */}
          <div className="form-group mb-4">
            <label>{t('Preparation Time')}</label>
            <input
              type="text"
              value={preparationTime}
              onChange={e => {
                setPreparationTime(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    preparationTimeWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t('Enter')}
            />
            {/* preparationtime warning */}
            {/* {validation.combinedOrderWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter combined Order')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =========== preparation time end ============ */}

          {/* =========== Mounting time start ============ */}
          <div className="form-group mb-4">
            <label>{t('Mounting Time')}</label>
            <input
              type="text"
              value={mountingTime}
              onChange={e => {
                setMountingTime(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    mountingTimeWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t('Enter')}
            />
            {/* preparationtime warning */}
            {/* {validation.combinedOrderWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter combined Order')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =========== Mounting time end ============ */}

          {/* =========== Dismantle time start ============ */}
          <div className="form-group mb-4">
            <label>{t('Dismantle Time')}</label>
            <input
              type="text"
              value={dismantleTime}
              onChange={e => {
                setDismantleTime(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    dismantleTimeWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t('Enter')}
            />
            {/* preparationtime warning */}
            {/* {validation.combinedOrderWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter combined Order')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =========== Dismantle time end ============ */}

          {/* =========== Select scope start =========== */}
          <div className="form-group mb-4">
            <label htmlFor="scope">{t('Scope')}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={scopeOption}
              value={selectedScope}
              onChange={val => {
                scopeSelectionHandler(val),
                  setValidation(prevState => ({
                    ...prevState,
                    scopeWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* Scope warning */}
            {/* {console.log(`scopeWarning========>>>`, validation.scopeWarning)} */}
            {/* {validation.scopeWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter Scope')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =========== Select scope end =========== */}

          {/* ========== Select Coordinating Entity starts ===========  */}
          <div className="form-group mb-4">
            <label htmlFor="entity">{t('Coordinating Entity')}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={CoordinatingEntity}
              value={selectedEntity}
              onChange={val => {
                entitySelectionHandler(val),
                  setValidation(prevState => ({
                    ...prevState,
                    entityWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* coordinating Entity warning */}

            {/* {validation.entityWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter coordinating Entity')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ========== Select Coordinating Entity ends ===========  */}

          {/* ========== select lot starts ========= */}
          <div className="form-group mb-4">
            <label htmlFor="lot">{t('Lot')}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={batchLotOption}
              value={selectedLot}
              onChange={val => {
                lotSelectionHandler(val),
                  setValidation(prevState => ({
                    ...prevState,
                    lotWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* lot warning */}
            {/* {validation.lotWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter Lot')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ========== select lot ends ========= */}

          {/* select Level starts */}
          <div className="form-group mb-4">
            <label htmlFor="level">{t('Level')}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={levelOption}
              value={selectedLevel}
              onChange={val => {
                levelSelectionHandler(val),
                  setValidation(prevState => ({
                    ...prevState,
                    levelWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* level warning */}
            {/* {validation.levelWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter Level')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* select Level ends */}

          {/* select educators starts */}
          <div className="form-group mb-4">
            <label htmlFor="user">{t('Educators')}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={educatorList}
              value={selectedEducators}
              onChange={val => {
                educatorSelectionHandler(val),
                  setValidation(prevState => ({
                    ...prevState,
                    educatorsWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* educator warning */}
            {/* {validation.educatorsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter Educators')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* select educators ends */}

          {/* =========== select Specific Audience starts =========== */}
          <div className="form-group mb-4">
            <label htmlFor="audience">{t('Specific Audience')}</label>
            <Select
              className="nosearch_select"
              options={specificAudience}
              value={selectedAudience}
              onChange={val => {
                audienceSelectionHandler(val),
                  setValidation(prevState => ({
                    ...prevState,
                    audienceWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* Specific Audience warning */}
            {/* {validation.audienceWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter Specific Audience')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =========== select Specific Audience ends =========== */}

          {/* =============  Limit Number of Activities per Course (Leave blank for an
              unlimited session) starts========  */}
          <div className="form-group mb-4">
            <label>
              {t(
                'Limit Number of Activities per Course (Leave blank for an unlimited session)'
              )}
            </label>
            <input
              type="number"
              value={activityNumberPerCourse}
              onChange={e => {
                setActivityNumberPerCourse(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    activitiesPerCourseWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t('Enter')}
            />
            {/* Number of Activities per Course warning */}
            {/* {validation.activitiesPerCourseWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter Activities per Course')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =============  Limit Number of Activities per Course (Leave blank for an
              unlimited session) ends========  */}

          {/* =========== Combined Order starts ============ */}
          <div className="form-group mb-4">
            <label>{t('Combined Order')}</label>
            <input
              type="number"
              value={combinedOrder}
              onChange={e => {
                setCombinedOrder(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    combinedOrderWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t('Enter')}
            />
            {/* Combined Order warning */}
            {/* {validation.combinedOrderWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter combined Order')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =========== Combined Order ends ============ */}

          {/* =============== Minimum Number of Participants per Group starts======= */}
          <div className="form-group mb-4">
            <label>{t('Minimum Number of Participants per Group')}</label>
            <input
              type="number"
              value={minParticipants}
              onChange={e => {
                setMinParticipants(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    minParticipantsWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t('Enter')}
            />
            {/* Minimum Number of Participants warning */}
            {/* {validation.minParticipantsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>
                    {t('Please enter minimum Number of Participants per Group')}
                    !
                  </span>
                </p>
              </div>
            )} */}
          </div>
          {/* =============== Minimum Number of Participants per Group ends======= */}

          {/* ========= Maximum Number of Participants per Group starts ========== */}
          <div className="form-group mb-4">
            <label>{t('Maximum Number of Participants per Group')}</label>
            <input
              type="number"
              value={maxParticipants}
              onChange={e => {
                setMaxParticipants(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    maxParticipantsWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t('Enter')}
            />
            {/* Maximum Number of Participants warning */}
            {/* {validation.maxParticipantsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>
                    {t('Please enter maximum Number of Participants per Group')}
                    !
                  </span>
                </p>
              </div>
            )} */}
          </div>
          {/* ========= Maximum Number of Participants per Group ends ========== */}

          {/* ============= Maximun Number of Group starts============ */}
          <div className="form-group mb-4">
            <label>{t('Maximum Number of Group')}</label>
            <input
              type="number"
              value={groups}
              onChange={e => {
                setGroups(e.target.value),
                  setGroups(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    maxGroupWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t('Enter')}
            />
            {/* Maximun Number of Group warning */}
            {/* {validation.maxGroupWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter maximun Number of Group')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ============= Maximun Number of Group ends========= */}

          {/* ========== Responsible Ratio per Group starts ============*/}
          <div className="form-group mb-4">
            <label>{t('Responsible Ratio per Group')}</label>
            <input
              type="number"
              value={groupRatio}
              onChange={e => {
                setGroupRatio(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    ratioWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t('Enter')}
            />
            {/* Responsible Ratio per Group warning */}
            {/* {validation.ratioWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter responsible Ratio per Group')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ========== Responsible Ratio per Group ends ============*/}

          {/* ========= Select material kit starts */}
          <div className="form-group mb-4">
            <label htmlFor="kit">{t('Material kit')}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={materialKitList}
              value={selectedMaterialKit}
              onChange={val => {
                materialSelectionHandler(val),
                  setValidation(prevState => ({
                    ...prevState,
                    materialKitWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            />
            {/* Material kit warning */}
            {/* {validation.materialKitWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter material kit')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ========= Select material kit ends */}

          {/* =========== observation starts  ==============*/}
          <div className="form-group mb-4">
            <label htmlFor="observations">{t('Observations')}</label>
            <textarea
              name="observations"
              id="observations"
              cols="30"
              rows="4"
              className="form-control"
              placeholder={t('Enter Comments')}
              value={observations}
              onChange={e => {
                setObservations(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    observationsWarning: false,
                  }));
                //setWarningIndex(1);
              }}
            ></textarea>
            {/* Observations warning */}
            {/* {validation.observationsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter observations')}!</span>
                </p>
              </div>
            )} */}
          </div>

          {/* =========== observation ends  ==============*/}

          {/* date section starts */}
          <div className="form-group mb-4">
            <div className="row">
              <div className="col-lg-6">
                <label htmlFor="date">{t('Start Date')}</label>
                <input
                  type="date"
                  id="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  value={startDate}
                  onChange={e => {
                    setStartDate(e.target.value),
                      setValidation(prevState => ({
                        ...prevState,
                        dateCompareWarning: false,
                      }));
                  }}
                />
                {/*  date compare warning */}
                {validation.dateCompareWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>
                        {t('End date should be greater than start date')}!
                      </span>
                    </p>
                  </div>
                )}
              </div>

              <div className="col-lg-6">
                <label htmlFor="date">{t('End Date')}</label>
                <input
                  type="date"
                  id="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  value={endDate}
                  onChange={e => {
                    setEndDate(e.target.value),
                      setValidation(prevState => ({
                        ...prevState,
                        dateCompareWarning: false,
                      }));
                  }}
                />
              </div>
            </div>
          </div>
          {/* date section ends */}

          {/* ======= zipcode section start ======= */}
          <div className="form-group mb-4">
            <label>{t('Cost')}</label>
            <input
              type="number"
              value={activityCost}
              onChange={e => {
                setActivityCost(e.target.value),
                  setValidation(prevState => ({
                    ...prevState,
                    zipCodeWarning: false,
                  }));
                //setWarningIndex(1);
              }}
              className="form-control"
              placeholder={t('Enter zipcode')}
            />

            {/* zipcode warning */}
            {validation.zipCodeWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter zipcode')}!</span>
                </p>
              </div>
            )}
          </div>

          {/* ======= zipcode section end ======= */}

          {/* ========= select status start ======== */}
          <div className="form-group mb-4">
            <label>{t('Status')}</label>
            <Select
              className="nosearch_select"
              options={StatusOptions}
              value={selectedStatus}
              onChange={val => {
                {
                  statusSelectionHandler(val),
                    setValidation(prevState => ({
                      ...prevState,
                      statusWarning: false,
                    }));
                  //setWarningIndex(1);
                }
              }}
            />
            {/* Status warning */}
            {validation.statusWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter status')}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ========= select status end ======== */}

          {/* ========= Educator file upload start ========== */}
          <div className="form-group mb-4">
            {isEducatorFileUploading ? (
              <div className="d-flex align-items-center gap-1 mb-1">
                <p className="fw-bold">{t('Please wait while uploading')}</p>{' '}
                {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                  return (
                    <div
                      key={index}
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      style={{ height: '6px', width: '6px' }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <label>{t('Educator Uploads')}</label>
            )}

            <input
              type="file"
              className="form-control"
              id="uploadedEducatorActivityFile"
              multiple
              onChange={educatorFileUploadHandler}
            />

            {uploadedEducatorFiles.length > 0 && (
              <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                {uploadedEducatorFiles.map((file, index) => {
                  return (
                    <li
                      className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                      key={index}
                    >
                      <Link to="#" onClick={() => fileDownloadHandler(file)}>
                        <span className="d-block material-symbols-outlined">
                          download
                        </span>
                      </Link>
                      <p className="fw-medium">{file.name}</p>
                      <Link
                        to="#"
                        onClick={() => closeEducatorFileHandler(index)}
                      >
                        <span className="d-block material-symbols-outlined">
                          close
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          {/* ========= Educator file upload end ===========*/}

          {/* ========= Beificiary file upload start ========== */}
          <div className="form-group mb-4">
            {isBeneficiaryFileUploading ? (
              <div className="d-flex align-items-center gap-1 mb-1">
                <p className="fw-bold">{t('Please wait while uploading')}</p>{' '}
                {[1, 2, 3, 4, 5, 6, 7, 8].map((count, index) => {
                  return (
                    <div
                      key={index}
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      style={{ height: '6px', width: '6px' }}
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <label>{t('Beificiary Uploads')}</label>
            )}

            <input
              type="file"
              className="form-control"
              id="uploadedBeneficiaryActivityFile"
              multiple
              onChange={beneficiaryFileUploadHandler}
            />

            {uploadedBeneficiaryFiles.length > 0 && (
              <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
                {uploadedBeneficiaryFiles.map((file, index) => {
                  return (
                    <li
                      className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                      key={index}
                    >
                      <Link to="#" onClick={() => fileDownloadHandler(file)}>
                        <span className="d-block material-symbols-outlined">
                          download
                        </span>
                      </Link>
                      <p className="fw-medium">{file.name}</p>
                      <Link
                        to="#"
                        onClick={() => closeBeneficiaryFileHandler(index)}
                      >
                        <span className="d-block material-symbols-outlined">
                          close
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          {/* ========= Beificiary file upload end ===========*/}

          {/* ============= save button start =============== */}
          <div className="action d-flex align-items-center gap-2">
            <button type="submit" className="btn btn-primary">
              {editActivityData ? t('Update') : t('Save')}
            </button>
          </div>
          {/* ============= save button end =============== */}
        </form>
      </div>
    </div>
  );
};

export default ActivitySaveRecord;
