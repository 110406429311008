/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import Select from 'react-select';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/*import url and gateway methods */
import { putData, deleteData, getData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import context
import { GlobalProvider } from 'context/GlobalContext';

//import option for availibility
import availibilityOptions from 'data/Prod/CalendarAvailability.json';

const EditMaterialSchedulePopup = () => {
  const token = localStorage.getItem('token'); // token

  /* ========== get user id from url params ========= */
  const params = useParams();

  const { t } = useTranslation(); //for translation

  const {
    setReloadScheduleMaterial,
    editScheduleMaterialData,
    setEditScheduleMaterialData,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  /* useState hook for selected Availability */
  const [availabilityValue, setAvailabilityValue] = useState(null);
  const [availabilityData, setAvailabilityData] = useState(null);

  /* useState hook for input fields */
  const [scheduleInputs, setScheduleInputs] = useState({
    materialkit: params.id,
    name: '',
    date: '',
    enddate: '',
    materialkitcount: 0,
  });

  const [isEditing, setIsEditing] = useState({
    startTimeEdit: false,
    endTimeEdit: false,
  });

  //function for Availability selection
  const availabilitySelectionHandler = val => {
    setAvailabilityValue(val);
    setAvailabilityData(val.value);
  };

  /* function to edit calender event */
  const editScheduleHandler = async e => {
    e.preventDefault();

    let scheduleData = {
      materialkit: params.id,
      name: scheduleInputs.name,
      date: scheduleInputs.date,
      enddate: scheduleInputs.enddate,
      availability: availabilityData,
      materialkitcount: scheduleInputs.materialkitcount,
    };

    console.log(`Schedule Data===========`, scheduleData);

    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_UPDATE_SCHEDULE_MATERIALKIT +
        `/${editScheduleMaterialData.id}` +
        `?token=${token}`;

      console.log('Request url of edit schedule =========>', requestUrl);

      const response = await putData(requestUrl, scheduleData);

      console.log(`response updating schedule data ========>>`, response);

      if (response.status) {
        resetHandler();
        setReloadScheduleMaterial(true);
        setSuccess(true);
        setNotificationString('Calendar record edited successfully');
      }
    } catch (error) {
      console.error(error.message);
      setError(true);
      setNotificationString('Something went wrong, please try again later');
    }
    bootstrap.Offcanvas.getInstance(
      document.querySelector('#editMaterialSchedulePopup')
    ).hide();
  };

  //function for delete schedule
  const deleteScheduleHandler = async e => {
    e.preventDefault();

    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_DELETE_SCHEDULE_MATERIALKIT +
        `/${editScheduleMaterialData.id}` +
        `?token=${token}`;

      console.log('Request url of delete schedule =========>', requestUrl);

      const response = await deleteData(requestUrl);

      console.log(`response deleting schedule data ========>>`, response);

      if (response.status) {
        resetHandler();
        bootstrap.Offcanvas.getInstance(
          document.querySelector('#editMaterialSchedulePopup')
        ).hide();
        setReloadScheduleMaterial(true);
      }
    } catch (error) {
      console.log('Error in delete schedule=========>', error.message);
    }
  };

  /* reset handler */
  const resetHandler = () => {
    setScheduleInputs({
      name: '',
      date: '',
      materialkitcount: 0,
    });
    setAvailabilityData(null);
    setAvailabilityValue(null);
    setEditScheduleMaterialData(null);
  };

  /* Hook to assign edit data */
  useEffect(() => {
    console.log(
      'Edit material calender data=============>',
      editScheduleMaterialData
    );

    if (editScheduleMaterialData) {
      setScheduleInputs(prevState => ({
        ...prevState,
        name: editScheduleMaterialData.title || '',
        date: editScheduleMaterialData.date || '',
        materialkitcount: editScheduleMaterialData.materialkitcount || 0,
      }));

      /*--------- assign availability start -----------*/
      if (editScheduleMaterialData.availability) {
        if (editScheduleMaterialData.availability == '1') {
          let availabilitySelectData = {
            label: 'available',
            value: '1',
          };
          availabilitySelectionHandler(availabilitySelectData);
        } else if (editScheduleMaterialData.availability == '0') {
          let availabilitySelectData = {
            label: 'reserved',
            value: '0',
          };
          availabilitySelectionHandler(availabilitySelectData);
        } else {
          let availabilitySelectData = {
            label: 'holiday',
            value: '2',
          };
          availabilitySelectionHandler(availabilitySelectData);
        }
      }
      /*--------- assign availability end -----------*/
    }
  }, [editScheduleMaterialData]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      id="editMaterialSchedulePopup"
    >
      <div className="offcanvas-header align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t('Update Calendar Record')}</span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <div className="calendar_data_item">
          <div className="form-group mb-4">
            <label>{t('Title')} *</label>
            <input
              type="text"
              className="form-control"
              placeholder={t('Title')}
              value={scheduleInputs.name}
              onChange={val => {
                setScheduleInputs(prevState => ({
                  ...prevState,
                  name: val.target.value,
                }));
                // setValidation((prevState) => ({
                //   ...prevState,
                //   labelWarning: false,
                // }));
              }}
            />
            {/* Responsible name warning */}
            {/* {validation.responsileNameWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t('Please enter responsible name')}!</span>
                  </p>
                </div>
              )} */}
          </div>

          {/* ========== date/time section start ============ */}
          <div className="form-group mb-4">
            <div className="row">
              {/* ----- date section start ----- */}
              <div className="col-lg-4">
                <label htmlFor="date">{t('Date')} *</label>
                <input
                  type="date"
                  id="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  value={scheduleInputs.date}
                  onChange={val => {
                    setScheduleInputs(prevState => ({
                      ...prevState,
                      date: val.target.value,
                    }));
                  }}
                />
                {/* start date warning */}
                {/* {validation.startDateWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter date')}!</span>
                      </p>
                    </div>
                  )} */}
                {/* start date warning */}
                {/* {validation.startDateWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter start date')}!</span>
                      </p>
                    </div>
                  )} */}
              </div>
              {/* ----- date section end ----- */}

              {/* ------ Availability section start ----- */}
              <div className="col-lg-4">
                <label htmlFor="endTime">{t('Set record as')} *</label>
                <Select
                  options={availibilityOptions}
                  value={availabilityValue}
                  onChange={val => {
                    availabilitySelectionHandler(val);
                  }}
                />
              </div>
              {/* ------ Availability section end ----- */}

              {/* ------- kit count section start ----- */}
              <div className="col-lg-4">
                <label htmlFor="Kitcount">
                  {t('Number of kit available')} *
                </label>
                <input
                  type="number"
                  className="form-control bg-white"
                  value={scheduleInputs.materialkitcount}
                  onChange={val => {
                    setScheduleInputs(prevState => ({
                      ...prevState,
                      materialkitcount: val.target.value,
                    }));
                  }}
                />
              </div>
              {/* ------- kit count section end ----- */}
            </div>
          </div>
        </div>

        <div className="action d-flex align-items-center gap-2">
          <button className="btn btn-primary" onClick={editScheduleHandler}>
            {t('Update')}
          </button>

          <button className="btn btn-danger" onClick={deleteScheduleHandler}>
            {t('Delete')}
          </button>

          {/* <p className="warring">
              <i className="material-icons-outlined">warning</i>
              <span>{t('Fill up all fields. Please Try again!')}</span>
            </p> */}
        </div>
      </div>
    </div>
  );
};

export default EditMaterialSchedulePopup;
