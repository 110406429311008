/* eslint-disable */
import { useEffect, useState, useContext } from "react";

// kendo react modules ==========================
import "@progress/kendo-theme-material/dist/all.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

/* importing all survey data */
// import surveyData from 'data/AllSurveyData.json';

//import translation function for language translation
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import api requiremnts
import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";

const AllTotalReserveSurveyPopup = () => {
  const token = localStorage.getItem("token");
  const { t } = useTranslation(); //for translation
  // without pagination ------------------------------------
  const initialDataState = {};

  const { customReserveId, setCustomReserveId, setReserveSurveyData } =
    useContext(GlobalProvider);

  const [surveyList, setSurveyList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(process(surveyList, initialDataState));

  const onDataStateChange = (event) => {
    console.log("event -->", event);
    setDataState(event.dataState);
    setResult(process(surveyList, event.dataState));
  };

  //function for get all survey list
  const getAllSurveyList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_SURVEY_FEEDBACK +
        `?token=${token}&customreserveid=${customReserveId}`;

      console.log("Url of total survey========>", requestUrl);

      const response = await getData(requestUrl);

      console.log("Response in survey list=======>", response);

      if (response.status) {
        setSurveyList(response.data);
        setResponseReceived(true);
        setResult(process(response.data, initialDataState));
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  /* custom action HTML */
  const ActionHTML = ({ dataItem }) => {
    return (
      <td className="action">
        <Link
          to="#view_reserve_survey_feedback_popup"
          data-bs-toggle="offcanvas"
          role="button"
          aria-controls="viewFeedbackPopup"
          className="btn btn-outline-primary btn-sm mw-unset"
          onClick={() => {
            setReserveSurveyData(dataItem);
          }}
        >
          Details
        </Link>
      </td>
    );
  };

  //surve response HTML
  const surveyResponseHTML = ({ dataItem }) => {
    return (
      <td className="action">
        <p>{dataItem.surveyresponsestatus == "1" ? "Submitted" : "Pending"}</p>
      </td>
    );
  };

  //function for close button
  const resetHandler = () => {
    setCustomReserveId(null);
  };

  useEffect(() => {
    if (customReserveId) {
      getAllSurveyList();
    }
  }, [customReserveId, responseReceived]);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="allTotalReserveSurveyPopup"
      aria-labelledby="allReserveSurveyPopupLabel"
    >
      <div className="offcanvas-header p-4">
        <h3 className="text-secondary mb-0" id="allSurveyPopupLabel">
          {t("All Survey")}
        </h3>
        <button
          type="button"
          id="closeCreateMail"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4 pt-0">
        <div className="table-responsive">
          <Grid
            style={{ minWidth: "100%" }}
            data={result}
            // filterable={true}
            sortable={true}
            // groupable={true}
            onDataStateChange={onDataStateChange}
            {...dataState}
            //pageable = {true}
            //total = {products.length}
            reorderable
            // resizable
          >
            {/* <GridColumn field="no" title={t("No")} width="150px" /> */}
            <GridColumn field="surveydate" title={t("Date")} />
            <GridColumn field="survey.name" title={t("Survey")} />
            <GridColumn
              field="surveyresponsestatus"
              title={t("Status")}
              cell={surveyResponseHTML}
            />
            <GridColumn field="user" title={t("User")} />
            <GridColumn field="Action" title={t("Action")} cell={ActionHTML} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default AllTotalReserveSurveyPopup;
