import React from 'react';
import { Link } from 'react-router-dom';
//import translation function for language translation
import { useTranslation } from 'react-i18next';
const PrivacyTab = () => {
  const { t } = useTranslation(); //for translation
  return (
    <div className="profile_tabInnr">
      <div className="breadcum_area">
        <nav aria-label="breadcrumb">
          <ul className="breadcrumb list_stye_none">
            <li className="breadcrumb-item">
              <Link to="/" className="text-secondary">
                {t('Dashboard')}
              </Link>
            </li>
            <li className="breadcrumb-item text-gray-600" aria-current="page">
              {t('Privacy')}
            </li>
          </ul>
        </nav>
      </div>
      <div className="profile_graybx_Outer">
        <div className="profile_graybx_item">
          <div className="interest_hdng">
            <h4>{t('Privacy')}</h4>
          </div>
          <div className="profile_fillOuter wd_360">
            <div className="profile_fill_row">
              <label>{t('Who can see what you are looking for')}</label>
              <div className="input_fill">
                <ul className="profile_chkList list_stye_none">
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo1" />
                      <span></span>
                      <p>{t('Nobody')}</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo1" />
                      <span></span>
                      <p>{t('My Contacts')}</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo1" />
                      <span></span>
                      <p>{t('Everyone')}</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="profile_fill_row">
              <label>{t('Who can see your mail')}</label>
              <div className="input_fill">
                <ul className="profile_chkList list_stye_none">
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo2" />
                      <span></span>
                      <p>{t('Nobody')}</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo2" />
                      <span></span>
                      <p>{t('My Contacts')}</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo2" />
                      <span></span>
                      <p>{t('Everyone')}</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="profile_fill_row">
              <label>{t('Who can see your phone')}</label>
              <div className="input_fill">
                <ul className="profile_chkList list_stye_none">
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>{t('Nobody')}</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>{t('My Contacts')}</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo3" />
                      <span></span>
                      <p>{t('Everyone')}</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="profile_fill_row">
              <label>{t('Who can see your contacts')}</label>
              <div className="input_fill">
                <ul className="profile_chkList list_stye_none">
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo4" />
                      <span></span>
                      <p>{t('Nobody')}</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo4" />
                      <span></span>
                      <p>{t('My Contacts')}</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo4" />
                      <span></span>
                      <p>{t('Everyone')}</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="profile_fill_row">
              <label>{t('Subscribe to commercial emails')}</label>
              <div className="input_fill">
                <ul className="profile_chkList list_stye_none">
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo5" />
                      <span></span>
                      <p>{t('Yes')}</p>
                    </label>
                  </li>
                  <li className="">
                    <label className="radio-check">
                      <input type="radio" name="rdo5" />
                      <span></span>
                      <p>{t('No')}</p>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="prfile_btnOuter">
            <div className="profile_rightBtn">
              <button className="btn btn-primary">{t('Save Settings')}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyTab;
