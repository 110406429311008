/* eslint-disable */
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';

import UserMenu from 'components/common/UserMenu';
import LeftMenu from 'components/common/LeftMenu';
// import UserManagementBreadCrumb from "components/UserManagementComponents/BreadCrumb/UserManagementBreadCrumb";
import UserManagementHeader from 'components/UserManagementComponents/UserManagementHeader/UserManagementHeader';
import Filter from 'components/UserManagementComponents/Filter/Filter';
import UserListArea from 'components/UserManagementComponents/UserListArea/UserListArea';
import UserFormPopup from 'components/UserManagementComponents/PopUp/UserFormPopup';
import ToggleColumn from 'components/UserManagementComponents/ToggleColumns/ToggleColumn';
import AddLabel from 'components/common/AddLabel';

//import translation function for language translation
import { useTranslation } from 'react-i18next';
import CustomNotification from 'components/common/CustomNotification';

const UserManagement = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);

  /* hook to get number of records */
  const [records, setRecords] = useState(0);

  const { t } = useTranslation(); //for translation

  /* hook to search */
  const [search, setSearch] = useState('');

  useEffect(() => {
    document.title = ' Usermanagement'; // Update the title when Register component mounts
  }, []);

  const blockRefSelected = useRef(null);

  const checkedRecordCount = selectCount => {
    console.log('selectCount', selectCount);
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };

  return (
    <>
      <UserMenu />
      <LeftMenu />
      <section className="mail-wrapper pt-2">
        <div className="container-fluid px-4">
          {/* <UserManagementBreadCrumb /> */}
          {/* Breadcrumb and record count display ----------------------------- */}
          <div className="filter-container d-flex flex-wrap align-items-center gap-2">
            <nav aria-label="breadcrumb">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="#" className="text-secondary">
                    {t('Dashboard')}
                  </Link>
                </li>
                <li
                  className="breadcrumb-item text-gray-600"
                  aria-current="page"
                >
                  {t('Users Management')}
                </li>
              </ul>
            </nav>
          </div>
          {/* Breadcrumb end here -------------------------------------- */}
          {/* -------------- user management header start --------------- */}
          <UserManagementHeader
            setDownloadExcel={setDownloadExcel}
            setSearch={setSearch}
            records={records}
            blockRefSelected={blockRefSelected}
          />
          {/* -------------- user management header end --------------- */}
          {/* ----------- toggle columns start -------------- */}
          <ToggleColumn />
          {/* ----------- toggle columns end -------------- */}
          {/* ---------------- filter start --------------- */}
          <Filter />
          {/* ---------------- filter end --------------- */}

          {/* --------------- user list area start ---------------- */}
          <UserListArea
            downloadExcel={downloadExcel}
            search={search}
            setRecords={setRecords}
            checkedRecordCount={checkedRecordCount}
          />
          {/* --------------- user list area end ---------------- */}
        </div>
      </section>
      {/* -------------- user form popup start ----------------- */}
      <UserFormPopup />
      {/* -------------- user form popup end ----------------- */}

      {/* -------------- addlabel start -------------------- */}
      <AddLabel module={'user'} />
      {/* -------------- addlabel end -------------------- */}
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default UserManagement;
