/* eslint-disable */
import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//import context
import { GlobalProvider } from "context/GlobalContext";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const ToggleColumn = () => {
  const { t } = useTranslation(); //for translation

  const {
    setShowSalesOrderInvoiceIdColumn,
    setShowSalesOrderClientColumn,
    setShowSalesOrderInvoiceDateColumn,
    setShowSalesOrderGroupedColumn,
    setShowSalesOrderDiscountColumn,
    setShowSalesOrderTotalAmountColumn,
    setShowSalesOrderCreatedDateColumn,
    setShowSalesOrderPayblePriceColumn,
  } = useContext(GlobalProvider);

  //use state for select field/columns
  const [selectedColumnValue, setSelectedColumnValue] = useState(null);
  const [columns, setColumns] = useState([]);

  //filed options for select
  const columnOption = [
    { label: t("Invoice Id"), value: "Invoice Id" },
    { label: t("Client"), value: "Client" },
    { label: t("Invoice Date"), value: "Invoice Date" },
    { label: t("Grouped"), value: "Grouped" },
    { label: t("Total Amount"), value: "Total Amount" },
    { label: t("Discount"), value: "Discount" },
    { label: t("Payble Amount"), value: "Payble Amount" },
    { label: t("Created Date"), value: "Created Date" },
  ];

  //function for select column by dropdown
  const fieldSelectionHandler = (val) => {
    console.log(`val`, val);
    setSelectedColumnValue(val);
    const columnValaues = val.map((column) => {
      return column.value;
    });
    setColumns(columnValaues);
  };

  //function for display selected columns
  const columnSelectionHandler = (e) => {
    e.preventDefault();

    if (columns.length == 0) {
      return;
    } else {
      setShowSalesOrderInvoiceIdColumn(false);
      setShowSalesOrderClientColumn(false);
      setShowSalesOrderInvoiceDateColumn(false);
      setShowSalesOrderGroupedColumn(false);
      setShowSalesOrderDiscountColumn(false);
      setShowSalesOrderTotalAmountColumn(false);
      setShowSalesOrderCreatedDateColumn(false);
      setShowSalesOrderPayblePriceColumn(false);

      columns.map((column) => {
        console.log(column);
        if (column == "Invoice Id") {
          setShowSalesOrderInvoiceIdColumn(true);
        }

        if (column == "Client") {
          setShowSalesOrderClientColumn(true);
        }

        if (column == "Invoice Date") {
          setShowSalesOrderInvoiceDateColumn(true);
        }

        if (column == "Grouped") {
          setShowSalesOrderGroupedColumn(true);
        }

        if (column == "Total Amount") {
          setShowSalesOrderTotalAmountColumn(true);
        }

        if (column == "Discount") {
          setShowSalesOrderDiscountColumn(true);
        }

        if (column == "Payble Amount") {
          setShowSalesOrderPayblePriceColumn(true);
        }

        if (column == "Created Date") {
          setShowSalesOrderCreatedDateColumn(true);
        }
      });
    }
  };

  //function for reset select
  const resetColumn = () => {
    setShowSalesOrderInvoiceIdColumn(true);
    setShowSalesOrderClientColumn(true);
    setShowSalesOrderInvoiceDateColumn(true);
    setShowSalesOrderGroupedColumn(true);
    setShowSalesOrderDiscountColumn(true);
    setShowSalesOrderTotalAmountColumn(true);
    setShowSalesOrderCreatedDateColumn(true);
    setSelectedColumnValue(null);
    setColumns([]);
  };

  return (
    <div className="collapse mt-3" id="salesorder_show_columnCollapse">
      <div className="p-3 bg-gray-200">
        <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
          <h4 className="mb-0">{t("Show Columns")}</h4>
          <Link
            to="#"
            onClick={resetColumn}
            className="d-flex gap-1 align-items-center text-black"
          >
            <span className="d-block material-symbols-outlined icon-md">
              sync
            </span>
            <span className="d-block fw-medium">{t("Refresh")}</span>
          </Link>
        </div>
        <form onSubmit={columnSelectionHandler}>
          <div className="form-container d-flex flex-wrap gap-2">
            <div className="form-group flex-fill">
              <Select
                className="nosearch_select"
                isMulti
                options={columnOption}
                value={selectedColumnValue}
                onChange={(val) => fieldSelectionHandler(val)}
              />
            </div>
            <div className="action">
              <button type="submit" className="btn btn-primary mw-unset">
                {t("Show")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ToggleColumn;
