/* eslint-disable */
import AcceptMemberShipThanksModal from "components/EventMemberShipComponents/Modal/AcceptMemberShipThanksModal";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

//import api requiremnts
import * as url from "urlhelpers/UrlHelper";
import { getData } from "utils/Gateway";

const EventMemberShipProcess = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const rid = searchParams.get("rid");
  const uid = searchParams.get("uid");
  const mstatus = searchParams.get("mstatus");

  //function for submit event membership
  const eventMembershipHandler = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_SAVE_EVENT_MEMBERSHIP +
        `?rid=${rid}&uid=${uid}&mstatus=${mstatus}`;

      console.log("Url of membership-------->", requestUrl);

      const response = await getData(requestUrl);

      console.log("Response in membership =======>", response);

      if (response.status) {
        let myModal = new bootstrap.Modal(
          document.getElementById("thanks_membership_modal")
        );
        myModal.show();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    eventMembershipHandler();
  }, []);

  return (
    <>
      <AcceptMemberShipThanksModal />
    </>
  );
};

export default EventMemberShipProcess;
