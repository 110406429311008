/* eslint-disable */
import React, { useState, createRef, useEffect, useContext } from 'react';

// kendo react modules ==========================
import '@progress/kendo-theme-material/dist/all.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
// impoting excel colums
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import api requiremnts
import * as url from 'urlhelpers/UrlHelper';
import { getData, postData } from 'utils/Gateway';

import { Link } from 'react-router-dom';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const SalesOrderList = ({ downloadExcel, checkedRecordCount, search }) => {
  const {
    setSalesOrderData,
    reloadSalesOrderList,
    setReloadSalesOrderList,
    setSalesOrderCount,
    showSalesOrderInvoiceIdColumn,
    showSalesOrderClientColumn,
    showSalesOrderInvoiceDateColumn,
    showSalesOrderGroupedColumn,
    showSalesOrderDiscountColumn,
    showSalesOrderTotalAmountColumn,
    showSalesOrderCreatedDateColumn,
    showSalesOrderPayblePriceColumn,
    // ----- search requriement -----
    searchSalesOrderDate,
    setSearchSalesOrderDate,
    searchSalesOrderDateType,
    setSearchSalesOrderDateType,
    searchSalesOrderClients,
    setSearchSalesOrderClients,
    searchSalesOrderGroupType,
    setsearchSalesOrderGroupType,
    searchSalesOrderDiscount,
    setSearchSalesOrderDiscount,
  } = useContext(GlobalProvider);

  const token = localStorage.getItem('token');

  const { t } = useTranslation(); //for translation

  const [salesOrderList, setSalesOrderList] = useState([]);
  const [responseReceived, setResponseReceived] = useState(false);

  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = useState();
  const [result, setResult] = useState(
    process(salesOrderList, initialDataState)
  );

  const onDataStateChange = event => {
    console.log('event -->', event);
    setDataState(event.dataState);
    setResult(process(salesOrderList, event.dataState));
  };
  /* Handle the button's onClick event and set it to export the data by using the ExcelExport save method. */
  const _exporter = createRef();
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = e => {
    document
      .querySelectorAll('input[name="selectSalesOrderRecord"]')
      .forEach(checkbox => {
        checkbox.checked = e.target.checked;
      });
    handelSelectedCount();
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = props => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectSalesOrderRecord"
            filtername="selectedrecord"
            onClick={handelSelectedCount}
          />
        </div>
      </td>
    );
  };

  // check the selected count and show the count in top and also selected download xls ------------------------
  const handelSelectedCount = () => {
    let checkboxes = document.querySelectorAll(
      'input[name="selectSalesOrderRecord"]'
    );

    let totalSelected = 0;
    let filteredData = [];
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        totalSelected++;
        const filteredUser = salesOrderList.find(
          user => user._id === checkbox.value
        );
        filteredUser && filteredData.push(filteredUser);
      }
    });

    // Update the content of the block
    checkedRecordCount(totalSelected);
    return filteredData;
  };

  //sales order id html
  const salesOrderBlockHtml = ({ dataItem }) => {
    return (
      <td>
        <div className="d-flex align-items-center">
          <p className="name mb-1">{dataItem.invoiceid}</p>
          <Link
            to="#viewsalesOrderPopup"
            data-bs-toggle="offcanvas"
            onClick={() => {
              setSalesOrderData(dataItem);
            }}
          >
            <span className="material-icons-outlined">chevron_right</span>
          </Link>
        </div>
      </td>
    );
  };

  //function for get all sales record
  const getAllSalesRecords = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl + url.API_GET_ALL_SALES_ORDER + `?token=${token}`;

      //search by date
      if (searchSalesOrderDate != '' && searchSalesOrderDateType != null) {
        requestUrl =
          requestUrl +
          `&salesdate=${searchSalesOrderDate}&salesdatetype=${searchSalesOrderDateType}`;
      }

      //search by client
      if (searchSalesOrderClients.length > 0) {
        requestUrl = requestUrl + `&salesclients=${searchSalesOrderClients}`;
      }

      //search by grouped
      if (searchSalesOrderGroupType != null) {
        requestUrl = requestUrl + `&salesgrouped=${searchSalesOrderGroupType}`;
      }

      //search by discount
      if (searchSalesOrderDiscount != 0) {
        requestUrl = requestUrl + `&salesdiscount=${searchSalesOrderDiscount}`;
      }

      console.log('url for sales order list------->', requestUrl);

      const response = await getData(requestUrl);

      console.log('response in sales order------->', response);

      if (response.status) {
        setSalesOrderList(response.data);
        setResponseReceived(true);
        dataState
          ? setResult(process(response.data, dataState))
          : setResult(process(response.data, initialDataState));
        setSalesOrderCount(response.data.length);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  /* function to search */
  const filterData = () => {
    if (!result || !result.data) {
      return []; // Return an empty array if result or result.data is undefined
    }

    const filteredData = result.data.filter(item => {
      const invoiceid = item?.invoiceid?.toString() || '';
      const clientfullname = item?.clientfullname?.toLowerCase() || '';
      const invoicedate = item?.invoicedate || '';
      const grouped = item?.grouped?.toLowerCase() || '';
      const totalactivitycost = item?.totalactivitycost?.toString() || '';
      const discountpercent = item?.discountpercent?.toString() || '';
      const totalpaybleprice = item?.totalpaybleprice?.toString() || '';
      const createddate = item?.createddate || '';

      return (
        (invoiceid && invoiceid.includes(search)) ||
        (clientfullname && clientfullname.includes(search.toLowerCase())) ||
        (invoicedate && invoicedate.includes(search)) ||
        (grouped && grouped.includes(search.toLowerCase())) ||
        (totalactivitycost && totalactivitycost.includes(search)) ||
        (discountpercent && discountpercent.includes(search)) ||
        (totalpaybleprice && totalpaybleprice.includes(search)) ||
        (createddate && createddate.includes(search))
      );
    });

    return filteredData;
  };

  //use effect for search
  useEffect(() => {
    if (
      (searchSalesOrderDate != '' && searchSalesOrderDateType != null) ||
      searchSalesOrderClients.length > 0 ||
      searchSalesOrderGroupType != null ||
      searchSalesOrderDiscount != 0
    ) {
      getAllSalesRecords();
    }
  }, [
    searchSalesOrderDate,
    searchSalesOrderDateType,
    searchSalesOrderClients,
    searchSalesOrderGroupType,
    searchSalesOrderDiscount,
  ]);

  useEffect(() => {
    if (responseReceived === false) {
      getAllSalesRecords();
    }
  }, [responseReceived]);

  useEffect(() => {
    if (reloadSalesOrderList) {
      getAllSalesRecords();
      // checkedRecordCount(0);
      setResult(process(salesOrderList, initialDataState));
      setReloadSalesOrderList(false);
    }
  }, [reloadSalesOrderList]);

  /* hook to hit  exportExcel() function*/
  useEffect(() => {
    downloadExcel && exportExcel();
  }, [downloadExcel]);

  /* hook to hit filterData function on search */
  useEffect(() => {
    filterData();
  }, [search]);

  return (
    <div className="table-responsive">
      {/* ============ download xls start ========== */}
      <ExcelExport
        data={
          handelSelectedCount().length > 0
            ? handelSelectedCount()
            : salesOrderList
        }
        fileName="Sales Order.xlsx"
        ref={_exporter}
      >
        <ExcelExportColumn field="invoiceid" title={t('Invoice Id')} />
        <ExcelExportColumn field="clientfullname" title={t('Client')} />
        <ExcelExportColumn field="invoicedate" title={t('Invoice Date')} />
        <ExcelExportColumn field="grouped" title={t('Grouped')} />
        <ExcelExportColumn
          field="totalactivitycost"
          title={t('Total Amount')}
        />
        <ExcelExportColumn
          field="discountpercent"
          title={t('Discount') + ` %`}
        />
        <ExcelExportColumn field="totalpaybleprice" title={t('Total Amount')} />
        <ExcelExportColumn field="createddate" title={t('Created Date')} />
      </ExcelExport>

      {/* ============ download xls end ========== */}

      {/* ================= grid view starts ====================== */}

      {search ? (
        <Grid
          style={{ minWidth: '100%' }}
          data={filterData()}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          {showSalesOrderInvoiceIdColumn && (
            <GridColumn
              field="invoiceid"
              title={t('Invoice Id')}
              cell={salesOrderBlockHtml}
            />
          )}
          {showSalesOrderClientColumn && (
            <GridColumn field="clientfullname" title={t('Client')} />
          )}
          {showSalesOrderInvoiceDateColumn && (
            <GridColumn field="invoicedate" title={t('Invoice Date')} />
          )}
          {showSalesOrderGroupedColumn && (
            <GridColumn field="grouped" title={t('Grouped')} />
          )}
          {showSalesOrderTotalAmountColumn && (
            <GridColumn field="totalactivitycost" title={t('Total Amount')} />
          )}
          {showSalesOrderDiscountColumn && (
            <GridColumn field="discountpercent" title={t('Discount') + ` %`} />
          )}
          {showSalesOrderPayblePriceColumn && (
            <GridColumn field="totalpaybleprice" title={t('Payble Amount')} />
          )}
          {showSalesOrderCreatedDateColumn && (
            <GridColumn field="createddate" title={t('Created Date')} />
          )}
        </Grid>
      ) : (
        <Grid
          style={{ minWidth: '100%' }}
          data={result}
          filterable={true}
          sortable={true}
          groupable={true}
          onDataStateChange={onDataStateChange}
          {...dataState}
          //pageable = {true}
          //total = {products.length}
          reorderable
          // resizable
        >
          <GridColumn
            field="id"
            headerCell={SelectRecordHeader}
            cell={SelectRecordRow}
            filterable={false}
            sortable={false}
            width="70px"
          />
          {showSalesOrderInvoiceIdColumn && (
            <GridColumn
              field="invoiceid"
              title={t('Invoice Id')}
              cell={salesOrderBlockHtml}
            />
          )}
          {showSalesOrderClientColumn && (
            <GridColumn field="clientfullname" title={t('Client')} />
          )}
          {showSalesOrderInvoiceDateColumn && (
            <GridColumn field="invoicedate" title={t('Invoice Date')} />
          )}
          {showSalesOrderGroupedColumn && (
            <GridColumn field="grouped" title={t('Grouped')} />
          )}
          {showSalesOrderTotalAmountColumn && (
            <GridColumn field="totalactivitycost" title={t('Total Amount')} />
          )}
          {showSalesOrderDiscountColumn && (
            <GridColumn field="discountpercent" title={t('Discount') + ` %`} />
          )}
          {showSalesOrderPayblePriceColumn && (
            <GridColumn field="totalpaybleprice" title={t('Payble Amount')} />
          )}
          {showSalesOrderCreatedDateColumn && (
            <GridColumn field="createddate" title={t('Created Date')} />
          )}
        </Grid>
      )}

      {/* ================= grid view ends ====================== */}
    </div>
  );
};

export default SalesOrderList;
