/* eslint-disable */
import { useState } from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { postData } from "../../../utils/Gateway";
import * as url from "../../../urlhelpers/UrlHelper";

//import translation function for language translation
import { useTranslation } from "react-i18next";

const LoginRightPanel = () => {
  const { t } = useTranslation(); //for translation

  /*----- UseState to send username and password for Login ----*/
  const [userEmail, setUserEmail] = useState("");
  const [emailWarning, setEmailWarning] = useState(false);
  const [notValidEMail, setNotValidEMail] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [passwordWarning, setPasswordWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [loginFailed, setLoginFailed] = useState(false);

  // function for change text type
  const toggleText = () => {
    setShowPassword(!showPassword);
  };

  const LogInHandler = async (e) => {
    e.preventDefault();
    switch (true) {
      case userEmail == "" || userPassword == "":
        userEmail == "" ? setEmailWarning(true) : setEmailWarning(false);
        userPassword == ""
          ? setPasswordWarning(true)
          : setPasswordWarning(false);
        break;
      default:
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (userEmail.match(mailformat)) {
          setNotValidEMail(false);
          setIsLoading(true);
          try {
            let userData = {
              email: userEmail,
              password: userPassword,
            };
            let requestURL = url.API_BaseUrl + url.API_LOGINURL;

            console.log("request url -->", requestURL);

            const response = await postData(requestURL, userData);

            console.log("response -->", response);

            if (response.status) {
              setLoginFailed(false);
              // assigning the loggedin user's id,token,all data to the browser's local storage.
              localStorage.setItem("token", response.token);
              localStorage.setItem("userInfo", JSON.stringify(response.data));
              localStorage.setItem("userID", JSON.stringify(response.data._id));
              resetLoginData();
              window.location.reload();
            } else {
              setLoginFailed(true);
            }
            setIsLoading(false);
          } catch (error) {
            console.error(error.message);
          }
        } else {
          setNotValidEMail(true);
        }
    }
  };

  // function for reset the useState containing the email and password text fields
  const resetLoginData = () => {
    setUserEmail("");
    setUserPassword("");
  };

  return (
    <div className="login_rightpnl">
      <form
        action=""
        id="login_form"
        className="login_sec"
        onSubmit={LogInHandler}
      >
        <div className="form-title mb-4">
          <h3>{t("Sign in")}</h3>
          <h6 className="mb-0">{t("Please enter details to proceed")}</h6>
        </div>

        {loginFailed && (
          <div className="error-message mb-4">
            <p className="d-flex align-items-center gap-1 text-danger">
              <span className="material-symbols-outlined">warning</span>
              <span>{t("Email and password are not matched")} !</span>
            </p>
          </div>
        )}

        {/* ------ email section start ------ */}
        <div className="form-group mb-4">
          <label htmlFor="email" className="text-gray-dark fw-semibold">
            {t("Email")}
          </label>
          <input
            type="email"
            id="email"
            className="form-control bg-white border-white rounded-3"
            placeholder={t("Enter Email")}
            value={userEmail}
            onChange={(e) => {
              setEmailWarning(false);
              setUserEmail(e.target.value);
            }}
            required
          />
          {/* ------ email warning start ------ */}
          {emailWarning && (
            <div className="error-message mt-2">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please enter email")}!</span>
              </p>
            </div>
          )}

          {/* ----- valid email warning ------ */}
          {notValidEMail && (
            <div className="error-message mt-2">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please enter valid email")}!</span>
              </p>
            </div>
          )}
          {/* ------ email warning end ------ */}
        </div>
        {/* ------ email section end ------ */}

        {/* ------ password section start ------ */}
        <div className="form-group mb-4">
          <label htmlFor="password" className="text-gray-dark fw-semibold">
            {t("Password")}
          </label>
          <div className="password-visibility-toggler position-relative">
            <input
              type={showPassword ? "password" : "text"}
              id="password"
              className="form-control bg-white border-white rounded-3"
              placeholder={t("Enter Password")}
              value={userPassword}
              onChange={(e) => {
                setPasswordWarning(false);
                setUserPassword(e.target.value);
              }}
              required
            />
            <Link
              to="#"
              className="password-visibility text-gray position-absolute top-50 end-0 translate-middle"
              onClick={toggleText}
            >
              <span className="d-block material-symbols-outlined">
                visibility
              </span>
            </Link>
          </div>

          {/* ------ password warning start ------ */}
          {passwordWarning && (
            <div className="error-message mt-2">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{t("Please enter password")}!</span>
              </p>
            </div>
          )}
          {/* ------ password warning end ------ */}
        </div>
        {/* ------ password section end ------ */}

        <div className="action d-flex flex-wrap align-items-center justify-content-between gap-3">
          {/* ------ login button section start ------ */}
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isLoading ? true : false}
            style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
          >
            {t("Log in")}
            {isLoading && (
              <div
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginRightPanel;
