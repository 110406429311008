/* eslint-disable */
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

/*import url and gateway methods */
import { postData, getData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const MailTemplateFilter = () => {
  const {
    setSearchMailTemplateDate,
    setSearchMailTemplateName,
    setSearchMailTemplateSubject,
    setSearchMailTemplateLabels,
    setReloadMailTemplateList,
  } = useContext(GlobalProvider);

  const { t } = useTranslation(); //for translation
  const todayValue = new Date().toISOString().split('T')[0];
  const token = localStorage.getItem('token'); // token

  const [selectedChecked, setSelectedChecked] = useState('');

  //date range
  const [fromDate, setFromDate] = useState(todayValue);
  const [toDate, setToDate] = useState(todayValue);

  //single date
  const [selectedDate, setSelectedDate] = useState(todayValue);

  const [labelList, setLabelList] = useState([]); // for label list

  const [templateName, setTemplateName] = useState('');
  const [templateSubject, setTemplateSubject] = useState('');

  const [labelValue, setLabelValue] = useState(null);
  const [searchedLabel, setSearchedLabel] = useState([]);

  //function for get all label list
  const getAllLabels = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_TAG +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setLabelList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for select radio
  const radioCheckHandler = event => {
    setSelectedChecked(event.target.value);
    setDateWarning(false);
  };

  //function for label selection
  const labelSectionHandler = val => {
    setLabelValue(val);
    const labelData = val.map(data => data.value);
    setSearchedLabel(labelData);
  };

  //function for reset
  const resetHandler = () => {
    setSelectedChecked('');
    setTemplateName('');
    setTemplateSubject('');
    setFromDate(todayValue);
    setToDate(todayValue);
    setSelectedDate(todayValue);
    setLabelValue(null);
    setSearchedLabel([]);
    setSearchMailTemplateDate('');
    setSearchMailTemplateName('');
    setSearchMailTemplateSubject('');
    setSearchMailTemplateLabels([]);
    setReloadMailTemplateList(true);
  };

  // Hook for date compare validation
  const [dateWarning, setDateWarning] = useState(false);

  //function for search
  const searchHandler = () => {
    //assign data for search
    /*===== switch statement for date input radio start ======*/
    switch (selectedChecked) {
      case 'daterange':
        if (fromDate < toDate) {
          setSearchMailTemplateDate(fromDate + ',' + toDate);
        } else {
          setDateWarning(true);
        }
        break;
      case 'todayradio':
        setSearchMailTemplateDate(todayValue);
        break;
      case 'eventdate':
        setSearchMailTemplateDate(selectedDate);
        break;
      default:
        setSearchMailTemplateDate('');
        break;
    }
    /*===== switch statement for date input radio end ======*/
    setSearchMailTemplateName(templateName);
    setSearchMailTemplateSubject(templateSubject);
    setSearchMailTemplateLabels(searchedLabel);
  };

  useEffect(() => {
    getAllLabels();
  }, []);

  return (
    <div className="collapse mt-3" id="filterCollapse">
      <div className="p-3 bg-gray-200">
        <h4 className="mb-3">{t('Filter')}</h4>
        <form onSubmit={e => e.preventDefault()}>
          <div className="form-group mb-3">
            {/* -------- all date row start -------- */}
            <div className="row align-items-center mb-3">
              {/* -------- date range section start -------- */}
              <div className="col-lg mb-3 mb-lg-0">
                <div className="d-flex align-items-center">
                  {/* -------- date input radio section start -------- */}
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="daterange"
                      id="dateRange"
                      value="daterange"
                      checked={selectedChecked === 'daterange'}
                      onChange={radioCheckHandler}
                    />
                  </div>
                  {/* -------- date input radio section end -------- */}

                  {/* -------- date selection section start -------- */}
                  <div className="field-container d-sm-flex border flex-fill">
                    {/* ------- from date input start ------- */}
                    <input
                      type="date"
                      id="from_date"
                      className="form-control bg-white border-0 mb-3 mb-sm-0"
                      placeholder={t('From date')}
                      value={fromDate}
                      onChange={e => {
                        setFromDate(e.target.value), setDateWarning(false);
                      }}
                    />
                    {/* ------- from date input end ------- */}

                    {/* ------- to date input start ------- */}
                    <input
                      type="date"
                      id="to_date"
                      className="form-control bg-white border-0"
                      placeholder={t('To date')}
                      value={toDate}
                      onChange={e => {
                        setToDate(e.target.value), setDateWarning(false);
                      }}
                    />
                    {/* ------- to date input end ------- */}
                  </div>
                  {/* -------- date selection section end -------- */}
                </div>
                {/* date compare warning */}
                {dateWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>
                        {t('End date should be greater than start date')}!
                      </span>
                    </p>
                  </div>
                )}
              </div>
              {/* -------- date range section end -------- */}

              {/* --------- today section start --------- */}
              <div className="col-lg-auto mb-3 mb-lg-0">
                <div className="form-check mb-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="todayradio"
                    id="today"
                    value="todayradio"
                    checked={selectedChecked === 'todayradio'}
                    onChange={radioCheckHandler}
                  />
                  <label className="form-check-label mb-0" htmlFor="today">
                    {t('Today')}
                  </label>
                </div>
              </div>
              {/* --------- today section end --------- */}

              {/* --------- date section start --------- */}
              <div className="col-lg">
                <div className="d-flex align-items-center">
                  <div className="form-check mb-0 me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="eventdate"
                      id="multipleDates"
                      value="eventdate"
                      checked={selectedChecked === 'eventdate'}
                      onChange={radioCheckHandler}
                    />
                  </div>
                  <input
                    type="date"
                    id="to_date"
                    className="form-control bg-white"
                    placeholder={t('Select Multiple Dates')}
                    value={selectedDate}
                    onChange={e => setSelectedDate(e.target.value)}
                  />
                </div>
              </div>
              {/* --------- date section end --------- */}
            </div>
            {/* -------- all date row end -------- */}

            {/* -------- template, label selcetion row section start -------- */}
            <div className="row align-items-center">
              {/* ------- name section start ------- */}
              <div className="col-lg-4 mb-3 mb-lg-0">
                <input
                  className="form-control bg-white"
                  placeholder={t('Search by name')}
                  value={templateName}
                  onChange={e => setTemplateName(e.target.value)}
                />
              </div>
              {/* ------- name section end ------- */}

              {/* ------- subject section start ------- */}
              <div className="col-lg-4 mb-3 mb-lg-0">
                <input
                  className="form-control bg-white"
                  placeholder={t('Search by subject')}
                  value={templateSubject}
                  onChange={e => setTemplateSubject(e.target.value)}
                />
              </div>
              {/* ------- subject section end ------- */}

              {/* ------- label selection section start ------- */}
              <div className="col-lg-4 mb-3 mb-lg-0">
                <Select
                  isMulti
                  placeholder={t('Select Label')}
                  options={labelList}
                  value={labelValue}
                  onChange={val => {
                    labelSectionHandler(val);
                  }}
                />
              </div>
              {/* ------- label selection section end ------- */}
            </div>
            {/* -------- template, label selcetion row section end -------- */}
          </div>

          {/* -------- search and reset button section start -------- */}
          <div className="action d-flex gap-3 align-items-center justify-content-end">
            <Link
              onClick={resetHandler}
              to="#"
              className="d-flex gap-1 align-items-center text-black"
            >
              <span className="d-block material-symbols-outlined icon-md">
                sync
              </span>
              <span className="d-block fw-medium">{t('Refresh')}</span>
            </Link>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={searchHandler}
            >
              {t('Filter')}
            </button>
          </div>
          {/* -------- search and reset button section end -------- */}
        </form>
      </div>
    </div>
  );
};

export default MailTemplateFilter;
