/* eslint-disable */
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

//import context
import { GlobalProvider } from 'context/GlobalContext';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

const ToggleColumn = () => {
  const { t } = useTranslation(); //for translation
  const {
    setShowActivityNameColumn,
    setShowActivityCourseColumn,
    setShowActivityCodeColumn,
    setShowActivityStartDateColumn,
    setShowActivityEndDateColumn,
    setShowActivityAudienceColumn,
    setShowActivityObservationColumn,
    setShowActivityDurationColumn,
    setshowActivityPreparationTimeColumn,
    setshowActivityMountingTimeColumn,
    setshowActivityDismantleTimeColumn,
  } = useContext(GlobalProvider);

  //use state for select field/columns
  const [selectedColumnValue, setSelectedColumnValue] = useState(null);
  const [columns, setColumns] = useState([]);

  //option for select dropdown
  const columnOption = [
    { label: t('Name'), value: 'Name' },
    { label: t('Course'), value: 'Course' },
    { label: t('Code'), value: 'Code' },
    { label: t('Start Date'), value: 'Start Date' },
    { label: t('Duration'), value: 'Duration' },
    { label: t('Preparation Time'), value: 'Preparation Time' },
    { label: t('Mounting Time'), value: 'Mounting Time' },
    { label: t('Dismantle Time'), value: 'Dismantle Time' },
    { label: t('End Date'), value: 'End Date' },
    { label: t('Audience'), value: 'Audience' },
    { label: t('Observation'), value: 'Observation' },
  ];

  //function for select column by dropdown
  const fieldSelectionHandler = val => {
    setSelectedColumnValue(val);
    const columnValaues = val.map(column => {
      return column.value;
    });
    setColumns(columnValaues);
  };

  //function for display selected columns
  const columnSelectionHandler = e => {
    e.preventDefault();
    if (columns.length > 0) {
      setShowActivityNameColumn(false);
      setShowActivityCourseColumn(false);
      setShowActivityCodeColumn(false);
      setShowActivityStartDateColumn(false);
      setShowActivityEndDateColumn(false);
      setShowActivityAudienceColumn(false);
      setShowActivityObservationColumn(false);
      setShowActivityDurationColumn(false);
      setshowActivityPreparationTimeColumn(false);
      setshowActivityMountingTimeColumn(false);
      setshowActivityDismantleTimeColumn(false);

      columns.forEach(column => {
        if (column === 'Name') {
          setShowActivityNameColumn(true);
        }
        if (column === 'Course') {
          setShowActivityCourseColumn(true);
        }
        if (column === 'Code') {
          setShowActivityCodeColumn(true);
        }
        if (column === 'StartDate') {
          setShowActivityStartDateColumn(true);
        }
        if (column === 'EndDate') {
          setShowActivityEndDateColumn(true);
        }
        if (column === 'Audience') {
          setShowActivityAudienceColumn(true);
        }
        if (column === 'Observation') {
          setShowActivityObservationColumn(true);
        }
        if (column === 'Duration') {
          setShowActivityDurationColumn(true);
        }
        if (column === 'Preparation Time') {
          setshowActivityPreparationTimeColumn(true);
        }
        if (column === 'Mounting Time') {
          setshowActivityMountingTimeColumn(true);
        }
        if (column === 'Dismantle Time') {
          setshowActivityDismantleTimeColumn(true);
        }
      });
    }
  };

  //function for reset select
  const resetColumn = () => {
    setShowActivityNameColumn(true);
    setShowActivityCourseColumn(true);
    setShowActivityCodeColumn(true);
    setShowActivityStartDateColumn(true);
    setShowActivityEndDateColumn(true);
    setShowActivityAudienceColumn(true);
    setShowActivityObservationColumn(true);
    setShowActivityDurationColumn(true);
    setshowActivityPreparationTimeColumn(true);
    setshowActivityMountingTimeColumn(true);
    setshowActivityDismantleTimeColumn(true);
    setSelectedColumnValue(null);
    setColumns([]);
  };

  return (
    <div className="collapse mt-3" id="activitycolumnCollapse">
      <div className="p-3 bg-gray-200">
        <div className="d-flex gap-3 align-items-center justify-content-between mb-3">
          <h4 className="mb-0">{t('Select Columns')}</h4>

          {/* ----- return button start ------ */}
          <Link
            to="#"
            className="d-flex gap-1 align-items-center text-black"
            onClick={resetColumn}
          >
            <span className="d-block material-symbols-outlined icon-md">
              sync
            </span>
            <span className="d-block fw-medium">{t('Refresh')}</span>
          </Link>
          {/* ----- return button end ------ */}
        </div>
        <form onSubmit={columnSelectionHandler}>
          <div className="form-container d-flex flex-wrap gap-2">
            <div className="form-group flex-fill">
              <Select
                className="nosearch_select"
                isMulti
                options={columnOption}
                value={selectedColumnValue}
                onChange={val => fieldSelectionHandler(val)}
              />
            </div>
            <div className="action">
              <button type="submit" className="btn btn-primary mw-unset">
                {t('Show')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ToggleColumn;
