import { assetImages } from "constants";
import React from "react";
import { useHistory } from "react-router-dom";

const AcceptMemberShipThanksModal = () => {
  const history = useHistory();

  const closeModalHandler = () => {
    history.push("/");
  };
  return (
    <div
      className="modal fade"
      id="thanks_membership_modal"
      tabIndex="-1"
      aria-labelledby="submitSurveyLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0">
          <div className="modal-header border-0">
            <button
              onClick={closeModalHandler}
              type="button"
              className="btn-close p-2 bg-white shadow-none shadow-sm m-0 ms-auto"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-center p-5 pt-0">
            <img
              src={assetImages.submitSurvey}
              alt="Survey Submitted Successfully!"
              className="img-fluid"
            />
            <h3>Thanks!</h3>
            <p>For accepting membership!</p>
            <button
              data-bs-dismiss="modal"
              aria-label="Close"
              className="mt-3 btn btn-primary"
              onClick={closeModalHandler}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptMemberShipThanksModal;
