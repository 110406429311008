import React from 'react';
// kendo react modules ==========================
import '@progress/kendo-theme-material/dist/all.css';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/* importing role data  */
import role from '../../data/RoleData.json';

const AddRolePrivilageBody = () => {
  const { t } = useTranslation(); //for translation
  // without pagination ------------------------------------
  const initialDataState = {};
  const [dataState, setDataState] = React.useState();
  const [result, setResult] = React.useState(process(role, initialDataState));

  const onDataStateChange = event => {
    console.log('event -->', event);
    setDataState(event.dataState);
    setResult(process(role, event.dataState));
  };

  const customHeadHtml = () => {
    return (
      <th>
        <div className="d-flex align-items-center gap-3">
          <div className="title">{t('Module')}</div>
        </div>
      </th>
    );
  };

  /* checkbox html */
  const customCheckbox = () => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            name=""
            id=""
          />
        </div>
      </td>
    );
  };

  // mark all checkbox as selected ----------------
  const selectAllRecords = e => {
    document
      .querySelectorAll('input[name="selectRecord"]')
      .forEach(checkbox => {
        checkbox.checked = e.target.checked;
      });
  };

  // blocks to replace the Grid HTML ==============================================
  const SelectRecordHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="selectAllMain"
            onClick={selectAllRecords}
          />
        </div>
      </div>
    );
  };

  /* Select single row */
  const SelectRecordRow = props => {
    return (
      <td>
        <div className="form-check">
          <input
            className="form-check-input "
            type="checkbox"
            value={props.dataItem._id}
            name="selectRecord"
          />
        </div>
      </td>
    );
  };

  return (
    <div className="table-responsive">
      <Grid
        style={{ minWidth: '100%' }}
        data={result}
        // filterable={true}
        sortable={true}
        groupable={true}
        onDataStateChange={onDataStateChange}
        {...dataState}
        //pageable = {true}
        //total = {products.length}
        reorderable
        // resizable
      >
        <GridColumn
          field="id"
          headerCell={SelectRecordHeader}
          cell={SelectRecordRow}
          filterable={false}
          sortable={false}
          width="70px"
        />

        <GridColumn
          headerCell={customHeadHtml}
          field="name"
          // title="Template Name"
        />
        <GridColumn cell={customCheckbox} title={t('Customer')} />
        <GridColumn cell={customCheckbox} title={t('User')} />
        <GridColumn cell={customCheckbox} title={t('Manager')} />
        <GridColumn cell={customCheckbox} title={t('Educator')} />
      </Grid>
    </div>
  );
};

export default AddRolePrivilageBody;
