/* eslint-disable */
import React, { useState, useEffect, useContext, createRef } from "react";
import { Link } from "react-router-dom";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "urlhelpers/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";

// Manage Tags or Label for all modules =============================
// ==================================================================

const AddLabel = (params) => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token");

  const {
    setShowUserList,
    setReloadReservationList,
    setReloadConversationList,
    setReloadMailTemplateList,
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const [tagList, setTagList] = useState([]);
  const [tagName, setTagName] = useState("");
  const [tagWarning, setTagWarning] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const module = params.module;

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl + url.API_GET_ALL_TAG + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for create new tag using plus icon
  const createNewTagHandler = async () => {
    try {
      let tagData = {
        title: tagName,
        slug: tagName,
        isreserved: "0",
      };
      let requestUrl = url.API_BaseUrl + url.API_ADD_TAG + `?token=${token}`;

      const response = await postData(requestUrl, tagData);

      console.log(response);

      if (response.status) {
        resetTagFieldHandler();
        getAllTags();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for set tags
  const tagselectedHandler = (data) => {
    //console.log(data);
    let prevTags = selectedTags;

    if (prevTags.includes(data._id)) {
      let selectedTagItem = document.getElementById("tagdata-" + data._id);
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data._id);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagdata-" + data._id);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data._id);
    }

    setSelectedTags(prevTags);
    // console.log('prev tags >>>> ',prevTags);
    // console.log('selected tags >>>> ',selectedTags);
  };

  //function for save tag to user
  const addRemoveTagHandler = async (action) => {
    const checkboxes = document.querySelectorAll(
      'input[filtername="selectedrecord"]'
    );

    let requestUrl = url.API_BaseUrl;

    // select request url based on which module this label component is working on
    if (module === "reserve") {
      requestUrl = requestUrl + url.API_ADD_TAG_TO_EVENT;
    } else if (module === "user") {
      requestUrl = requestUrl + url.API_ADD_TAG_TO_USER;
    } else if (module === "conversation") {
      requestUrl = requestUrl + url.API_ADD_TAG_TO_CONVERSATION;
    } else if (module === "mailtemplate") {
      requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_TEMPLATE;
    } else {
      alert("error in module selection. please refresh once");
    }

    setIsUpdating(true);
    // run a loop for all selected components or checkboxId
    checkboxes.forEach(async (checkbox, index) => {
      // if checked
      if (checkbox.checked) {
        // get the checked components
        let selectedComponentId = checkbox.value;

        let tagUpdateUrl =
          requestUrl +
          `/${selectedComponentId}` +
          `?token=${token}` +
          `&action=${action}`;

        console.log("tagUpdateUrl", tagUpdateUrl);

        try {
          const response = await putData(tagUpdateUrl, selectedTags);
          console.log("response in add remove tag-------->", response);

          let addNotificationMessage = t("Tag/s has been added successfully");
          let removeNotificationMessage = t(
            "Tag/s has been removed successfully"
          );

          //notification
          setSuccess(true);
          if (action === "1") {
            setNotificationString(addNotificationMessage);
          } else {
            setNotificationString(removeNotificationMessage);
          }

          //if checkbox are selected after operation reload things
          if (module == "reserve") {
            setReloadReservationList(true);
          } else if (module == "user") {
            setShowUserList(true);
          } else if (module == "conversation") {
            setReloadConversationList(true);
          } else if (module === "mailtemplate") {
            setReloadMailTemplateList(true);
          } else {
          }
        } catch (error) {
          console.error(error.message);
          setError(true);
          setNotificationString("Something went wrong, please try again later");
        }
      }
    });
    setIsUpdating(false);
    //rest to default
    closePopupHandler();

    // close the offcanvas of addLabel
    let offCanvasPopup = document.querySelector("#tags_pnl");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
  };

  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".addtagblock li");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  //function for reset data
  const resetTagFieldHandler = () => {
    setTagName("");
  };

  // remove active class after close modal
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("displayTags");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  // function for reset all data after close modal
  const closePopupHandler = () => {
    resetTagFieldHandler();
    setSelectedTags([]);
    resetSelectTags();
    removeActiveClass();
    setTagWarning(false);
  };

  useEffect(() => {
    getAllTags();
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end border-0 tag_offcanvas"
      id="tags_pnl"
      aria-labelledby="tags_pnl"
    >
      <div className="offcanvas-header position-relative align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">Add/Delete Tags</span>
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body position-relative p-4 pt-0">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="tag_fill add_fill position-relative mb-4">
            <input
              type="text"
              className="form-control"
              placeholder={t("Enter to create new label")}
              value={tagName}
              onChange={(e) => {
                setTagName(e.target.value);
              }}
            />
            <Link
              to="#"
              className="add_icon position-absolute"
              onClick={createNewTagHandler}
            >
              <span className="material-icons-round">add_circle </span>
            </Link>
          </div>
          {tagList.length > 0 && (
            <div className="tag_links ">
              <ul className="addtagblock list_stye_none d-flex flex-wrap align-items-center gap-2 ">
                {tagList.map((tag, index) => (
                  <li
                    key={index}
                    className="displayTags k-cursor-pointer"
                    onClick={() => tagselectedHandler(tag)}
                    id={"tagdata-" + tag._id}
                  >
                    <span>{tag.title}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="tags_btns d-flex align-items-center gap-2">
            <button
              className="btn  btn-primary d-flex align-items-center justify-content-center gap-1 w-100"
              onClick={() => {
                addRemoveTagHandler("1");
              }}
              disabled={isUpdating ? true : false}
              style={{ cursor: isUpdating ? "not-allowed" : "pointer" }}
            >
              <i className="material-icons-round">check_circle</i>
              <span>Add</span>
              {isUpdating && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
            <button
              className="btn btn-danger delete_btn d-flex align-items-center justify-content-center gap-1 w-100"
              onClick={() => {
                addRemoveTagHandler("0");
              }}
              disabled={isUpdating ? true : false}
              style={{ cursor: isUpdating ? "not-allowed" : "pointer" }}
            >
              <i className="material-icons-round">delete</i>
              <span>Delete</span>
              {isUpdating && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddLabel;
