/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import UserMenu from 'components/common/UserMenu';
import LeftMenu from 'components/common/LeftMenu';
import Filter from 'components/ActivityComponents/Filter/Filter';
import ActivitySaveRecord from 'components/ActivityComponents/ActivitySaveRecord/ActivitySaveRecord';
import ActivityList from 'components/ActivityComponents/ActivityList/ActivityList';
import ActivityHeader from 'components/ActivityComponents/Header/ActivityHeader';
import ToggleColumn from 'components/ActivityComponents/ToggleColumn/ToggleColumn';
import ActivityBreadCrumb from 'components/ActivityComponents/BreadCrumb/ActivityBreadCrumb';
import CustomNotification from 'components/common/CustomNotification';

const Activity = () => {
  /* useState hook to download excel file */
  const [downloadExcel, setDownloadExcel] = useState(false);
  /* hook to get number of records */
  const [records, setRecords] = useState(0);
  /* hook to search */
  const [search, setSearch] = useState('');

  const blockRefSelected = useRef(null); // this will be used to acccess dom element directly

  /* function for checking record count */
  const checkedRecordCount = selectCount => {
    console.log('selectCount', selectCount);
    const blockElementSelect = ReactDOM.findDOMNode(blockRefSelected.current);
    if (blockElementSelect) {
      blockElementSelect.innerHTML = selectCount;
    }
  };

  useEffect(() => {
    document.title = 'Activity'; // Update the title when Register component mounts
  }, []);

  return (
    <>
      {/* Menu section starts */}
      <UserMenu />
      <LeftMenu />
      {/* Menu section ends */}
      <section className="mail-wrapper pt-2">
        <div className="container-fluid px-4">
          {/* Activity navigation starts */}
          <ActivityBreadCrumb />
          <ActivityHeader
            setDownloadExcel={setDownloadExcel}
            setSearch={setSearch}
            records={records}
            blockRefSelected={blockRefSelected}
          />
          <ToggleColumn />
          <Filter />
          {/* Activity navigation ends */}

          {/* Activity list starts */}
          <ActivityList
            downloadExcel={downloadExcel}
            search={search}
            setRecords={setRecords}
            checkedRecordCount={checkedRecordCount}
          />
          {/* Activity list ends */}
        </div>
      </section>

      {/* Activity save record popup starts */}
      <ActivitySaveRecord />
      {/* Activity save record popup ends */}
      {/*----------- custom notification component start -----------*/}
      <CustomNotification />
      {/*----------- custom notification component end -----------*/}
    </>
  );
};

export default Activity;
