/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';

/* Importing react select */
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

/* import status from json */
import statusData from '../../../data/Prod/Status.json';

//import context
import { GlobalProvider } from 'context/GlobalContext';

/*import url and gateway methods */
import { getData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

const Filter = () => {
  const token = localStorage.getItem('token'); // token
  const { t } = useTranslation(); //for translation

  const {
    setSearchUserRoles,
    setSearchUserTags,
    setSearchUserStatus,
    setSearchUserZipcodes,
    setShowUserList,
  } = useContext(GlobalProvider);

  const [rolesList, setRolesList] = useState([]); //for roles
  const [labelList, setlabelList] = useState([]); // for label list

  /* useState hook to change select values */
  const [selectedOptions, setSelectedOptions] = useState({
    roleValue: null,
    roleData: [],
    tagValue: null,
    tagData: [],
    statusValue: null,
    statusData: [],
    zipCodeValue: null,
    zipCodeData: [],
  });

  //function for get all role
  const getAllRoles = async () => {
    let requestUrl =
      url.API_BaseUrl + url.API_GET_ALL_ROLES + `?token=${token}`;

    const response = await getData(requestUrl);

    if (response.status) {
      setRolesList(response.data);
    }
  };

  /* function to get all labels */
  const getAllLabels = async () => {
    let requestUrl =
      url.API_BaseUrl +
      url.API_GET_ALL_TAG +
      `?token=${token}&isselect=${true}`;

    const response = await getData(requestUrl);

    if (response.status) {
      setlabelList(response.data);
    }
  };

  //function for search
  const searchHandler = () => {
    //assign data for search
    selectedOptions.roleData.length > 0 &&
      setSearchUserRoles(selectedOptions.roleData);

    selectedOptions.tagData.length > 0 &&
      setSearchUserTags(selectedOptions.tagData);

    selectedOptions.statusData.length > 0 &&
      setSearchUserStatus(selectedOptions.statusData);

    selectedOptions.zipCodeData.length > 0 &&
      setSearchUserZipcodes(selectedOptions.zipCodeData);
  };

  //function for reset
  const resetHandler = () => {
    setSelectedOptions({
      roleValue: null,
      roleData: [],
      tagValue: null,
      tagData: [],
      statusValue: null,
      statusData: [],
      zipCodeValue: null,
      zipCodeData: [],
    });
    setSearchUserRoles([]);
    setSearchUserTags([]);
    setSearchUserStatus([]);
    setSearchUserZipcodes([]);
    setShowUserList(true);
  };

  useEffect(() => {
    getAllRoles();
    getAllLabels();
  }, []);

  return (
    <div className="collapse mt-3 user_filter" id="userfilterCollapse">
      <div className="p-3 bg-gray-200">
        <h4 className="mb-3">{t('Filter')}</h4>
        <form onSubmit={e => e.preventDefault()}>
          <div className="user_filter_outer">
            <div className="row">
              {/* ======== select role section start ======== */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <Select
                    placeholder={t('Select role')}
                    className="nosearch_select"
                    isMulti
                    options={rolesList}
                    value={selectedOptions.roleValue}
                    onChange={val => {
                      setSelectedOptions(prevData => ({
                        ...prevData,
                        roleValue: val,
                        roleData: val.map(label => label.value),
                      }));
                    }}
                  />
                </div>
              </div>
              {/* ======== select role section end ======== */}

              {/* ======== select label section start ======== */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <Select
                    placeholder={t('Select tag')}
                    className="nosearch_select"
                    isMulti
                    options={labelList}
                    value={selectedOptions.tagValue}
                    onChange={val => {
                      setSelectedOptions(prevData => ({
                        ...prevData,
                        tagValue: val,
                        tagData: val.map(label => label.value),
                      }));
                    }}
                  />
                </div>
              </div>
              {/* ======== select label section end ======== */}

              {/* ======== select status start============== */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <Select
                    placeholder={t('Select status')}
                    className="nosearch_select"
                    isMulti
                    options={statusData}
                    value={selectedOptions.statusValue}
                    onChange={val => {
                      setSelectedOptions(prevData => ({
                        ...prevData,
                        statusValue: val,
                        statusData: val.map(label => label.value),
                      }));
                    }}
                  />
                </div>
              </div>
              {/* ======== select status end============== */}

              {/* ======== select zipcode start ============== */}
              <div className="col-lg-3">
                <div className="form-group mb-3">
                  <CreatableSelect
                    placeholder={t('Enter zipcode')}
                    className="nosearch_select"
                    isMulti
                    options={statusData}
                    value={selectedOptions.zipCodeValue}
                    onChange={val => {
                      setSelectedOptions(prevData => ({
                        ...prevData,
                        zipCodeValue: val,
                        zipCodeData: val.map(label => label.value),
                      }));
                    }}
                  />
                </div>
              </div>
              {/* ======== select zipcode end ============== */}

              {/* ------ button section start -------- */}
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <div className="action d-flex gap-3 align-items-center">
                      {/* ------- search button start ------- */}
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={searchHandler}
                      >
                        {t('Select')}
                      </button>
                      {/* ------- reset button end ------- */}

                      {/* ------- reset button start ------- */}
                      <Link
                        to="#"
                        className="d-flex gap-1 align-items-center text-black"
                        onClick={resetHandler}
                      >
                        <span className="d-block material-symbols-outlined icon-md">
                          sync
                        </span>
                        <span className="d-block fw-medium">
                          {t('Refresh')}
                        </span>
                      </Link>
                      {/* ------- reset button end ------- */}
                    </div>
                  </div>
                </div>
              </div>
              {/* ------ button section end -------- */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Filter;
