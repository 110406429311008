/* eslint-disable */
import { useEffect, useState, useContext } from 'react';

//import translation function for language translation
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Select from 'react-select';

//import context
import { GlobalProvider } from 'context/GlobalContext';

//import api requiremnts
import * as url from 'urlhelpers/UrlHelper';
import { getData, postData } from 'utils/Gateway';

const CreateSalesOrderPopup = () => {
  //today value
  const todayValue = new Date().toISOString().split('T')[0];

  const token = localStorage.getItem('token');
  const { t } = useTranslation(); //for translation

  const {
    setReloadReservationList,
    /* -------- context for notifications-------- */
    setSuccess,
    setError,
    setNotificationString,
  } = useContext(GlobalProvider);

  const [clientList, setClientList] = useState([]); //client option
  const [selectedClient, setSelectedClient] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(todayValue);
  const [discountRate, setDiscountRate] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isSending, setIsSending] = useState(false);

  //function for get all user list
  const getAllClientList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      console.log('list clients in sales order pop up -->', response);

      if (response.status) {
        //client list
        let filteredClient = response.data.filter(user => {
          let userData;
          if (user.role) {
            if (user.role.name == 'client' || user.role.name == 'beneficiary') {
              userData = user;
            }
          }
          return userData;
        });

        setClientList(filteredClient);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //option for select individual or group
  const groupOption = [
    {
      label: t('Individual'),
      value: '0',
    },
    {
      label: t('Group'),
      value: '1',
    },
  ];

  //function for create sales order
  const createSalesOrderHandler = async () => {
    try {
      setIsSending(true);
      const checkboxes = document.querySelectorAll(
        'input[name="selectReserveRecord"]'
      );

      let checkBoxArr = [];

      //set checkbox value
      checkboxes.forEach(checkbox => {
        if (checkbox.checked) {
          checkBoxArr.push(checkbox.value);
        }
      });

      //check condition where reserve selected or not
      if (checkBoxArr.length > 0) {
        let salesOrderData = {
          reservations: checkBoxArr,
          client: selectedClient.value,
          invoicedate: invoiceDate
            ? invoiceDate.split('-').reverse().join('/')
            : '',
          discountpercent: discountRate,
          grouped: selectedGroup.value,
          createdsalesdate: todayValue.split('-').reverse().join('/'),
        };

        console.log('salesOrderData------>', salesOrderData);

        let requestUrl =
          url.API_BaseUrl + url.API_CREATE_SALES_ORDER + `?token=${token}`;

        console.log('url of sales order create======>', requestUrl);

        const response = await postData(requestUrl, salesOrderData);

        console.log('response in sales order pop up========>', response);

        if (response.status) {
          setIsSending(false);
          resetHandler();
          setReloadReservationList(true);
          setSuccess(true);
          setNotificationString(t('Sales order created successfully'));
        }
      }
    } catch (error) {
      console.log(error.message);
      setError(true);
      setNotificationString(t('Something went wrong, please try again later'));
    }
    let offCanvasPopup = document.querySelector('#createsalesorder');
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
  };

  //function for reset
  const resetHandler = () => {
    setSelectedClient(null);
    setInvoiceDate(todayValue);
    setDiscountRate(0);
    setSelectedGroup(null);
  };

  useEffect(() => {
    getAllClientList();
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="createsalesorder"
      aria-labelledby="createsalesorderlabel"
    >
      <div className="offcanvas-header p-4">
        {/* ------- offcanvas heading start -------- */}
        <h3 className="text-secondary mb-0" id="allSurveyPopupLabel">
          {t('Create Sales Order')}
        </h3>
        {/* ------- offcanvas heading end -------- */}

        {/* ------- offcanvas close button start -------- */}
        <button
          type="button"
          id="createsalesorderbutton"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
        {/* ------- offcanvas close button end -------- */}
      </div>
      <div className="offcanvas-body px-4 pt-0">
        {/* ------- sales order form start ------- */}
        <form onSubmit={e => e.preventDefault()}>
          {/* ------- customer selection start ------- */}
          <div className="form-group mb-4">
            <label htmlFor="selectTemplate">{t('Select Customer')}</label>
            <Select
              options={clientList}
              value={selectedClient}
              onChange={val => {
                setSelectedClient(val);
              }}
            />
          </div>
          {/* ------- customer selection end ------- */}

          {/* ------- invoice date start ------- */}
          <div className="form-group mb-4">
            <label htmlFor="invoice date">{t('Invoice Date')}</label>
            <input
              type="date"
              id="date"
              className="form-control"
              placeholder="DD/MM/YYYY"
              value={invoiceDate}
              onChange={e => setInvoiceDate(e.target.value)}
            />
          </div>
          {/* ------- invoice date end ------- */}

          {/* ------- invoice discount start ------- */}
          <div className="form-group mb-4">
            <label htmlFor="discount">{t('Discount')} %</label>
            <input
              type="number"
              id="discount"
              className="form-control"
              placeholder=""
              value={discountRate}
              onChange={e => setDiscountRate(e.target.value)}
            />
          </div>
          {/* ------- invoice discount end ------- */}

          {/* ------- individual/group selection start ------- */}
          <div className="form-group mb-4">
            <label htmlFor="Individual or group">
              {t('Select Individual or Group')}
            </label>
            <Select
              options={groupOption}
              value={selectedGroup}
              onChange={val => {
                setSelectedGroup(val);
              }}
            />
          </div>
          {/* ------- individual/group selection end ------- */}

          {/* ============ save button section start ================== */}
          <div className="action d-flex align-items-center gap-2">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSending ? true : false}
              style={{ cursor: isSending ? 'not-allowed' : 'pointer' }}
              onClick={createSalesOrderHandler}
            >
              {t('Save')}
              {isSending && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          {/* ============ save button section end ================== */}
        </form>
        {/* ------- sales order form end ------- */}
      </div>
    </div>
  );
};

export default CreateSalesOrderPopup;
