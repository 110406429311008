/* eslint-disable */
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

/* Importing react select */
import Select from 'react-select';

//import translation function for language translation
import { useTranslation } from 'react-i18next';

//import options from json file
import StatusOptions from 'data/Prod/ReserveStatus.json';
import typeOfActivity from 'data/Prod/ActivityType.json';
import levelOption from 'data/Prod/Level.json';
import batchLotOption from 'data/Prod/Batch.json';
import specificAudience from 'data/Prod/SpecialAudience.json';
import SpecialNeeds from 'data/Prod/SpecialNeeds.json';

/*import url and gateway methods */
import { postData, getData } from 'utils/Gateway';
import * as url from 'urlhelpers/UrlHelper';

//import context
import { GlobalProvider } from 'context/GlobalContext';

const ReservationSavePopup = () => {
  const token = localStorage.getItem('token');
  const { t } = useTranslation(); //for translation

  const {
    setReloadReservationList,
    editReserveData,
    setEditReserveData,
    resetReserveSaveForm,
    setResetReserveSaveForm,
    setSuccess,
    setNotificationString,
    setError,
    copyReserveData,
    setCopyReserveData,
  } = useContext(GlobalProvider);

  const dateOfToday = new Date().toISOString().split('T')[0];

  //function for get current time and set by defualt
  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const timenow = `${hours}:${minutes}`;
    return timenow;
  };

  const [reserveId, setReserveId] = useState(null);
  const [ambReserveId, setAmbReserveId] = useState(null);
  const [courseList, setCourseList] = useState([]); //course option
  const [activityList, setActivityList] = useState([]); //materila kit option
  const [userList, setUserList] = useState([]); //educator option
  const [labelList, setLabelList] = useState([]); //label option
  const [educatorList, setEducatorList] = useState([]); //educator option
  const [clientList, setClientList] = useState([]); //client option
  const [observerList, setObserverList] = useState([]); //observer option
  const [benificiaryList, setBenificiaryList] = useState([]); //benificiary option
  const [participantList, setParticipantList] = useState([]); //participant option
  const [reserveTemplateList, setReserveTemplateList] = useState([]); //template option

  /* hooks for input fields starts */
  const [startDate, setStartDate] = useState(dateOfToday);
  // const [endDate, setEndDate] = useState("");
  const [startTime, setstartTime] = useState(getCurrentTime());
  const [endTime, setEndTime] = useState(getCurrentTime());
  const [noOfEducators, setNoOfEducators] = useState('1');
  const [noOfParticipants, setNoOfParticipants] = useState('');
  const [noParticipantsReal, setNoParticipantsReal] = useState('');
  const [billingUnit, setBillingUnit] = useState('');
  const [tutor, setTutor] = useState('');
  const [grade, setGrade] = useState('');
  const [noOfCompanions, setNoOfCompanions] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [location, setLocation] = useState('');
  const [responsileName, setResponsileName] = useState('');
  const [responsileEmail, setResponsileEmail] = useState('');
  const [responsilePhone, setResponsilePhone] = useState('');
  const [comments, setComments] = useState('');
  const [isTemplate, setIsTemplate] = useState(false);

  /* hooks for input fields ends */

  const [changeSelect, setChangeSelect] = useState({
    selectedTemplate: null,
    selectedStatus: null,
    selectedCourses: null,
    selectedActivity: null,
    selectedActivityType: null,
    selectedEducators: null,
    selectedParticipants: null,
    selectedScope: null,
    selectedSpecialNeeds: null,
    selectedBatch: null,
    selectedClient: null,
    selectedObserver: null,
    selectedAudience: null,
    selectedBenificiary: null,
    selectLabels: null,
  });

  /* hooks to post select data start */
  const [statusData, setStatusData] = useState(null);
  const [courseData, setCourseData] = useState(null);
  const [activityData, setActivityData] = useState(null);
  const [activityTypeData, setActivityTypeData] = useState(null);
  const [educatorData, setEducatorData] = useState([]);
  const [levelData, setLevelData] = useState([]);
  const [specialNeedsData, setSpecialNeedsData] = useState([]);
  const [batchData, setBatchData] = useState(null);
  const [specificAudienceData, setSpecificAudienceData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [observerData, setObserverData] = useState([]);
  const [benificiaryData, setBenificiaryData] = useState([]);
  const [participantsData, setParticipantsData] = useState([]);
  const [labelData, setLabelData] = useState([]);
  /* hooks to post select data end */

  /* Hook for validation end */
  //function for get all reserve as select
  const getAllReserveTemplate = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_RESERVE_EVENTS +
        `?token=${token}&isselect=${true}`;

      console.log('Get all reserver template url========>', requestUrl);

      const response = await getData(requestUrl);

      console.log('Get all reserver template response========>', response);

      if (response.status) {
        setReserveTemplateList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get course list
  const getAllCourseList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_COURSE +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setCourseList(response.data);
        courseSelectionHandler(response.data[response.data.length - 1]);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all activity
  const getAllActivityList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_ACTIVITY +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setActivityList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all user list
  const getAllUserList = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_USER +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      console.log('list app users -->', response);

      if (response.status) {
        setUserList(response.data);

        //eductor list
        let filteredEducator = response.data.filter(user => {
          let userData;
          if (user.role) {
            if (user.role.name == 'educator') {
              userData = user;
            }
          }
          return userData;
        });

        setEducatorList(filteredEducator);

        //client list
        let filteredClient = response.data.filter(user => {
          let userData;
          if (user.role) {
            if (user.role.name == 'client' || user.role.name == 'beneficiary') {
              userData = user;
            }
          }
          return userData;
        });

        setClientList(filteredClient);

        //observer list
        let filteredObserver = response.data.filter(user => {
          let userData;
          if (user.role) {
            if (user.role.name == 'observer') {
              userData = user;
            }
          }
          return userData;
        });

        setObserverList(filteredObserver);

        //beificiary list
        let filteredBeneficiary = response.data.filter(user => {
          let userData;
          if (user.role) {
            if (user.role.name == 'beneficiary') {
              userData = user;
            }
          }
          return userData;
        });

        setBenificiaryList(filteredBeneficiary);

        //participant list
        let filteredParticipants = response.data.filter(user => {
          let userData;
          if (user.role) {
            if (user.role.name == 'participant') {
              userData = user;
            }
          }
          return userData;
        });

        setParticipantList(filteredParticipants);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for get all label list
  const getAllLabels = async () => {
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_ALL_TAG +
        `?token=${token}&isselect=${true}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setLabelList(response.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for select status
  const statusSelectionHandler = val => {
    setChangeSelect(prevState => ({
      ...prevState,
      selectedStatus: val,
    }));
    setStatusData(val.value);
  };

  //function for course selection
  const courseSelectionHandler = val => {
    setChangeSelect(prevState => ({
      ...prevState,
      selectedCourses: val,
    }));
    setCourseData(val.value);
  };

  //function for activity selection
  const activitySelectionHandler = val => {
    setChangeSelect(prevState => ({
      ...prevState,
      selectedActivity: val,
    }));
    setActivityData(val.value);

    if (val.duration) {
      const additionalTime = parseInt(val.duration, 10);

      let timeParts = startTime.split(':');
      let hours = parseInt(timeParts[0], 10);
      let minutes = parseInt(timeParts[1], 10);
      let timeDate = new Date();

      timeDate.setHours(hours);
      timeDate.setMinutes(minutes);

      // Calculate the result by adding the number to the time
      let resultDate = new Date(
        timeDate.getTime() + additionalTime * 60 * 1000
      );

      // Extract the individual components (hours, minutes) from the result date
      let resultHours = resultDate.getHours();
      let resultMinutes = resultDate.getMinutes();

      // Format the result as a string
      let newHour = resultHours.toString().padStart(2, '0');
      let newMinute = resultMinutes.toString().padStart(2, '0');

      const timeExtended = `${newHour}:${newMinute}`;

      setEndTime(timeExtended);
    }
  };

  /* function to select activity type */
  const selectActivityTypeHandler = val => {
    setChangeSelect(prevState => ({
      ...prevState,
      selectedActivityType: val,
    }));
    setActivityTypeData(val.value);
  };

  /* function to select educators */
  const educatorsSelectionHandler = val => {
    const noOfEducatorsInt = parseInt(noOfEducators);

    if (val.length <= noOfEducatorsInt) {
      setChangeSelect(prevState => ({
        ...prevState,
        selectedEducators: val,
      }));
      setEducatorData(val.map(educator => educator.value));
    }
  };

  /* function to select scope */
  const levelSelectionHandler = val => {
    setChangeSelect(prevState => ({
      ...prevState,
      selectedScope: val,
    }));
    setLevelData(val.map(scope => scope.value));
  };

  /* function to select batch  */
  const batchSelectionHandler = val => {
    setChangeSelect(prevState => ({
      ...prevState,
      selectedBatch: val,
    }));
    setBatchData(val.value);
  };

  /* function to select audience */
  const specificAudienceHandler = val => {
    setChangeSelect(prevState => ({
      ...prevState,
      selectedAudience: val,
    }));
    setSpecificAudienceData(val.map(audience => audience.value));
  };

  /* function to select client */
  const selectClientHandler = val => {
    setChangeSelect(prevState => ({
      ...prevState,
      selectedClient: val,
    }));
    setClientData(val.map(client => client.value));
  };

  /* function to select observer */
  const selectObserverHandler = val => {
    setChangeSelect(prevState => ({
      ...prevState,
      selectedObserver: val,
    }));
    setObserverData(val.map(observer => observer.value));
  };

  /* function to select benificiary */
  const selectBenificiaryHandler = val => {
    if (val.length > 0) {
      setLocation(val[0].location);
      setZipCode(val[0].zipcode);
    }
    setChangeSelect(prevState => ({
      ...prevState,
      selectedBenificiary: val,
    }));
    setBenificiaryData(val.map(benificiary => benificiary.value));
  };

  /* function to select participants */
  const selectParticipantsHandler = val => {
    const noOfParticipantsInt = parseInt(noOfParticipants);

    if (val.length <= noOfParticipantsInt) {
      setChangeSelect(prevState => ({
        ...prevState,
        selectedParticipants: val,
      }));
      setParticipantsData(val.map(participant => participant.value));
    }
  };

  /* function to select special needs */
  const selectSpecialNeedsHandler = val => {
    setChangeSelect(prevState => ({
      ...prevState,
      selectedSpecialNeeds: val,
    }));
    setSpecialNeedsData(val.map(specialNeed => specialNeed.value));
  };

  //function for label selection
  const labelSelectionHandler = val => {
    setChangeSelect(prevState => ({
      ...prevState,
      selectLabels: val,
    }));
    setLabelData(val.map(client => client.value));
  };

  /* Hook for validation start */
  const [validation, setValidation] = useState({
    startDateWarning: false,
    startTimeWarning: false,
    endTimeWarning: false,
    noOfEducatorsWarning: false,
    noOfParticipantsWarning: false,
    noParticipantsRealWarning: false,
    billingUnitWarning: false,
    noOfCompanionsWarning: false,
    locationWarning: false,
    zipcodewarning: false,
    responsileNameWarning: false,
    responsileEmailWarning: false,
    responsilePhoneWarning: false,
    commentsWarning: false,
    statusWarning: false,
    courseWarning: false,
    activityWarning: false,
    activityTypeWarning: false,
    educatorWarning: false,
    levelWarning: false,
    specialNeedsWarning: false,
    batchWarning: false,
    specificAudienceWarning: false,
    clientWarning: false,
    observerWarning: false,
    benificiaryWarning: false,
    participantsWarning: false,
    timeCompareWarning: false,
  });

  /* function for validation */
  const validateForm = () => {
    let isValid = true;

    if (startDate === '') {
      setValidation(prevState => ({ ...prevState, startDateWarning: true }));
      isValid = false;
    }
    if (startTime === '') {
      setValidation(prevState => ({ ...prevState, startTimeWarning: true }));
      isValid = false;
    }
    if (endTime === '') {
      setValidation(prevState => ({ ...prevState, endTimeWarning: true }));
      isValid = false;
    }
    if (noOfEducators === '') {
      setValidation(prevState => ({
        ...prevState,
        noOfEducatorsWarning: true,
      }));
      isValid = false;
    }
    if (noOfParticipants === '') {
      setValidation(prevState => ({
        ...prevState,
        noOfParticipantsWarning: true,
      }));
      isValid = false;
    }
    if (changeSelect.selectedEducators === null) {
      setValidation(prevState => ({
        ...prevState,
        educatorWarning: true,
      }));
      isValid = false;
    }
    if (changeSelect.selectedBenificiary === null) {
      setValidation(prevState => ({
        ...prevState,
        benificiaryWarning: true,
      }));
      isValid = false;
    }
    if (changeSelect.selectedClient === null) {
      setValidation(prevState => ({
        ...prevState,
        clientWarning: true,
      }));
      isValid = false;
    }
    if (changeSelect.selectedObserver === null) {
      setValidation(prevState => ({
        ...prevState,
        observerWarning: true,
      }));
      isValid = false;
    }
    if (changeSelect.selectedParticipants === null) {
      setValidation(prevState => ({
        ...prevState,
        participantsWarning: true,
      }));
      isValid = false;
    }
    if (zipCode === '') {
      setValidation(prevState => ({
        ...prevState,
        zipcodewarning: true,
      }));
      isValid = false;
    }

    if (startTime > endTime) {
      setValidation(prevState => ({
        ...prevState,
        timeCompareWarning: true,
      }));
      isValid = false;
    }
    return isValid;
  };

  //function for get reserve details
  const getReserveDetails = async value => {
    setChangeSelect(prevState => ({ ...prevState, selectedTemplate: value }));
    try {
      let requestUrl =
        url.API_BaseUrl +
        url.API_GET_RESERVE_DETAILS +
        `?reserveid=${value.value}`;

      const response = await getData(requestUrl);

      console.log('reserve details=======>', response);

      if (response.status) {
        setValidation({
          startDateWarning: false,
          startTimeWarning: false,
          endTimeWarning: false,
          noOfEducatorsWarning: false,
          noOfParticipantsWarning: false,
          noParticipantsRealWarning: false,
          billingUnitWarning: false,
          noOfCompanionsWarning: false,
          locationWarning: false,
          zipcodewarning: false,
          responsileNameWarning: false,
          responsileEmailWarning: false,
          responsilePhoneWarning: false,
          commentsWarning: false,
          statusWarning: false,
          courseWarning: false,
          activityWarning: false,
          activityTypeWarning: false,
          educatorWarning: false,
          levelWarning: false,
          specialNeedsWarning: false,
          batchWarning: false,
          specificAudienceWarning: false,
          clientWarning: false,
          observerWarning: false,
          benificiaryWarning: false,
          participantsWarning: false,
        });

        setReserveId(null);
        setAmbReserveId(null);

        /*========= assign status start =========*/
        if (response.data.reservestatus == '1') {
          let statusData = {
            label: 'Confirmed',
            value: '1',
          };
          statusSelectionHandler(statusData); //set status value
        } else if (response.data.reservestatus == '0') {
          let statusData = {
            label: 'Delete reserve',
            value: '0',
          };
          statusSelectionHandler(statusData); //set status value
        } else if (response.data.reservestatus == '3') {
          let statusData = {
            label: 'Pending Confirmation',
            value: '3',
          };
          statusSelectionHandler(statusData); //set status value
        } else {
          let statusData = {
            label: 'Cancelled at the moment',
            value: '2',
          };
          statusSelectionHandler(statusData); //set status value
        }
        /*========= assign status end =========*/

        /*========= assign course start =========*/
        if (response.data.course) {
          let courseData = {
            label: response.data.course.name,
            value: response.data.course._id,
          };
          courseSelectionHandler(courseData);
        }
        /*========= assign course end =========*/

        /*========= assign date start =========*/
        response.data?.startdate && setStartDate(response.data.startdate);
        /*========= assign date end =========*/

        /*========= assign start time start =========*/
        response.data?.starttime && setstartTime(response.data.starttime);
        /*========= assign start time end =========*/

        /*========= assign end time start =========*/
        response.data?.endtime && setEndTime(response.data.endtime);
        /*========= assign end time end =========*/

        /*========= assign activity with name and code start =========*/
        if (response.data.activitycode) {
          let activityData = {
            label:
              response.data.activitycode.code +
              ' : ' +
              response.data.activitycode.name,
            value: response.data.activitycode._id,
          };
          activitySelectionHandler(activityData);
        }
        /*========= assign activity with name and code end =========*/

        /*========= assign activity type start =========*/
        if (response.data.activitytype) {
          let activityData = {
            label: response.data.activitytype,
            value: response.data.activitytype,
          };
          selectActivityTypeHandler(activityData);
        }
        /*========= assign activity type end =========*/

        /*========= no of educator start =========*/
        response.data?.educatorcount &&
          setNoOfEducators(response.data.educatorcount);
        /*========= no of educator end =========*/

        /*========= no of participants start =========*/
        response.data?.participantcount &&
          setNoOfParticipants(response.data.participantcount);
        /*========= no of participants end =========*/

        /*========= no of participants real start =========*/
        response.data?.realparticipantcount &&
          setNoParticipantsReal(response.data.realparticipantcount);
        /*========= no of participants real end =========*/

        /*========= no of billing unit start =========*/
        response.data?.billingunits &&
          setBillingUnit(response.data.billingunits);
        /*========= no of billing unit end =========*/

        /*========= tutor start =========*/
        response.data?.tutor && setTutor(response.data.tutor);
        /*========= tutor end =========*/

        /*========= grade start =========*/
        response.data?.grade && setGrade(response.data.grade);
        /*========= grade end =========*/

        /*========= assign istemplate start =========*/
        response.data?.istemplate && setIsTemplate(response.data.istemplate);
        /*========= assign istemplate end =========*/

        /*========= assign educator start =========*/
        if (response.data.educators) {
          let educatorValue = response.data.educators.map(educator => ({
            label: educator.name + ' ' + educator.surname,
            value: educator._id,
          }));

          educatorsSelectionHandler(educatorValue);
        }
        /*========= assign educator end =========*/

        /*========= assign levels start =========*/
        if (response.data.levels) {
          let levelValue = response.data.levels.map(level => ({
            label: level,
            value: level,
          }));
          levelSelectionHandler(levelValue);
        }
        /*========= assign levels end =========*/

        /*========= specific need start =========*/
        if (response.data.specificneeds) {
          let needValue = response.data.specificneeds.map(needValue => ({
            label: needValue,
            value: needValue,
          }));
          selectSpecialNeedsHandler(needValue);
        }
        /*========= specific need end =========*/

        /*========= batch/lot start =========*/
        if (response.data.batch) {
          batchSelectionHandler({
            label: response.data.batch,
            value: response.data.batch,
          });
        }
        /*========= batch/lot end =========*/

        /*========= specific audience start =========*/
        if (response.data.specificaudience) {
          let audienceValue = response.data.specificaudience.map(audience => ({
            label: audience,
            value: audience,
          }));
          specificAudienceHandler(audienceValue);
        }
        /*========= specific audience end =========*/

        /*========= no of companion start =========*/
        response.data?.companioncount &&
          setNoOfCompanions(response.data.companioncount);
        /*========= no of companion end =========*/

        /*========= assign client start =========*/
        if (response.data.clients) {
          let clientValue = response.data.clients.map(client => ({
            label:
              client.name && client.surname
                ? client.name + ' ' + client.surname
                : client.email,
            value: client._id,
          }));
          selectClientHandler(clientValue);
        }
        /*========= assign client end =========*/

        /*========= assign observer start =========*/
        if (response.data.observers) {
          let observerValue = response.data.observers.map(observer => ({
            label: observer.name + ' ' + observer.surname,
            value: observer._id,
          }));
          selectObserverHandler(observerValue);
        }
        /*========= assign observer end =========*/

        /*========= assign Beneficiary start =========*/
        if (response.data.beneficiaries) {
          let beneficiaryValue = response.data.beneficiaries.map(
            beneficiary => ({
              label: beneficiary.name + ' ' + beneficiary.surname,
              value: beneficiary._id,
            })
          );
          selectBenificiaryHandler(beneficiaryValue);
        }
        /*========= assign Beneficiary end =========*/

        /*========= assign Participants start =========*/
        if (response.data.participants) {
          let participantsValue = response.data.participants.map(
            participant => ({
              label: participant.name + ' ' + participant.surname,
              value: participant._id,
            })
          );
          selectParticipantsHandler(participantsValue);
        } /*========= assign Participants end =========*/

        /*========= assign Location start =========*/
        response.data?.location && setLocation(response.data.location);
        /*========= assign Location end =========*/

        /*========= assign zipcode start =========*/
        response.data?.zipcode && setZipCode(response.data.zipcode);
        /*========= assign zipcode end =========*/

        /*========= assign responsiblename start =========*/
        response.data?.responsiblename &&
          setResponsileName(response.data.responsiblename);
        /*========= assign responsiblename end =========*/

        /*========= assign responsiblemail start =========*/
        response.data?.responsiblemail &&
          setResponsileEmail(response.data.responsiblemail);
        /*========= assign responsiblemail end =========*/

        /*========= assign responsiblephone start =========*/
        response.data?.responsiblephone &&
          setResponsilePhone(response.data.responsiblephone);
        /*========= assign responsiblephone end =========*/

        /*========= assign comments start =========*/
        response.data?.comments && setComments(response.data.comments);
        /*========= assign comments end =========*/

        /*========= assign Labels / Tags start =========*/
        if (response.data.tags) {
          let tagValue = response.data.tags.map(tag => ({
            label: tag.title,
            value: tag._id,
          }));
          labelSelectionHandler(tagValue);
        } /*========= assign Labels / Tags end =========*/
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  /* function to save reservation */
  const saveReservationHandler = async e => {
    e.preventDefault();

    let reservationData = {
      parentid: editReserveData ? editReserveData._id : null,
      reserveid: reserveId,
      ambreservationid: ambReserveId,
      reservestatus: statusData,
      course: courseData,
      startdate: startDate,
      starttime: startTime,
      endtime: endTime,
      activitytype: activityTypeData,
      activitycode: activityData,
      educatorcount: noOfEducators,
      participantcount: noOfParticipants,
      realparticipantcount: noParticipantsReal,
      billingunits: billingUnit,
      grade,
      tutor,
      educators: educatorData,
      levels: levelData,
      specificneeds: specialNeedsData,
      batch: batchData,
      specificaudience: specificAudienceData,
      companioncount: noOfCompanions,
      clients: clientData,
      observers: observerData,
      beneficiaries: benificiaryData,
      participants: participantsData,
      location,
      zipcode: zipCode,
      responsiblename: responsileName,
      responsiblemail: responsileEmail,
      responsiblephone: responsilePhone,
      comments,
      tags: labelData,
      istemplate: isTemplate,
    };

    if (validateForm()) {
      try {
        console.log(`reservation Data ==========>>>`, reservationData);

        let requestUrl =
          url.API_BaseUrl + url.API_ADD_RESERVE_EVENT + `?token=${token}`;

        if (editReserveData) {
          requestUrl = requestUrl + `&previousid=${editReserveData._id}`;
        }

        const response = await postData(requestUrl, reservationData);

        console.log(`reservation response ==========>>>`, reservationData);

        if (response.status) {
          resetHandler();
          setNotificationString('Reservation updated successfully');
          setSuccess(true);
          setReloadReservationList(true);
        }
      } catch (error) {
        console.error(error.message);
        resetHandler();
        setNotificationString('Something went wrong, please try again later');
        setError(true);
      }

      let offCanvasPopup = document.querySelector('#saveReservationPopup');
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    }
  };

  //function for call api react select
  const optionHandler = () => {
    getAllCourseList();
    getAllActivityList();
    getAllUserList();
    getAllReserveTemplate();
  };

  //function for reset
  const resetHandler = () => {
    let statusData = {
      label: 'Pending Confirmation',
      value: '3',
    };
    statusSelectionHandler(statusData); //set status value

    setEditReserveData(null);
    setCopyReserveData(null);

    setReserveId(null);
    setAmbReserveId(null);
    setStartDate(dateOfToday);
    setstartTime(getCurrentTime());
    setEndTime(getCurrentTime());
    setNoOfEducators('1');
    setNoOfParticipants('');
    setNoParticipantsReal('');
    setBillingUnit('');
    setTutor('');
    setGrade('');
    setNoOfCompanions('');
    setLocation('');
    setZipCode('');
    setResponsileName('');
    setResponsileEmail('');
    setResponsilePhone('');
    setComments('');
    // setStatusData(null);
    // setCourseData(null);
    setActivityData(null);
    setActivityTypeData(null);
    setEducatorData([]);
    setLevelData([]);
    setSpecialNeedsData([]);
    setBatchData(null);
    setSpecificAudienceData([]);
    setClientData([]);
    setObserverData([]);
    setBenificiaryData([]);
    setParticipantsData([]);
    setLabelData([]);
    setIsTemplate(false);
    setChangeSelect(prevState => ({
      ...prevState,
      selectedTemplate: null,
      // selectedStatus: null,
      // selectedCourses: null,
      selectedActivity: null,
      selectedActivityType: null,
      selectedEducators: null,
      selectedParticipants: null,
      selectedScope: null,
      selectedSpecialNeeds: null,
      selectedBatch: null,
      selectedClient: null,
      selectedObserver: null,
      selectedAudience: null,
      selectedBenificiary: null,
      selectLabels: null,
    }));

    setValidation({
      startDateWarning: false,
      startTimeWarning: false,
      endTimeWarning: false,
      noOfEducatorsWarning: false,
      noOfParticipantsWarning: false,
      noParticipantsRealWarning: false,
      billingUnitWarning: false,
      noOfCompanionsWarning: false,
      locationWarning: false,
      zipcodewarning: false,
      responsileNameWarning: false,
      responsileEmailWarning: false,
      responsilePhoneWarning: false,
      commentsWarning: false,
      statusWarning: false,
      courseWarning: false,
      activityWarning: false,
      activityTypeWarning: false,
      educatorWarning: false,
      levelWarning: false,
      specialNeedsWarning: false,
      batchWarning: false,
      specificAudienceWarning: false,
      clientWarning: false,
      observerWarning: false,
      benificiaryWarning: false,
      participantsWarning: false,
      timeCompareWarning: false,
    });
    optionHandler();
  };

  //use effect for assign edit data
  useEffect(() => {
    if (editReserveData) {
      /* remove the wwarnings */
      setValidation({
        startDateWarning: false,
        startTimeWarning: false,
        endTimeWarning: false,
        noOfEducatorsWarning: false,
        noOfParticipantsWarning: false,
        noParticipantsRealWarning: false,
        billingUnitWarning: false,
        noOfCompanionsWarning: false,
        locationWarning: false,
        zipcodewarning: false,
        responsileNameWarning: false,
        responsileEmailWarning: false,
        responsilePhoneWarning: false,
        commentsWarning: false,
        statusWarning: false,
        courseWarning: false,
        activityWarning: false,
        activityTypeWarning: false,
        educatorWarning: false,
        levelWarning: false,
        specialNeedsWarning: false,
        batchWarning: false,
        specificAudienceWarning: false,
        clientWarning: false,
        observerWarning: false,
        benificiaryWarning: false,
        participantsWarning: false,
      });
      console.log('editReserveData===========>', editReserveData);

      //assign reserve id
      editReserveData?.reserveid && setReserveId(editReserveData.reserveid);

      //assign ambreserve id
      editReserveData?.ambreservationid &&
        setAmbReserveId(editReserveData.ambreservationid);

      /*========= assign status start =========*/
      if (editReserveData.reservestatus == '1') {
        let statusData = {
          label: 'Confirmed',
          value: '1',
        };
        statusSelectionHandler(statusData); //set status value
      } else if (editReserveData.reservestatus == '0') {
        let statusData = {
          label: 'Delete reserve',
          value: '0',
        };
        statusSelectionHandler(statusData); //set status value
      } else if (editReserveData.reservestatus == '3') {
        let statusData = {
          label: 'Pending Confirmation',
          value: '3',
        };
        statusSelectionHandler(statusData); //set status value
      } else {
        let statusData = {
          label: 'Cancelled at the moment',
          value: '2',
        };
        statusSelectionHandler(statusData); //set status value
      }
      /*========= assign status end =========*/

      /*========= assign course start =========*/
      if (editReserveData.course) {
        let courseData = {
          label: editReserveData.course.name,
          value: editReserveData.course._id,
        };
        courseSelectionHandler(courseData);
      }
      /*========= assign course end =========*/

      /*========= assign date start =========*/
      editReserveData?.startdate && setStartDate(editReserveData.startdate);
      /*========= assign date end =========*/

      /*========= assign start time start =========*/
      editReserveData?.starttime && setstartTime(editReserveData.starttime);
      /*========= assign start time end =========*/

      /*========= assign end time start =========*/
      editReserveData?.endtime && setEndTime(editReserveData.endtime);
      /*========= assign end time end =========*/

      /*========= assign activity with name and code start =========*/
      if (editReserveData.activitycode) {
        let activityData = {
          label:
            editReserveData.activitycode.code +
            ' : ' +
            editReserveData.activitycode.name,
          value: editReserveData.activitycode._id,
        };
        activitySelectionHandler(activityData);
      }
      /*========= assign activity with name and code end =========*/

      /*========= assign activity type start =========*/
      if (editReserveData.activitytype) {
        let activityData = {
          label: editReserveData.activitytype,
          value: editReserveData.activitytype,
        };
        selectActivityTypeHandler(activityData);
      }
      /*========= assign activity type end =========*/

      /*========= no of educator start =========*/
      editReserveData?.educatorcount &&
        setNoOfEducators(editReserveData.educatorcount);
      /*========= no of educator end =========*/

      /*========= no of participants start =========*/
      editReserveData?.participantcount &&
        setNoOfParticipants(editReserveData.participantcount);
      /*========= no of participants end =========*/

      /*========= no of participants real start =========*/
      editReserveData?.realparticipantcount &&
        setNoParticipantsReal(editReserveData.realparticipantcount);
      /*========= no of participants real end =========*/

      /*========= no of billing unit start =========*/
      editReserveData?.billingunits &&
        setBillingUnit(editReserveData.billingunits);
      /*========= no of billing unit end =========*/

      /*========= tutor start =========*/
      editReserveData?.tutor && setTutor(editReserveData.tutor);
      /*========= tutor end =========*/

      /*========= grade start =========*/
      editReserveData?.grade && setGrade(editReserveData.grade);
      /*========= grade end =========*/

      /*========= assign istemplate start =========*/
      editReserveData?.istemplate && setIsTemplate(editReserveData.istemplate);
      /*========= assign istemplate end =========*/

      /*========= assign educator start =========*/
      if (editReserveData.educators) {
        let educatorValue = editReserveData.educators.map(educator => ({
          label: educator.name + ' ' + educator.surname,
          value: educator._id,
        }));

        educatorsSelectionHandler(educatorValue);
      }
      /*========= assign educator end =========*/

      /*========= assign levels start =========*/
      if (editReserveData.levels) {
        let levelValue = editReserveData.levels.map(level => ({
          label: level,
          value: level,
        }));
        levelSelectionHandler(levelValue);
      }
      /*========= assign levels end =========*/

      /*========= specific need start =========*/
      if (editReserveData.specificneeds) {
        let needValue = editReserveData.specificneeds.map(needValue => ({
          label: needValue,
          value: needValue,
        }));
        selectSpecialNeedsHandler(needValue);
      }
      /*========= specific need end =========*/

      /*========= batch/lot start =========*/
      if (editReserveData.batch) {
        batchSelectionHandler({
          label: editReserveData.batch,
          value: editReserveData.batch,
        });
      }
      /*========= batch/lot end =========*/

      /*========= specific audience start =========*/
      if (editReserveData.specificaudience) {
        let audienceValue = editReserveData.specificaudience.map(audience => ({
          label: audience,
          value: audience,
        }));
        specificAudienceHandler(audienceValue);
      }
      /*========= specific audience end =========*/

      /*========= no of companion start =========*/
      editReserveData?.companioncount &&
        setNoOfCompanions(editReserveData.companioncount);
      /*========= no of companion end =========*/

      /*========= assign client start =========*/
      if (editReserveData.clients) {
        let clientValue = editReserveData.clients.map(client => ({
          label:
            client.name && client.surname
              ? client.name + ' ' + client.surname
              : client.email,
          value: client._id,
        }));
        selectClientHandler(clientValue);
      }
      /*========= assign client end =========*/

      /*========= assign observer start =========*/
      if (editReserveData.observers) {
        let observerValue = editReserveData.observers.map(observer => ({
          label: observer.name + ' ' + observer.surname,
          value: observer._id,
        }));
        selectObserverHandler(observerValue);
      }
      /*========= assign observer end =========*/

      /*========= assign Beneficiary start =========*/
      if (editReserveData.beneficiaries) {
        let beneficiaryValue = editReserveData.beneficiaries.map(
          beneficiary => ({
            label: beneficiary.name + ' ' + beneficiary.surname,
            value: beneficiary._id,
          })
        );
        selectBenificiaryHandler(beneficiaryValue);
      }
      /*========= assign Beneficiary end =========*/

      /*========= assign Participants start =========*/
      if (editReserveData.participants) {
        let participantsValue = editReserveData.participants.map(
          participant => ({
            label: participant.name + ' ' + participant.surname,
            value: participant._id,
          })
        );
        selectParticipantsHandler(participantsValue);
      } /*========= assign Participants end =========*/

      /*========= assign Location start =========*/
      editReserveData?.location && setLocation(editReserveData.location);
      /*========= assign Location end =========*/

      /*========= assign zipcode start =========*/
      editReserveData?.zipcode && setZipCode(editReserveData.zipcode);
      /*========= assign zipcode end =========*/

      /*========= assign responsiblename start =========*/
      editReserveData?.responsiblename &&
        setResponsileName(editReserveData.responsiblename);
      /*========= assign responsiblename end =========*/

      /*========= assign responsiblemail start =========*/
      editReserveData?.responsiblemail &&
        setResponsileEmail(editReserveData.responsiblemail);
      /*========= assign responsiblemail end =========*/

      /*========= assign responsiblephone start =========*/
      editReserveData?.responsiblephone &&
        setResponsilePhone(editReserveData.responsiblephone);
      /*========= assign responsiblephone end =========*/

      /*========= assign comments start =========*/
      editReserveData?.comments && setComments(editReserveData.comments);
      /*========= assign comments end =========*/

      /*========= assign Labels / Tags start =========*/
      if (editReserveData.tags) {
        let tagValue = editReserveData.tags.map(tag => ({
          label: tag.title,
          value: tag._id,
        }));
        labelSelectionHandler(tagValue);
      } /*========= assign Labels / Tags end =========*/
    }
  }, [editReserveData]);

  // on change of no. of educator, add same number of max educators
  useEffect(() => {
    if (editReserveData) {
      /* remove the wwarnings */
      setValidation({
        startDateWarning: false,
        startTimeWarning: false,
        endTimeWarning: false,
        noOfEducatorsWarning: false,
        noOfParticipantsWarning: false,
        noParticipantsRealWarning: false,
        billingUnitWarning: false,
        noOfCompanionsWarning: false,
        locationWarning: false,
        zipcodewarning: false,
        responsileNameWarning: false,
        responsileEmailWarning: false,
        responsilePhoneWarning: false,
        commentsWarning: false,
        statusWarning: false,
        courseWarning: false,
        activityWarning: false,
        activityTypeWarning: false,
        educatorWarning: false,
        levelWarning: false,
        specialNeedsWarning: false,
        batchWarning: false,
        specificAudienceWarning: false,
        clientWarning: false,
        observerWarning: false,
        benificiaryWarning: false,
        participantsWarning: false,
      });
      /*========= assign educator start =========*/
      if (editReserveData.educators) {
        let educatorValue = editReserveData.educators.map(educator => ({
          label: educator.name + ' ' + educator.surname,
          value: educator._id,
        }));

        educatorsSelectionHandler(educatorValue);
      }
      /*========= assign educator end =========*/
    }

    if (copyReserveData) {
      /* remove the wwarnings */
      setValidation({
        startDateWarning: false,
        startTimeWarning: false,
        endTimeWarning: false,
        noOfEducatorsWarning: false,
        noOfParticipantsWarning: false,
        noParticipantsRealWarning: false,
        billingUnitWarning: false,
        noOfCompanionsWarning: false,
        locationWarning: false,
        zipcodewarning: false,
        responsileNameWarning: false,
        responsileEmailWarning: false,
        responsilePhoneWarning: false,
        commentsWarning: false,
        statusWarning: false,
        courseWarning: false,
        activityWarning: false,
        activityTypeWarning: false,
        educatorWarning: false,
        levelWarning: false,
        specialNeedsWarning: false,
        batchWarning: false,
        specificAudienceWarning: false,
        clientWarning: false,
        observerWarning: false,
        benificiaryWarning: false,
        participantsWarning: false,
      });
      /*========= assign educator start =========*/
      if (copyReserveData.educators) {
        let educatorValue = copyReserveData.educators.map(educator => ({
          label: educator.name + ' ' + educator.surname,
          value: educator._id,
        }));

        educatorsSelectionHandler(educatorValue);
      }
      /*========= assign educator end =========*/
    }
  }, [noOfEducators]);

  // on change of no. of participants, add same number of max educators
  useEffect(() => {
    if (editReserveData) {
      /* remove the wwarnings */
      setValidation({
        startDateWarning: false,
        startTimeWarning: false,
        endTimeWarning: false,
        noOfEducatorsWarning: false,
        noOfParticipantsWarning: false,
        noParticipantsRealWarning: false,
        billingUnitWarning: false,
        noOfCompanionsWarning: false,
        locationWarning: false,
        zipcodewarning: false,
        responsileNameWarning: false,
        responsileEmailWarning: false,
        responsilePhoneWarning: false,
        commentsWarning: false,
        statusWarning: false,
        courseWarning: false,
        activityWarning: false,
        activityTypeWarning: false,
        educatorWarning: false,
        levelWarning: false,
        specialNeedsWarning: false,
        batchWarning: false,
        specificAudienceWarning: false,
        clientWarning: false,
        observerWarning: false,
        benificiaryWarning: false,
        participantsWarning: false,
      });
      /*========= assign Participants start =========*/
      if (editReserveData.participants) {
        let participantsValue = editReserveData.participants.map(
          participant => ({
            label: participant.name + ' ' + participant.surname,
            value: participant._id,
          })
        );
        selectParticipantsHandler(participantsValue);
      } /*========= assign Participants end =========*/
    }

    if (copyReserveData) {
      /* remove the wwarnings */
      setValidation({
        startDateWarning: false,
        startTimeWarning: false,
        endTimeWarning: false,
        noOfEducatorsWarning: false,
        noOfParticipantsWarning: false,
        noParticipantsRealWarning: false,
        billingUnitWarning: false,
        noOfCompanionsWarning: false,
        locationWarning: false,
        zipcodewarning: false,
        responsileNameWarning: false,
        responsileEmailWarning: false,
        responsilePhoneWarning: false,
        commentsWarning: false,
        statusWarning: false,
        courseWarning: false,
        activityWarning: false,
        activityTypeWarning: false,
        educatorWarning: false,
        levelWarning: false,
        specialNeedsWarning: false,
        batchWarning: false,
        specificAudienceWarning: false,
        clientWarning: false,
        observerWarning: false,
        benificiaryWarning: false,
        participantsWarning: false,
      });
      /*========= assign Participants start =========*/
      if (copyReserveData.participants) {
        let participantsValue = copyReserveData.participants.map(
          participant => ({
            label: participant.name + ' ' + participant.surname,
            value: participant._id,
          })
        );
        selectParticipantsHandler(participantsValue);
      } /*========= assign Participants end =========*/
    }
  }, [noOfParticipants]);

  //use effect for reset form
  useEffect(() => {
    if (resetReserveSaveForm) {
      resetHandler();
      setResetReserveSaveForm(false);
    }
  }, [resetReserveSaveForm]);

  //use effect for copy reserve data
  useEffect(() => {
    if (copyReserveData) {
      /* remove the wwarnings */
      setValidation({
        startDateWarning: false,
        startTimeWarning: false,
        endTimeWarning: false,
        noOfEducatorsWarning: false,
        noOfParticipantsWarning: false,
        noParticipantsRealWarning: false,
        billingUnitWarning: false,
        noOfCompanionsWarning: false,
        locationWarning: false,
        zipcodewarning: false,
        responsileNameWarning: false,
        responsileEmailWarning: false,
        responsilePhoneWarning: false,
        commentsWarning: false,
        statusWarning: false,
        courseWarning: false,
        activityWarning: false,
        activityTypeWarning: false,
        educatorWarning: false,
        levelWarning: false,
        specialNeedsWarning: false,
        batchWarning: false,
        specificAudienceWarning: false,
        clientWarning: false,
        observerWarning: false,
        benificiaryWarning: false,
        participantsWarning: false,
      });

      console.log('copyReserveData=========>', copyReserveData);

      //assign ambreserve id
      copyReserveData?.ambreservationid &&
        setAmbReserveId(copyReserveData.ambreservationid);

      /*========= assign status start =========*/
      if (copyReserveData.reservestatus == '1') {
        let statusData = {
          label: 'Confirmed',
          value: '1',
        };
        statusSelectionHandler(statusData); //set status value
      } else if (copyReserveData.reservestatus == '0') {
        let statusData = {
          label: 'Delete reserve',
          value: '0',
        };
        statusSelectionHandler(statusData); //set status value
      } else if (copyReserveData.reservestatus == '3') {
          let statusData = {
            label: 'Pending Confirmation',
            value: '3',
          };
          statusSelectionHandler(statusData); //set status value
        } else {
        let statusData = {
          label: 'Cancelled at the moment',
          value: '2',
        };
        statusSelectionHandler(statusData); //set status value
      }
      /*========= assign status end =========*/

      /*========= assign course start =========*/
      if (copyReserveData.course) {
        let courseData = {
          label: copyReserveData.course.name,
          value: copyReserveData.course._id,
        };
        courseSelectionHandler(courseData);
      }
      /*========= assign course end =========*/

      /*========= assign date start =========*/
      copyReserveData?.startdate && setStartDate(copyReserveData.startdate);
      /*========= assign date end =========*/

      /*========= assign start time start =========*/
      copyReserveData?.starttime && setstartTime(copyReserveData.starttime);
      /*========= assign start time end =========*/

      /*========= assign end time start =========*/
      copyReserveData?.endtime && setEndTime(copyReserveData.endtime);
      /*========= assign end time end =========*/

      /*========= assign activity with name and code start =========*/
      if (copyReserveData.activitycode) {
        let activityData = {
          label:
            copyReserveData.activitycode.code +
            ' : ' +
            copyReserveData.activitycode.name,
          value: copyReserveData.activitycode._id,
        };
        activitySelectionHandler(activityData);
      }
      /*========= assign activity with name and code end =========*/

      /*========= assign activity type start =========*/
      if (copyReserveData.activitytype) {
        let activityData = {
          label: copyReserveData.activitytype,
          value: copyReserveData.activitytype,
        };
        selectActivityTypeHandler(activityData);
      }
      /*========= assign activity type end =========*/

      /*========= no of educator start =========*/
      copyReserveData?.educatorcount &&
        setNoOfEducators(copyReserveData.educatorcount);
      /*========= no of educator end =========*/

      /*========= no of participants start =========*/
      copyReserveData?.participantcount &&
        setNoOfParticipants(copyReserveData.participantcount);
      /*========= no of participants end =========*/

      /*========= no of participants real start =========*/
      copyReserveData?.realparticipantcount &&
        setNoParticipantsReal(copyReserveData.realparticipantcount);
      /*========= no of participants real end =========*/

      /*========= no of billing unit start =========*/
      copyReserveData?.billingunits &&
        setBillingUnit(copyReserveData.billingunits);
      /*========= no of billing unit end =========*/

      /*========= tutor start =========*/
      copyReserveData?.tutor && setTutor(copyReserveData.tutor);
      /*========= tutor end =========*/

      /*========= grade start =========*/
      copyReserveData?.grade && setGrade(copyReserveData.grade);
      /*========= grade end =========*/

      /*========= assign istemplate start =========*/
      copyReserveData?.istemplate && setIsTemplate(copyReserveData.istemplate);
      /*========= assign istemplate end =========*/

      /*========= assign educator start =========*/
      if (copyReserveData.educators) {
        let educatorValue = copyReserveData.educators.map(educator => ({
          label: educator.name + ' ' + educator.surname,
          value: educator._id,
        }));

        educatorsSelectionHandler(educatorValue);
      }
      /*========= assign educator end =========*/

      /*========= assign levels start =========*/
      if (copyReserveData.levels) {
        let levelValue = copyReserveData.levels.map(level => ({
          label: level,
          value: level,
        }));
        levelSelectionHandler(levelValue);
      }
      /*========= assign levels end =========*/

      /*========= specific need start =========*/
      if (copyReserveData.specificneeds) {
        let needValue = copyReserveData.specificneeds.map(needValue => ({
          label: needValue,
          value: needValue,
        }));
        selectSpecialNeedsHandler(needValue);
      }
      /*========= specific need end =========*/

      /*========= batch/lot start =========*/
      if (copyReserveData.batch) {
        batchSelectionHandler({
          label: copyReserveData.batch,
          value: copyReserveData.batch,
        });
      }
      /*========= batch/lot end =========*/

      /*========= specific audience start =========*/
      if (copyReserveData.specificaudience) {
        let audienceValue = copyReserveData.specificaudience.map(audience => ({
          label: audience,
          value: audience,
        }));
        specificAudienceHandler(audienceValue);
      }
      /*========= specific audience end =========*/

      /*========= no of companion start =========*/
      copyReserveData?.companioncount &&
        setNoOfCompanions(copyReserveData.companioncount);
      /*========= no of companion end =========*/

      /*========= assign client start =========*/
      if (copyReserveData.clients) {
        let clientValue = copyReserveData.clients.map(client => ({
          label:
            client.name && client.surname
              ? client.name + ' ' + client.surname
              : client.email,
          value: client._id,
        }));
        selectClientHandler(clientValue);
      }
      /*========= assign client end =========*/

      /*========= assign observer start =========*/
      if (copyReserveData.observers) {
        let observerValue = copyReserveData.observers.map(observer => ({
          label: observer.name + ' ' + observer.surname,
          value: observer._id,
        }));
        selectObserverHandler(observerValue);
      }
      /*========= assign observer end =========*/

      /*========= assign Beneficiary start =========*/
      if (copyReserveData.beneficiaries) {
        let beneficiaryValue = copyReserveData.beneficiaries.map(
          beneficiary => ({
            label: beneficiary.name + ' ' + beneficiary.surname,
            value: beneficiary._id,
          })
        );
        selectBenificiaryHandler(beneficiaryValue);
      }
      /*========= assign Beneficiary end =========*/

      /*========= assign Participants start =========*/
      if (copyReserveData.participants) {
        let participantsValue = copyReserveData.participants.map(
          participant => ({
            label: participant.name + ' ' + participant.surname,
            value: participant._id,
          })
        );
        selectParticipantsHandler(participantsValue);
      } /*========= assign Participants end =========*/

      /*========= assign Location start =========*/
      copyReserveData?.location && setLocation(copyReserveData.location);
      /*========= assign Location end =========*/

      /*========= assign zipcode start =========*/
      copyReserveData?.zipcode && setZipCode(copyReserveData.zipcode);
      /*========= assign zipcode end =========*/

      /*========= assign responsiblename start =========*/
      copyReserveData?.responsiblename &&
        setResponsileName(copyReserveData.responsiblename);
      /*========= assign responsiblename end =========*/

      /*========= assign responsiblemail start =========*/
      copyReserveData?.responsiblemail &&
        setResponsileEmail(copyReserveData.responsiblemail);
      /*========= assign responsiblemail end =========*/

      /*========= assign responsiblephone start =========*/
      copyReserveData?.responsiblephone &&
        setResponsilePhone(copyReserveData.responsiblephone);
      /*========= assign responsiblephone end =========*/

      /*========= assign comments start =========*/
      copyReserveData?.comments && setComments(copyReserveData.comments);
      /*========= assign comments end =========*/

      /*========= assign Labels / Tags start =========*/
      if (copyReserveData.tags) {
        let tagValue = copyReserveData.tags.map(tag => ({
          label: tag.title,
          value: tag._id,
        }));
        labelSelectionHandler(tagValue);
      } /*========= assign Labels / Tags end =========*/
    }
  }, [copyReserveData]);

  useEffect(() => {
    optionHandler();
    getAllLabels();
  }, []);

  // console.log(`start time`, startTime);
  // console.log(`start time`, new Date());

  // if (startTime < endTime) {
  //   console.log(`smaller`);
  // } else {
  //   console.log('greater');
  // }
  return (
    <div
      className="offcanvas offcanvas-end border-0"
      tabIndex="-1"
      id="saveReservationPopup"
      aria-labelledby="saveReservationPopupLabel"
    >
      <div className="offcanvas-header position-relative align-items-start p-4">
        <h3 className="text-secondary mb-0" id="saveReservationPopupLabel">
          <span className="d-block">{t('Save Reserve')}</span>
          {/* {editReserveData && (
            <p className="id fs-lg fw-medium text-uppercase mt-2">
              {t("ID")} &nbsp;
              <span>{editReserveData._id}</span>
            </p>
          )} */}
        </h3>
        <button
          type="button"
          className="btn-close p-2 bg-white shadow-none shadow-sm m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
        {/* <Link
          to="#ruleConfigurationPopup"
          className="rule-configuration-toggler bg-primary text-white position-absolute end-0 bottom-0 shadow-md"
          data-bs-toggle="offcanvas"
          role="button"
          aria-controls="ruleConfigurationPopup"
        >
          <span className="d-inline-block spinner-border material-symbols-outlined icon-fill w-auto h-auto border-0">
            settings
          </span>
        </Link> */}
      </div>
      <div className="offcanvas-body position-relative p-4 pt-0">
        <form onSubmit={saveReservationHandler}>
          <div className="form-group mb-4">
            {/* ======== select Template start =========*/}
            {editReserveData ? null : (
              <label htmlFor="selectTemplate">{t('Select Template')}</label>
            )}
            {editReserveData ? null : (
              <Select
                className="nosearch_select"
                options={reserveTemplateList}
                value={changeSelect.selectedTemplate}
                onChange={value => {
                  getReserveDetails(value);
                }}
              />
            )}
            {/* ======== select Template end =========*/}

            {/* ======== save as Template start =========*/}
            <div className="form-check mt-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="saveAsReserveTemplate"
                checked={isTemplate}
                onChange={e => {
                  setIsTemplate(e.target.checked);
                }}
              />
              <label
                className="form-check-label text-primary fw-medium"
                htmlFor="saveAsReserveTemplate"
              >
                {t('Save as Template')}
              </label>
            </div>
            {/* ======== save as Template end =========*/}
          </div>

          {/* ========== Activity start ============ */}
          <div className="form-group mb-4">
            <div className="row">
              {/* <div className="col-lg-4">
                <label htmlFor="code">{t("Code")}</label>
                <input
                  type="text"
                  id="code"
                  className="form-control"
                  value="ACTI2.3"
                />
              </div> */}

              {/* =========== select activity starts =================*/}
              <div className="col-lg-12">
                <label htmlFor="activity">{t('Activity')}</label>
                <Select
                  className="nosearch_select"
                  options={activityList}
                  value={changeSelect.selectedActivity}
                  onChange={val => {
                    activitySelectionHandler(val),
                      setValidation(prevState => ({
                        ...prevState,
                        activityWarning: false,
                      }));
                  }}
                />
                {/* activity warning */}
                {validation.activityWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter activity')}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* =========== select activity ends =================*/}
            </div>
          </div>
          {/* ========== Activity end ============ */}
          {/* =========== select activity type starts =================*/}
          <div className="form-group mb-4">
            <label htmlFor="typeOfActivity">{t('Type of Activity')}</label>
            <Select
              className="nosearch_select"
              options={typeOfActivity}
              value={changeSelect.selectedActivityType}
              onChange={val => {
                selectActivityTypeHandler(val);
                // setValidation(prevState => ({
                //   ...prevState,
                //   activityTypeWarning: false,
                // }));
              }}
            />
            {/* Activity type warning */}
            {/* {validation.activityTypeWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter type of activity')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* =========== select activity type ends =================*/}
          <div className="form-group mb-4">
            <div className="row">
              {/* ======== select status start =========*/}
              <div className="col-lg-6">
                <label htmlFor="status">{t('Status')}</label>
                <Select
                  className="nosearch_select"
                  options={StatusOptions}
                  value={changeSelect.selectedStatus}
                  onChange={val => {
                    statusSelectionHandler(val),
                      setValidation(prevState => ({
                        ...prevState,
                        statusWarning: false,
                      }));
                  }}
                />
                {/* status warning */}
                {validation.statusWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter status')}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ======== select status end =========*/}

              {/* ======== select courses start =========*/}
              <div className="col-lg-6">
                <label htmlFor="course">{t('Course')}</label>
                <Select
                  className="nosearch_select"
                  options={courseList}
                  value={changeSelect.selectedCourses}
                  onChange={val => {
                    courseSelectionHandler(val),
                      setValidation(prevState => ({
                        ...prevState,
                        courseWarning: false,
                      }));
                  }}
                />
                {/* Course warning */}
                {validation.courseWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter Course')}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ======== select courses end =========*/}
            </div>
          </div>

          {/* ========== date/time section start ============ */}
          <div className="form-group mb-4">
            <div className="row">
              <div className="col-lg-4">
                <label htmlFor="date">{t('Date')}</label>
                <input
                  type="date"
                  id="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  value={startDate}
                  onChange={e => {
                    setStartDate(e.target.value),
                      setValidation(prevState => ({
                        ...prevState,
                        startDateWarning: false,
                      }));
                  }}
                />
                {/* start date warning */}
                {validation.startDateWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter date')}!</span>
                    </p>
                  </div>
                )}
                {/* start date warning */}
                {/* {validation.startDateWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter start date')}!</span>
                      </p>
                    </div>
                  )} */}
              </div>

              <div className="col-lg-4">
                <label htmlFor="startTime">{t('Start Time')}</label>
                <input
                  type="time"
                  id="startTime"
                  className="form-control"
                  placeholder="HH : MM"
                  value={startTime}
                  onChange={e => {
                    setstartTime(e.target.value),
                      setValidation(prevState => ({
                        ...prevState,
                        startTimeWarning: false,
                      }));
                    setValidation(prevState => ({
                      ...prevState,
                      timeCompareWarning: false,
                    }));
                  }}
                />
                {/* start time warning */}
                {validation.startTimeWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter start time')}!</span>
                    </p>
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <label htmlFor="endTime">{t('End Time')}</label>
                <input
                  type="time"
                  id="endTime"
                  className="form-control"
                  placeholder="HH : MM"
                  value={endTime}
                  onChange={e => {
                    setEndTime(e.target.value),
                      setValidation(prevState => ({
                        ...prevState,
                        endTimeWarning: false,
                      }));
                    setValidation(prevState => ({
                      ...prevState,
                      timeCompareWarning: false,
                    }));
                  }}
                />
                {/* start time warning */}
                {validation.endTimeWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter end time')}!</span>
                    </p>
                  </div>
                )}
                {/* time compare warning */}
                {validation.timeCompareWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('End time should be greater start time')}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* <Link
                to="#"
                className="d-flex gap-2 align-items-center text-primary fw-medium mt-3"
              >
                <span className="d-block material-symbols-outlined icon-md icon-fill">
                  free_cancellation
                </span>{" "}
                {t("Check Availability")}
              </Link> */}
              {/* <div className="col-lg-6">
                <label htmlFor="date">{t('End Date')}</label>
                <input
                  type="date"
                  id="date"
                  className="form-control"
                  placeholder="DD/MM/YYYY"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
                
                {validation.endDateWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter end date')}!</span>
                      </p>
                    </div>
                  )}
              </div> */}
            </div>
          </div>
          {/* ========== date/time section end ============ */}

          <div className="form-group mb-4">
            <div className="row">
              {/* ========= no of educator section start ========= */}
              <div className="col-lg-3">
                <label htmlFor="numberOfEducators">
                  {t('Number of Educators')}
                </label>
                <input
                  type="text"
                  id="numberOfEducators"
                  className="form-control"
                  value={noOfEducators}
                  onChange={e => {
                    console.log(e.target.value);

                    setNoOfEducators(e.target.value);
                    setValidation(prevState => ({
                      ...prevState,
                      noOfEducatorsWarning: false,
                    }));
                  }}
                />
                {/* start date warning */}
                {validation.noOfEducatorsWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter number of educators')}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ========= no of educator section end ========= */}

              {/* ========= no of participants section start ========= */}
              <div className="col-lg-3">
                <label htmlFor="numberOfParticipants">
                  {t('Number of Participants')}
                </label>
                <input
                  type="text"
                  id="numberOfParticipants"
                  className="form-control"
                  value={noOfParticipants}
                  onChange={e => {
                    setNoOfParticipants(e.target.value),
                      setValidation(prevState => ({
                        ...prevState,
                        noOfParticipantsWarning: false,
                      }));
                  }}
                />
                {/* start date warning */}
                {validation.noOfParticipantsWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter number of participants')}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ========= no of participants section start ========= */}

              {/* ========= no of real participants section start ========= */}
              <div className="col-lg-3">
                <label htmlFor="numberParticipantsReal">
                  {t('Number Participants Real')}
                </label>
                <input
                  type="text"
                  id="numberParticipantsReal"
                  className="form-control"
                  value={noParticipantsReal}
                  onChange={e => {
                    setNoParticipantsReal(e.target.value);
                    // setValidation(prevState => ({
                    //   ...prevState,
                    //   noParticipantsRealWarning: false,
                    // }));
                  }}
                />
                {/* Participants Real warning */}
                {/* {validation.noParticipantsRealWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter participants real')}!</span>
                    </p>
                  </div>
                )} */}
              </div>
              {/* ========= no of real participants section start ========= */}

              {/* ========= no billing unit section start ========= */}
              <div className="col-lg-3">
                <label htmlFor="billingUnit">{t('Billing Unit')}</label>
                <input
                  type="text"
                  id="billingUnit"
                  className="form-control"
                  // placeholder="Enter Here"
                  value={billingUnit}
                  onChange={e => {
                    setBillingUnit(e.target.value);
                    // setValidation(prevState => ({
                    //   ...prevState,
                    //   billingUnitWarning: false,
                    // }));
                  }}
                />
                {/* billing unit warning */}
                {/* {validation.billingUnitWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter billing unit')}!</span>
                    </p>
                  </div>
                )} */}
              </div>
              {/* ========= no billing unit section end ========= */}
            </div>
          </div>

          <div className="form-group mb-4">
            <div className="row">
              {/* ------- tutor section start ------- */}
              <div className="col-lg-6">
                <label htmlFor="billingUnit">{t('Tutor')}</label>
                <input
                  type="text"
                  id="billingUnit"
                  className="form-control"
                  // placeholder="Enter Here"
                  value={tutor}
                  onChange={e => {
                    setTutor(e.target.value);
                  }}
                />
              </div>
              {/* ------- tutor section end ------- */}

              {/* ------- grade section start ------- */}
              <div className="col-lg-6">
                <label htmlFor="billingUnit">{t('Grade')}</label>
                <input
                  type="text"
                  id="billingUnit"
                  className="form-control"
                  // placeholder="Enter Here"
                  value={grade}
                  onChange={e => {
                    setGrade(e.target.value);
                  }}
                />
              </div>
              {/* ------- grade section end ------- */}
            </div>
          </div>

          {/* ============ select educators starts ============= */}
          <div className="form-group mb-4">
            <label htmlFor="educators">{t('Educators')}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={educatorList}
              value={changeSelect.selectedEducators}
              onChange={val => {
                educatorsSelectionHandler(val),
                  setValidation(prevState => ({
                    ...prevState,
                    educatorWarning: false,
                  }));
              }}
            />
            {/* Educators warning */}
            {validation.educatorWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter educators')}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ============ select educators ends ============= */}

          {/* ============ select level starts ============= */}
          <div className="form-group mb-4">
            <label htmlFor="scope">{t('Levels')}</label>
            <Select
              className="nosearch_select"
              options={levelOption}
              isMulti
              value={changeSelect.selectedScope}
              onChange={val => {
                levelSelectionHandler(val);
                // setValidation(prevState => ({
                //   ...prevState,
                //   levelWarning: false,
                // }));
              }}
            />
            {/* levels warning */}
            {/* {validation.levelWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter levels')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ============ select level ends ============= */}

          {/* ========= specific needs start =========== */}
          <div className="form-group mb-4">
            <label htmlFor="scope">{t('Specific Needs')}</label>
            <Select
              className="nosearch_select"
              options={SpecialNeeds}
              isMulti
              value={changeSelect.selectedSpecialNeeds}
              onChange={val => {
                selectSpecialNeedsHandler(val);
                // setValidation(prevState => ({
                //   ...prevState,
                //   specialNeedsWarning: false,
                // }));
              }}
            />
            {/* specific needs warning */}
            {/* {validation.specialNeedsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter specific needs')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ========= specific needs ends =========== */}

          <div className="form-group mb-4">
            <div className="row">
              {/* ============ select batch starts ============= */}

              <div className="col-lg-4">
                <label htmlFor="batch">{t('Batch')}</label>
                <Select
                  className="nosearch_select"
                  options={batchLotOption}
                  value={changeSelect.selectedBatch}
                  onChange={val => {
                    batchSelectionHandler(val);
                    // setValidation(prevState => ({
                    //   ...prevState,
                    //   batchWarning: false,
                    // }));
                  }}
                />
                {/* batch warning */}
                {/* {validation.batchWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter batch')}!</span>
                    </p>
                  </div>
                )} */}
              </div>
              {/* ============ select batch ends ============= */}

              <div className="col-lg-4">
                <label htmlFor="specificAudience">
                  {t('Specific Audience')}
                </label>
                <Select
                  className="nosearch_select"
                  isMulti
                  options={specificAudience}
                  value={changeSelect.selectedAudience}
                  onChange={val => {
                    specificAudienceHandler(val);
                    // setValidation(prevState => ({
                    //   ...prevState,
                    //   specificAudienceWarning: false,
                    // }));
                  }}
                />
                {/* specific audienceWarning warning */}
                {/* {validation.specificAudienceWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter specific audience')}!</span>
                    </p>
                  </div>
                )} */}
              </div>
              <div className="col-lg-4">
                <label htmlFor="numberOfCompanions">
                  {t('Number of Companions')}
                </label>
                <input
                  type="text"
                  id="numberOfCompanions"
                  className="form-control"
                  placeholder={t('Companions Numbers')}
                  value={noOfCompanions}
                  onChange={e => {
                    setNoOfCompanions(e.target.value);
                    // setValidation(prevState => ({
                    //   ...prevState,
                    //   noOfCompanionsWarning: false,
                    // }));
                  }}
                />
                {/* no of companionsWarning warning */}
                {/* {validation.noOfCompanionsWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter number of companions')}!</span>
                    </p>
                  </div>
                )} */}
              </div>
            </div>
          </div>

          <div className="form-group mb-4">
            <div className="row">
              {/* ============ select beneficiary start ============= */}
              <div className="col-lg-4">
                <label htmlFor="beneficiary">{t('Beneficiary')}</label>
                <Select
                  className="nosearch_select"
                  options={benificiaryList}
                  isMulti
                  value={changeSelect.selectedBenificiary}
                  onChange={val => {
                    selectBenificiaryHandler(val),
                      setValidation(prevState => ({
                        ...prevState,
                        benificiaryWarning: false,
                      }));
                  }}
                />
                {/* benificiary warning */}
                {validation.benificiaryWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter benificiary')}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ============ select beneficiary end ============= */}

              {/* ============ select clients starts ============= */}
              <div className="col-lg-4">
                <label htmlFor="client">{t('Client')}</label>
                <Select
                  className="nosearch_select"
                  isMulti
                  options={clientList}
                  value={changeSelect.selectedClient}
                  onChange={val => {
                    selectClientHandler(val),
                      setValidation(prevState => ({
                        ...prevState,
                        clientWarning: false,
                      }));
                  }}
                />
                {/* client warning warning */}
                {validation.clientWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter client')}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ============ select clients ends ============= */}

              {/* ============ select observer starts ============= */}
              <div className="col-lg-4">
                <label htmlFor="observer">{t('Observer')}</label>
                <Select
                  className="nosearch_select"
                  options={observerList}
                  isMulti
                  value={changeSelect.selectedObserver}
                  onChange={val => {
                    selectObserverHandler(val),
                      setValidation(prevState => ({
                        ...prevState,
                        observerWarning: false,
                      }));
                  }}
                />
                {/* observer warning */}
                {validation.observerWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>{t('Please enter observer')}!</span>
                    </p>
                  </div>
                )}
              </div>
              {/* ============ select observer ends ============= */}
            </div>
          </div>

          {/* ============= participants start ===================== */}
          <div className="form-group mb-4">
            <label htmlFor="educators">{t('Participants')}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={participantList}
              value={changeSelect.selectedParticipants}
              onChange={val => {
                selectParticipantsHandler(val),
                  setValidation(prevState => ({
                    ...prevState,
                    participantsWarning: false,
                  }));
              }}
            />
            {/* ParticipantsWarning warning */}
            {validation.participantsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter participants')}!</span>
                </p>
              </div>
            )}
          </div>
          {/* ============= participants end ===================== */}

          <div className="row">
            {/*========== location start ==========*/}
            <div className="col-lg-6">
              <div className="form-group mb-4">
                <label htmlFor="location">{t('Location')}</label>
                <div className="field-container position-relative">
                  <input
                    type="text"
                    id="location"
                    className="form-control"
                    placeholder={t('Enter Address')}
                    value={location}
                    onChange={e => {
                      setLocation(e.target.value);
                      // setValidation(prevState => ({
                      //   ...prevState,
                      //   locationWarning: false,
                      // }));
                    }}
                  />
                  {/* Location warning */}
                  {/* {validation.locationWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter location')}!</span>
                      </p>
                    </div>
                  )} */}
                  <span className="d-block material-symbols-outlined icon-md text-gray position-absolute top-50 end-0 translate-middle-y me-3">
                    my_location
                  </span>
                </div>
              </div>
            </div>
            {/*========== location end ==========*/}

            {/*========== zipcode start ==========*/}
            <div className="col-lg-6">
              <div className="form-group mb-4">
                <label htmlFor="location">{t('Zipcode')}</label>
                <div className="field-container position-relative">
                  <input
                    type="text"
                    id="location"
                    className="form-control"
                    placeholder={t('Enter zipcode')}
                    value={zipCode}
                    onChange={e => {
                      setZipCode(e.target.value),
                        setValidation(prevState => ({
                          ...prevState,
                          zipcodewarning: false,
                        }));
                    }}
                  />
                  {/* Location warning */}
                  {validation.zipcodewarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t('Please enter zipcode')}!</span>
                      </p>
                    </div>
                  )}
                  <span className="d-block material-symbols-outlined icon-md text-gray position-absolute top-50 end-0 translate-middle-y me-3">
                    location_on
                  </span>
                </div>
              </div>
            </div>
            {/*========== zipcode end ==========*/}
          </div>

          {/* ========== Responsible name starts ============== */}
          <div className="form-group mb-4">
            <label>{t('Responsible Name')}</label>
            <input
              type="text"
              className="form-control"
              placeholder={t('Enter Responsible Name')}
              value={responsileName}
              onChange={e => {
                setResponsileName(e.target.value);
                // setValidation(prevState => ({
                //   ...prevState,
                //   responsileNameWarning: false,
                // }));
              }}
            />
            {/* Responsible name warning */}
            {/* {validation.responsileNameWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter responsible name')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ========== Responsible name ends ============== */}

          {/* ========== Responsible email starts =============== */}
          <div className="form-group mb-4">
            <label>{t('Responsible Email')}</label>
            <input
              type="email"
              className="form-control"
              placeholder={t('Enter Responsible Email')}
              value={responsileEmail}
              onChange={e => {
                setResponsileEmail(e.target.value);
                // setValidation(prevState => ({
                //   ...prevState,
                //   responsileEmailWarning: false,
                // }));
              }}
            />
            {/* responsile Email warning */}
            {/* {validation.responsileEmailWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter responsile email')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ========== Responsible email ends =============== */}

          {/* ============ Responsilbe phone starts ================== */}
          <div className="form-group mb-4">
            <label>{t('Responsible Phone')}</label>
            <input
              type="tel"
              className="form-control"
              placeholder={t('Enter Responsible Phone')}
              value={responsilePhone}
              onChange={e => {
                setResponsilePhone(e.target.value);
                // setValidation(prevState => ({
                //   ...prevState,
                //   responsilePhoneWarning: false,
                // }));
              }}
            />
            {/* responsile Phone warning */}
            {/* {validation.responsilePhoneWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter responsile phone')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ============ Responsilbe phone ends ================== */}

          {/* ============ comment section start ================== */}
          <div className="form-group mb-4">
            <label htmlFor="educatorComments">{t('Educator Comments')}</label>
            <textarea
              name="educatorComments"
              id="educatorComments"
              cols="30"
              rows="4"
              className="form-control"
              placeholder={t('Enter Comments')}
              value={comments}
              onChange={e => {
                setComments(e.target.value);
                // setValidation(prevState => ({
                //   ...prevState,
                //   commentsWarning: false,
                // }));
              }}
            ></textarea>
            {/* comments warning */}
            {/* {validation.commentsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter comments')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ============ comment section end ================== */}

          {/* ============ Label starts ================ */}
          {/* <div className="form-group mb-4">
            <label htmlFor="educators">{t('Labels')}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={labelList}
              value={changeSelect.selectLabels}
              onChange={(val) => labelSelectionHandler(val)}
            />
          </div> */}
          {/* ============ tags ends ================ */}

          {/* ============ Label starts ================ */}
          <div className="form-group mb-4">
            <label htmlFor="educators">{t('Labels')}</label>
            <Select
              isMulti
              className="nosearch_select"
              options={labelList}
              value={changeSelect.selectLabels}
              onChange={val => {
                labelSelectionHandler(val);
                // setValidation(prevState => ({
                //   ...prevState,
                //   participantsWarning: false,
                // }));
              }}
            />
            {/* ParticipantsWarning warning */}
            {/* {validation.participantsWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t('Please enter participants')}!</span>
                </p>
              </div>
            )} */}
          </div>
          {/* ============ tags ends ================ */}

          {/* ============ save button section start ================== */}
          <div className="action d-flex align-items-center gap-2">
            <button type="submit" className="btn btn-primary">
              {t('Save')}
            </button>
          </div>
          {/* ============ save button section end ================== */}
        </form>
      </div>
    </div>
  );
};

export default ReservationSavePopup;
